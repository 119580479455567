import React  from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import {Alert, Row, Col} from 'react-bootstrap';
import {mask_decimal, parseDecimal} from '../../util/inputMask';
import {sessionGet} from '../../usuario/Auth';
import {getDmy,parseUS, firstDateOfMonth, lastDateOfMonth, objectToDate} from '../../util/date';
import { chain } from 'mathjs';
import axios from "axios"
import { CFG, axiosHeader } from "../../CFG"
import { requestException } from "../../util/exception"
import LoadingModal from '../../util/LoadingModal';
import {data2Input} from '../../util/date'

class ExtratoDRE extends React.Component{
  
  constructor(props) {
    super(props);
    
    this.state = {
       filters: {
            filtro_data: 'dt_pagto',
            //inicio: '2021-02-01',
            //fim:'2021-02-28',
            inicio: firstDateOfMonth(),
            fim: lastDateOfMonth()
       },
        empresa_id: sessionGet('empresa'),
        form:[],
        data: [],
        searchTerm: null,
        activeIndex: undefined,
        usuarioHidden: false,
        subitens: [],
        carregando:false
    };

    this.tableRender = tableRender;
    this.getListing = getListing.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    
    }

  componentDidMount(){
    this.updateListing();
  }

  updateListing = (columnsSearch) => {

    let data_up_tostring = '';

    if(Object.getOwnPropertyNames(this.state.filters).length > 0){
        
        let data_up = Object.entries(this.state.filters);

        data_up_tostring = data_up.map(function(e){
            return e[0]+'='+e[1];
        }).join('&');
    } 

    this.getListing('dre/extrato?empresa_id='+this.state.empresa_id+'&'+data_up_tostring, columnsSearch);
    this.setState({subitens:[]})
  }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.filters != this.props.filters){
        
        let REF = this;
        
        this.setState({ filters: {
            ...this.props.filters
        } });

        setTimeout(() => {
            REF.updateListing();              
        }, 1000);
    }

    if(prevState.fetchComplete !== this.state.fetchComplete){
        this.props.setloading(false);
    }
  }

    showSubitens = (centrocustos_id) => {
        const url  = CFG.URL_API ;
        const REF = this;
        REF.setState({carregando:true})
        if(typeof this.state.subitens[centrocustos_id] !== 'undefined'){
            const subitens = REF.state.subitens
            delete subitens[centrocustos_id]
            REF.setState({subitens,carregando:false})
        }else{
            axios.get(url + `/dre/itens-custos?empresa_id=${REF.props.empresa}&centrocustos_id=${centrocustos_id}&inicio=${REF.state.filters.inicio}&fim=${REF.state.filters.fim}
            `, axiosHeader())
            .then(function(response) {
            const {data} = response;
            const subitens = REF.state.subitens
            subitens[centrocustos_id] = data
            REF.setState({subitens,carregando:false})
            })
            .catch(function(error) {
                REF.setState({carregando:false})
                alert("Error:" + error.message)
                //requestException(error)
            })
        }
        
    }

    displayExtrato = () => {

        const displayGroupItems = (items) => {
            return items.map((i) => {
                const textSubtotal = i.total < 0 ? 'text-danger' : 'text-info'
                return (
                <>
                <tr key={i.centrocustos_id}onClick={()=>this.showSubitens(i.centrocustos_id)}>
                    <td className="text-left">
                        {
                            typeof this.state.subitens[i.centrocustos_id] !== 'undefined' ? <i className="fa fa-minus" style={{marginRight:10}}></i> : <i style={{marginRight:10}} className="fa fa-plus"></i>
                        }
                        {`${i.classificacao} ${i.descricao}`}
                    </td>
                    <td className="text-right" tyle={{width:100}}>R$  {mask_decimal(parseFloat(i.total).toFixed(2))}</td>
                    <td className={`${textSubtotal} text-right`}  style={{width:40}}>{i.tipo}</td>
                </tr>
                {
                    typeof this.state.subitens[i.centrocustos_id] !== 'undefined' && 
                        <tr>
                            <td colspan="4">
                                <div style={{maxHeight:400,overflow:'auto',paddingLeft:50}}>
                                    <table className="table" style={{width:'50%'}}>
                                        <tr>
                                            <th>Descricao</th>
                                            <th>Tipo</th>
                                            <th>Emissão</th>
                                            <th>Valor</th>
                                        </tr>
                                        {
                                            this.state.subitens[i.centrocustos_id].map(sb=>{
                                                return <tr>
                                                    <td>{sb.descricao}</td>
                                                    <td style={{width:50}}>{sb.tipo}</td>
                                                    <td>{sb.data.split("-").join("/")}</td>
                                                    <td>R$ {sb.total}</td>
                                                </tr>
                                            })
                                        }
                                    
                                    </table>
                                </div>
                            </td>
                        </tr>
                 }
                </>);
            });
        }

        const displaySubtotal = (items) => {
            let total = 0;

            let tipo_empty = items.find((i) => i.tipo == "");

            if(tipo_empty)
            return (<tr key={0}>
                <td style={{width:150}}>SUBTOTAL</td>
                <td className="text-danger text-right">O tipo da conta com ID {tipo_empty.id} precisa ser C ou D</td>
                <td className="text-right"></td>
            </tr>);

            items.map((i) => {
                switch(i.tipo){
                    case "D":
                        total -= parseFloat(i.total) * -1;
                    break;
                    default:
                        total += parseFloat(i.total);
                    break;
                }
            });

            const textSubtotal = total < 0 ? 'text-danger' : 'text-info'

            return (<tr key={0}>
                <td style={{width:150}}>SUBTOTAL</td>
                <td className={`${textSubtotal} text-right`}  style={{ width: '25%' }}>R$ {mask_decimal(total.toFixed(2))}</td>
                <td className="text-right"></td>
            </tr>);
        }

        if(this.state.data.length > 0){
            return this.state.data.map((entry, dre) => {
                return (  
                <>
                <h5>{entry.indicador}</h5>
                    <table className="table table-striped table-hover">
                        <tbody>
                            {displayGroupItems(entry.items)}
                            {displaySubtotal(entry.items)}
                        </tbody>
                    </table>
                <hr/>
                </>);
            })
        } else {
            if(this.state.fetchComplete)
            return (<Alert variant="warning">Sem dados para exibir</Alert>);
        }
    }

    displayTotal = () => {
        let total = chain(0);
        this.state.data.map((entry, dre) => {
            entry.items.map((i) => {
                switch(i.tipo){
                    case "D":
                        total -= parseFloat(i.total) * -1;
                    break;
                    default:
                        total += parseFloat(i.total);
                    break;
                }
            });
        });

        if(this.state.data.length > 0){
            const textSubtotal = total < 0 ? 'text-danger' : 'text-info'
        return (<div style={{ marginBottom: '10vh'}}><table className="table"><tbody><tr key={0}>
            <td>TOTAL</td>
            <td className="text-right"></td>
            <td className="text-right"></td>
            <td className={`${textSubtotal} text-right`} style={{ width: '30%' }}>R$ {mask_decimal(total.toFixed(2))}</td>
        </tr></tbody></table></div>);
        } else {
            if(this.state.fetchComplete)
            return (<Alert variant="warning">Sem total para exibir</Alert>);
        }
    }


 
    render(){


        return (
            <>
                 <LoadingModal show={this.state.carregando}/>
                {this.displayExtrato()}            
                {this.displayTotal()}    

            </>
            )
        }
    }
        
export default ExtratoDRE;