import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import {sessionGet} from '../../usuario/Auth';

class InatividadeDataTable extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            id: undefined,
            CartaoHistorico_id: '',
            form: {
                id: undefined
            },
            empresa: sessionGet('empresa'),
            data: [],
            fetchComplete: true,
            searchTerm: null,
            activeIndex: undefined,
            filters: {},
            origem: this.props.origem
        };
        this.getCustomToggleList = CustomToggleList.bind(this);
        this.getListing = getListing.bind(this);
        this.tableRender = tableRender;   

    }


    setModalShow = (status, form) => {
            this.props.setModalShow(status, form);
    }

    componentDidMount(){
        this.updateListing();
    }

    componentDidUpdate(prevProps, prevState){

    if(prevState.fetchComplete !== this.state.fetchComplete){
        this.props.setloading(false);
    }
   
    }

    updateListing = (columnsSearch) => {
        this.getListing('relatorio/inatividade-recente?empresa='+this.state.empresa);
    }
    
    getCustomToggleList({
        columns,
        onColumnToggle,
        toggles
    }){
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }
    
    getColumns(){
        return [
            {
                dataField: 'id',
                text: 'Cartão',
                sort: true
            },{
                dataField: 'titular',
                text: 'Titular',
                sort: true
            },{
                dataField: 'saldo_format',
                text: 'Crédito',
                sort: true
            },{
                dataField: 'mes_nome',
                text: 'Mês',
                sort: true
            }
        ];
    }
    
    render(){
        let REF = this;

        let rowClasses = function callback(cell, row, rowIndex, colIndex) { 

            if(cell.cv_cancelado === 1){
                return 'removed';
            }

            if(cell.statusRecarga !== null){
                return 'add';
            } else {
                return 'sub';
            }

        };

        return (
            <div className="table-report mt-4">               
                {
                    (() => REF.tableRender(false, rowClasses))()
                }
            </div>
            )
        }
    }
    
    export default InatividadeDataTable;