import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import {getListing} from '../CustomDataTable'; // Import React
import { isValid, inputChangeHandler, selectChangeHandler } from '../CustomForm';
import { sessionGet } from '../usuario/Auth';
import {Row, Col, Alert, Button} from 'react-bootstrap';
import ProdutoSelect from './ProdutoSelect';

class FormulaForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      produto_id : 0,
      select_current_id: undefined,
      select_current_nome: undefined,
      qtd: undefined,
      prvenda: undefined,
      empresa: sessionGet('empresa'),
      data: [],
      input_list: [],
      item_exists: false,
      commit: false,
      loading: true,
      classList: undefined,
      validator: {}
    };

    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
    this.getListing = getListing.bind(this);

    this._isMounted = false;
    

  }

  

  componentDidUpdate(prevProps){
    let REF = this;

    if(this.props.submit && prevProps.submit !== this.props.submit){
    
      this.post();
    
  }

    if(this.state.produto_id != this.props.produto_id ){
        this.setState({ ...this.props.form, produto_id: this.props.produto_id });
        this.getListing('formula?produto='+this.props.produto_id).then(function(response){
          REF.load_db();
        });
      }

    if(!this.state.commit){
        this.props.formcontrol(this);
        this.setState({ commit: true });
    }

 
  }

  componentWillUnmount() {
    this._isMounted = false;
 }
 

  componentDidMount(){

    this._isMounted = true;
    
    let REF = this;
    this.setState({ commit: false });

  
  

  }

  render(){ 
      
    let REF = this;
  
    let countOrMessage = () => {

      let msg;

              //excluiu dados que vieram do banco
        if(REF.state.input_list.length == 0) 
       msg = 'Selecione da lista';


      if(REF.state.input_list.length > 0 && typeof this.state.produto_ !== "undefined")
      msg = '('+REF.state.input_list.length+')';

      if(REF.state.loading)
      msg = 'Aguarde...';


      return msg;
    };

    return (<>
    { (() => REF.add())() }
    <Row>
    <Col>
      <Alert variant="warning" className={this.state.item_exists ? "d-block" : "d-none"}>
        <strong>{this.state.select_current_nome}</strong> já consta na lista
      </Alert>
    </Col>
    </Row>
    <Row>
      <Col>
    <fieldset className="mt-3">
      <legend>Produto <span className="text-muted">{ countOrMessage() }</span></legend>
          { (() => REF.load_input_list())() }
      </fieldset>
      </Col>
         </Row>
     </>
    );
  }

  input = (key, id, nome, qtd, prvenda) => {
    let REF = this;
    
    return (
    <Row className="my-2" key={key}>
    <Col md="5">
      <label>Produto #{key+1}</label>
        <input className="form-control" disabled type="text" value={nome}/>
      </Col>
      <Col md="3">
        <label htmlFor="qtd">Qtd.</label>
        <input readOnly={true} name="qtd" value={qtd} id="qtd" type="number" onChange={this.inputChangeHandler} className="form-control" />
      </Col>
      <Col md="3">
        <label htmlFor="prvenda">Preço</label>
        <input readOnly={true} name="prvenda" value={prvenda} id="prvenda" type="text" onChange={this.inputChangeHandler} className="form-control" />
      </Col>
      <Col md="1 pt-4 px-0">
          <Button variant="danger" onClick={() => REF.delete(key, id)}><i className="fa fa-close"></i></Button>
        </Col>
      </Row>
   );
  }

  load_db = () => {
    let REF = this;
    let input_list_copy = this.state.input_list;
    var c = input_list_copy.length;

    this.state.data.map(function(p){
      c++;

      input_list_copy.unshift({ 
        key: c, 
        id: p.id, 
        produto: REF.state.produto_id, 
        produto_: p.id, 
        nome: p.nome,
        qtd: p.qtd,
        prvenda: p.prvenda
      });

    });

    this.setState({ input_list: input_list_copy });


    setTimeout(() => REF.setState({ loading: false }) , 1000);

  }

  item_exists = (produto_) => {
    return typeof this.state.input_list.find(function(p) {
      return p.produto_ == produto_;
    }) !== "undefined";
  }

  load_input_list = () => {
    let REF = this;
    let key_count = 0;
    return this.state.input_list.map(function(p){
      let i = REF.input(key_count, p.id, p.nome, p.qtd, p.prvenda);
      key_count++;
      return i;
    });
  }

  onAddChange = (pair, actionMeta) => {

    let item_exists = this.item_exists(pair.value);
  
    this.setState({ item_exists, 
          select_current_id: pair.value, 
          select_current_nome: pair.label,
    });

    if(typeof this.props.formcontrol === "function")
    this.props.formcontrol(this);

  }

  add = () => {
    let REF = this;
    return (
    
    <Row className="my-2" >
        <Col md="5">
          <label>Produto</label>
          <ProdutoSelect produto_id={this.state.select_current_id} change={ (e) => REF.onAddChange(e) }  />
        </Col>
        <Col md="3">
          <label htmlFor="qtd">Qtd.</label>
          <input name="qtd" min="1" value={this.state.qtd} id="qtd" type="number" onChange={this.inputChangeHandler} className="form-control" />
        </Col>
        <Col md="3">
          <label htmlFor="prvenda">Preço</label>
          <input name="prvenda" value={this.state.prvenda} id="prvenda" type="text" onChange={this.inputChangeHandler} className="form-control" />
        </Col>
        <Col md="1 pt-4 px-0">
            <Button variant="secondary" onClick={(e) => REF.create(e)}><i className="fa fa-plus"></i></Button>
        </Col>
        </Row>
    )
  }

  create = (e) => {

    let item_exists = this.item_exists(this.state.select_current_id);

    if(item_exists || this.state.qtd == 0){
      return;
    }

    let list_copy = this.state.input_list;
   
    list_copy.unshift({ 
      key: list_copy.length,
      produto: this.state.produto_id, 
      produto_: this.state.select_current_id, 
      nome: this.state.select_current_nome,
      qtd: this.state.qtd,
      prvenda: this.state.prvenda
     });

    this.setState({ 
      key: 0,
      select_current_id: 0,
      select_current_nome: "",
      qtd: "",
      prvenda: "",
      input_list :  list_copy
     });

      
  }

   post = () => {

    let REF = this;

    if(this.state.input_list.length === 0)
    return REF.props.submit_confirm('FormulaForm');

    return this.state.input_list.map(function(p){
      axios.post(CFG.URL_API+'/formula', p, axiosHeader())
        .then(function(response){
          //Perform action based on response
      })
      .catch(function(error){
      requestException(error);
    }).finally(() => REF.props.submit_confirm('FormulaForm'));
      
    })
  
   }

  delete = (key, id) => {
    let data_copy = this.state.input_list;
    data_copy.splice(key,1);
    this.setState({ item_exists:false, input_list : data_copy });
   
    if(typeof id === "undefined")
    return;

    axios.delete(CFG.URL_API+'/formula/'+id, axiosHeader())
        .then(function(response){
          //Perform action based on response
      })
      .catch(function(error){
      requestException(error);
    });
      
  }

}

export default FormulaForm;
