import React from 'react';
import CentroCustoDataTable from './CentroCustoDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import CentroCustoModal from './CentroCustoModal';

class CentrosCusto extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      show: false,
      activeIndex: 0,
      refresh: false
    };

    this.props.titlebar(this.props.title, this.props.description);

    this.setModalShow = this.setModalShow.bind(this);
  }

  setModalShow (status, data){
      let refresh = !status;
      let up_data = status === false ? [] : data; 
      this.setState({  show: status, data, refresh });
  }

  componentDidMount(){
    window.checkUserCan('financeiro');
  }


  render (){
    let REF = this;
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => this.setModalShow(true)}>
                  <span className="fa fa-plus-square"></span>  Cadastrar centro de custo
                </Button>              
              <CentroCustoModal
                form={{}}
                show={this.state.show}             
                onHide={() => REF.setModalShow(false)}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <CentroCustoDataTable refresh={this.state.refresh} setModalShow={this.setModalShow}/>
      </div>);
    }
}
  
export default CentrosCusto;