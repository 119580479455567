import React from 'react';
import MovCabDataTable from './MovCabDataTable'; 
import {ButtonToolbar} from 'react-bootstrap';
import MovCabModal from './MovCabModal';

class Movimentacoes extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
        loading: false,
      refresh: false,
      show: false,
      form: {}
    };

  }

  updateLoading = (status) => {
    this.setState({ loading: status  });
}
   

  setModalShow  = (status, data) => {
      this.setState({  show: status , form: data, refresh: !status });
 }

  componentDidMount(){
    this.props.titlebar(this.props.title, this.props.description);
  }
  
  render (){
    let REF = this;
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
              <MovCabModal
                form={this.state.form}
                show={this.state.show}              
                onHide={() => REF.setModalShow(false, [])}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <MovCabDataTable setloading={this.updateLoading} hidden_column={"qtd,total"} refresh={REF.state.refresh} setModalShow={REF.setModalShow} recent={true}/>
      </div>);
    }
  }
  
  export default Movimentacoes;