import React from 'react';
import ProdutosDataTable from './ProdutosDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import ProdutoModal from './ProdutoModal';

class Produtos extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      show: false,
      form: {
        id: undefined
      }
    };

  }

  setModalShow  = (status, data) => {
      this.setState({  show: status , form: data, refresh: !status });
 }

  componentDidMount(){
    this.props.titlebar(this.props.title, this.props.description);
  }
  
  render (){
    let REF = this;
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => REF.setModalShow(true)}>
                <span className="fa fa-plus-square"></span>  Cadastrar produto
                </Button>              
              <ProdutoModal
                form={this.state.form}
                show={this.state.show}              
                onHide={() => REF.setModalShow(false)}
              />
            </ButtonToolbar>            
          </div>
        </div>  
          <ProdutosDataTable hidden_column={"qtd,total"} refresh={REF.state.refresh} setModalShow={REF.setModalShow}/>
      </div>);
    }
  }
  
  export default Produtos;