import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import { inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import {sessionGet} from '../usuario/Auth';

class GrupoProdutoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      empresa: sessionGet('empresa'),
      grupo: '',
      vias: undefined,
      terminal: undefined,
      validator: {},
      show: false
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
  }

  componentDidMount(){
    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });
      console.log(this.state);

      this.props.modalcontrol(this);
    }
  }

  getId(){
    return this.state.id;
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.show != this.props.show){
     this.setState({ ...this.props.form });
     this.setState({ show: this.props.show });

     this.props.modalcontrol(this);
   }
  }

  render() {
    return (
    <form>
      <input type="hidden" name="empresa" defaultValue={this.state.empresa}/>
      <div className="row">  
      <div className="form-group col-md-3">         
          <label htmlFor="f-codigo required">
            ID
          </label>
          <input readOnly className="form-control " name="id" id="f-codigo" type="text" value={this.state.id}  />
        </div>      
        <div className="form-group col-md-9">         
          <label htmlFor="f-grupo required">
            Grupo
          </label>
          <input className="form-control" name="grupo" id="f-grupo" type="text" defaultValue={this.state.grupo} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div> 

        <div className="form-group col-md-3">         
          <label>
            Liberar no terminal
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="terminal" className="custom-control-input" id="f-terminal-sim" type="radio" value="S" checked={this.state.terminal == "S"} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-terminal-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="terminal" className="custom-control-input" id="f-terminal-nao" type="radio" value="N" checked={this.state.terminal == "N"} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-terminal-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div>  

      </div>
    </form>
    );
  }

  get(){
    axios.get(CFG.URL_API+'/grupo-produto/'+this.state.id, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   post(){
    
    let post_data = this.state;
    delete post_data.id;
    axios.post(CFG.URL_API+'/grupo-produto', post_data, axiosHeader())
    .then(function(response){
        window.location.reload();
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   put(){

    axios.put(CFG.URL_API+'/grupo-produto/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   delete(){

    axios.delete(CFG.URL_API+'/grupo-produto/'+this.state.id, axiosHeader())
    .then(function(response){
      window.location.reload();
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
}
export default GrupoProdutoForm;