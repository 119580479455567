import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import {Row, Col} from 'react-bootstrap';
import { inputChangeHandler,inputBlurHandler } from '../CustomForm';
import IconeThumbnail from './IconeThumbnail';
import IconeCategoriaSelect from './IconeCategoriaSelect'; 
import LoadingModal from '../util/LoadingModal';

class IconeSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            list: [],
            icone_select_id: this.props.icone_select_id,
            icone_select_url: this.props.icone_select_url,
            categoria: '',
            loading: false,
            validator: {}
        };
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.inputBlurHandler = inputBlurHandler.bind(this);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
        this.get = this.get.bind(this);
        
    }



    componentDidUpdate(prevProps, prevState){
     
        if(prevState.icone_select_id != this.props.icone_select_id){
            this.setState({ icone_select_id : this.props.icone_select_id, icone_select_url : this.props.icone_select_url });
            this.props.currenticon(this.props.icone_select_id, this.props.icone_select_url);
        }
        

        if(prevProps.categoria != this.props.categoria){
            this.setState({ categoria : this.props.categoria });
    
            this.get();
        }            
            

  }

  selectChangeHandler(event){
    this.setState({ categoria: event.target.value });
    let IS = this;
    this.setState({ loading: true });
  
    setTimeout(() => IS.get(), 500);
  }
  
    render(){
        let IST = this;
            return (
                <div className="IconeSelect">
                <Row>
                    <Col>
                    <div className="form-group col-md-6">
                    <label className="text-uppercase">Categoria</label>
                    <IconeCategoriaSelect categoria={this.state.categoria} change={this.selectChangeHandler} />
                </div> 
                    </Col>
                </Row>
                    <LoadingModal show={this.state.loading} />
                <Row>
                    {
                        this.state.list.map(function(val){
                        return <IconeThumbnail radio={true} className="col-6 col-md-2" id={val[0]} key={val[0]} url={val[1]} icone_select_id={IST.props.icone_select_id} currenticon={IST.props.currenticon}/>     
                    })}
               </Row>
               </div>
            );
        
     
    }

    get(){
        let CS = this; 

        axios.get(CFG.URL_API+'/icone?categoria='+this.state.categoria+'&selectMode=pluck&pluckKey=id&pluckValue=icone', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);

            let htmlList = entries;
            
            CS.setState({ list: htmlList });

            CS.setState({ loading: false });

          //Perform action based on response
      })
        .catch(function(error){
            CS.setState({ loading: false });
      requestException(error);
 });
    }

}

export default IconeSelect;
