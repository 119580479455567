import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import { inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import {sessionGet} from '../usuario/Auth';

class GrupoFornecedorForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      empresa_id: sessionGet('empresa'),
      descricao: '',
      validator: {},
      show: false
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
  }

  componentDidMount(){

    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });

      this.props.modalcontrol(this);
    }
    
  }

  getId(){
    return this.state.id;
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.show != this.props.show){
     this.setState({ ...this.props.form });
     this.setState({ show: this.props.show });

     this.props.modalcontrol(this);
   }
  }

  render() {
    return (
    <form>
      <input type="hidden" name="empresa" defaultValue={this.state.empresa_id}/>
      <div className="row">  
      <div className="form-group col-md-3">         
          <label htmlFor="f-codigo required">
            ID
          </label>
          <input readOnly className="form-control " name="id" id="f-codigo" type="text" value={this.state.id}  />
        </div>      
        <div className="form-group col-md-9">         
          <label htmlFor="f-grupo required">
            Grupo
          </label>
          <input className="form-control" name="descricao" id="f-descricao" type="text" defaultValue={this.state.descricao} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div> 

      </div>
    </form>
    );
  }

  get(){
    axios.get(CFG.URL_API+'/grupo-fornecedor/'+this.state.id, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   post(){
    
    let post_data = this.state;
    delete post_data.id;
    axios.post(CFG.URL_API+'/grupo-fornecedor', post_data, axiosHeader())
    .then(function(response){
        window.location.reload();
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   put(){

    axios.put(CFG.URL_API+'/grupo-fornecedor/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   delete(){

    axios.delete(CFG.URL_API+'/grupo-fornecedor/'+this.state.id, axiosHeader())
    .then(function(response){
      window.location.reload();
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
}
export default GrupoFornecedorForm;