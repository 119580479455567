import React from 'react';
import axios from 'axios';
import {axiosHeader, CFG} from '../CFG';
import { checkRequired, inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler, radioSwitchHandler, selectChangeHandler } from '../CustomForm';
import CategoriaSelect from '../produto-categoria/CategoriaSelect'; 
import UnidadeSelect from '../produto/UnidadeSelect'; 
import IconeThumbnail from '../icone/IconeThumbnail'; 
import { sessionGet } from '../usuario/Auth';
import {mask_decimal, parseDecimal} from '../util/inputMask';

class ProdutoForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      icone_select_id: '',
      icone_select_url: '',
      validator: {
        ean: true,
        categoria:true
      },
      show: false,
      id: undefined,
      empresa: sessionGet('empresa'),
      icone: 0,
      grupo: 0,
      subgrupo: 0,
      categoria: 0,
      unique: '',
      sittrib: '',
      ncm: 0,
      produto: '',
      detalhes: '',
      unidade: 'UN',
      estoque: 0,
      alterado: '',
      terminal: 'N',
      ativo: 'S',
      excluido: '',
      promocional: '',
      vias: 'N',
      materiaprima: '',
      producao: '',
      cst: 0,
      cfop: 0,
      icms: 0,
      mva: 0,
      ipi: 0,
      cofins: 0,
      icms_st: 0,
      cfop_entrada: 0,
      pis: 0,
      ean: '',
      tara: '',
      estoquefundo: '',
      estoqueperda: '',
      estoqueproduto: '',
      estoqueminino : 0,
      custo : 0,
      desconto : 0,
      preco : 0
    };

    
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.radioSwitchHandler = radioSwitchHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
    this.setCurrentIcon = this.setCurrentIcon.bind(this);
    this.queryIconeUrl = this.queryIconeUrl.bind(this);
    this.checkRequired = checkRequired.bind(this);

  }

  componentDidMount(){

    if(this.state.produto === ""){
      let validator = this.state.validator;
      validator.produto = true;
      this.setState({ validator });
    }

  }


  queryIconeUrl(icone_id){

    let PF = this;
    axios.get(CFG.URL_API+'/icone/'+icone_id, axiosHeader())
    .then(function(response){

      PF.setCurrentIcon( response.data.id, response.data.icone );

      //Perform action based on response
    })
    .catch(function(error){
      //Perform action based on error
    });
   }

  setCurrentIcon(id, url) {
    this.setState({ icone_select_id: id, icone_select_url: url });
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.state.show !== this.props.show){
      
      let ean_invalid = typeof this.props.form !== "undefined" 
      && (typeof this.props.form.id === "undefined" 
        || (typeof this.props.form.id !== "undefined" && this.props.form.ean === '')
      );
      let target = document.getElementById("f-ean");

    
      
      let form_spread = this.props.form;
      //evitamos unique restriction;
      if(typeof form_spread !== "undefined")
      delete form_spread.barras;
    this.setState({  ...form_spread, 
      icone_select_url: this.props.icone_url,
   
      show: this.props.show });      
    
      let enable_formula = typeof this.props.form !== "undefined" && typeof this.props.form.id !== "undefined" && this.props.form.producao == 1;
      this.props.toggleformula(enable_formula);
 
      this.props.formcontrol(this);
     }



    if(this.props.icone_select_id != '' && this.state.icone_select_id != this.props.icone_select_id){
      this.setState({ icone: this.props.icone_select_id, icone_select_id : this.props.icone_select_id, icone_select_url : this.props.icone_select_url });
     // this.props.currenticon(this.props.icone_select_id, this.props.icone_select_url);
    }

    if(prevState.icone_url != this.state.icone_url){
      this.setState({ icone_select_url: this.state.icone_url  });
    }

    if(this.props.submit && prevProps.submit !== this.props.submit){
      if(this.state.categoria === 0){
        let validator = this.state.validator
        validator.categoria = false
        this.setState({validator})
      }
      this.isValid()
      console.log("validacao",this.state.validator)
      if(typeof this.state.id !== "undefined"){
        this.put();
      } else {
        this.post();
      }
    }

    if(this.props.delete && prevProps.delete !== this.props.delete){
      this.delete();
    }

  }

  eanChangeHandler = (e) => {

    e.persist();

    let REF = this;

    if(e.target.value === ""){
      e.target.classList.add('is-valid');
      e.target.classList.remove('is-invalid');
      REF.setState({ validator: {
        ean : true
      }});
      return;
    }


    setTimeout(() => {

      axios.post(CFG.URL_API+'/produto/check-codigo-ean/',
      {
        id: this.state.id,
        ean: this.state.ean,
        empresa: this.state.empresa
      }, axiosHeader())
      .then((response) => {

        let ean = true;
  
        if(response.data){     
          e.target.classList.add('is-valid');
          e.target.classList.remove('is-invalid');
        } 

        if(!response.data){
          ean = false;
          e.target.classList.add('is-invalid');
          e.target.classList.remove('is-valid');
        }

        REF.setState({ validator: {
          ean 
        }});
  
      });

    }, 500);
  
    if(typeof e !== "undefined")
    this.inputChangeHandler(e); 
  
  }

  render(){

    let REF = this;

    return (
      <div>
        <div className="row">   
        <IconeThumbnail radio={false} className="form-group col-md-3" id={this.state.icone_select_id} key={this.state.icone_select_id} url={this.state.icone_select_url} />                         
        <div className="form-group col-md-6">         
          <label htmlFor="f-produto required">
            Nome do produto
          </label>
          <input required className="form-control text-uppercase" name="produto" id="f-produto" type="text" value={this.state.produto} onChange={this.inputChangeHandler}/>
        </div>

        <div className="form-group col-md-3 col-6">         
          <label>
            Ativo no terminal
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="terminal" className="custom-control-input" id="f-terminal-sim" type="radio" value="S" checked={this.state.terminal == "S" || this.state.terminal == ""} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-terminal-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="terminal" className="custom-control-input" id="f-terminal-nao" type="radio" value="N" checked={this.state.terminal == "N"} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-terminal-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div> 

        
        </div>   

        <div className="row mt-2">

        <div className="form-group col-6 col-md-3">         
          <label>
            Produto promocional
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="promocional" className="custom-control-input" id="f-promocional-sim" type="radio" value="S" checked={this.state.promocional == "S"} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-promocional-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="promocional" className="custom-control-input" id="f-promocional-nao" type="radio" value="N" checked={this.state.promocional == "N" || this.state.promocional == ''} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-promocional-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div> 
<div className="form-group col-md-3 col-6">         
          <label>
            Matéria prima
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="materiaprima" className="custom-control-input" id="f-materiaprima-sim" type="radio" value="1" checked={this.state.materiaprima.toString() === "1"} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-materiaprima-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="materiaprima" className="custom-control-input" id="f-materiaprima-nao" type="radio" value="0" checked={this.state.materiaprima.toString() === "0" || this.state.materiaprima === "" || this.state.materiaprima === null} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-materiaprima-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div> 
<div className="form-group col-md-3 col-6">         
          <label>
            Produção própria
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="producao" className="custom-control-input" id="f-producao-sim" type="radio" value="1" checked={this.state.producao == 1} onClick={() => REF.props.toggleformula(true)} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-producao-sim">
                      Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="producao" className="custom-control-input" id="f-producao-nao" type="radio" value="0" checked={this.state.producao == 0} onClick={() => REF.props.toggleformula(false)} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-producao-nao">
                      Não    </label>
                  </div>
                </li> 
            </ul>
        </div> 



            <div className="form-group col-md-3 col-6">         
          <label>
            Produto ativo
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="ativo" className="custom-control-input" id="f-ativo-sim" type="radio" value="S" checked={this.state.ativo == "S"} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-ativo-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="ativo" className="custom-control-input" id="f-ativo-nao" type="radio" value="N" checked={this.state.ativo == "N" || this.state.ativo == ""} onChange={this.inputChangeHandler}/>
                  <label className="custom-control-label" htmlFor="f-ativo-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div> 
        </div>


        <div className="row">
        <div className="form-group col-12 col-md-3">         
          <label>
            Imprimir duas vias
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="vias" className="custom-control-input" id="f-vias-sim" type="radio" value="S" checked={this.state.vias == "S"} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-vias-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="vias" className="custom-control-input" id="f-vias-nao" type="radio" value="N" checked={this.state.vias == "N" || this.state.vias == ""} onChange={this.radioSwitchHandler}/>
                  <label className="custom-control-label" htmlFor="f-vias-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div>  

        <div className="form-group col-md-9">
            <label className="text-uppercase">Categoria</label>
            <CategoriaSelect validate={
              (isValid)=>{
                let validator = this.state.validator
                validator.categoria = isValid
                this.setState({validator})
              }
            }error={!this.state.validator.categoria} categoria={this.state.categoria} change={this.selectChangeHandler} />
          </div> 

 
        </div>

        <div className="row">

   

        <div className="col-md-12">
        <label htmlFor="f-detalhes required">
            Descrição do produto
          </label>
          <textarea className="form-control" name="detalhes" id="f-detalhes" onChange={this.inputChangeHandler}value={this.state.detalhes} />
        </div>
        </div>

        <div className="row">
        <div className="col-md-3">
          <label htmlFor="f-custo required">
            Preço de custo
          </label>
          <input className="form-control" name="custo" id="f-custo" type="text" value={this.state.custo} onChange={this.inputChangeHandler}/>
        </div>
        <div className="col-md-3">
        <label htmlFor="f-preco required">
            Preço de venda
          </label>
          <input className="form-control" name="preco" id="f-preco" type="text" value={this.state.preco} onChange={this.inputChangeHandler}/>
        </div>
        <div className="col-md-3">
        <label htmlFor="f-estoqueminino required">
            Desconto
          </label>
          <input className="form-control" name="desconto" id="f-desconto" type="text" value={this.state.desconto} onChange={this.inputChangeHandler}/>
        </div>
        <div className="col-md-3">
        <label htmlFor="f-estoqueminino required">
            Estoque mínimo
          </label>
          <input className="form-control" name="estoqueminino" id="f-estoqueminino" type="text" value={this.state.estoqueminino} onChange={this.inputChangeHandler}/>
        </div>
        
     
        </div>

        <div className="row">
        <div className="col-md-3">
        <label className="text-uppercase" htmlFor="f-unidade">Unidade</label>
            <UnidadeSelect required unidade={this.state.unidade} change={this.selectChangeHandler}/>
        </div>
        <div className="col-md-5">
        <label htmlFor="f-codigo required">
           Código manual
          </label>
          <input className="form-control" name="codigo" id="f-codigo" type="text" value={this.state.codigo} onChange={this.inputChangeHandler}/>
        </div>
        <div className="col-md-4">
        <label htmlFor="f-ean required">
           Código EAN
          </label>
          <input className="form-control" name="ean" id="f-ean" type="text" value={this.state.ean} onChange={this.eanChangeHandler} />
        </div>
        </div>

        <h5 className="mt-4 text-info text-uppercase"><strong>Códigos fiscais</strong></h5>
        <div className="row">
          <div className="col-md-3">
          <label htmlFor="f-cst required">
            CST/CSOSN
            </label>
            <input className="form-control" name="cst" id="f-cst" type="text" value={this.state.cst} onChange={this.inputChangeHandler}/>
          </div>
          <div className="col-md-3">
          <label htmlFor="f-ncm required">
            Código NCM
            </label>
            <input className="form-control" name="ncm" id="f-ncm" type="text" value={this.state.ncm} onChange={this.inputChangeHandler}/>
          </div>
          <div className="col-md-3">
          <label htmlFor="f-cfop required">
            Código CFOP Saída
            </label>
            <input className="form-control" name="cfop" id="f-cfop" type="text" value={this.state.cfop} onChange={this.inputChangeHandler}/>
          </div>
          <div className="col-md-3">
          <label htmlFor="f-cfop_entrada required">
            Código CFOP Entrada
            </label>
            <input className="form-control" name="cfop_entrada" id="f-cfop_entrada" type="text" value={this.state.cfop_entrada} onChange={this.inputChangeHandler}/>
          </div>
        </div>
        
        <h5 className="mt-4 text-info text-uppercase"><strong>Alíquotas de imposto</strong></h5>
        <div className="row">
          <div className="col-md-3">
          <label htmlFor="f-icms required">
              ICMS
            </label>
            <input className="form-control" name="icms" id="f-icms" type="text" value={mask_decimal(this.state.icms)} onChange={this.inputChangeHandler}/>
          </div>
          <div className="col-md-3">
          <label htmlFor="f-ipi required">
            IPI
            </label>
            <input className="form-control" name="ipi" id="f-ipi" type="text" value={mask_decimal(this.state.ipi)} onChange={this.inputChangeHandler}/>
          </div>
          <div className="col-md-3">
          <label htmlFor="f-icms_st required">
            ICMS ST
            </label>
            <input className="form-control" name="icms_st" id="f-icms_st" type="text" value={mask_decimal(this.state.icms_st)} onChange={this.inputChangeHandler}/>
          </div>
  
    <div className="col-md-3">
          <label htmlFor="f-mva required">
            MVA
            </label>
            <input className="form-control" name="mva" id="f-mva" type="text" value={mask_decimal(this.state.mva)} onChange={this.inputChangeHandler}/>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
          <label htmlFor="f-cofins required">
              COFINS
            </label>
            <input className="form-control" name="cofins" id="f-cofins" type="text" value={mask_decimal(this.state.cofins)} onChange={this.inputChangeHandler}/>
          </div>
          <div className="col-md-3">
          <label htmlFor="f-pis required">
            PIS
            </label>
            <input className="form-control" name="pis" id="f-pis" type="text" value={mask_decimal(this.state.pis)} onChange={this.inputChangeHandler}/>
          </div>
  
        </div>

      </div>
    );
  }

  get(){
    axios.get(CFG.URL_API+'/produto/'+this.state.id, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   getFormattedState = () => {
    let formData = new FormData();
    let entries = Object.entries(this.state);
    let money_list = ["preco", "custo","desconto", "estoqueminino", "icms", "ipi", "icms_st", "mva", "cofins", "pis"];
    entries.map(([property,value], k) => {
      let v_format = value; 
      if(money_list.includes(property)){
        v_format = parseDecimal(v_format);
        console.log(v_format);
      }
      formData.set(property, v_format);
    });

    let object = {};
    formData.forEach((value, key) => {
      if(typeof value !== "undefined")
      object[key] = value;
    });

    object.produto = object.produto.toString().toUpperCase();

    if(object.codigo === "null" || object.codigo === "")
    object.codigo = null;

    if(object.ean === "null" || object.ean === "")
    object.ean = null;

    return object;
  }

   post = () => {
    let check_req = this.checkRequired('na aba "Dados" do produto');

    if(!check_req || !this.isValid()){
      this.props.loading(false);
      return 
    }

    let REF = this;
    let post_data = this.getFormattedState();
    delete post_data.id;
    if(post_data.icone == null){
      post_data.icone = 1;
    }
    axios.post(CFG.URL_API+'/produto', post_data, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.submit_confirm('ProdutoForm'));
   }

   put = () => {

    let check_req = this.checkRequired('na aba "Dados" do produto');

    if(!check_req || !this.isValid()){
      this.props.loading(false);
      return 
    }


     let REF = this;
    let post_data = this.getFormattedState();
    if(post_data.icone == null){
      post_data.icone = 1;
    }
    axios.put(CFG.URL_API+'/produto/'+this.state.id, post_data, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.submit_confirm('ProdutoForm'));
   }

   delete = () => {
    let REF = this;
    axios.delete(CFG.URL_API+'/produto/'+this.state.id, axiosHeader())
    .then(function(response){
       
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() =>  REF.props.close());
   }
}

export default ProdutoForm;
