import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import { inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import GrupoProdutoSelect from '../grupo-produto/GrupoProdutoSelect'
import {sessionGet} from '../usuario/Auth';

class SubgrupoProdutoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      empresa: sessionGet('empresa'),
      subgrupo: '',
      grupo: '',
      vias: undefined,
      terminal: undefined,
      validator: {},
      show: false
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
  }
  getId(){
    return this.state.id;
  }
  componentDidUpdate(prevProps, prevState) {

    if(prevState.show != this.props.show){
     this.setState({ ...this.props.form });
     this.setState({ show: this.props.show });

     this.props.modalcontrol(this);
   }
  }
  render() {
    return (
    <form>
      <input type="hidden" name="empresa" defaultValue={this.state.empresa}/>
      <div className="row">     

        <div className="form-group col-md-5">
            <label className="text-uppercase">Grupo</label>
            <GrupoProdutoSelect grupo={this.state.grupo} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
          </div> 
          </div> 
          <div className="row"> 

        <div className="form-group col-md-5">         
          <label htmlFor="f-grupo required">
            Subgrupo
          </label>
          <input className="form-control" name="subgrupo" id="f-subgrupo" type="text" defaultValue={this.state.subgrupo} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div> 
        </div> 
   
    </form>
    );
  }
  get(){
    axios.get(CFG.URL_API+'/subgrupo-produto/'+this.state.id, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   post(){
    
    let post_data = this.state;
    delete post_data.id;
    axios.post(CFG.URL_API+'/subgrupo-produto', post_data, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   put(){

    axios.put(CFG.URL_API+'/subgrupo-produto/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   delete(){

    axios.delete(CFG.URL_API+'/subgrupo-produto/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
}
export default SubgrupoProdutoForm;