import React from 'react';

function Error404 (props){

    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 text-center"> 
            <h1 className="text-danger">404</h1>
            <p>Não encontramos o recurso solicitado</p>          
          </div>
        </div>      
      </div>);
    
}
  
export default Error404;