import React from 'react';
import axios from 'axios';
import { axiosHeader, CFG } from '../CFG';
import SimpleCrypto from "simple-crypto-js";

export function AuthCheck(){
    
    if(sessionGet('key') === null)
    return logout(true);

    updateSessao();
    this.setState({ session: true });
}

export function getUser(){
    let REF = this;
    return axios.get(CFG.URL_API+'/usuario?login='+sessionGet('usuario')+'&empresa='+sessionGet('empresa'), axiosHeader())
    .then(function(response){       
        REF.setState({ usuario: response.data[0] });
    });   
}

export const permissionLabels = {
        cartoes: 'Gerenciar cartões',
        categorias: 'Gerenciar categorias',
        fornecedores: 'Gerenciar fornecedores',
        estoque: 'Gerenciar estoque',
        rel_extrato_vendas: 'Relatório: extrato das vendas',
        rel_sangria_suprimento: 'Relatório: sangria e suprimento',
        rel_pagamentos: 'Relatório: contas a pagar',
        rel_recargas: 'Relatório: recargas',
        rel_extrato_cartoes: 'Relatório: extrato dos cartões',
        rel_segunda_via: 'Relatório: segunda via de cartões',
        rel_saldo_cartoes: 'Relatório: saldo dos cartões',
        rel_fechamento: 'Relatório: fechamento',
        rel_extrato_tef: 'Relatório: Extrato TEF',
        rel_produtos_vendidos: 'Relatório: produtos vendidos',
        rel_sedex: 'Relatório: Sedex',
        senha_cartoes: 'Alterar senhas de cartões',
        segunda_via: 'Expedir segunda via de cartão',
        status_format: 'Gerenciar Formato de Status',
        financeiro: 'Financeiro',
        cancelar_cupom: 'Cancelar cupom (mov.)',
        usuario: 'Gerenciar usuário (2)',
        subgrupos: 'Gerenciar Subgrupos',
        vincular_cartao: 'Vincular cartão ao resp.',
        cadastrar_responsavel: 'Cadastrar responsável',
        grupos: 'Gerenciar grupos',
        produtos: 'Gerenciar produtos',
        recarregar_cartao: 'Recarregar cartões',
        usuarios: 'Gerenciar usuários (1)',
        grade: 'Gerenciar Grade de produtos',
        info_sangrias: 'Gerenciar motivos de sangrias'
}

export function serverCheck(){
    let [empresa, usuario, senha] = atob(sessionGet('key')).split("||");
  
    let REF = this;

    return axios.post(CFG.URL_API+'/usuario/check', {
        empresa: empresa, 
        login: usuario,
        senha: senha
     }, axiosHeader())
    .then(function(response){
        updateSessao();
        REF.setState({ session: response.data.success });
    })
    .catch(function(error){
      //Perform action based on error
        logout(true);
    });
}

export function sessionRemove(keys){
    if(Array.isArray(keys)){
        for(let key of keys){
            localStorage.removeItem(CFG.SESSION_PREFIX+'.'+key);
        }
    } else {
        localStorage.removeItem(CFG.SESSION_PREFIX+'.'+keys);
    }
}

export function sessionGet(key){
    return localStorage.getItem(CFG.SESSION_PREFIX+'.'+key);
}

export function sessionSet(key,value){
    localStorage.setItem(CFG.SESSION_PREFIX+'.'+key, value);
}

export function generateKey(empresa, usuario, senha){
    return btoa(empresa+'||'+usuario+'||'+senha);
}

export function updateEmpresa(grupo, cnpj, empresa, razao){
    
    if(grupo !== null && grupo !== "")
    sessionSet('grupo', grupo);

    sessionSet('cnpj', cnpj);
    sessionSet('empresa', empresa);
    sessionSet('empresa_razao', razao);
}

export function updateUsuario(empresa,usuario,senha){
    sessionSet('usuario', usuario);
    sessionSet('key', generateKey(empresa,usuario,senha));
}

export function startSessao(grupo, cnpj, empresa,razao,usuario,senha,location){

    updateEmpresa(grupo, cnpj, empresa,razao);
    updateUsuario(empresa,usuario,senha);
    let simpleCrypto = new SimpleCrypto(CFG.SESSION_PREFIX);
    
    setTimeout(() => {
        getUserPermissions().then((res) => {
            sessionSet('permissions', simpleCrypto.encrypt(res));
            sessionSet('last_activity', Date.now());
    
            if(location != undefined)
            setTimeout(() => window.location.href=location, 750);
        });
    }, 1000);   
}

export function updateSessao(){

    if(sessionGet('last_activity') == null)
    return logout(true);

    let diff = Math.abs(new Date() - new Date(sessionGet('last_activity')));

    //15 minutos
    if(diff > (15 * 60000) || diff <= 0){
        removeAllSessionKeys();
        return logout(true);
    } else {
        sessionSet('last_activity', Date.now());
    }
}

export  function requestPermission (user_id) {
    return axios.get(CFG.URL_API+'/usuario-permissao/'+user_id)
     .then( (response) => {

        if(typeof response.data.id === "undefined"){
            return requestPermission(1);
        }
        
         return {
             ...response.data
         };
     });
 }

export function getUserPermissions(){

    return axios.get(CFG.URL_API+'/usuario-id?empresa='+sessionGet('empresa')+'&login='+sessionGet('usuario'))
    .then(async (response) => {
        let user_id = 1;
        if(response.data.id !== "undefined")
        user_id = response.data.id;

    return requestPermission(user_id);

    });

}

export function removeAllSessionKeys(){
    sessionRemove(['key','permissions','email','grupo','usuario','senha','last_activity']);
}


export function logout(msg = false){
        document.body.innerHTML = "";
        removeAllSessionKeys();
   //     if(msg)
       // window.alert('Sessão expirada. Efetue o login novamente.');
        window.location.href=CFG.URL_APP+'/login';
}


export function logoutConfirm(){
    if(window.confirm("Tem certeza de que deseja sair?")){
        removeAllSessionKeys();
        window.location.href=CFG.URL_APP+'/login';  
      }
}

export default AuthCheck;