import React from "react"
import axios from "axios"
import { sessionGet } from "../usuario/Auth"
import { requestException } from "../util/exception"
import { CFG, axiosHeader } from "../CFG"
import { Alert } from "react-bootstrap"
import Select from "react-select"

class ProdutoSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            empresa: sessionGet("empresa"),
            list: [],
            produto: 0,
            validator: {},
            value: null
        }

        this.inputChangeHandler = this.inputChangeHandler.bind(this)
    }

    componentDidMount() {
        this.get()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.empresa != this.props.empresa) this.setState({ empresa: this.props.empresa })

        if (prevState.produto != this.props.produto_id)
            this.setState({ produto: this.props.produto_id })

        if (prevState.list != this.state.list) {
            let REF = this
            let find = REF.state.list.find(([value, text]) => {
                return value == REF.state.produto
            })
            if (typeof find !== "undefined") {
                let value = {
                    value: find[0],
                    label: "( " + find[0] + " )".padEnd(10, " ") + find[1]
                }
                setTimeout(() => REF.setState({ value }), 300)
            }
        }
    }

    inputChangeHandler(data, event) {
        this.setState({ value: data })
        this.props.change(event.name, data.value)
    }

    render() {
        if (!this.state.loading && this.state.list.length == 1)
            return <Alert variant="warning">Lista vazia</Alert>

        let REF = this

        return (
            <>
                <Select
                    noOptionsMessage={() =>
                        REF.state.loading
                            ? "Carregando opções..."
                            : "Lamento. Não há opções disponíveis"
                    }
                    isDisabled={this.props.readonly}
                    name="produto"
                    value={REF.state.value}
                    isClearable={true}
                    placeholder="pesquisar e selecionar"
                    id="f-produto"
                    onChange={this.inputChangeHandler}
                    options={this.state.list.map(([value, text]) => {
                        return { value, label: "( " + value + " )".padEnd(10, " ") + text }
                    })}
                />
            </>
        )
    }

    get = () => {
        let CS = this

        axios
            .get(
                CFG.URL_API +
                    "/produto?empresa=" +
                    this.state.empresa +
                    "&selectMode=pluck&pluckKey=id&pluckValue=produto",
                axiosHeader()
            )
            .then(function(response) {
                let entries = Object.entries(response.data)

                let htmlList = []

                htmlList = entries.sort(function(a, b) {
                    return a[1] > b[1] ? 1 : -1
                })

                CS.setState({ list: htmlList, loading: false })
            })
            .catch(function(error) {
                requestException(error)
            })
    }
}

export default ProdutoSelect
