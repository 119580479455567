import React from 'react'
import { Form, Row,Col } from 'react-bootstrap';

//csc, idcsc, senha certificado, tpambiemtenfce

class TabNfce extends React.Component{
    constructor(props) {
        super(props);
        this.handleInput = this.handleInput.bind(this) 
        this.checkedAmbiente = this.checkedAmbiente.bind(this) 
    }

    componentDidMount(){
        let form = this.props.form;
        if(form.dadosNfce.tpambiemtenfce == ''){
            form.dadosNfce.tpambiemtenfce = 0;
        }
        this.props.handleForm(form);
    }

    handleInput = (e)=>{
        let form = this.props.form;
        form.dadosNfce[e.target.name] = e.target.value;
        this.props.handleForm(form);
    }

    checkedAmbiente = (n)=>{
        let form = this.props.form;
        form.dadosNfce.tpambiemtenfce = n;
        this.props.handleForm(form);
    }
      
    render(){
        const empresa = this.props.form
        return <div style={{ padding:10}}>
            <fieldset className="fieldset">
                <legend>Parametros</legend>
                <Form>
                    <Form.Group as={Col} xs={12} lg={4} >
                        <label>CSC</label>
                        <Form.Control value={empresa.dadosNfce.csc} onChange={this.handleInput} name="csc" />
                    </Form.Group>
                
                    <Form.Group as={Col} xs={6} lg={4} >
                        <label>IdCSC</label>
                        <Form.Control value={empresa.dadosNfce.idcsc} onChange={this.handleInput} name="idcsc" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6}  lg={4}>
                        <label>Senha certificado</label>
                        <Form.Control value={empresa.dadosNfce.senhaCertificado} onChange={this.handleInput} name="senhaCertificado" />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} >
                        <label>Tipo de ambiente</label>
                        <div style={{height:5}}></div>
                        <Form.Check name="tpambiemtenfce" onChange={()=>this.checkedAmbiente("0")} checked={empresa.dadosNfce.tpambiemtenfce === '0'?true : false} inline label="Homologação" type="radio" id="homologacao" />
                        <Form.Check name="tpambiemtenfce" onChange={()=>this.checkedAmbiente("1")} checked={empresa.dadosNfce.tpambiemtenfce === '1'?true : false} inline label="Produção" type="radio" id="producao" />
                    </Form.Group>    
                    
                </Form>
            </fieldset>
        </div>
    }
}
    
    export default TabNfce
    