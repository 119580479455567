import React from 'react'; // Import React
import {requestException} from '../../util/exception';
import '../../DataTable.scss';
import {axiosHeader, CFG} from '../../CFG';
import axios from 'axios';
import {mask_decimal} from '../../util/inputMask';
import {sessionGet} from '../../usuario/Auth';
import './Quadros.scss';

class TotaisDataTable extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            hidden: false,
            empresa: sessionGet('empresa'),
            caixa: '',
            inicio: '',
            fim: '',
            totais: {
                dinheiro: 0,
                recargas: 0,
                lanchar: 0,
                cheque: 0,
                cartao: 0,
                ticket: 0,
                suprimento: 0,
                sangria: 0,
                cancelado: 0,
                bruto: 0,
                liquido: 0
            }
        };
        
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.inicio != this.props.inicio 
            || prevState.caixa != this.props.caixa
            || this.props.hidden != this.state.hidden
            ){
            let REF = this;
            this.setState({ ...this.props });
            setTimeout(() => REF.refreshListing(), 1000);
        }
    }

 
    refreshListing = () => {

        let REF = this;

        return axios.get(CFG.URL_API+'/fechamento/totais?&empresa='+this.state.empresa+'&caixa='+this.state.caixa+'&inicio='+this.state.inicio+'&fim='+this.state.fim, axiosHeader())
        .then(function(response){
          REF.setState({ totais : response.data });
          //Perform action based on response
        })
        .catch(function(error){
      requestException(error);
 });

    }
 
    formUpdate(form_data_complete){
        this.setState({ form: form_data_complete });
    }
    
    render(){

        if(this.state.hidden)
        return (<></>);

        let REF = this;
        return (
<div className="info-frame">
<fieldset>
    <legend><h4 className="text-muted">Totais <small className="badge badge-info" hidden={this.props.result_count == 0}>{this.props.result_count} fechamentos</small></h4></legend>
    <table className="table table-striped table-bordered">
        <tbody>
            <tr>
                <th>Dinheiro:</th>
                <td>R$ {mask_decimal(this.state.totais.dinheiro)}</td>
                <th>Recargas:</th>
                <td>R$ {mask_decimal(this.state.totais.recargas)}</td>
                <th>Lanchar:</th>
                <td>R$ {mask_decimal(this.state.totais.lanchar)}</td>
            </tr>
            <tr>
                <th>Cheque:</th>
                <td>R$ {mask_decimal(this.state.totais.cheque)}</td>
                <th>Cartão:</th>
                <td>R$ {mask_decimal(this.state.totais.cartao)}</td>
                <th>Ticket:</th>
                <td>R$ {mask_decimal(this.state.totais.ticket)}</td>
            </tr>
            <tr>
                <th>Suprimento:</th>
                <td>R$ {mask_decimal(this.state.totais.suprimento)}</td>
                <th>Sangria:</th>
                <td>R$ {mask_decimal(this.state.totais.sangria)}</td>
                <th>Cancelado:</th>
                <td>{mask_decimal(this.state.totais.cancelado)}</td>
            </tr>
            <tr>
                <th>Bruto:</th>
                <td>R$ {mask_decimal(this.state.totais.bruto)}</td>
                <th>Liquido:</th>
                <td>R$ {mask_decimal(this.state.totais.liquido)}</td>
            </tr>
        </tbody>
    </table>
    </fieldset>
</div>
        )
        }
    }
    
    export default TotaisDataTable;