import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import ResponsavelSelect from '../responsavel/ResponsavelSelect'; 

import {sessionGet} from '../usuario/Auth';
import {rand} from '../util/number';
import { inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import {parseDecimal, mask_decimal, floatToReal} from '../util/inputMask';

class CartaoForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      responsavel: this.props.form.responsavel,
      empresa: sessionGet('empresa'),
      titular: '',
      rg: '',
      cpf: '',
      email: '',
      telefone: '',
      celular: '',
      cep: '',
      rfid: '',
      senha: '',
      credencial: '',
      senha_decode: '',
      nascimento: '',
      validator: {},
      show: false,
      responsavel_lock: true
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.nCartao = React.createRef();
  } 

  getId(){
    return this.state.id;
  }

  formFiltered = () => {
      let form_prev = Object.assign({}, this.props.form);
    
      delete form_prev.limite_d1;
      delete form_prev.limite_d2;
      delete form_prev.limite_d3;
      delete form_prev.limite_d4;
      delete form_prev.limite_d5;
      delete form_prev.limite_d6;
      return form_prev;
  }
  
    componentDidUpdate(prevProps, prevState){
      if(this.props.submit && prevProps.submit !== this.props.submit){
        if(this.isValid()){
          if(this.state.id !== 0 && typeof this.state.id !== "undefined"){
            this.put();
          } else {
            console.log("Prev do post")
            this.post();
          }  
        } else {
          alert('Atenção aos campos em vermelho do formulário de cartão');
        }
      }
    }

    componentDidMount(){
      let REF = this;
      this.setState(this.formFiltered());
      if(typeof this.props.form.credencial === "undefined"){
        console.log("O form ta sempre vazio")
        this.geraCredencial(); 
      }
      setTimeout(() => REF.getResponsavel(), 1000);
    }

    geraCredencial = () => {
      let REF = this;
      let c = rand(1000, 9999);
      axios.get(CFG.URL_API+'/cartao/check-credencial?empresa='+this.state.empresa+'&credencial='+c, axiosHeader())
      .then(function(response){
        console.log("rsponsta da credencial",response.data)
        REF.setState({ credencial: response.data.credencial });
    })
      .catch(function(error){
        //Perform action based on error
      });
      
    }

    onResponsavelChange = (pair, actionMeta) => {
     this.setState({ responsavel: pair.value });
     let CF = this;
     this.state.validator.responsavel = true;
     setTimeout(() => {
      CF.getResponsavel();
     } , 1000);
   }

   getResponsavel = () => {
     let CF = this;
     if(typeof this.state.responsavel !== "undefined"){
      axios.get(CFG.URL_API+'/responsavel/'+this.state.responsavel, axiosHeader())
      .then(function(response){
  
        let nascimento_filtered = response.data.nascimento == '0000-00-00' ? null : response.data.nascimento;
  
        CF.setState({ 
          responsavel_nome : response.data.nome,
          rg: response.data.rg,
          telefone: response.data.telefone,
          celular: response.data.celular,
          cep: response.data.cep,
          email: response.data.email,
          endereco: response.data.endereco,
          numero: response.data.numero,
          bairro: response.data.bairro,
          cidade: response.data.cidade,
          nascimento: nascimento_filtered,
          uf: response.data.uf
        });
  
      })
      .catch(function(error){
        //Perform action based on error
      });
     }
    
   }

   get(){
    axios.get(CFG.URL_API+'/cartao/'+this.state.id)
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   post (){
      const REF = this;
      //let up_state = Object.assign({}, this.state);
      let up_state = this.state;
      
      delete up_state.id;
      up_state.senha = btoa(this.state.senha_decode);
      
      axios.post(CFG.URL_API+'/cartao', up_state, axiosHeader())
      .then(function(response){
        REF.setState({ ...response.data });
        REF.props.setid(response.data.id);
        //Perform action based on response
      })
      .catch(function(error){
        REF.props.errorSubmit(error.response.data.message)
      });
   }

   put () {
     console.log('atualiza o cartão')
     let REF = this;
    let up_state = this.state;
    up_state.senha = btoa(this.state.senha_decode);

    //sem permissão
    if(window.userCan.senha_cartoes !== 1)
    delete up_state.senha;

    axios.put(CFG.URL_API+'/cartao/'+this.state.id, up_state, axiosHeader())
      .then(function(response){
        //Perform action based on response
    })
    .catch(function(error){
      console.log(error);
    });


   }

   render(){

   return(
     <>
     <fieldset className="border p-2 mr-3">
     <legend  className="w-auto">Cartão</legend>
     <input value={this.state.empresa} name="empresa" type="hidden" />
    <div className="row">
    <div className="form-group col-md-4">         
      <label htmlFor="f-ncartao">
      Nº Cartão
      </label>
      <input className="form-control" ref={this.nCartao} name="ncartao" id="f-id" type="text" value={this.state.ncartao} onChange={this.inputChangeHandler} />
    </div>
    <div className="form-group col-md-4">         
      <label htmlFor="f-rfid">
        RFID
      </label>
      <input className="form-control" name="rfid" id="f-rfid" type="text" value={this.state.rfid} onChange={this.inputChangeHandler}/>
    </div> 
    <div className="form-group col-md-4">         
      <label htmlFor="f-name required">
      Tipo de cartão
      </label>
      <select className="form-control" name="tipo" id="f-tipo" value={this.state.tipo} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}>
        <option value="A">Aluno</option>
        <option value="P">Professor</option>
        <option value="C">Caixa ( Operador )</option>
        <option value="G">Gerente ( Operador )</option>
        <option value="F">Funcionário (Empresarial)</option>
      </select>
    </div>

  </div>
  <div className="row">
    <div className="form-group col-md-4">         
      <label htmlFor="f-senha_decode">
        Senha (4 números)
      </label>
      <input className="form-control" name="senha_decode" id="f-senha_decode" type="text" value={this.state.senha_decode} onChange={this.inputChangeHandler}/>
    </div>  
    <div className="form-group col-md-4">         
      <label htmlFor="f-credencial">
        Cod Seg
      </label>
      <input className="form-control" readOnly name="credencial" id="f-credencial" type="text" value={this.state.credencial} onChange={this.inputChangeHandler}/>
    </div>  

    <div className="form-group col-md-4">         
      <label htmlFor="f-credito">
        Saldo
      </label>
      <input className="form-control" readOnly name="credito" id="f-credito" type="text" value={mask_decimal(this.state.credito)}/>
    </div>  
   
    </div>
    <div className="row">
      <div className="form-group col-12">         
        <label htmlFor="f-titular">
          Titular
        </label>
        <input className="form-control" name="titular" id="f-titular" type="text" value={this.state.titular} onChange={this.inputChangeHandler}/>
      </div>
</div>
</fieldset>
<fieldset className="border p-2 mt-2 mr-3">
     <legend  className="w-auto">Responsável</legend>
<div className="row">
    <div className="form-group col-12">         
    <label htmlFor="f-responsavel_nome">
      Responsável
    </label>
    <ResponsavelSelect change={this.onResponsavelChange} empresa={this.state.empresa} responsavel={this.state.responsavel} />
  </div>
</div>

<div className="row">
<div className="form-group col-md-4">         
    <label htmlFor="f-rg">
      RG
    </label>
    <input readOnly={this.state.responsavel_lock} className="form-control" name="rg" id="f-rg" type="text" value={this.state.rg || ""} onChange={this.inputChangeHandler}/>
  </div>
    <div className="form-group col-md-4">         
      <label htmlFor="f-telefone required">
        Telefone
      </label>
      <input readOnly={this.state.responsavel_lock} className="form-control" name="telefone" id="f-telefone" type="text" value={this.state.telefone} onChange={this.inputChangeHandler}/>
    </div>
    <div className="form-group col-md-4">         
      <label htmlFor="f-celular">
        Celular
      </label>
      <input readOnly={this.state.responsavel_lock} className="form-control" name="celular" id="f-celular" type="text" value={this.state.celular} onChange={this.inputChangeHandler}/>
    </div>

</div>

<div className="row">
<div className="form-group col-md-4">         
    <label htmlFor="f-nascimento">
      Nascimento
    </label>
    <DatePicker locale="pt-BR"  disabled={this.state.responsavel_lock} autoComplete="off" className="form-control" name="nascimento" id="f-nascimento" type="text" value={this.state.nascimento} onChange={this.nascimentoChangeHandler} />
  </div>
  <div className="form-group col-8">
    <label htmlFor="f-email">
      Email
    </label>
    <input readOnly={this.state.responsavel_lock} className="form-control" name="email" id="f-email" type="email" value={this.state.email} onChange={this.inputChangeHandler}/>
  </div>
</div>
<div className="row">   
  <div className="form-group col-md-3">     
    <label htmlFor="f-name">
      CEP
    </label>
    <input readOnly={this.state.responsavel_lock} className="form-control" name="cep" id="f-name" type="text" value={this.state.cep} onChange={this.cepChangeHandler}/>
  </div>
  <div className="form-group col-md-7">         
    <label htmlFor="f-endereco">
      Endereço
    </label>
    <input readOnly={this.state.responsavel_lock} className="form-control" name="endereco" id="f-endereco" type="text" value={this.state.endereco} onChange={this.inputChangeHandler}/>
  </div>
  <div className="form-group col-md-2">         
    <label htmlFor="f-numero">
      Número
    </label>
    <input readOnly={this.state.responsavel_lock} className="form-control" name="numero" id="f-numero" type="tel" value={this.state.numero} onChange={this.inputChangeHandler}/>
  </div>
</div>
<div className="row">
  <div className="form-group col-md-5">         
    <label htmlFor="f-name">
      Bairro
    </label>
    <input readOnly={this.state.responsavel_lock} className="form-control" name="bairro" id="f-bairro" type="text" value={this.state.bairro} onChange={this.inputChangeHandler}/>
  </div>
  <div className="form-group col-md-5">         
    <label htmlFor="f-cidade">
      Cidade
    </label>
    <input readOnly={this.state.responsavel_lock} className="form-control" name="cidade" id="f-cidade" type="text" value={this.state.cidade} onChange={this.inputChangeHandler}/>
  </div>
  <div className="form-group col-md-2">         
    <label htmlFor="f-estado">
      Estado
    </label>
    <select readOnly={this.state.responsavel_lock} className="form-control" id="estado" name="estado" value={this.state.estado} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}>
      <option></option>
      <option value="AC">Acre</option>
      <option value="AL">Alagoas</option>
      <option value="AP">Amapá</option>
      <option value="AM">Amazonas</option>
      <option value="BA">Bahia</option>
      <option value="CE">Ceará</option>
      <option value="DF">Distrito Federal</option>
      <option value="ES">Espírito Santo</option>
      <option value="GO">Goiás</option>
      <option value="MA">Maranhão</option>
      <option value="MT">Mato Grosso</option>
      <option value="MS">Mato Grosso do Sul</option>
      <option value="MG">Minas Gerais</option>
      <option value="PA">Pará</option>
      <option value="PB">Paraíba</option>
      <option value="PR">Paraná</option>
      <option value="PE">Pernambuco</option>
      <option value="PI">Piauí</option>
      <option value="RJ">Rio de Janeiro</option>
      <option value="RN">Rio Grande do Norte</option>
      <option value="RS">Rio Grande do Sul</option>
      <option value="RO">Rondônia</option>
      <option value="RR">Roraima</option>
      <option value="SC">Santa Catarina</option>
      <option value="SP">São Paulo</option>
      <option value="SE">Sergipe</option>
      <option value="TO">Tocantins</option>
      <option value="EX">Estrangeiro</option>
  </select>
  </div>
  </div>
</fieldset>
</>
);
   }

 
 
}
export default CartaoForm;