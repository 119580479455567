import React from 'react'; // Import React
import { Row,Col, Container, Button, ButtonToolbar} from 'react-bootstrap';
import { sessionGet } from '../../usuario/Auth';
import InatividadeDataTable from './InatividadeDataTable';
import {firstDateOfMonth, lastDateOfMonth} from '../../util/date';
import CartaoHistorico from '../cartao/CartaoHistorico';

class Extrato extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            movimentacao_id: '',
            empresa: sessionGet('empresa'),
            data: [],
            form: {
                id: undefined
            },
            searchTerm: null,
            activeIndex: undefined,
            show_modal_filters: false,
            filters: {
                inicio: firstDateOfMonth(),
                fim: lastDateOfMonth()
            }
        };

        this.props.titlebar(this.props.title, this.props.description);
    }

    updateLoading = (status) => {
        this.setState({ loading: status  });
    }
  
    setModalShow  = (status, data) => {
        this.setState({  show: status, form: data, refresh: !status });
   }
       
    render(){
        let REF = this;
        return (<>
        <Container fluid={true}>
           <CartaoHistorico
            form={this.state.form}           
            loading={this.state.loading}
            empresa={this.state.empresa}
            show={this.state.show}                    
            onHide={() => REF.setModalShow(false)}
        />
        <InatividadeDataTable setloading={this.updateLoading} title={this.props.title} sizeperpage={this.state.data.length} empresa={this.state.empresa} filters={this.state.filters} setModalShow={this.setModalShow}  />
        </Container>
        </>);
    }
    }
    
    export default Extrato;