import React from 'react';
import {Tabs, Tab, Overlay, Popover} from 'react-bootstrap';
import ProdutoForm from './ProdutoForm';
import FornecedoresForm from './FornecedoresForm';
import IconeSelect from '../icone/IconeSelect';
import './ProdutoTab.scss';
import './../Popover.scss';
import FormulaForm from './FormulaForm';

class ProdutoTab extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      produto_id: 0,
      icone_select_id : '',
      icone_select_url : '',
      produto_id: '',
      key: 'home',
      formula_enabled: false,
      submit_status: {
        ProdutoForm: false,
        FormulaForm: false,
        FornecedoresForm: false
      }
    };


      
  }

  componentDidMount(){

    if(typeof this.props.form !== "undefined"  && this.props.form.icone != null)
    this.setState({ icone_select_id: this.props.form.icone });
  }

  componentDidUpdate = (prevProps) => {

    if(this.props.form != null
      && Object.getOwnPropertyNames(this.props.form).length > 0
      && this.state.produto_id != this.props.form.id){
      this.setState({ produto_id: this.props.form.id });   
    }

    if(this.state.submit_status.ProdutoForm && this.state.submit_status.FormulaForm && this.state.submit_status.FornecedoresForm){
      this.setState({ submit_status: {
        ProdutoForm: false,
        FormulaForm: false,
        FornecedoresForm: false
      } });

      this.props.close();
    }
  }

  setCurrentIcon = (id, url) => {
    this.setState({ icone_select_id: id, icone_select_url: url });
  }

  toggleFormula = (status) => {
    this.setState({ formula_enabled: status });
  }

 

  render(){
    const REF = this;

    const updateSubmitStatus = (formName) => {
      let submit_status = REF.state.submit_status;
      submit_status[formName] = true;
      this.setState({ submit_status });
    }

    const setKey = (k) => {
      this.setState({ key: k });
      this.props.tabupdate(k);
    }

    const formulaTabRef = REF;
    const fornecedorTabRef = REF;

    let formula_disabled = this.state.produto_id == 0 || (this.state.produto_id != 0 && !this.state.formula_enabled)
    let fornecedor_disabled = this.state.produto_id == 0;

    return (
            <>

      <div className="ProdutoTab">

  <Overlay
    show={fornecedor_disabled}
    target={fornecedorTabRef}
    placement="top"
    container={fornecedorTabRef.current}
    containerPadding={20}
  >
    <Popover id="popover-fornecedor-tab">
      <Popover.Title as="h3">Fornecedor liberado para produto existente </Popover.Title>
    </Popover>
  </Overlay>

    <Overlay
      show={formula_disabled}
      target={formulaTabRef}
      placement="top"
      container={formulaTabRef.current}
      containerPadding={20}
    >
      <Popover id="popover-combo-tab">
        <Popover.Title as="h3">Combo liberado para produto existente, produção própria </Popover.Title>
      </Popover>
    </Overlay>

      <Tabs  defaultActiveKey="home" transition={false} activeKey={this.state.key} onSelect={k => setKey(k)}>
            <Tab eventKey="home" title="Dados">
                <ProdutoForm loading={this.props.loading} close={this.props.close} delete={this.props.delete} submit={this.props.submit} submit_confirm={updateSubmitStatus} delete={this.props.delete} toggleformula={this.toggleFormula} show={this.props.show} form={this.props.form} formcontrol={this.props.formcontrol} icone_select_url={this.state.icone_select_url} icone_select_id={this.state.icone_select_id} currenticon={this.setCurrentIcon}  />
            </Tab>
            <Tab ref={fornecedorTabRef} disabled={fornecedor_disabled} eventKey="fornecedores" title="Fornecedores">
                <FornecedoresForm submit={this.props.submit} submit_confirm={updateSubmitStatus} show={this.props.show} produto_id={this.state.produto_id} formcontrol={this.props.formcontrol}  />
            </Tab>
            <Tab eventKey="icone" title="Mudar ícone">
                <IconeSelect icone_select_url={this.state.icone_select_url} icone_select_id={this.state.icone_select_id} currenticon={this.setCurrentIcon}/>
            </Tab>
            <Tab ref={formulaTabRef} disabled={formula_disabled} eventKey="formula" title="Combo">
                <FormulaForm submit={this.props.submit} submit_confirm={updateSubmitStatus} formcontrol={this.props.formcontrol} produto_id={this.state.produto_id} />
          </Tab>
      </Tabs>
  
        </div>
        </>
    );
  }
}


export default ProdutoTab;