import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import ProdutoSelect from '../produto/ProdutoSelect';
import { inputChangeHandler, isValid, selectChangeHandler } from '../CustomForm';
import {todayDmy} from '../util/date';

class EstoqueForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      id: undefined,
      empresa: sessionGet('empresa'),
      validator: {},
      show: false,
      dt_vencimento: new Date(),
      data_removido_format: undefined,
      dt_entrada: todayDmy(),
      //debito
      tipo: undefined
    };

    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }


  fileChangeHandler = (files) => {

    if(files.length == 0)
    return;
    
    var reader = new FileReader();
    let REF = this;
    reader.onload = function() {
      REF.setState({ 
        arquivo_nome: files[0].name,
        arquivo_mime: files[0].type,
        arquivo_conteudo_blob: reader.result
      });
    }
    reader.readAsText(files[0]);

    this.props.modalcontrol(this);
  }

  componentDidMount(){

    let form_fields = this.props.form;
    if(form_fields != undefined && form_fields.dt_vencimento == '0000-00-00'){
      form_fields.dt_vencimento = null;
    }

    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });
      this.props.modalcontrol(this);
    }

    if(typeof this.props.tipo !== "undefined" && this.props.tipo != this.state.tipo){
      this.setState({ tipo: this.props.tipo });
    }

 
    this.updateDateIfRemoved();
  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear()+' às '+dt.getHours()+':'+dt.getMinutes(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);
    }

  }

  componentDidUpdate(prevProps){

    if(this.props.submit && prevProps.submit !== this.props.submit){
      
      if(this.isValid()){
        if(typeof this.state.id !== "undefined"){
          this.put();
        } else {
          this.post();
        }  
      } else {
        alert('Atenção aos campos em vermelho do formulário de cartão');
      }
      
   
  
    }

       if(this.props.delete && prevProps.delete !== this.props.delete){
        this.delete();
      }


    
    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }
  }

   post (){
    let REF = this;
    axios.post(CFG.URL_API+'/estoque', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
     // window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => {
      REF.props.close();
    });
   }

   put () {
    let REF = this;
    axios.put(CFG.URL_API+'/estoque/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => {
      REF.props.close();
    });

    return true;
   }

   downloadAnexo = () => {
    const a = document.createElement("a");
     // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(
    new Blob([this.state.arquivo.conteudo_blob], { type: this.state.arquivo.mime })
  );

  // Use download attribute to set set desired file name
  a.setAttribute("download", this.state.arquivo.nome);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);

   }

  uploadAnexo = (e) => {
    this.inputElement.click();
  }

   render(){
    
    let REF = this;

   return(
     <>
      <div className="row m-0">      
          <div className="form-group col-md-10">         
            <label htmlFor="f-centrocustos_id">
              Produto
            </label>
            <ProdutoSelect readonly={typeof this.state.id !== "undefined"} change={this.selectChangeHandler} produto_id={this.state.produto} />
          </div>
          <div className="form-group col-md-2">         
            <label htmlFor="f-qtd">
              Qtd.
            </label>
            <input readOnly={typeof this.state.id !== "undefined"} className="form-control" name="qtd" onChange={this.inputChangeHandler} value={this.state.qtd} />
          </div>
        </div> 
    
        <div className="row m-0"> 
          <div className="form-group col-md-12">         
            <label htmlFor="f-motivo">
              Motivo
            </label>
            <textarea readOnly={typeof this.state.id !== "undefined"} className="form-control" name="motivo" id="f-motivo" type="text" value={this.state.motivo} onChange={(e) => REF.inputChangeHandler(e)}  />
          </div>
        </div>
    </>
);
   }

   delete = () => {
     let REF = this;

    axios.delete(CFG.URL_API+'/estoque/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => {
      REF.props.close();
    });
   }
 
 
}
export default EstoqueForm;