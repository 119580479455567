import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../CustomDataTable'; // Import React
import '../DataTable.scss';

import FormaPagamentoModal from './FormaPagamentoModal';
import { sessionGet } from '../usuario/Auth';

class FormasPagamentoDataTable extends React.Component{
  
  constructor(props) {
    super(props);
    
    this.state = {
      id: '',
      empresa_id: sessionGet('empresa'),
      form: {},
      data: [],
      searchTerm: null,
      activeIndex: undefined
    };

    this.hideModal = this.hideModal.bind(this);
    this.tablerowupdate = this.tablerowupdate.bind(this);

    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender;

 }

 updateListing = (columnsSearch) => {
    this.getListing('forma-pagamento?empresa_id='+this.state.empresa_id, columnsSearch);
 }

 componentDidMount(){
  this.updateListing();
 }

  componentDidUpdate(prevProps){

    if(prevProps.empresa != this.props.empresa){
    
      this.setState({empresa: this.props.empresa });
      this.updateListing();

    }

    if(prevProps.form.id !== this.props.form.id){
      this.setState({ form: this.props.form });
    }
  }

  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }

  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }

  setModalShow (status, data){

    if(data != null){
      this.setState({  form : data });
    }

    this.setState({  show: status });
  }

  hideModal(){
    this.setState({ show: false });
    this.updateListing();
  }

  getColumns(){
    return [
      {
        dataField: 'id',
        text: 'ID',
        style: { 'textAlign' : 'center' },
        headerStyle: { 'width' : '10%', 'textAlign':'center' }
      }, {
        dataField: 'empresa_id',
        text: 'Empresa',
        hidden: true
      },{
        dataField: 'qtd_parcelas',
        text: 'Qtd. Parcelas',
        sort: true
      },{
        dataField: 'descricao',
        text: 'Descrição',
        sort: true
      },{
        dataField: 'acrescimo',
        text: 'Acréscimo',
        hidden: true
      },{
        dataField: 'desconto',
        text: 'Desconto',
        sort: true
      },{
        dataField: 'juros',
        text: 'Juros',
        hidden: true
      },{
        dataField: 'tipo_format',
        text: 'Tipo' 
      }
    ];
  }

  tablerowupdate(form){
    let data = this.state.data; 
    let data_updated = this.updateTableRow(data, this.state.activeIndex, form);
    
    let REF = this;
    setTimeout(() => { 
      REF.setState({ data: data_updated });
      REF.updateListing();
    }, 500);
  }

  updateTableRow(array, target_index, data) {
    return array.map((item, index) => {
      if (index == target_index) {
        // This isn't the item we care about - keep it as-is
        return Object.assign(item, data);
      } else {
   // Otherwise, this is the one we want - return an updated value
   return item;
      } 
    })
  }

  render(){
    return (
    <>
          <FormaPagamentoModal
            show={this.state.show}        
            form={this.state.form}        
            onHide={this.hideModal}
            tablerowupdate={this.tablerowupdate}
            formupdate={this.formUpdate}
            />  
        
            {
              (() => this.tableRender())()
            }
    </>
    )
  }
        }
        
        export default FormasPagamentoDataTable;
        
        