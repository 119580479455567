import React from 'react';
import '../../Modal.scss';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import LoadingModal from '../../util/LoadingModal';
import {money_format} from '../../CustomForm';
import {mask_decimal} from '../../util/inputMask';
import url_btn_close from '../../img/icons/close.png';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import {CFG, axiosHeader} from '../../CFG';
import MovCabDataTable from '../../movimentacao/MovCabDataTable'; 

class CartaoHistorico extends React.Component{

    constructor(props) {
      super(props);

      this.state = {   
        show: this.props.show,
        form: '',
        qtde: 0,
        total: 0,
        loading: false,
        loading: false,
        refresh: false,
        show: false,
        form: {
          id: undefined
        }
      };

    }

        updateLoading = (status) => {
        this.setState({ loading: status  });
    }
    
    componentDidUpdate(prevProps){
        if(typeof this.props.form !== "undefined" && this.props.form.id !== this.state.form.id)
        this.setState({ form: this.props.form });

    
    }

    updateSum = () => {

      let qtde = 0.0;
      let total = 0.0;

      this.state.form.detalhes.map(function(d){
        qtde += parseFloat(d.qtde);
        total +=  parseFloat(d.total)
      });

      this.setState((state) => ({
        total: total,
        qtde: qtde
    }));

    }




  render () {

    const REF = this;
    return (
      <>
      <Modal    
       
      show={this.props.show}
        onHide={this.props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            Extrato de movimentações do cartão
          </Modal.Title>
         
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body   ref={REF}   >
     

          <MovCabDataTable cartao={this.state.form.id} setloading={this.updateLoading} refresh={REF.state.refresh} setModalShow={REF.setModalShow} />


        </Modal.Body>
                <Modal.Footer>
                <ReactToPrint
                            className="ml-auto"
                            trigger={() => <button className="btn btn-secondary float-right"><i className="fa fa-file-text-o"></i> Imprimir</button>}
                            content={() => REF.current}
                          /> 
                </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  
   
}

export default CartaoHistorico;