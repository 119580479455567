import React from 'react'; // Import React
import {getListing, CustomToggleList, filtersForQuery, tableRender} from '../CustomDataTable'; // Import React
import '../DataTable.scss';
import MovCabDetalhes from './MovCabDetalhes';
import {firstDateOfMonth, lastDateOfMonth} from '../util/date';
import {sessionGet} from '../usuario/Auth';

class MovCabDataTable extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            movimentacao_id: '',
            empresa: sessionGet('empresa'),
            data: [],
            fetchComplete: false,
            form_state: {},
            show_modal: false,
            searchTerm: null,
            activeIndex: undefined,
            filters: {
                inicio: firstDateOfMonth(),
                fim: lastDateOfMonth()
            }
        };
        
        this.handleTableRefresh = this.handleTableRefresh.bind(this);
        this.tablerowupdate = this.tablerowupdate.bind(this);
        this.formUpdate = this.formUpdate.bind(this);
        
        this.onKeyPressed = this.onKeyPressed.bind(this);
        this.getCustomToggleList = CustomToggleList.bind(this);
        this.getListing = getListing.bind(this);
        this.tableRender = tableRender.bind(this);

    }
    
    updateListing = (columnsSearch) => {
        let data_up_tostring = '';

        if(Object.getOwnPropertyNames(this.state.filters).length > 0){
            data_up_tostring = filtersForQuery(this.state.filters);
        }

        if(typeof this.props.recent !== "undefined"){
            data_up_tostring += '&recent=1';
        }
        
        this.getListing('movimentacao?empresa='+this.state.empresa+'&'+data_up_tostring, columnsSearch);
        
    }
    
    componentDidMount(){
        if(typeof this.props.cartao === "undefined")
        this.updateListing();
    }
    
    componentDidUpdate(prevProps, prevState){

        if(prevProps.filters !== this.props.filters){
            let REF = this;
            this.setState({ filters: this.props.filters });
            setTimeout(() => {
                REF.updateListing();              
            }, 1000);
        }

        if(prevState.fetchComplete !== this.state.fetchComplete){
            this.props.setloading(false);
        }

        if(prevProps.cartao !== this.props.cartao){
            let REF = this;
            this.setState({ filters: {
                ...REF.state.filters,
                cartao: this.props.cartao
            } });

            setTimeout(() => {
                REF.updateListing();
            }, 2000);
        }
    }
    
    onKeyPressed(event){
        if(event.keyCode == 27 )
        this.setState({ popoverVisible : false });
    }
    
    getCustomToggleList({
        columns,
        onColumnToggle,
        toggles
    }){
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }

    setModalShow = (status, form_state) => {
        
        if(form_state != null){            
            this.setState({  form_state : form_state });
            this.setState({  movimentacao_id : form_state.id });
        }
        
        this.setState({  show_modal: status });
        this.updateListing();
    }

    
    handleTableRefresh(refresh){
        
        this.setState({ refresh : refresh });
        
        var Rs = this;
        
        setTimeout(function(){            
            Rs.setState({ refresh : false });
        }, 100);
        
    }
    
    getColumns(){
        return [
            {
                dataField: 'id',
                text: 'ID',
                sort: true,
                headerClasses: 'alpha',
                style: { 'width': '120px' }
            },{
                dataField: 'emissao_format',
                text: 'Data de emissão'
            },{
                dataField: 'unique',
                text: 'Código',
                sort: true
            },{
                dataField: 'cartao',
                text: 'Cartão',
                sort: true,
             //   headerStyle: { width: '50%' }
            },{
                dataField: 'local',
                text: 'Local',
                sort: true,
                style: { fontSize: '12px' }
             //   headerStyle: { width: '50%' }
            },{
                dataField: 'tipo_operacao',
                text: 'Operação',
                sort: true,
             //   headerStyle: { width: '50%' }
            },{
                dataField: 'totalFormat',
                text: 'Total',
                sort: true
            },{
                dataField: 'descFormat',
                text: 'Desconto',
                style: { 'width': '100px' }
            }
        ];
    }
    
    tablerowupdate(form){
        let data = this.state.data; 
        let data_updated = this.updateTableRow(data, this.state.activeIndex, form);
        
        setTimeout(() => this.setState({ data: data_updated }));
    }
    
    updateTableRow(array, target_index, data) {
        return array.map((item, index) => {
            if (index == target_index) {
                // This isn't the item we care about - keep it as-is
                return Object.assign(item, data);
            } else {
                // Otherwise, this is the one we want - return an updated value
                return item;
            } 
        })
    }
    
    formUpdate(form_data_complete){
        this.setState({ form: form_data_complete });
    }
    
    render(){
        let REF = this;


        let rowClasses = function callback(cell, row, rowIndex, colIndex) { 

            if(cell.cancelado === 1){
                return 'removed';
            }

        
        };


        return (
            <div className="table-report">
                <MovCabDetalhes
                    show={this.state.show_modal}  
                    cancel_hide={this.state.form_state.cancelado === 1}      
                    form={this.state.form_state}        
                    onHide={() => REF.setModalShow(false, null)}
                    tablerowupdate={this.tablerowupdate}
                />            
                {
                    (() => REF.tableRender(false, rowClasses))()
                }
            </div>
            )
        }
    }
    
    export default MovCabDataTable;