import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { inputChangeHandler, isValid } from '../../CustomForm';
import DatePicker from 'react-date-picker';
import {getYmd} from '../../util/date';

class FiltroForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            validator: {},
            show: false,
            edit_lock: true,
            cal_inicio: new Date(),
            cal_fim: new Date(),
            inicio: getYmd(new Date()),
            fim: getYmd(new Date()),
        };
        
        this.inputChangeHandler = inputChangeHandler.bind(this);
        
        this.isValid = isValid.bind(this);
    }
    
    formattedState = () => {

      let state_old = {};
      Object.assign(state_old, this.state);
      delete state_old.validator;
      delete state_old.cal_inicio;
      delete state_old.cal_fim;
      delete state_old.show;
      delete state_old.edit_lock;

      return state_old;
    }

   

    componentDidUpdate(prevProps, prevState){

      if(prevState != this.state){
        let REF = this;
        setTimeout(function() {
            REF.props.modalcontrol(REF);
        }, 1000);
      }
    }
    
    componentDidMount() {
        this.setState({ show: this.props.show });
    }

    inicioChangeHandler = (date) => {

        if(date == null){
          this.setState({ cal_inicio: null, inicio: '' });
          return;
        }

        let REF = this;
        let dt = date;
        let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
        this.setState({ cal_inicio: dt, inicio: dt_format }); 
      }
    
      fimChangeHandler = (date) => {


        if(date == null){
          this.setState({ cal_fim: null, fim: '' });
          return;
        }

        let dt = date;
        let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
        this.setState({ cal_fim: dt, fim: dt_format }); 
      }
    
    render(){
        
        return(
            <>   
            
      <Row className="my-2">
          <Col md="3" className="p-1">
          </Col>
          <Col md="3" className="p-1 cj">
              <label>de</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="B_emissao" id="f-inicio" value={this.state.cal_inicio} onChange={this.inicioChangeHandler} />
          </Col>
            <Col md="3" className="p-1 cj">
              <label>até</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="B_emissao_" id="f-fim" value={this.state.cal_fim} onChange={this.fimChangeHandler} />
          </Col>
        </Row>

        <Row>
      <div className="col-md-12">
        <label htmlFor="titular">Titular</label>
        <input className="form-control" onChange={this.inputChangeHandler} id="titular" name="titular" />
      </div>
    </Row>
         <Row>
      <div className="col-md-4">
        <label htmlFor="cartao_id">ID Cartão</label>
        <input className="form-control" onChange={this.inputChangeHandler} id="cartao_id" name="cartao_id" />
      </div>
      <div className="col-md-4">
        <label htmlFor="ncartao">Núm. do Cartão</label>
        <input className="form-control" onChange={this.inputChangeHandler} id="ncartao" name="ncartao" />
      </div>
      <div className="col-md-4">
        <label htmlFor="status">Status da recarga</label>
        <select name="status" onChange={this.inputChangeHandler} className="form-control">
        <option value="">Todas</option>
          <option value="0">Aberto (aguarda pagto.)</option>
          <option value="1">Pago</option>
          <option value="2">Cancelado</option>
          <option value="3">Autorizado mas falta pagar</option>
          <option value="4">Boleto reprovado pelo Banco</option>
     </select>
      </div>		

      </Row>

      <Row>
      <div className="col-md-4">
        <label htmlFor="origem">Origem</label>
        <select name="origem" onChange={this.inputChangeHandler} className="form-control">
         
        <option value="">Todas</option>
          <option value="{CAN,CANT}">Cantina</option>
          <option value="{BOL,RBOL}">Boleto do Site</option>
          <option value="{LUBOL,LRBOL}">Boleto no App</option>
          <option value="{LUCRE,LRCRE}">C. Crédito no App</option>
        
 
        </select>
      </div>	

        <div className="col-md-4">
          <label htmlFor="id">ID da Recarga</label>
          <input className="form-control" onChange={this.inputChangeHandler} id="id" name="id" />
        </div>
        <div className="col-md-4">
          <label htmlFor="codigo">Código da Recarga</label>
          <input className="form-control" onChange={this.inputChangeHandler} id="codigo" name="codigo" />
        </div>
      
      </Row>
      <Row>
      
      <div className="col-md-4">
          <label htmlFor="valor">Valor</label>
          <input className="form-control" onChange={this.inputChangeHandler} id="valor" name="valor" />
        </div>
      </Row>
     
            </>
            );
        }        
    }
    export default FiltroForm;