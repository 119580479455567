import React from 'react';
import '../../Modal.scss';
import {Modal, Row, Col} from 'react-bootstrap';
import LoadingModal from '../../util/LoadingModal';
import {mask_decimal} from '../../util/inputMask';
import url_btn_close from '../../img/icons/close.png';
import ReactToPrint from 'react-to-print';

class FechamentoEspelho extends React.Component{

    constructor(props) {
      super(props);

      this.state = {   
        show: this.props.show,
        form: '',
        qtde: 0,
        total: 0,
        loading: false
      };

    }
    
    componentDidUpdate(prevProps){
        if(this.props.form.id != this.state.form.id)
        this.setState({ form: this.props.form });

        if(Object.getOwnPropertyNames(this.state.form).length > 0
          && typeof this.state.form.espelho !== "undefined"
          && this.props.show != this.state.show
        ) {
          let REF = this;
          this.setState({ show: this.props.show });
        }
    }

    renderEspelho = (d, i) => {
      return (<tr>
          <td>{d.descricao} ({d.unidade})</td>
          <td>R$ {mask_decimal(d.preco)}</td>
          <td>{mask_decimal(d.qtde)}</td>
          <td>R$ {mask_decimal(d.total)}</td>
      </tr>);
    }

  render () {

    const REF = this;
    return (
      <>
      <Modal    
       
      show={this.props.show}
        onHide={this.props.onHide}
        size="md"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
          Espelho do fechamento
          </Modal.Title>
         
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body ref={REF} className="coupon"  >
     
        {this.state.form.detalhes != null && this.state.form.detalhes != "" ? atob(this.state.form.detalhes) : 'Não há detalhes do fechamento para mostrar'}
        </Modal.Body>
        <Modal.Footer>
          <ReactToPrint trigger={() => <button className="btn btn-secondary float-right"><i className="fa fa-file-text-o"></i> Imprimir</button>} content={() => REF.current} /> 
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  

}

export default FechamentoEspelho;