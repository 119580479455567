import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import NotaFiscalModal from './NotaFiscalModal';
import { sessionGet } from '../../usuario/Auth';

class NotaFiscalDataTable extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      empresa: sessionGet('empresa'),
      form:[],
      data: [],
      searchTerm: null,
      activeIndex: undefined
    };
    this.hideModal = this.hideModal.bind(this);
    this.tablerowupdate = this.tablerowupdate.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender;
  }
  updateListing = (columnsSearch) => {
    this.getListing('empresa-estoque?empresa='+this.state.empresa, columnsSearch);
  }
  componentDidMount(){
    this.updateListing();
  }
  componentDidUpdate(prevProps, prevState){

    if(prevState.form != this.state.form){
      this.setState({ tipo: this.state.form.tipo })
    }

    if(prevProps.empresa != this.props.empresa){
      this.setState({empresa: this.props.empresa });
      this.updateListing();
    }
    if(prevProps.form != this.props.form){
      this.setState({ form: this.props.form });
    }
  }
  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }
  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }
  setModalShow (status, data){
    if(data != null){
      this.setState({  form : data });
    }
    this.setState({  show: status });
  }
  hideModal(){
    this.setState({ show: false });
    this.updateListing();
  }
  getColumns(){
    return [
      {
        dataField: 'id',
        text: 'ID',
        style: { 'textAlign' : 'center' },
        headerStyle: { 'width' : '10%', 'textAlign':'center' }
      }, {
        dataField: 'fornecedor_nome',
        text: 'Fornecedor',
        sort: true
      },{
        dataField: 'nf',
        text: 'NF',
        sort: true
      },{
        dataField: 'emissao_format',
        text: 'Data de emissão',
        sort: true
      },{
        dataField: 'total_format',
        text: 'Total',
      },{
        dataField: 'razao',
        hidden: true,
      },{
        dataField: 'fantasia',
        hidden: true,
      }
    ];
  }
  tablerowupdate(form){
    let data = this.state.data; 
    let data_updated = this.updateTableRow(data, this.state.activeIndex, form);
    let REF = this;
    setTimeout(() => { 
      REF.setState({ data: data_updated });
      REF.updateListing();
    }, 500);
  }
  updateTableRow(array, target_index, data) {
    return array.map((item, index) => {
      if (index == target_index) {
        // This isn't the item we care about - keep it as-is
        return Object.assign(item, data);
      } else {
        // Otherwise, this is the one we want - return an updated value
        return item;
      } 
    })
  }
  render(){
    return (
      <>
      <NotaFiscalModal
      tipo={this.state.tipo}
      show={false}        
      form={this.state.form}        
      onHide={this.hideModal}
      tablerowupdate={this.tablerowupdate}
      formupdate={this.formUpdate}
      />  
      {
        (() => this.tableRender())()
      }
      </>
      )
    }
  }
  export default NotaFiscalDataTable;
