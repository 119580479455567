import React from "react" // Import React
import { getListing, CustomToggleList, tableRender } from "../../../CustomDataTable" // Import React
import "../../../DataTable.scss"

import { sessionGet } from "../../../usuario/Auth"

class ProdutosVendidosDataTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            filters: {},
            empresa: sessionGet("empresa"),
            form: [],
            data: [],
            searchTerm: null,
            activeIndex: undefined,
            usuarioHidden: false,
            loading: true,
            fetchComplete: false
        }

        this.tableRender = tableRender
        this.formUpdate = this.formUpdate.bind(this)
        this.getListing = getListing.bind(this)
        this.getCustomToggleList = CustomToggleList.bind(this)
    }

    componentDidMount() {
        this.updateListing()
    }

    updateListing = columnsSearch => {
        let data_up_tostring = ""
        this.setState({ fetchComplete: false })

        if (Object.getOwnPropertyNames(this.state.filters).length > 0) {
            let data_up = Object.entries(this.state.filters)

            data_up_tostring = data_up
                .map(function(e) {
                    return e[0] + "=" + e[1]
                })
                .join("&")
        }

        this.getListing(
            "relatorio/produtos-vendidos-periodo?empresa=" +
                sessionGet("empresa") +
                "&produtos-vendidos=1&" +
                data_up_tostring,
            columnsSearch
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filters != this.props.filters) {
            let usuarioHidden = typeof this.props.filters.agrupar !== "undefined"
            let REF = this

            this.setState({ filters: this.props.filters })

            setTimeout(() => {
                REF.updateListing()
            }, 1000)
        }

        if (prevState.fetchComplete !== this.state.fetchComplete) {
            this.props.setloading(false)
        }
    }

    setModalShow = () => {}

    hideModal() {
        this.setState({ show: false })

        let PC = this
        setTimeout(function() {
            this.updateListing()
        }, 500)
    }

    isColumnHidden = column => {
        if (typeof this.props.hidden_column === "undefined") return false

        return this.props.hidden_column.split(",").includes(column)
    }
    usuarioToggle = (cell, row) => {
        if (this.state.usuarioHidden) return null
    }

    getColumns = () => {
        let REF = this

        return [
            {
                dataField: "id",
                text: "ID",
                hidden: true
            },
            {
                dataField: "usuario",
                text: "Usuário",
                sort: true,
                headerClasses: "usuario alpha",
                classes: "usuario"
            },
            {
                dataField: "produto",
                text: "Descrição",
                sort: true,
                headerClasses: "alpha"
            },
            {
                dataField: "categoria",
                text: "Categoria",
                hidden: true,
                sort: true,
                headerClasses: "alpha"
            },
            {
                dataField: "categoria_nome",
                text: "Categoria",
                sort: true,
                headerClasses: "alpha"
            },
            {
                dataField: "preco_format",
                text: "Preço",
                sort: true
            },
            {
                dataField: "qtd_format",
                text: "Qtd.",
                hidden: this.isColumnHidden("qtd"),
                sort: true
            },
            {
                dataField: "group_total_format",
                text: "Total",
                hidden: this.isColumnHidden("total"),
                sort: true
            },
            {
                dataField: "cancelado",
                text: "Cancelado",
                hidden: this.isColumnHidden("cancelado"),
                sort: true
            },
            {
                dataField: "search",
                hidden: true
            }
        ]
    }

    formUpdate(form_data_complete) {
        this.setState({ form: form_data_complete })
    }

    onKeyPressed(event) {
        if (event.keyCode == 27) this.setState({ popoverVisible: false })
    }

    render() {
        let REF = this
        let rowClasses = function callback(cell, row, rowIndex, colIndex) {
            if (cell.cancelado === "S") {
                return "removed"
            }
        }

        return (
            <>
                <div className="table-report">{REF.tableRender(false, rowClasses)}</div>
            </>
        )
    }
}

export default ProdutosVendidosDataTable
