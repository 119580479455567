import React from 'react';
import '../../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../../util/LoadingModal';
import FiltroForm from './FiltroForm';
import url_btn_close from '../../img/icons/close.png';

class FiltroModal extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      empresa: this.props.empresa,
      formFilters: {},
      loading: false,
      submit: false
    };

  }

  modalControl = (form_comp) => {
    this.setState({ formFilters: form_comp });
  }

  componentDidUpdate(){

    if(this.props.loading !== this.state.loading){
      let REF = this;
      REF.setState({ loading: this.props.loading });

      if(this.props.loading){
        REF.setState({ submit: false });
      }

      this.props.onHide();
    }

  }

  render () {
    return (
      <>
      <Modal   
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
              Filtro do Relatório de Cardápios {this.state.tipo_movimentacao}
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body style={{ height: '60vh' }}>        
          <FiltroForm
            empresa={this.props.empresa}
            show={this.props.show}
            setfilters={this.props.setfilters}
            submit={this.state.submit}
          />       
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  
  formSubmit = () =>  {

    this.setState({ submit: true });
   
  }  
}

export default FiltroModal;