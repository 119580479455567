export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
  }

  export function def(chain){
      try{
        return true;
      } catch (e){
          return false;
      }
  }
  


  export default def;