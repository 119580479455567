import React from 'react';

import { Card, Accordion, ListGroup, ListGroupItem } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import EstoqueMenu from './EstoqueMenu';

class MovimentacoesMenu extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            show: this.props.show,
            extraclasses: ''
        };
  }

    toggle = () => {
        let REF = this;

        this.props.onchange();

        if(this.state.show){
            this.setState({ show: false, extraClasses: '' });
            setTimeout(() => REF.setState({ extraClasses: '' }), 500);
        } else {
            this.setState({ show: true, extraClasses: 'active' });
        } 
    }


    render(){
        return (<Accordion defaultActiveKey={this.props.defaultActiveKey} >
            <Card>
                <Accordion.Header className={"lv-1 " + this.state.extraClasses} onClick={this.toggle}  as={Card.Header} eventKey="0">
                    <div className="w-icon"><i className="fa fa-exchange"></i></div> <span className="text">Movimentações</span>
                </Accordion.Header>
                <Accordion.Body eventKey="0">
                <Card.Body>
                <ListGroup>
                        <ListGroupItem>
                            <Link className="lv-2" onClick={this.props.ontoggle} to="/movimentacao/"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Gerenciar movimentações</Link>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Link className="lv-2" onClick={this.props.ontoggle} to="/sangria-info/"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Info. Sangrias</Link>
                        </ListGroupItem>
                    </ListGroup>
                    <EstoqueMenu show={this.props.show} onchange={this.props.onchange} ontoggle={this.props.ontoggle} />
            
                </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion>);
    }

}   

export default MovimentacoesMenu;

