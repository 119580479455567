import React from 'react';
import {Tabs, Tab, Popover, Overlay } from 'react-bootstrap';
import DadosForm from './DadosForm';
import {parseDecimal} from '../../util/inputMask';
import GerarParcelas from '../../forma-pagamento/GerarParcelas';

class DadosTab extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      produto_id: 0,
      icone_select_id : '',
      icone_select_url : '',
      produto_id: '',
      key: 'home',
      formula_enabled: false
    };
      
  }

  componentDidUpdate = (prevProps) => {

    if(this.props.form != null
      && Object.getOwnPropertyNames(this.props.form).length > 0
      && this.state.produto_id != this.props.form.id){
      this.setState({ produto_id: this.props.form.id });   
    }
  }

  setCurrentIcon = (id, url) => {
    this.setState({ icone_select_id: id, icone_select_url: url });
  }

  toggleFormula = (status) => {
    this.setState({ formula_enabled: status });
  }

  render(){
    let REF = this;


    let installment_enable = parseFloat(parseDecimal(this.props.vldocumento)) > 0;

    return (
            <>

      <div className="DadosTab">

      <div hidden={installment_enable} role="tooltip" x-placement="top" className="fade show popover bs-popover-top" id="popover-forma-pagamento">
        <div className="arrow">
        </div>
        <h3 className="popover-header">Parcelas</h3>
        <div className="popover-body">Preencha "Valor da Nota" para habilitar
        </div>
      </div>
      <Tabs defaultActiveKey="dados" transition={false}>
     
            <Tab eventKey="dados" title="Dados">
                <DadosForm post_confirm={this.props.post_confirm} set_centrocustos={(centrocustos_id) => REF.setState({ centrocustos_id })} set_fornecedor={(fornecedor_id) => REF.setState({ fornecedor_id })} tipo={this.props.tipo} import={this.props.import} emitente={this.props.emitente} form={this.props.form} formcontrol={this.props.formcontrol} />
            </Tab>
            <Tab eventKey="forma_pagto" title="Parcelamento" disabled={!installment_enable}>
                <GerarParcelas post_confirm={this.props.post_confirm} change={this.props.change_formaspagamento} fornecedor_id={REF.state.fornecedor_id} centrocustos_id={REF.state.centrocustos_id} tipo={this.props.tipo} nfe_id={this.props.nfe_id} submit={this.props.submit} form={this.props.form} vldocumento={this.props.vldocumento} formcontrol={this.props.formcontrol}  />
            </Tab>         
      </Tabs>

   
  
        </div>
        </>
    );
  }
}


export default DadosTab;