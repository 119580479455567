import React from 'react';
import Emissao2ViaDataTable from './Emissao2ViaDataTable'; 
import {Row, Container, Button, ButtonToolbar, Col} from 'react-bootstrap';
import CartaoSelect from '../CartaoSelect';
import {selectChangeHandler} from '../../CustomForm';
import Modal2Via from './Modal2Via';

class Emissao2Via extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      data: {},
      show: false
    };

    this.props.titlebar(this.props.title, this.props.description);
    this.handleTableRefresh = this.handleTableRefresh.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.setModalShow = this.setModalShow.bind(this);
  }

  setModalShow  = (status, data) => {

    if(data != null){
      this.setState({  data: data });
    }

 
    this.setState({  show: status, refresh: !status });
  }

    componentDidMount(){
      window.checkUserCan('segunda_via');    
    }


  handleTableRefresh(refresh){
    this.setState({ refresh : refresh });
    var Rs = this;
    setTimeout(function(){
      Rs.setState({ refresh : false });
    }, 100);
  }
  
  render (){
    let REF = this;
    return (<Container fluid={true}>
           <Row>
             <Col md={4} offset={8} className="ml-auto my-3">
             
          <ButtonToolbar>
                <Button  className="d-block ml-auto" variant="success" onClick={() => REF.setModalShow(true)}>
                <span className="fa fa-plus-square"></span> Registrar 2a via
                </Button>              
              <Modal2Via
                form={{}}
                show={this.state.show}              
                onHide={() => REF.setModalShow(false)}
              />
            </ButtonToolbar> 
            </Col>
          </Row>
          <Row>
            <Col>
              <Emissao2ViaDataTable refresh={this.state.refresh} setModalShow={this.setModalShow}/>
            </Col>
          </Row>
          </Container>
      );
    }
  }
  
  export default Emissao2Via;