import React from 'react';
import {sessionGet} from '../../usuario/Auth';
import {Row, Col} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import CaixaSelect from '../../caixa/CaixaSelect';
import { inputChangeHandler, isValid } from '../../CustomForm';
import {getYmd} from '../../util/date';

class FiltroForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            empresa: sessionGet('empresa'),
            validator: {},
            show: false,
            cal_inicio: new Date(),
            cal_fim: new Date(),
            inicio: getYmd(new Date()),
            fim: getYmd(new Date()),
            terminal: '',
            caixa: ''
        };
        
        this.inputChangeHandler = inputChangeHandler.bind(this);
        
        this.isValid = isValid.bind(this);
    }
    
    formattedState = () => {

      let state_old = {};
      Object.assign(state_old, this.state);
      delete state_old.validator;
      delete state_old.cal_inicio;
      delete state_old.cal_fim;
      delete state_old.show;
      
      if(typeof state_old.caixa == "undefined")
      delete state_old.caixa;

      return state_old;
    }

    componentDidUpdate(prevProps, prevState){

      if(prevState != this.state){
        let REF = this;
  
        REF.props.modalcontrol(REF);
    
      }
    }

    componentDidMount() {
        this.setState({ show: this.props.show });
    }

    inicioChangeHandler = (date) => {

      if(date == null){
        this.setState({ cal_inicio: null, inicio: '' });
        return;
      }
  
      let REF = this;
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_inicio: dt, inicio: dt_format }); 
    }
  
    fimChangeHandler = (date) => {
  
  
      if(date == null){
        this.setState({ cal_fim: null, fim: '' });
        return;
      }
  
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_fim: dt, fim: dt_format }); 
    }
    
    render(){
        
        return(
            <>
          <input value={this.state.empresa} name="empresa" type="hidden" />
          <Row className="my-2">
          <Col md="3" className="p-1">
          </Col>
          <Col md="3" className="p-1 cj">
              <label>de</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="col_inicio" id="f-inicio" value={this.state.cal_inicio} onChange={this.inicioChangeHandler} />
          </Col>
            <Col md="3" className="p-1 cj">
              <label>até</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="col_fim" id="f-fim" value={this.state.cal_fim} onChange={this.fimChangeHandler} />
          </Col>
        </Row>   
          <Row>
      <div className="col-md-6 m-auto">
        <label>Caixa</label>
        <CaixaSelect keyfield="id" change={this.inputChangeHandler} empresa={this.state.empresa} />
            </div>	
      </Row>
  
            </>
            );
        }        
    }
    export default FiltroForm;