import React from 'react';
import {Row, Col} from 'react-bootstrap';
import CaixaSelect from '../../caixa/CaixaSelect';
import TerminalSelect from '../../terminal/TerminalSelect';
import { inputChangeHandler, isValid } from '../../CustomForm';
import DatePicker from 'react-date-picker';
import {getYmd} from '../../util/date';
import {sessionGet} from '../../usuario/Auth';

class FiltroForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
          empresa: sessionGet('empresa'),
            validator: {},
            show: false,
            edit_lock: true,
            cal_inicio: new Date(),
            cal_fim: new Date(),
            inicio: getYmd(new Date()),
            fim: getYmd(new Date()),
            terminal: ''

        };
        
        this.inputChangeHandler = inputChangeHandler.bind(this);
        
        this.isValid = isValid.bind(this);
    }
    
    formattedState = () => {

      let state_old = {};
      Object.assign(state_old, this.state);
      delete state_old.validator;
      delete state_old.cal_inicio;
      delete state_old.cal_fim;
      delete state_old.show;
      delete state_old.edit_lock;

      return state_old;
    }

   

    componentDidUpdate(prevProps, prevState){

      if(prevState != this.state){
        let REF = this;
        setTimeout(function() {
            REF.props.modalcontrol(REF);
        }, 1000);
      }
    }
    
    componentDidMount() {
        this.setState({ show: this.props.show });
    }

    inicioChangeHandler = (date) => {

      if(date == null){
        this.setState({ cal_inicio: null, inicio: '' });
        return;
      }
  
      let REF = this;
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_inicio: dt, inicio: dt_format }); 
    }
  
    fimChangeHandler = (date) => {
  
  
      if(date == null){
        this.setState({ cal_fim: null, fim: '' });
        return;
      }
  
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_fim: dt, fim: dt_format }); 
    }
    
    render(){
        
        return(
            <>
        
             <Row className="my-2">
          <Col md="3" className="p-1">
          </Col>
          <Col md="3" className="p-1 cj">
              <label>de</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="col_inicio" id="f-inicio" value={this.state.cal_inicio} onChange={this.inicioChangeHandler} />
          </Col>
            <Col md="3" className="p-1 cj">
              <label>até</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="col_fim" id="f-fim" value={this.state.cal_fim} onChange={this.fimChangeHandler} />
          </Col>
        </Row>
        <Row>
        <div className="col-md-3">
             <label>Tipo</label>
        <select className="form-control" name="tipo" onChange={this.inputChangeHandler} id="cab-tipo">
        <option value="">:: Selecione ::</option>        
         <option  value="0,1">Cartão Lanchar</option>
         <option value={5}>Dinheiro</option>
         <option value={9}>Cartão</option>
         <option value={10}>Ticket</option>
         <option value={11}>Cheque</option>
         <option value={15}>Cartão de débito</option>
         <option value={16}>Cartão de crédito</option>
         <option value={7}>Sangria</option>
         <option value={6}>Suprimento</option>
         </select>
        </div>
      <div className="col-md-3">
        <label>CV</label><input className="form-control" onChange={this.inputChangeHandler} id="cv" name="cv"  />
      </div>
      <div className="col-md-6">
        <label>Titular</label><input className="form-control" onChange={this.inputChangeHandler} id="titular" name="titular" />
      </div>
    </Row>
         <Row>
      <div className="col-md-6">
        <label>Situação</label>
        <select className="form-control" name="cab.cancelado" onChange={this.inputChangeHandler} id="cab.cancelado">
        <option value="">:: Selecione ::</option>
          <option value>TODOS</option><option value={0}>SOMENTE REALIZADOS</option><option value={1}>SOMENTE CANCELADOS</option></select>
        </div>		
  	
      <div className={this.state.tipo == "0,1" ? "d-block col-md-6" : "d-none"}>
      <label>Tipo C. Lanchar</label>
        <select className="form-control" name="car.tipo" id="car.tipo">
        <option value="">:: Selecione ::</option>
          <option value="A">PRE_PAGO</option><option value="P">POS_PAGO</option><option value="LANCHOU">LANCHOU</option></select>
        </div>
      <div className="col-md-6">
      <label>Terminal</label>
          <TerminalSelect change={this.inputChangeHandler} empresa={this.props.empresa} />
        </div>	
        </Row>
         <Row>
      <div className="col-md-6">
        <label>Caixa</label>
        <CaixaSelect change={this.inputChangeHandler} empresa={this.props.empresa} />
            </div>		
 
      </Row>
     
            </>
            );
        }        
    }
    export default FiltroForm;