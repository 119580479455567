import React from 'react';
import RecargaDataTable from './RecargaDataTable'; 
import {ButtonToolbar, Button} from 'react-bootstrap';
import RecargaModal from './RecargaModal';
import {setUser, setUserPermissions} from '../usuario/Auth';

class Recargas extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
        loading: false,
      refresh: false,
      show: false,
      form: {},
      session: {
        user: {

        }
      }
    };


  }

  updateLoading = (status) => {
    this.setState({ loading: status  });
}
   

  setModalShow  = (status, data) => {
      this.setState({  show: status , form: data, refresh: !status });
 }

  componentDidMount(){
    window.checkUserCan('recarregar_cartao');      
    this.props.titlebar(this.props.title, this.props.description);
  }


  
  render (){
    let REF = this;
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
              <RecargaModal
                form={this.state.form}
                show={this.state.show}              
                onHide={() => REF.setModalShow(false, {})}
              />
              <Button hidden={window.userCan.recarregar_cartao !== 1} variant="success" onClick={() => REF.setModalShow(true, {})}>Recarregar</Button>
            </ButtonToolbar>            
          </div>
        </div>      
      <RecargaDataTable setloading={this.updateLoading} hidden_column={"qtd,total"} refresh={REF.state.refresh} setModalShow={REF.setModalShow} />
      </div>);
    }
  }
  
  export default Recargas;