import React from 'react';
import '../Modal.scss';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import {money_format} from '../CustomForm';
import {mask_decimal} from '../util/inputMask';
import url_btn_close from '../img/icons/close.png';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';

class MovCabDetalhes extends React.Component{

    constructor(props) {
      super(props);

      this.state = {   
        show: this.props.show,
        form: '',
        qtde: 0,
        total: 0,
        loading: false
      };

    }
    
    componentDidUpdate(prevProps){
        if(this.props.form.id != this.state.form.id)
        this.setState({ form: this.props.form });

        if(
          Object.getOwnPropertyNames(this.state.form).length > 0
          && typeof this.state.form.detalhes !== "undefined"
          && this.props.show != this.state.show
        ) {
          let REF = this;
          this.setState({ show: this.props.show });
          setTimeout(() => REF.updateSum(), 250);
        }
    }

    updateSum = () => {

      let qtde = 0.0;
      let total = 0.0;

      this.state.form.detalhes.map(function(d){
        qtde += parseFloat(d.qtde);
        total +=  parseFloat(d.total)
      });

      this.setState((state) => ({
        total: total,
        qtde: qtde
    }));

    }

    listDetalhes = (d, i) => {
 
            return (<tr>
                <td>{d.descricao} ({d.unidade})</td>
                <td>{money_format(d.preco) }</td>
                <td>{mask_decimal(parseFloat(d.qtde).toFixed(2)) }</td>
                <td>{money_format(d.total) }</td>
            </tr>);
    }

  render () {

    const REF = this;
    return (
      <>
      <Modal    
       
      show={this.props.show}
        onHide={this.props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
          Detalhes da {this.props.recarga ? "recarga" : "movimentação"} {this.state.tipo_movimentacao} 
          </Modal.Title>
         
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body   ref={REF}   >
     
        <fieldset className="border border-warning">
          <Row>
            <Col>
            <ul className="raw m-2">
                <li hidden={typeof this.state.form.unique == "undefined"}>CV: {this.state.form.unique}</li>    
                <li hidden={typeof this.state.form.local == "undefined"}>Usuário: {this.state.form.local}</li>    
                <li hidden={typeof this.state.form.bandeira_cartao == "undefined" || this.state.form.bandeira_cartao == null}>Fornecedor: {this.state.form.bandeira_cartao}</li>    
              </ul>
              </Col>
              <Col>
                <ul className="raw m-2">
                <li hidden={typeof this.state.form.tipo_operacao == "undefined"}>Operação: {this.state.form.tipo_operacao}</li>    
                <li hidden={typeof this.state.form.emissao_format == "undefined"}>Emissão: {this.state.form.emissao_format}</li>    
           
              </ul>
            </Col>
          </Row>
          <legend>Identificação</legend>
          
            </fieldset>  
            <fieldset className="border mt-3 border-info">
          <legend>Itens</legend>
    
            <table className="table table-stripe" hidden={typeof this.state.form.detalhes === "undefined"}>
            <thead>
                <tr>
                    <th>Descrição</th>
                    <th>Preço</th>
                    <th>Quantidade</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>
                {
                   (() => {
                       if(
                         Object.getOwnPropertyNames(this.state.form).length > 0
                         && typeof this.state.form.detalhes !== "undefined"
                       )
                       return this.state.form.detalhes.map(this.listDetalhes);
                   })() 
                }
            </tbody>
            <tfoot>
            <tr>
                <th>Total</th>
                <th></th> 
                <th>{mask_decimal(parseFloat(this.state.qtde).toFixed(2))}</th>
                <th>{money_format(this.state.total)}</th>       
            </tr>
            </tfoot>
            </table>    
            </fieldset> 
        </Modal.Body>
                <Modal.Footer>
                <Button hidden={this.props.cancel_hide || window.userCan.recarregar_cartao !== 1} className="mr-auto" variant="danger" onClick={this.formCancel}>Cancelar</Button>

                <ReactToPrint
                            className="ml-auto"
                            trigger={() => <button className="btn btn-secondary float-right"><i className="fa fa-file-text-o"></i> Imprimir</button>}
                            content={() => REF.current}
                          /> 
                </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }

  formCancel = () => {
    let c = window.confirm("Deseja mesmo cancelar a movimentação "+this.props.form.unique+" de "+this.props.form.local+" ?");
   
    if (c == true) {
      this.cancel();       
    }

  }

  cancel = () => {
    let REF = this;
    axios.post(CFG.URL_API+'/movimentacao/cancel', {id: this.props.form.id}, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(function(){
      REF.props.onHide();
    });
   }
 
}

export default MovCabDetalhes;