import React from 'react';
import Login from './usuario/Login';
import DRE from './dre/DRE';
import Dashboard from './dashboard/Dashboard';
import ContasReceber from './conta-receber/ContasReceber';
import ContasPagar from './conta-pagar/ContasPagar';
import FormasPagamento from './forma-pagamento/FormasPagamento';
import CentrosCusto from './centrocusto/CentrosCusto';
import LoginRecuperar from './usuario/LoginRecuperar';
import GrupoProdutos from './grupo-produto/GrupoProdutos';
import GrupoFornecedores from './grupo-fornecedor/GrupoFornecedores';
import Fornecedores from './fornecedor/Fornecedores';
import SubgrupoProdutos from './subgrupo-produto/SubgrupoProdutos';
import Categorias from './produto-categoria/Categorias';
import Responsaveis from './responsavel/Responsaveis';
import Cartoes from './cartao/Cartoes';
import Produtos from './produto/Produtos';
import ExtratoMovimentacao from './relatorio/movimentacao/Extrato';
import FechamentoRelatorio from './relatorio/fechamento/FechamentoRelatorio';
import RecargaRelatorio from './relatorio/recarga/Extrato';
import { Route, Routes, Switch, BrowserRouter } from "react-router-dom";
import ProdutosVendidos from './relatorio/produto/venda/Extrato';
import Estoque from './estoque/Estoque';
import NotaFiscal from './estoque/nota/NotaFiscal';
import NotaFiscalForm from './estoque/nota/NotaFiscalForm';
import RelatorioDRE from './relatorio/dre/Relatorio';
import Error404 from './error/Error404';
import ProdutoGrade from './produto-grade/ProdutoGrade';
import Movimentacoes from './movimentacao/Movimentacoes';
import Recargas from './recarga/Recargas';
import RecargaComprovante from './recarga/RecargaComprovante';

//import RelatorioCartao from './relatorio/cartao/Extrato';
import RelatorioCartao from './relatorio/cartao';

import SangriaInfo from './sangria-info/SangriaInfo';
import RelatorioCardapio from './relatorio/cardapio/Relatorio';
import Turma from './turma/Turmas';
import Usuarios from './usuario/Usuarios';
import Cartao2Via from './cartao/2via/Emissao2Via';
import TEF from './relatorio/tef/Extrato';
import EstoqueAlerta from './relatorio/produto/estoque/PosicaoEstoque';
import Atividade from './relatorio/atividade/Extrato';
import Inatividade from './relatorio/inatividade/Extrato';
import Relatorios from './relatorio/Index';

import DadosCadastrais from './empresa/DadosCadastrais'

export function BRoutes(comp_props){
    let REF = this;
   
    return (
        <>
   <Switch>
            <Route path="/login"  render={(props) => <Login {...props} title="Login" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/login-recuperar"  render={(props) => <LoginRecuperar {...props} title="Recuperar" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/produto"  render={(props) => <Produtos {...props} title="Produtos" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/cartao"  render={(props) => <Cartoes {...props} title="Cartões" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/cartao-2via"  render={(props) => <Cartao2Via {...props} title="2ª via do cartão" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/responsavel"  render={(props) => <Responsaveis {...props} title="Responsáveis" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/produto-categoria"  render={(props) => <Categorias {...props} title="Categorias" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/produto-grade"  render={(props) => <ProdutoGrade {...props} title="Grade de produtos" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/grupo-produto"  render={(props) => <GrupoProdutos {...props} title="Grupos de Produtos" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/subgrupo-produto"  render={(props) => <SubgrupoProdutos {...props} title="Subgrupos de Produtos" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/grupo-fornecedor"  render={(props) => <GrupoFornecedores {...props} title="Grupos de Fornecedores" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/fornecedor"  render={(props) => <Fornecedores {...props} title="Fornecedores" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/dre"  render={(props) => <DRE {...props} title="Indicadores D.R.E." description="" titlebar={comp_props.titlebar} />} />
            <Route path="/centro-custo"  render={(props) => <CentrosCusto {...props} title="Centros de Custo" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/conta-pagar"  render={(props) => <ContasPagar {...props} title="Contas a Pagar" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/conta-receber"  render={(props) => <ContasReceber {...props} title="Contas a Receber" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/forma-pagamento"  render={(props) => <FormasPagamento {...props} title="Formas de Pagamento" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/estoque"  render={(props) => <Estoque {...props} title="Estoque" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/movimentacao"  render={(props) => <Movimentacoes {...props} title="Movimentações" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/recarga"  render={(props) => <Recargas {...props} title="Recargas" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/recarga-comprovante"  render={(props) => <RecargaComprovante {...props} title="Comprovante de Recarga" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/sangria-info"  render={(props) => <SangriaInfo {...props} title="Informações de Sangria" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/usuario"  render={(props) => <Usuarios {...props} title="Usuários" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/turma"  render={(props) => <Turma {...props} title="Turmas" description="" titlebar={comp_props.titlebar} />} />
          
       
            <Route exact path="/nota/:tipo/:id?"  render={(rProps) => <NotaFiscalForm {...rProps.match.params} {...rProps} title="Nota fiscal" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/nota"  render={(props) => <NotaFiscal {...props} title="Nota fiscal" description="" titlebar={comp_props.titlebar} />} />
            
            <Route path="/relatorios" render={(props) => <Relatorios {...props} title="Relatórios" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/tef" render={(props) => <TEF {...props} title="Extrato TEF" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/cardapio"  render={(props) => <RelatorioCardapio {...props} title="Cardápio - Relatório" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/cartao"  render={(props) => <RelatorioCartao {...props} title="Cartão - Extrato" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/dre"  render={(props) => <RelatorioDRE {...props} title="Relatório D.R.E - Demonstrativo de Resultados do Exercício" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/produtos-vendidos"  render={(props) => <ProdutosVendidos {...props} title="Produtos vendidos" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/movimentacao"  render={(props) => <ExtratoMovimentacao {...props} title="Extrato de vendas" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/fechamento"  render={(props) => <FechamentoRelatorio {...props} title="Fechamento" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/recarga"  render={(props) => <RecargaRelatorio {...props} title="Recargas" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/estoque-alerta"  render={(props) => <EstoqueAlerta {...props} title="Alertas de estoque baixo" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/atividade"  render={(props) => <Atividade {...props} title="Relatório de atividade de cartões" description="" titlebar={comp_props.titlebar} />} />
            <Route path="/relatorio/inatividade"  render={(props) => <Inatividade {...props} title="Relatório de inatividade de cartões" description="" titlebar={comp_props.titlebar} />} />

            <Route path="/empresa/dados-cadastrais"  render={(props) => <DadosCadastrais {...props} title="Dados cadastrais" description="" titlebar={comp_props.titlebar} />} />
            
            
            <Route exact path="/" render={(props) => <Dashboard {...props} title="Dashboard" description="" titlebar={comp_props.titlebar} />}/>
            
            <Route path='*' exact={true} component={Error404} />
        </Switch>
        </>
    );
}

export default BRoutes;