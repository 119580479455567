import React from 'react';
import SangriaInfoDataTable from './SangriaInfoDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import SangriaInfoModal from './SangriaInfoModal';

class SangriaInfo extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      show: false,
      activeIndex: 0
    };

    this.props.titlebar(this.props.title, this.props.description);

    this.hideModal = this.hideModal.bind(this);
    this.setModalShow = this.setModalShow.bind(this);
  }

  setModalShow (status, data){

    if(data != null){
      this.setState({  data: data });
    }

    this.setState({  show: status });
  }

  showModal = () => {
    this.setState({ show: true, data: null });
  }

  hideModal(){
    this.setState({ show: false });
  }

  render (){
    let REF = this;
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => REF.showModal()}>
                  <span className="fa fa-plus-square"></span>  Cadastrar Informação de Sangria
                </Button>              
              <SangriaInfoModal
                form={{}}
                show={this.state.show}             
                onHide={this.hideModal}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <SangriaInfoDataTable setModalShow={this.setModalShow}/>
      </div>);
    }
}
  
export default SangriaInfo;