import React from "react"
import { Row, Col } from "react-bootstrap"
import { inputChangeHandler, isValid, checkboxClickHandler } from "../../../CustomForm"
import "../../../CustomForm.scss"
import DatePicker from "react-date-picker"
import { getYmd } from "../../../util/date"
import CategoriaSelect from "../../../produto-categoria/CategoriaSelect"
import GrupoProdutoSelect from "../../../grupo-produto/GrupoProdutoSelect"
import SubgrupoProdutoSelect from "../../../subgrupo-produto/SubgrupoProdutoSelect"
import ProdutoSelect from "../../../produto/ProdutoSelect"
import { sessionGet } from "../../../usuario/Auth"
import { firstDateOfMonth, lastDateOfMonth } from "../../../util/date"

class FiltroForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            empresa: sessionGet("empresa"),
            validator: {},
            show: false,
            edit_lock: true,
            cal_inicio: new Date(),
            cal_fim: new Date(),
            inicio: firstDateOfMonth(),
            fim: lastDateOfMonth(),
            horario_de: "",
            horario_ate: ""
        }

        this.checkboxClickHandler = checkboxClickHandler.bind(this)
        this.inputChangeHandler = inputChangeHandler.bind(this)

        this.isValid = isValid.bind(this)
    }

    formattedState = () => {
        let state_old = {}
        Object.assign(state_old, this.state)
        delete state_old.validator
        delete state_old.cal_inicio
        delete state_old.cal_fim
        delete state_old.show
        delete state_old.edit_lock

        return state_old
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState != this.state) {
            let REF = this
            setTimeout(function() {
                REF.props.modalcontrol(REF)
            }, 1000)
        }
    }

    componentDidMount() {
        this.setState({ show: this.props.show })
    }

    inicioChangeHandler = date => {
        if (date == null) {
            this.setState({ cal_inicio: null, inicio: "" })
            return
        }

        let REF = this
        let dt = date
        let dt_format =
            dt.getFullYear() +
            "-" +
            (dt.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            dt
                .getDate()
                .toString()
                .padStart(2, "0")
        this.setState({ cal_inicio: dt, inicio: dt_format })
    }

    fimChangeHandler = date => {
        if (date == null) {
            this.setState({ cal_fim: null, fim: "" })
            return
        }

        let dt = date
        let dt_format =
            dt.getFullYear() +
            "-" +
            (dt.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            dt
                .getDate()
                .toString()
                .padStart(2, "0")
        this.setState({ cal_fim: dt, fim: dt_format })
    }

    handleSelect = (name, value) => {
        this.setState({ [name]: value })
    }

    render() {
        return (
            <>
                <Row>
                    <Col md="6" className="p-1 cj">
                        <label>de</label>
                        <DatePicker
                            locale="pt-BR"
                            autoComplete="off"
                            name="B_emissao"
                            id="f-inicio"
                            value={this.state.cal_inicio}
                            onChange={this.inicioChangeHandler}
                        />
                    </Col>
                    <Col md="6" className="p-1 cj">
                        <label>até</label>
                        <DatePicker
                            locale="pt-BR"
                            autoComplete="off"
                            name="B_emissao_"
                            id="f-fim"
                            value={this.state.cal_fim}
                            onChange={this.fimChangeHandler}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md="6" className="p-1">
                        <label>Horário - de</label>
                        <input
                            autoComplete="off"
                            className="form-control"
                            name="horario_de"
                            id="f-inicio"
                            value={this.state.horario_de}
                            onChange={this.inputChangeHandler}
                        />
                    </Col>
                    <Col md="6" className="p-1">
                        <label>Horário - até</label>
                        <input
                            autoComplete="off"
                            className="form-control"
                            name="horario_ate"
                            id="f-fim"
                            value={this.state.horario_ate}
                            onChange={this.inputChangeHandler}
                        />
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col md="6" className="p-1">
                        <label className="text-uppercase d-block">Categoria</label>
                        <CategoriaSelect
                            empresa_id={this.state.empresa}
                            categoria={this.state.categoria}
                            change={this.handleSelect}
                        />
                    </Col>

                    <Col md="6" className="p-1">
                        <label className="text-uppercase d-block">Produto</label>
                        <ProdutoSelect
                            empresa={this.state.empresa}
                            grupo={this.state.grupo}
                            subgrupo={this.state.subgrupo}
                            change={this.handleSelect}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md="6" className="p-4">
                        <label htmlFor="f-agrupar-por-produto" className="btn btn-secondary">
                            <input
                                type="checkbox"
                                autoComplete="off"
                                name="agrupar"
                                id="f-agrupar-por-produto"
                                checked={this.state.agrupar}
                                value={1}
                                onClick={this.checkboxClickHandler}
                            />
                            Agrupar por produto
                        </label>
                    </Col>

                    <Col md="6" className="p-1">
                        <label htmlFor="orderby">Ordenar por</label>
                        <select
                            id="orderby"
                            name="orderby"
                            className="form-control"
                            onChange={this.inputChangeHandler}
                        >
                            <option value="">Selecione</option>
                            <option value="descricao">Descrição do produto</option>
                            <option value="categoria">Categoria</option>
                            <option value="quantidade">Quantidade Vendido</option>
                        </select>
                    </Col>
                </Row>
            </>
        )
    }
}
export default FiltroForm
