import React from 'react'; // Import React
import {Container, Button, ButtonToolbar, Row, Col} from 'react-bootstrap';
import { sessionGet } from '../../usuario/Auth';
import FiltroModal from './FiltroModal';
import FechamentoDataTable from './FechamentoDataTable';
import MediasDataTable from './MediasDataTable';
import TotaisDataTable from './TotaisDataTable';
import {firstDateOfMonth, lastDateOfMonth}  from '../../util/date';

class FechamentoRelatorio extends React.Component{
    
    constructor(props) {
        super(props);
        

        this.state = {
            loading: false,
            movimentacao_id: '',
            empresa: sessionGet('empresa'),
            data: [],
            searchTerm: null,
            activeIndex: undefined,
            show_modal_filters: false,
            filters: {
                caixa: '',
                inicio: firstDateOfMonth(),       
                fim: lastDateOfMonth(),       
            },
            result_count: 0
        };

        this.props.titlebar(this.props.title, this.props.description);
        
    }

    setFiltroModalShow = () => {
        this.setState({ show_modal_filters: true });
    }
    
    updateListing = (columnsSearch) => {
        this.getListing('fechamento?', columnsSearch);
    }

    setFilters = (form_state) => {
        this.setState({ filters: form_state, loading: true });
    }

    hideFiltroModal = () => {
        this.setState({ show_modal_filters: false });
    }

    updateLoading = (status) => {
        this.setState({ loading: status  });
    }
       
    resultCount = (count) => {
        this.setState({ result_count: count })
    }

    render(){
        return (<>
           <Container fluid={true}>
            <div className="row">
                <div className="col-12 my-2"> 
                    <ButtonToolbar className="float-right m-3">
                        <Button variant="success" onClick={() => this.setFiltroModalShow(true)}>
                            <span className="fa fa-filter"></span>
                        </Button>              
                    <FiltroModal
                        loading={this.state.loading}
                        empresa={this.state.empresa}
                        show={this.state.show_modal_filters} 
                        setfilters={this.setFilters} 
                        onHide={this.hideFiltroModal} 
                    />
                    </ButtonToolbar>            
                </div>
            </div>
            <Row>
                <Col>
                    <TotaisDataTable hidden={this.state.result_count == 0} result_count={this.state.result_count} empresa={this.state.empresa} caixa={this.state.filters.caixa} inicio={this.state.filters.inicio} fim={this.state.filters.fim} />
                </Col>
                <Col>
                    <MediasDataTable hidden={this.state.result_count == 0} result_count={this.state.result_count} empresa={this.state.empresa} caixa={this.state.filters.caixa} inicio={this.state.filters.inicio} fim={this.state.filters.fim} />
                </Col>
            </Row>
        <FechamentoDataTable resultcount={this.resultCount} setloading={this.updateLoading} title={this.props.title} sizeperpage={this.state.data.length} empresa={this.state.empresa} filters={this.state.filters} />
        </Container>
        </>);
    }
    }
    
    export default FechamentoRelatorio;