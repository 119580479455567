import React from 'react'; // Import React
import CustomDataTable from '../CustomDataTable'; // Import React
import '../DataTable.scss';
import {Col} from 'react-bootstrap';
// es6
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import SubgrupoProdutoModal from './SubgrupoProdutoModal';
import {sessionGet} from '../usuario/Auth';


class SubgrupoProdutosDataTable extends CustomDataTable{
  
  constructor(props) {
    super(props);
    
    this.state = {
        empresa: sessionGet('empresa'),
        form:[],
        data: [],
        searchTerm: null,
        activeIndex: undefined
    };

    this.handleTableRefresh = this.handleTableRefresh.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.tablerowupdate = this.tablerowupdate.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
    this.getListing('subgrupo-produto?empresa='+this.state.empresa);
    
  }

  componentDidUpdate(prevProps){

    if(prevProps.form != this.props.form){
      this.setState({ form: this.props.form });
    }

  }

  setModalShow (status, data){

    if(data != null){

      this.setState({  form : data });
    }

    this.setState({  show: status });
  }

  hideModal(){
    this.setState({ show: false });
  }

  handleTableRefresh(refresh){

    this.setState({ refresh : refresh });

    var Rs = this;

    setTimeout(function(){
      Rs.setState({ refresh : false });
    }, 100);

  }

  getColumns(){
    return [
      {
        dataField: 'id',
        text: 'ID',
        //    hidden: true
        sort: true
      },{
        dataField: 'empresa',
        text: 'Empresa',
        sort: true,
        hidden: true
      },{
        dataField: 'grupo',
        text: 'Grupo'
      },{
        dataField: 'subgrupo',
        text: 'Subgrupo'
      }
    ];
  }

  tablerowupdate(form){
    let data = this.state.data; 
    this.setState({ data: this.updateTableRow(data, this.state.activeIndex, form) });
    this.forceUpdate();
  }

  updateTableRow(array, target_index, form) {
    return array.map((item, index) => {
      if (index == target_index) {
        let item_replace = Object.assign(item, form.state);
        // This isn't the item we care about - keep it as-is
        return item_replace;
      }
  
      // Otherwise, this is the one we want - return an updated value
      return item;
    })
  }

  formUpdate(form_data_complete){
    this.setState({ form: form_data_complete });
  }

  render(){
    let CDT = this;

    const selectRow = {
        mode: 'radio',
        hideSelectColumn: true,
        classes: 'active',
        clickToSelect: true
      };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({ activeIndex : rowIndex });
        CDT.setModalShow(true, row);
      }
    };
      
       const MainSearch = (props) => {
        let input;
        const handleFilterChange = () => {
          let results = props.onSearch(input.value);

          this.setState({ searchTerm : input.value  });

          let columnsSearch = {};
          for (var entry of this.getColumns()) {
              if(entry.dataField != 'empresa')
            columnsSearch[entry.dataField] = input.value;
          }

          this.getListing('subgrupo-produto?empresa='+this.state.empresa, columnsSearch);

        };
   
        return (
          <input
            placeholder="Filtro: comece a digitar para filtrar"
            onChange={handleFilterChange}
            className="form-control"
            autoFocus
            style={ { backgroundColor: '#fff' } }
            ref={ n => input = n }
            type="text"
            defaultValue={this.state.searchTerm}
            />
          );
        };

        return (
          <div>
            <SubgrupoProdutoModal
            show={this.state.show}        
            form={this.state.form}        
            onHide={this.hideModal}
            tablerowupdate={this.tablerowupdate}
            formupdate={this.formUpdate}
            />  
            <ToolkitProvider
            keyField="id"
            data={ this.state.data }
            columns={ this.getColumns() }
            search
            >
                {
                    props => (
                    <div>
                        <Col className="col-md-4 float-right my-1 p-0">
                            <MainSearch { ...props.searchProps }  />
                        </Col>
                        <br />
                        <BootstrapTable
                        { ...props.baseProps }
                        selectRow={ selectRow }
                        keyField='id' data={ this.state.data } columns={ this.getColumns() } pagination={ paginationFactory({  sizePerPage: 5 }) } rowEvents={ rowEvents } 
                        />
                    </div>
                    )
                }
                </ToolkitProvider>
            </div>
            );
          }
        }
        
        export default SubgrupoProdutosDataTable;
        
        