import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { inputChangeHandler, selectChangeHandler, isValid } from '../../CustomForm';

import {sessionGet} from '../../usuario/Auth';
import CartaoSelect from '../../cartao/CartaoSelect';
import ProdutoSelect from '../../produto/ProdutoSelect';

class FiltroForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
          loading: false,
          empresa: sessionGet('empresa'),
          validator: {},
          show: false,
          cartao: undefined
        };
        
        this.selectChangeHandler = selectChangeHandler.bind(this);
        this.inputChangeHandler = inputChangeHandler.bind(this);
        
        this.isValid = isValid.bind(this);
    }   

    componentDidUpdate(prevProps, prevState){

        if(prevProps.submit !== this.props.submit){
        this.props.setfilters(this.state);
      }

    }

    inicioChangeHandler = (date) => {

      if(date == null){
        this.setState({ cal_inicio: null, inicio: '' });
        return;
      }
  
      let REF = this;
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_inicio: dt, inicio: dt_format }); 
    }
  
    fimChangeHandler = (date) => {
  
  
      if(date == null){
        this.setState({ cal_fim: null, fim: '' });
        return;
      }
  
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_fim: dt, fim: dt_format }); 
    }

    render(){
        
        return(
            <>        
            <Row>
              <div className="col-md-6">
                <label>ID do cartão</label>
                <CartaoSelect change={this.selectChangeHandler} name="cartao" cartao={this.state.cartao} />
              </div>
              <div className="col-md-6">
                <label>Produto</label>
                <ProdutoSelect change={this.selectChangeHandler} name="produto" produto_id={this.state.produto} />
              </div>
            </Row>    
            <Row>
              <div className="col-md-6">
                <label>Dia</label>
                <select onChange={this.inputChangeHandler} name="dia" className="form-control" >
                <option value="" selected="selected">Selecione</option>
                <option value="0">Domingo</option>
                <option value="1">Segunda</option>
                <option value="2">Terça</option>
                <option value="3">Quarta</option>
                <option value="4">Quinta</option>
                <option value="5">Sexta</option>
                <option value="6">Sábado</option>
                </select>
              </div>
            </Row>   
            </>
            );
        }        
    }
    export default FiltroForm;