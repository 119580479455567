import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import { inputBlurHandler } from '../CustomForm';
import {sessionGet} from '../usuario/Auth';
import {Alert} from 'react-bootstrap';

class GrupoProdutoSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            list: [],
            grupo: '',
            empresa: sessionGet('empresa'),
            validator: {}
        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputBlurHandler = inputBlurHandler.bind(this);
        this.get = this.get.bind(this);
        this.get();
    }

    componentDidUpdate(prevProps, prevState){
     
    if(prevState.grupo != this.props.grupo)
    this.setState({ grupo : this.props.grupo });

    if(this.state.empresa != this.props.empresa)
    this.setState({ empresa : this.props.empresa });
      
  }

  inputChangeHandler(event){

    if(typeof this.props.onChange == "function")
      this.props.change(event);
  }

    render(){

        if(!this.state.loading && this.state.list.length == 1)
        return (<Alert variant="warning" className="d-block" >
            Lista vazia
        </Alert>);

        let CS = this;
        return (
            <select className="form-control" value={this.state.grupo} name="grupo" id="f-grupo" onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}  >
                {
                      this.state.list.map(function(val) {
                          return (<option key={val[0]} value={val[0]}>{val[1]}</option>) 
                      })
                }
            </select>
        );
    }

    get(){
        let CS = this; 

        axios.get(CFG.URL_API+'/grupo-produto?empresa='+this.state.empresa+'&selectMode=pluck&pluckKey=id&pluckValue=grupo', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);

            let htmlList = [];
            htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });
            entries.unshift(['', 'Selecione']);
            
            CS.setState({ list: htmlList, loading: false });

          //Perform action based on response
      })
        .catch(function(error){
      requestException(error);
 });
    }

}

export default GrupoProdutoSelect;
