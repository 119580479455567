import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import {getListing} from '../CustomDataTable'; // Import React
import { isValid} from '../CustomForm';
import { sessionGet } from '../usuario/Auth';
import {Row, Col, Alert} from 'react-bootstrap';
import FornecedorSelect from '../fornecedor/FornecedorSelect';
import FornecedorInput from '../fornecedor/FornecedorInput';

class FornecedoresForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      select_current_id: undefined,
      select_current_razao: undefined,
      produto_id: undefined,
      empresa: sessionGet('empresa'),
      ...this.props.form,
      data: [],
      input_list: [],
      item_exists: false,
      commit: false,
      loading: true,
      validator: {}
    };

    this.getListing = getListing.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
  
  }

  componentDidMount(){
   
    //listar fornecedores vinculados ao produto
    this.props.formcontrol(this);

  }

  inputChangeHandler(event){
    if(event.target.value != "" && typeof this.props.formcontrol === "function")
    this.props.formcontrol(this);
  }

  componentDidUpdate(prevProps, prevState){

    if(this.props.submit && prevProps.submit !== this.props.submit){
    
      this.post();
    
    }

      if(this.state.produto_id != this.props.produto_id){

        let REF = this;
        this.setState({ produto_id: this.props.produto_id });
      
      }
    
      if(typeof this.state.produto_id !== "undefined" && this.state.produto_id != ""
       && prevState.produto_id !== this.state.produto_id){
        this.updateListing();        
      }

      if(!this.state.commit){
        this.props.formcontrol(this);
        this.setState({ commit: true });
      }

      if(this.state.input_list.length == 0 && prevState.data !== this.state.data){
        let REF = this;
  
          setTimeout(() => REF.load_db(), 2000);
        
      }

    


  }

  componentDidMount(){
 
    this.setState({ commit: false });
  
  }

  updateListing = () => {
    let REF = this;
    this.getListing('fornecedor-produto?produto_id='+this.state.produto_id).then(function(response){
      
     
    });
  }

  render(){ 
      
    let REF = this;
  
    let countOrMessage = () => {

      let msg;

              //excluiu dados que vieram do banco
        if(REF.state.input_list.length == 0) 
       msg = 'Selecione da lista';


      if(REF.state.input_list.length > 0 && typeof this.state.produto_id !== "undefined")
      msg = '('+REF.state.input_list.length+')';

      if(REF.state.loading)
      msg = 'Aguarde...';


      return msg;
    };

    return (<>
    { (() => REF.add())() }
    <Row>
    <Col>
      <Alert variant="warning" className={this.state.item_exists ? "d-block" : "d-none"}>
        Já consta na lista
      </Alert>
    </Col>
    </Row>
    <Row>
      <Col>
    <fieldset className="mt-3">
      <legend>Fornecedores <span className="text-muted">{ countOrMessage() }</span></legend>
          { (() => REF.load_input_list())() }
      </fieldset>
      </Col>
         </Row>
     </>
    );
  }

  input = (key, id, fornecedor_id, razao) => {
    return (<div key={key}>
      <label>Fornecedor #{key}</label>
      <FornecedorInput onchange={this.inputChangeHandler} delete={this.delete} index={key} id={id} fornecedor_id={fornecedor_id} fornecedor_razao={razao} />
   </div>);
  }

  load_db = () => {

    let input_list_copy = this.state.input_list;
    var c = input_list_copy.length;

    this.state.data.map(function(f){
      c++;

        input_list_copy.unshift({ key: c, id: f.id, fornecedor: {
          id: f.fornecedor.id,
          razao: f.fornecedor.razao
        }});
     
    });

    let REF = this;
    setTimeout(() => REF.setState({ input_list: input_list_copy, loading: false }) , 1000);
  }

  item_exists = (fornecedor_id) => {
    return this.state.input_list.find(function(f) {
      return f.fornecedor.id === fornecedor_id;
    });
  }

  load_input_list = () => {
    let REF = this;
    return this.state.input_list.map(function(f){
        return REF.input(f.key, f.id, f.fornecedor.id, f.fornecedor.razao);
    });
  }

  onAddChange = (pair, actionMeta) => {

    let REF = this;

    if(pair === null)
    return null;
  
    if(this.item_exists(pair.value)){
      this.setState({ item_exists : true });
    } else {    
        this.setState({ fornecedor: pair.value, item_exists : false, select_current_id: pair.value, select_current_razao: pair.label });
        this.state.validator.fornecedor = true;
        setTimeout((e) => REF.create(e), 500);
    }

  }

  add = () => {
    let REF = this;
    return (
    <Row className="my-2" >
        <Col md="8">
            <FornecedorSelect change={REF.onAddChange} />
        </Col>
    </Row>
    )
  }

  create = (e) => {
    let list_copy = this.state.input_list;
    list_copy.unshift({ key: list_copy.length+1, id: null, 
      fornecedor : { 
        id: this.state.select_current_id,
        razao: this.state.select_current_razao 
      }
    }
  );
    this.setState({ input_list :  list_copy });
  }

   post = () => {


    let REF = this;

    if(this.state.input_list.length === 0){
      return REF.props.submit_confirm('FornecedoresForm');
    }

    return this.state.input_list.map(function(f){

      //apenas novos registros requerem ação
      if(f.id == null){
        f.produto_id = REF.state.produto_id;
        f.fornecedor_id = f.fornecedor.id;
        axios.post(CFG.URL_API+'/fornecedor-produto', f, axiosHeader())
        .then(function(response){
          //Perform action based on response
      })
        .catch(function(error){
      requestException(error);
 }).finally(() => REF.props.submit_confirm('FornecedoresForm'));
      }
 
    });
  
   }

  delete = (event, key) => {
    let data_copy = this.state.input_list;
    data_copy.splice(key,1);
    this.setState({ item_exists:false, input_list : data_copy });
  }

}

export default FornecedoresForm;
