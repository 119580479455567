import React from 'react';
import {requestException} from '../util/exception';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import { inputBlurHandler } from '../CustomForm';
import {sessionGet} from '../usuario/Auth';

class CaixaSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            empresa: this.props.empresa,
            list: [['', 'Carregando opções']],
            caixa: '',
            validator: {}
        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputBlurHandler = inputBlurHandler.bind(this);
        this.get = this.get.bind(this);
       
    }

    componentDidMount(){
        this.get();
    }

    componentDidUpdate(prevProps, prevState){
     
    if(prevState.caixa != this.props.caixa)
    this.setState({ caixa : this.props.caixa });

  }

  inputChangeHandler(event){

    if(typeof this.props.change == "function")
      this.props.change(event);
  }

    render(){
        let REF = this;
        return (
            <select className="form-control" value={this.state.caixa} name="caixa" id="f-caixa" onChange={this.inputChangeHandler} >
                <option key={0} value="">Selecione</option>
                {
                      this.state.list.map(function(val) {
                          let kf = typeof REF.props.keyfield !== "undefined" ? REF.props.keyfield : "titular";
                          return (<option key={val[1][kf]} value={val[1][kf]}>{val[1].ncartao} - {val[1].titular}</option>) 
                      })
                }
            </select>
        );
    }

    get(){
        let REF = this; 

        axios.get(CFG.URL_API+'/empresa/'+this.state.empresa+'/caixas', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);

            let htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });
            
            REF.setState({ list: htmlList });

          //Perform action based on response
      })
        .catch(function(error){
      requestException(error);
 });
    }

}

export default CaixaSelect;
