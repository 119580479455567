import './Responsaveis.scss';
import CustomDataTable from '../CustomDataTable';
import '../DataTable.scss';
import {getListing, CustomToggleList, tableRender} from '../CustomDataTable'; // Import React

import {Button, Col, Dropdown, DropdownButton, Popover, Overlay, OverlayTrigger, Row} from 'react-bootstrap';

import React from 'react'; // Import React
// es6
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ResponsavelModal from './ResponsavelModal';
import {sessionGet} from '../usuario/Auth';


class ResponsaveisDataTable extends React.Component{
  
  constructor(props){
    super(props);

    this.state = {
      show: false,
       popoverVisible: false,
      empresa: sessionGet('empresa'),
      refresh: false,
      data: [],
      searchTerm: null,
      activeIndex: undefined
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender.bind(this);

  }

  updateListing = (columnsSearch) => {
    this.getListing('responsavel?empresa='+this.state.empresa, columnsSearch);

   }

  componentDidUpdate(prevProps) {

    if(this.props.refresh)
    this.getListing('responsavel?empresa='+this.state.empresa);

  }

  componentDidMount(){
    this.updateListing();
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }


  handleTableRefresh(refresh){

    this.setState({ refresh : refresh });

    this.getListing('responsavel?empresa='+this.state.empresa);

    var PDT = this;

    setTimeout(function(){
      
      PDT.setState({ refresh : false });
    }, 500);

  }

  getColumns(){
  return  [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true
    },{
      dataField: 'nome',
      text: 'Responsável',
      sort: true
    },{
      dataField: 'telefone',
      text: 'Telefone',
      sort: true
    },{
      dataField: 'celular',
      text: 'Celular',
      sort: true
    },{
      dataField: 'email',
      text: 'E-mail',
      sort: true
    }];
  }

  tablerowupdate(form){
    let data = this.state.data; 
    this.setState({ data: this.updateTableRow(data, this.state.activeIndex, form) });

  }


  formUpdate(form_data_complete){
    this.setState({ form: form_data_complete });
  }

  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }

  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }

  setModalShow (status, data){

    if(data !== null){

      this.setState({  form : data });
      //this.setState({  responsavel_id : data.responsavel });
    }

    this.setState({  show: status });
    this.updateListing();
  }

 
        render(){    
          let REF = this;
          return (
          <>
              <ResponsavelModal
          show={this.state.show}    
          form={this.state.form}        
          onHide={() => REF.setModalShow(false, {})}
          tablerowupdate={this.tablerowupdate}
        />  

        {         this.tableRender()       }
          </>
          )
        }
  
        }
        
        export default ResponsaveisDataTable;
        
        