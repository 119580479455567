import React from 'react'
import axios from "axios"
import { Form, Table,Col,Modal,Button, Alert } from 'react-bootstrap';
import { sessionGet } from '../usuario/Auth';
import { CFG, axiosHeader } from "../CFG"
import {mask_decimal, parseDecimal,mask_horario} from '../util/inputMask';

class TabDelivery extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            modal:false,
            dias:[],
            horaInicio:"00:00",
            horaFim:"00:00",
            submit:false,
            info:null
        }
        this.handleInput = this.handleInput.bind(this) 
        this.handleClose = this.handleClose.bind(this)
        this.handleDias = this.handleDias.bind(this)
        this.handleHora = this.handleHora.bind(this)
        this.salvaHorarios = this.salvaHorarios.bind(this)
        
        
    }
   
    handleInput = (e)=>{
        let form = this.props.form;
        form.dadosDelivery[e.target.name] = e.target.value;
        console.log(e.target.value);
        this.props.handleForm(form);
    }

    handleClose = ()=>{
        this.setState({
            modal:false,
            dias:[],
            horaInicio:"00:00",
            horaFim:"00:00",
        })
    }

    handleHora = (e)=>{
        let valor = mask_horario(e.target.value);
        console.log(valor);
        this.setState({[e.target.name]: valor})
    }

    handleDias = (e)=>{
        const dia = e.target.id;
        let dias = this.state.dias;
        if(dias.includes(dia)){
            const index = dias.indexOf(dia);
            dias.splice(index, 1);
        }else{
            dias.push(dia);
        }
        this.setState({dias})
    }

    salvaHorarios = (e)=>{
        this.setState({
            info:{variant:"warning",message:"Enviando..."}
        })
        let funcionamento = [];
        let REF = this;
        REF.state.dias.forEach(dia => {
            funcionamento.push({
                "dia_semana":dia,
                "inicio":REF.state.horaInicio,
                "fim":REF.state.horaFim
            })
        });
        const form = REF.props.form;
        const url = CFG.URL_API + "/empresa/funcionamento"
        axios.post(url,{funcionamento}, axiosHeader())
        .then(function(response) {
            form.dadosDelivery.funcionamento = response.data.funcionamento;
            REF.setState({
                info:{variant:"success",message:"Salvo com sucesso!"}
            })
            REF.props.handleForm(form);
            setTimeout(()=>{
                REF.handleClose();
            },600)
            console.log(response);
        })
        .catch(function(error) {
            console.log(error)
        })
    }

    render(){
        const empresa = this.props.form
        return <div style={{ padding:10}}>
            
            <fieldset className="fieldset">
                <Form>
                    <Form.Group as={Col} xs={6} lg={3} >
                        <label>Facebook</label>
                        <Form.Control value={empresa.dadosDelivery.facebook} onChange={this.handleInput} name="facebook" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} >
                        <label>Instagram</label>
                        <Form.Control value={empresa.dadosDelivery.instagram} onChange={this.handleInput} name="instagram" />
                    </Form.Group>
                
                    <Form.Group as={Col} xs={6} lg={3} >
                        <label>Pedido mínimo</label>
                        <Form.Control value={mask_decimal(empresa.dadosDelivery.pedidominimo||0)} onChange={this.handleInput} name="pedidominimo" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} >
                        <label>Taxa Entrega</label>
                        <Form.Control value={empresa.dadosDelivery.taxaentrega} onChange={this.handleInput} name="taxaentrega" />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} lg={6}>
                        <label>Slogan</label>
                        <Form.Control value={empresa.dadosDelivery.slogan} onChange={this.handleInput} name="slogan" />
                    </Form.Group>
                </Form>
            </fieldset>
            <fieldset className="fieldset">
                <Modal show={this.state.modal} onHide={this.handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Cadastrar horário de funcionamento</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Marque o dia da semana</p>
                        <Form.Check inline onChange={this.handleDias} id="SEGUNDA" label="SEGUNDA" type="checkbox" />
                        <Form.Check inline onChange={this.handleDias} id="TERCA" label="TERÇA" type="checkbox" />
                        <Form.Check inline onChange={this.handleDias} id="QUARTA" label="QUARTA" type="checkbox" />
                        <Form.Check inline onChange={this.handleDias} id="QUINTA" label="QUINTA" type="checkbox" />
                        <Form.Check inline onChange={this.handleDias} id="SEXTA" label="SEXTA" type="checkbox" />
                        <Form.Check inline onChange={this.handleDias} id="SABADO" label="SABADO" type="checkbox" />
                        <Form.Check inline onChange={this.handleDias} id="DOMINGO" label="DOMINGO" type="checkbox" />
                    
                        <Form>
                            <Form.Group as={Col} >
                                <label>Das</label>
                                <Form.Control value={this.state.horaInicio} onChange={this.handleHora} name="horaInicio" />
                            </Form.Group>
                            <Form.Group as={Col} >
                                <label>Até</label>
                                <Form.Control value={this.state.horaFim} onChange={this.handleHora} name="horaFim" />
                            </Form.Group>
                        </Form>

                    </Modal.Body>

                    <Modal.Footer>
                        {this.state.info && <Alert style={{padding:5,marginBottom:0}} variant={this.state.info.variant}>{this.state.info.message}</Alert>}
                        <Button onClick={this.handleClose} variant="secondary">Cancelar</Button>
                        <Button onClick={this.salvaHorarios} variant="primary">Cadastrar</Button>
                    </Modal.Footer>
                </Modal>
                <legend>Horário de funcionamento</legend>
                <button onClick={()=>this.setState({modal:true})} className="btn btn-info">Cadastrar</button>
                <Table striped bordered hover style={{marginTop:10}}>
                    <thead>
                        <tr>
                            <th>Dia da Semana</th>
                            <th>Inicio</th>
                            <th>Fim</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            empresa.dadosDelivery.funcionamento.map((horario=><tr style={{cursor:"default"}}>
                                <td>{horario.dia_semana}</td>
                                <td>{horario.inicio}</td>
                                <td>{horario.fim}</td>
                            </tr>))
                        }
                    </tbody>
                </Table>
            </fieldset>
        </div>
    }
}
    
    export default TabDelivery
    