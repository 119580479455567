import React from 'react';
import '../Modal.scss';

import {Modal, Button} from 'react-bootstrap';
import ProdutoTab from './ProdutoTab';
import url_btn_close from '../img/icons/close.png';
import LoadingModal from '../util/LoadingModal';

class ProdutoModal extends React.Component{

  constructor(props) {
      super(props);

    this.state = { 
      ProdutoForm: {},
        FornecedoresForm: {},
        FormulaForm: {},
        currentTab: "home",
        loading: false,
        submit: false,
        delete: false
      };

      this.formDelete = this.formDelete.bind(this);
  }

  updateTab = (t) => this.setState({ currentTab: t });

  formcontrol = (form_comp) => {
    this.setState({ [form_comp.constructor.name]: form_comp });
  }

  closeTrigger = () => {
    this.setState({ submit: false, loading: false, delete: false });
    this.props.onHide(true);
  }

  loadingHandle = (loading) => {
    this.setState({ loading, submit: loading });
  }

  componentDidUpdate(prevProps){
    if(this.props.show && prevProps.show !== this.props.show){
      this.setState({ delete: false, submit: false });
    }
  }


  render () {
    let REF = this;

    if(!this.props.show)
    return (<></>);

    return (
      <>
      
      <Modal
      className="ProdutoModal"
        onEscapeKeyDown={REF.props.onHide}
        show={this.props.show}
        onHide={REF.props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            Produto {typeof this.props.form !== "undefined" ? '#'+this.props.form.id : ''}
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={REF.props.onHide} />
        </Modal.Header>
        <Modal.Body>
            <ProdutoTab
            delete={this.state.delete}
            close={REF.closeTrigger}
            loading={REF.loadingHandle}
            submit={this.state.submit}
            tabupdate={this.updateTab}
            show={this.props.show}
            form={this.props.form}
            formcontrol={this.formcontrol}
          />           
        </Modal.Body>
        <Modal.Footer>
          <Button hidden={this.state.currentTab != "home"} className="btn-danger btn-left" onClick={this.formDelete}>Excluir</Button>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading} />
      </>
    );
  }

  formSubmit = () =>  {
    this.setState({ submit: true, loading: true });
  }

  formDelete() {
    let REF = this;
    let c = window.confirm("Deseja mesmo remover o produto "+this.props.form.produto+" ?");
    if (c == true) {

      if(typeof this.props.form.id !== null)
      this.setState({ delete: true });

      

    }

  }
  
}

export default ProdutoModal;