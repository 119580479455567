import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import {getListing} from '../CustomDataTable'; // Import React
import { isValid, inputBlurHandler } from '../CustomForm';
import { sessionGet } from '../usuario/Auth';
import {Row, Col, Button, Table} from 'react-bootstrap';
import ParcelaInput from './ParcelaInput';

class ParcelasForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formaspagamento_id: this.props.form.id,
      empresa: sessionGet('empresa'),
      data: [],
      input_list: [],
      item_exists: false,
      commit: false,
      loading: true,
      commit: false
    };

    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.isValid = isValid.bind(this);
    //listar fornecedores vinculados ao produto
    this.getListing = getListing.bind(this);

  }

  inputChangeHandler = (row) => {
    if(typeof row.key !== "undefined"){
      this.setState(state => {
        let r = state.input_list.find(e => 
          e.key == row.key);
          if(typeof r !== "undefined")
          Object.assign(r, row);
        });
      }    

    if(typeof this.props.formcontrol !== "undefined")
    this.props.formcontrol(this);

  }

  componentDidUpdate(prevProps, prevState){


      if(this.state.submit != this.props.submit){
        this.setState({ submit: this.props.submit });
     
        if(this.props.submit && typeof this.props.id !== "undefined"){

          this.submit();
        }

      }
    

      if(this.props.empresa != this.state.empresa){
        this.setState({ empresa: this.props.empresa });
      }

      if(prevState.data != this.state.data){
        this.load_db();
      }

      if(!this.state.commit && typeof this.props.formcontrol !== "undefined"){
        this.props.formcontrol(this);
        this.setState({ commit: true });
      }

      if(this.state.npar != this.props.npar){
        let REF = this;
        this.setState({ npar: this.props.npar });
        setTimeout(() => REF.gerarParcelas(), 300);
      }  


      if(prevProps.form != this.props.form){
        this.setState({ formaspagamento_id: this.props.form.id });
      }
  }

  componentDidMount(){
    this.setState({ commit: false });
    this.reloadForma();
  }


  reloadForma = () => {

    this.getListing('forma-parcela?formaspagamento_id='+this.state.formaspagamento_id).then(function(response){
      
    });
  }

  gerarParcelas = (e) => {
    let REF = this;

    let input_list_copy = this.state.input_list;
    let actual_size = input_list_copy.length;
 
    for(let c = actual_size; c < this.state.npar; c++){
        this.addRow();
    }    
  }

  addRow = (input = null) => {
    let REF = this;
    let input_list_copy = this.state.input_list;
    let actual_size = input_list_copy.length;
    let f = {};
    f.formaspagamento_id = this.state.formaspagamento_id;
    f.empresa = this.state.empresa;
    f.key = actual_size;
    f.parcela = (actual_size+1);
    f.juros = '';
    f.desco = '';
    f.dias = '';
    Object.assign(f, input);
    input_list_copy.push(f);
    this.setState({ input_list: input_list_copy });

    setTimeout(() => REF.props.update_npar(input_list_copy.length), 300);

  }

  render(){ 
      
    let REF = this;
  
    let countOrMessage = () => {

      let msg;

      //excluiu dados que vieram do banco
      if(typeof REF.state.data !== "undefined" && REF.state.loading && REF.state.data.length > 0 &&  REF.state.input_list.length == 0) 
       msg = 'Selecione da lista';


      if(REF.state.input_list.length > 0 && typeof this.state.formaspagamento_id !== "undefined")
      msg = '('+REF.state.input_list.length+')';

      if(REF.state.loading)
      msg = 'Aguarde...';

      return msg;
    };

    return (<>

    <Row >
      <Col >
      <fieldset className="mt-3">
        <legend>Parcelas <span className="text-muted">{ countOrMessage() }</span></legend>
        <hr/>
      <div  className="d-flex justify-content-end">
          <Button variant="info" className="my-2" onClick={this.onAddChange}><i className="fa fa-plus"></i> Adicionar parcela</Button>
      </div>
        <Table striped bordered hover>
            <thead>
              <tr>
                <th>Parc.</th>
                <th>Dias</th>
                <th>Juros</th>
                <th>Desc.</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
            { (() => REF.load_input_list())() }
            </tbody>
        </Table>
        </fieldset>
      </Col>
    </Row>
  
     </>
    );
  }

  input = (key, parcela) => {
    let REF = this;
    return (
    <ParcelaInput delete={() => REF.delete(key)} key={key} form={parcela} change={this.inputChangeHandler} />);
  }

  load_db = () => {

    let input_list_copy = this.state.input_list;
    if(typeof this.state.data !== "undefined"){
    
      Object.assign(input_list_copy, this.state.data);
    }

    let c = 0;
    input_list_copy.map(e => {
      e.key = c;
      c++;
    });
    
    this.setState({ input_list: input_list_copy });
    let REF = this;
    setTimeout(() => REF.setState({ loading: false }) , 1000);
  }

  load_input_list = () => {
    let REF = this;
    let c = 0;

    return this.state.input_list.map(function(f){
      let r = REF.input(c, f);
      c++;
      return r;
    });
  } 

  onAddChange = (e) => {

    this.addRow();
    
  }

   submit = () => {

    let REF = this;
    this.state.input_list.map(function(f){
      f.formaspagamento_id = REF.props.id;      
      if(typeof f.id !== "undefined"){
        REF.put(f);
      } else {
        REF.post(f);
      }
 
    });  

   }

   post = (f) => {
    let REF = this;
        axios.post(CFG.URL_API+'/forma-parcela', f, axiosHeader())
        .then(function(response){
          //Perform action based on response
      })
      .catch(function(error){
            requestException(error);
      }).finally(() => REF.props.submit_confirm('ParcelasForm'));
 
   }

   put = (f) => {
    let REF = this;
        axios.put(CFG.URL_API+'/forma-parcela/'+f.id, f, axiosHeader())
        .then(function(response){
          //Perform action based on response
      
      })
        .catch(function(error){
      requestException(error);
 }).finally(() => REF.props.submit_confirm('ParcelasForm'));
  
   }

  delete = (key) => {
    let REF = this;
    let data_copy = this.state.input_list;

    let r = data_copy.find(e => e.key == key);

      //Perform action based on response
      data_copy.splice(key,1);
      REF.setState({ input_list : data_copy });
  
      REF.props.update_npar(data_copy.length);

      if(typeof r.id === "undefined")
      return;
      
    axios.delete(CFG.URL_API+'/forma-parcela/'+r.id, axiosHeader())
    .then(function(response){
  
  })
    .catch(function(error){
  requestException(error);
});
 
  

  }

}

export default ParcelasForm;
