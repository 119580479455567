import React from 'react';
import axios from 'axios';
import {Row,Col} from 'react-bootstrap';
import {CFG, axiosHeader} from '../../CFG';
import {sessionGet} from '../../usuario/Auth';
import { inputChangeHandler, isValid } from '../../CustomForm';

class InfoAdicionaisForm extends React.Component {


  initialState = () => {
    return {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      indicador: '',     
      validator: {},
      show: false,
      fiscoadcio: '',
      infocomplementar: '',
      data_removido_format: undefined
    };
  }

  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.inputChangeHandler = inputChangeHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){
 
    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);

    }

  }

  componentDidUpdate(prevProps){

    if(prevProps.form.id !== this.props.form.id){
      let REF = this;
      if(typeof this.props.form.id === "undefined"){
        this.setState({ ...REF.initialState() });
      } else {
        this.setState({ ...this.props.form });
      }
      setTimeout(() => REF.props.formcontrol(REF), 2000);
    }

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }
  }

   post (){
    axios.post(CFG.URL_API+'/dre', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   put () {
    axios.put(CFG.URL_API+'/dre/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){

   return(
     <>
  <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />
    
    <Row>
      <Col>
      <label htmlFor="f-info-fisco">
      INFO. AO FISCO
      </label>
      <textarea rows="10" onChange={this.inputChangeHandler} name="fiscoadcio" value={this.state.fiscoadcio} className="form-control" />
    
      </Col>
      <Col>
        <label htmlFor="f-infocomplementar">
        INFO. COMPLEMENTAR
      </label>
      <textarea rows="10" onChange={this.inputChangeHandler} name="infocomplementar" value={this.state.infocomplementar} className="form-control" />
    
      </Col>
    </Row>
</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/dre/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default InfoAdicionaisForm;