import React from 'react'; // Import React
import {Container, Button, ButtonToolbar, Row, Col} from 'react-bootstrap';
import { sessionGet } from '../../../usuario/Auth';
import FiltroModal from './FiltroModal';
import ProdutosVendidosDataTable from './ProdutosVendidosDataTable';

class Extrato extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: undefined,
            movimentacao_id: '',
            empresa: sessionGet('empresa'),
            searchTerm: null,
            activeIndex: undefined,
            show_modal_filters: false,
            origem: [],
            filters: {}
        };

        this.props.titlebar(this.props.title, this.props.description);
    }

    setFiltroModalShow = () => {
        this.setState({ show_modal_filters: true });
    }

    setFilters = (form_state) => {
        this.setState({ filters: form_state, loading: true });
    }

    hideFiltroModal = () => {
        this.setState({ show_modal_filters: false });
    }

    updateLoading = (status) => {
        this.setState({ loading: status  });
    }
       
    setOrigem = (e, origem) => {
        this.setState({ origem });
    }

    componentDidMount(){
      window.checkUserCan('rel_produtos_vendidos');    
    }

    render(){
        let REF = this;
        return (<>
           <Container fluid={true}>
            <div className="row">
            <div className="col-md-6 my-2" > 
                           
                </div>
                <div className="col-md-6 my-2"> 
                    <ButtonToolbar className="float-right my-1 ml-auto">
                        <Button variant="success" onClick={() => this.setFiltroModalShow(true)}>
                            <span className="fa fa-filter"></span>
                        </Button>              
                        <FiltroModal
                            loading={this.state.loading}
                            empresa={this.state.empresa}
                            show={this.state.show_modal_filters} 
                            setfilters={this.setFilters} 
                            onHide={this.hideFiltroModal} 
                        />
                    </ButtonToolbar>            
                </div>
            </div>
        <ProdutosVendidosDataTable setloading={this.updateLoading} title={this.props.title} empresa={this.state.empresa} filters={this.state.filters} />
        </Container>
        </>);
    }
    }
    
    export default Extrato;