import React from 'react';
import '../../Modal.scss';
import {Modal, Button, Row} from 'react-bootstrap';
import LoadingModal from '../../util/LoadingModal';
import axios from 'axios';
import {CFG, axiosHeader} from '../../CFG';
import CartaoSelect from '../CartaoSelect';
import url_btn_close from '../../img/icons/close.png';
import {selectChangeHandler, inputChangeHandler} from '../../CustomForm';
import {requestException} from '../../util/exception';
import {sessionGet} from '../../usuario/Auth';

class Modal2Via extends React.Component{

    constructor(props) {
      super(props);

      this.state = {   
        empresa: sessionGet('empresa'),
        loading: false,
        validator: {},
        allowConfirm: false,   
      };

      this.closeTrigger = this.closeTrigger.bind(this);
      this.selectChangeHandler = selectChangeHandler.bind(this);
      this.inputChangeHandler = inputChangeHandler.bind(this);

    }

    componentDidMount(){
      this.setState({ submit: false, loading: false });
    
    }

    post = () => {
      let REF = this;
      REF.setState({ submit: true, loading: true });
  
      axios.post(CFG.URL_API+'/cartao/segunda-via', this.state, axiosHeader())
      .then(function(response){
        //Perform action based on response
    })
      .catch(function(error){
        requestException(error);
      }).finally(() => {
        REF.setState({ submit: false, loading: false });
        REF.props.onHide();
      });   
    
     }

    componentDidUpdate(prevProps, prevState){

      if(prevProps.show !== this.props.show){
        this.setState({ id: this.props.form.id });

        this.setState({ submit: false, loading: false, allowConfirm: false });

        let REF = this;
        setTimeout(() => { 
          REF.setState({ allowConfirm: true });
        }, 3000);
      }
      
    }


  closeTrigger  (form_name) {
    let REF = this;
    this.setState({ [form_name]: true });

    setTimeout(() => {
      REF.setState({ loading: false, CartaoForm: undefined, CartaoLimiteForm: undefined, submit: false, allowConfirm: false });
      REF.props.onHide();
    }, 1000);
      
    
  }

  componentWillUnmount(){
    this.setState({ allowConfirm: false });
  }

  render () {
    let REF = this;

    const setId = (id) => {
      REF.setState({ id });
    };

    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            2a via de Cartão 
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />
        </Modal.Header>
        <Modal.Body>
        <Row>
            <div className="col-md-6">
              <label className="text-uppercase">Cartão</label>
              <CartaoSelect change={this.selectChangeHandler} cartao={this.state.cartao} name="cartao" />
            </div>
            <div className="col-md-6">
              <label className="text-uppercase">Novo número</label>
              <input className="form-control mt-1" value={this.state.ncartao_novo} name="ncartao_novo" onChange={this.inputChangeHandler} />
            </div>
         
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={REF.post}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }



}

export default Modal2Via;