import React from 'react';
import {CFG, axiosHeader} from '../CFG';
import axios from 'axios';

import {Card, Row, Col} from 'react-bootstrap';
import './CardInfo.scss'
import {mask_decimal} from '../util/inputMask';

export class CardInfo extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            empresa: this.props.empresa,
            titulo: '',
            icone: undefined,
            inicio: undefined,
            fim: undefined,
            quantidade: 0,
            total: 0
        }
    }

    componentDidMount(){
        this.setState({ ...this.props });
    }

    componentDidUpdate(prevProps, prevState){


        if(this.state.total != this.props.total){
            this.setState({ ...this.props });
        }

  
    }

    render(){
        return (
            <>
                <Card className="m-1 CardInfo">
                    <Card.Body>
                        <Row>
                            <Col>
                                <h5>{this.state.titulo}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <i className={this.state.icone} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {typeof this.state.total !== "undefined" ? "R$ "+mask_decimal(this.state.total) : "..."}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default CardInfo;