import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import CentroCustoSelect from '../centrocusto/CentroCustoSelect';
import FornecedorSelect from '../fornecedor/FornecedorSelect';
import { inputChangeHandler, isValid, inputBlurHandler, vencimentoChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import {todayDmy} from '../util/date';

class ContaReceberForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      validator: {},
      show: false,
      dt_vencimento: new Date(),
      data_removido_format: undefined,
      dt_entrada: todayDmy(),
      //debito
      tipo: 0
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.vencimentoChangeHandler = vencimentoChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){

    let form_fields = this.props.form;
    if(form_fields != undefined && form_fields.dt_vencimento == '0000-00-00'){
      form_fields.dt_vencimento = null;
    }

    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });
      this.props.modalcontrol(this);
    }

    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear()+' às '+dt.getHours()+':'+dt.getMinutes(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);
    }

  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }
  }

   post (){
    axios.post(CFG.URL_API+'/titulo', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   put () {
    axios.put(CFG.URL_API+'/titulo/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){
    
    let REF = this;

   return(
     <>
  <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />
  <div className="row">      
  <div className="form-group col-md-2">         
      <label htmlFor="f-id">
        ID
      </label>
      <input readOnly className="form-control text-center" name="id" id="f-id" type="text" value={this.state.id} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
    </div>
           <div className="form-group col-md-3">         
          <label htmlFor="f-vencimento">
            Data de entrada
          </label>
          <input readOnly={true} className="form-control" name="dt_entrada" id="f-dt_entrada" type="text" value={this.state.dt_entrada} />
        </div>
        <div className="form-group col-md-3">         
          <label htmlFor="f-dt_pagto">
            Data da baixa
          </label>
          <input readOnly={true} className="form-control" name="dt_pagto" id="f-dt_pagto" type="text" value={this.state.vencimento} onChange={this.inputChangeHandler} />
        </div>
   
      <div className="form-group col-md-4">         
          <label htmlFor="f-dt_vencimento">
            Vencimento
          </label>
          <DatePicker locale="pt-BR"  autoComplete="off" name="dt_vencimento" id="f-dt_vencimento" type="text" value={this.state.dt_vencimento} onChange={this.vencimentoChangeHandler} />
        </div>
    </div>
    <div className="row">  
 
    <div className="form-group col-md-4">         
        <label htmlFor="f-documento">
          Documento
        </label>
        <input className="form-control" name="documento" id="f-documento" type="text" value={this.state.documento} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
      </div>

        <div className="form-group col-md-8">         
        <label htmlFor="f-codbarra">
          Código de barras
        </label>
        <input className="form-control" name="codbarra" id="f-codbarra" type="text" value={this.state.codbarra} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
      </div>
    </div>
  
    <div className="row">

      <div className="form-group col-md-6">         
        <label htmlFor="f-centrocustos_id">
          Centro de custos
        </label>
        <CentroCustoSelect empresa_id={this.state.empresa_id} onchange={this.inputChangeHandler} centrocustos_id={this.state.centrocustos_id} />
      </div>

      <div className="form-group col-md-6">         
        <label htmlFor="f-fornecedor_id">
         Fornecedor
        </label>
        <FornecedorSelect onchange={this.inputChangeHandler} fornecedor_id={this.state.fornecedor_id} />
      </div>

    </div> 
 
    <div className="row"> 
      <div className="form-group col-md-12">         
        <label htmlFor="f-descricao">
          Descrição
        </label>
        <input className="form-control" name="descricao" id="f-descricao" type="text" value={this.state.descricao} onChange={(e) => REF.inputChangeHandler(e)} onBlur={this.inputBlurHandler} />
      </div>
    </div>
 

    <div className="row">

      <div className="form-group col-md-4">         
        <label htmlFor="f-parcela">
          Parcela
        </label>
        <input max="999" className="form-control" name="parcela" id="f-parcela" type="number" value={this.state.parcela} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
      </div>

      <div className="form-group col-md-4">         
        <label htmlFor="f-nparcela">
        Qtd. Parcelas
        </label>
        <input max="999" className="form-control" name="nparcela" id="f-nparcela" type="number" value={this.state.nparcela} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
      </div>

      <div className="form-group col-md-4">         
        <label htmlFor="f-acrescimo">
        Acréscimo
        </label>
        <input className="form-control" name="acrescimo" id="f-acrescimo" type="text" value={this.state.acrescimo} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
      </div>

    </div>

    <div className="row">

      <div className="form-group col-md-4">         
        <label htmlFor="f-desconto">
          Desconto
        </label>
        <input className="form-control" name="desconto" id="f-desconto" type="text" value={this.state.desconto} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
      </div>

      <div className="form-group col-md-4">         
        <label htmlFor="f-valor_bruto">
        Valor bruto
        </label>
        <input className="form-control" name="valor_bruto" id="f-valor_bruto" type="text" value={this.state.valor_bruto} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
      </div>
      </div>

      <div className="row"> 
        <div className="form-group col-md-12">         
          <label htmlFor="f-observacao">
            Observações
          </label>
          <textarea rows="6" className="form-control" name="observacao" id="f-observacao" type="text" value={this.state.observacao} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
    </div>
</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/titulo/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default ContaReceberForm;