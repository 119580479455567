
import React from 'react';
import { Modal } from 'react-bootstrap';
import Img from 'react-image';
import load_gif from '../img/loading-1.gif';

function load(props){
    return (
        <Modal
        className="LoadingModal"
        {...props}
        onHide={() => null}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <Modal.Body>
        <div>
            <Img id="load" className={props.className} src={load_gif}  />
        </div>
        </Modal.Body>
      </Modal>
    )
}

export default load;