import React, {useEffect} from 'react';
import {CFG, axiosHeader} from '../../CFG';
import axios from 'axios';
import './Grafico.scss';
import Chart from 'react-apexcharts';
import {getWeek, daysUntil} from '../../util/date';
import {mask_decimal} from '../../util/inputMask';
import LoadingPlaceholder from '../../util/LoadingPlaceholder';

class GraficoVendasDiaSemana extends React.Component {
      
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      empresa: this.props.empresa,
      options: this.getOptions(),
      series: []
    };

  }

  

  getOptions = function() {

    let dt = new Date();
    let days = daysUntil(dt.getDay(),false).map((e) => e.substr(0,3));


   return {
      dataLabels: {
        enabled: false
      },

      stroke: {
        width: [1, 1, 4]
      },
      xaxis: {
        categories: days,
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            formatter: function (value) {
              return "R$ "+mask_decimal(value.toFixed(2));
            },
            style: {
              color: '#008FFB',
            }
          },
          title: {
            text: "Valor (em reais)",
            style: {
              color: '#008FFB',
            }
          },
          tooltip: {
            enabled: true
          }
        }  

      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
  };

  getSeries = () => [
    {
      tipo: 15,
      name: 'Débito',
      type: 'column',
      data: []
    }, 
    {
      tipo: 16,
      name: 'Crédito',
      type: 'column',
      data: []
    }, 
    {
      tipo: 5,
      name: 'Dinheiro',
      type: 'column',
      data: []
    },
    {
      tipo: 0,
      name: 'Cartão Lanchar',
      type: 'column',
      data: []
    },
    {
      tipo: 10,
      name: 'Voucher',
      type: 'column',
      data: []
    },
    {
      tipo: 11,
      name: 'Pix',
      type: 'column',
      data: []
    }
  ];


  loadRange = async () => {

    var REF = this;

    let dt = new Date();
    let days = daysUntil(dt.getDay(),false).map((e) => e.substr(0,3));

    var series_up = this.getSeries();
    let req_count = 0;
    let results = [];
    results = series_up.map(function(s){
      return REF.get(s.tipo);
    });
    
    const rs = await Promise.all(results);
    let data_sync = rs?.map(item => item.data);
    data_sync = data_sync?.map(item =>{
      return item?.map(i => {
        i.total = parseFloat(i.total?.toFixed(2));
        return i;
      });
    })
    const data_update = series_up.map((item, id) => {
      item.data = data_sync[id]?.map(i => i.total);
      return item;
    })

    REF.setState({series: data_update});
    REF.setState({ loading: false });

    //console.log('UPDATE', data_update);

    
  }

  get = (tipo) => {
    let dt = new Date();
    return axios.get(CFG.URL_API+'/relatorio/vendas-semana?empresa='+this.props.empresa+'&tipo='+tipo+'&ano='+dt.getFullYear(), axiosHeader())
  }

  isLoading = () => {
   return this.state.loading ? "d-block" : "d-none";
  }
  
  componentDidMount(){
    this.loadRange();
  }

  render() {


    return (
      <div className="Grafico">
    <LoadingPlaceholder show={this.state.loading}/>
    <h4>Vendas da semana</h4>
    <div id="chart">
      <Chart hidden={this.state.loading} options={this.state.options} series={this.state.series} type="bar" height="350" />
    </div>
  </div>);
  }
}

export default GraficoVendasDiaSemana;