import React from "react" // Import React
import { getListing, CustomToggleList, tableRender } from "../CustomDataTable" // Import React
import "../DataTable.scss"
import CartaoModal from "./CartaoModal"
import { sessionGet } from "../usuario/Auth"

class CartoesDataTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            componentStatus: false,
            responsavel_id: "",
            empresa: sessionGet("empresa"),
            form: [],
            data: [],
            searchTerm: null,
            activeIndex: undefined,
            status: "N"
        }

        this.onKeyPressed = this.onKeyPressed.bind(this)
        this.getCustomToggleList = CustomToggleList.bind(this)
        this.getListing = getListing.bind(this)
        this.tableRender = tableRender.bind(this)
    }

    updateListing = columnsSearch => {
        if (this.state.responsavel_id != "")
            this.getListing(
                "cartao?desativado=N&responsavel=" + this.state.responsavel_id,
                columnsSearch
            )
        else {
            this.getListing(
                `cartao?desativado=${this.state.status}&empresa=${this.state.empresa}`,
                columnsSearch
            )
        }
    }

    componentDidMount() {
        this.setState({
            componentStatus: () => (
                <select
                    class="form-control"
                    style={{ width: "20%" }}
                    onChange={e => this.setState({ status: e.target.value })}
                >
                    <option value="N">Ativos</option>
                    <option value="S">Inativos</option>
                </select>
            )
        })
        this.updateListing()
    }

    componentDidUpdate(prevProps) {
        if (this.props.refresh !== prevProps.refresh) {
            this.updateListing()
        }

        if (prevProps.responsavel_id != this.props.responsavel_id) {
            this.setState({ responsavel_id: this.props.responsavel_id })
            this.updateListing()
        }

        if (prevProps.form != this.props.form) {
            this.setState({ form: this.props.form })
        }
    }

    onKeyPressed(event) {
        if (event.keyCode == 27) this.setState({ popoverVisible: false })
    }

    getCustomToggleList({ columns, onColumnToggle, toggles }) {
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }

    setModalShow(status, data) {
        if (data != null) {
            if (data.senha != null) data.senha_decode = atob(data.senha)

            this.setState({ form: data })
            //this.setState({  responsavel_id : data.responsavel });
        }

        this.setState({ show: status })
        this.updateListing()
    }

    getColumns() {
        return [
            {
                dataField: "id",
                text: "ID",
                sort: true,
                headerClasses: "cardinal"
            },
            {
                dataField: "ncartao",
                text: "Nº Cartão",
                sort: true,
                headerClasses: "cardinal"
            },
            {
                dataField: "credencial",
                text: "Credencial",
                sort: true,
                headerClasses: "cardinal"
            },
            {
                dataField: "titular",
                text: "Titular",
                sort: true,
                headerStyle: { width: "50%" },
                headerClasses: "alpha"
            },
            {
                dataField: "credito_format",
                text: "Créd.",
                sort: true,
                headerClasses: "cardinal"
            },
            {
                dataField: "ativo",
                text: "Ativo",
                sort: true,
                headerClasses: "cardinal"
            }
        ]
    }

    render() {
        let REF = this
        let rowClasses = function callback(cell, row, rowIndex, colIndex) {
            if (cell.ativo != "Sim") {
                return "removed"
            }
        }
        return (
            <>
                <CartaoModal
                    responsavel_id={this.state.responsavel_id}
                    show={this.state.show}
                    form={this.state.form}
                    onHide={() => REF.setModalShow(false, {})}
                />
                {this.tableRender(false, rowClasses)}
            </>
        )
    }
}

export default CartoesDataTable
