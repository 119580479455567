import React from "react" // Import React
import { getListing, CustomToggleList, tableRender } from "../CustomDataTable" // Import React
import "../DataTable.scss"
import { Row, Col } from "react-bootstrap"
// es6
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator"

import { Route, Redirect } from "react-router"
import FornecedorModal from "./FornecedorModal"
import { sessionGet } from "../usuario/Auth"

class FornecedorDataTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: "",
            empresa: sessionGet("empresa"),
            form: [],
            data: [],
            searchTerm: null,
            activeIndex: undefined
        }

        this.hideModal = this.hideModal.bind(this)
        this.tablerowupdate = this.tablerowupdate.bind(this)

        this.onKeyPressed = this.onKeyPressed.bind(this)
        this.getCustomToggleList = CustomToggleList.bind(this)
        this.tableRender = tableRender.bind(this)
        this.getListing = getListing.bind(this)
    }

    updateListing = columnsSearch => {
        this.getListing("fornecedor?empresa_id=" + this.state.empresa, columnsSearch)
    }

    componentDidMount() {
        this.updateListing()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.empresa != this.props.empresa) {
            this.setState({ empresa: this.props.empresa })
            this.updateListing()
        }

        if (prevProps.refresh != this.props.refresh) {
            this.updateListing()
        }

        if (prevProps.form != this.props.form) {
            this.setState({ form: this.props.form })
        }
    }

    onKeyPressed(event) {
        if (event.keyCode == 27) this.setState({ popoverVisible: false })
    }

    getCustomToggleList({ columns, onColumnToggle, toggles }) {
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }

    setModalShow(status, data) {
        if (data != null) {
            this.setState({ form: data })
        }

        this.setState({ show: status })
    }

    hideModal() {
        this.setState({ show: false })
        this.updateListing()
    }

    getColumns() {
        return [
            {
                dataField: "id",
                text: "ID",
                style: { textAlign: "center" },
                headerStyle: { width: "10%", textAlign: "center" }
            },
            {
                dataField: "razao",
                text: "Razão",
                sort: true,
                headerClasses: "alpha"
            },
            {
                dataField: "descricao",
                hidden: true
            },
            {
                dataField: "grupo_nome",
                text: "Grupo",
                sort: true,
                headerClasses: "alpha"
            },
            {
                dataField: "cpf_cnpj",
                text: "CNPJ"
            },
            {
                dataField: "telefone",
                text: "Telefone"
            }
        ]
    }

    tablerowupdate(form) {
        let data = this.state.data
        let data_updated = this.updateTableRow(data, this.state.activeIndex, form)

        let CCDT = this
        setTimeout(() => {
            CCDT.setState({ data: data_updated })
            CCDT.updateListing()
        }, 300)
    }

    updateTableRow(array, target_index, data) {
        return array.map((item, index) => {
            if (index == target_index) {
                // This isn't the item we care about - keep it as-is
                return Object.assign(item, data)
            } else {
                // Otherwise, this is the one we want - return an updated value
                return item
            }
        })
    }

    render() {
        return (
            <>
                <FornecedorModal
                    show={this.state.show}
                    form={this.state.form}
                    onHide={this.hideModal}
                    tablerowupdate={this.tablerowupdate}
                    formupdate={this.formUpdate}
                />

                {(() => this.tableRender())()}
            </>
        )
    }
}

export default FornecedorDataTable
