import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import GrupoProdutoForm from './GrupoProdutoForm';

class GrupoProdutoModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = { 
        categoria: '',  
        data: {},
        form: {},
        show: false,
              allow_delete: false
      };

      this.modalControl = this.modalControl.bind(this);
      this.formSubmit = this.formSubmit.bind(this);
      this.formDelete = this.formDelete.bind(this);

      this.updateButtons();


  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.show != this.props.show){
      this.setState({ data: this.props.form, show: this.props.show });
    }
  }

  modalControl(form_comp){
    this.setState({ form: form_comp });
  }

  onKeyPressed = (event) => {
    if(event.keyCode == 13 )
    this.formSubmit();
  }


  updateButtons = () => {
      if(!this.state.allow_delete && Object.getOwnPropertyNames(this.state.form).length > 0){
        this.setState({ allow_delete: true });
      }
  }


  componentDidMount(){
    this.updateButtons();   
    document.addEventListener("keydown", this.onKeyPressed, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  render () {
    return (
      <Modal
      onShow={this.updateForm}      
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title id="contained-modal-title">
            Grupo de Produto 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>        
          <GrupoProdutoForm
            show={this.props.show}
            form={this.state.data}
            modalcontrol={this.modalControl}
          />       
        </Modal.Body>
        <Modal.Footer>

        {
            (() => {
              if(this.state.allow_delete)
              return ( <Button className="btn-danger btn-left" onClick={this.formDelete}>Excluir</Button>)
            })()
          }

          <Button onClick={this.props.onHide}>Fechar</Button>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  formSubmit() {

    if(!this.state.form.isValid())
    return false;

    if(typeof this.props.tablerowupdate === "function")
    this.props.tablerowupdate(this.state.form);

    if(this.state.form.state.id)
     this.state.form.put();
    else
     this.state.form.post();

    this.props.onHide();  
  }

  formDelete() {

    let c = window.confirm("Deseja mesmo remover o grupo "+this.props.form.grupo+" ?");
    if (c == true) {
      if(this.state.form.state.id)
      this.state.form.delete();
      this.props.onHide();  
    }


  }
  
}

export default GrupoProdutoModal;