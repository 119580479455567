import React from "react"
import CartoesDataTable from "./CartoesDataTable"
import { Button, ButtonToolbar } from "react-bootstrap"
import CartaoModal from "./CartaoModal"

class Cartoes extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            refresh: false,
            data: {},
            show: false
        }

        this.props.titlebar(this.props.title, this.props.description)
        this.handleTableRefresh = this.handleTableRefresh.bind(this)
        this.setModalShow = this.setModalShow.bind(this)
    }

    setModalShow = (status, data) => {
        if (data != null) {
            this.setState({ data: data })
        }

        this.setState({ show: status, refresh: !status })
    }

    handleTableRefresh(refresh) {
        this.setState({ refresh: refresh })
        var Rs = this
        setTimeout(function() {
            Rs.setState({ refresh: false })
        }, 100)
    }

    render() {
        let REF = this
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 my-2">
                        <ButtonToolbar className="float-right m-3">
                            <Button variant="success" onClick={() => REF.setModalShow(true)}>
                                <span className="fa fa-plus-square"></span> Cadastrar cartão
                            </Button>
                            <CartaoModal
                                form={{}}
                                show={this.state.show}
                                onHide={() => REF.setModalShow(false)}
                            />
                        </ButtonToolbar>
                    </div>
                </div>
                <CartoesDataTable refresh={this.state.refresh} setModalShow={this.setModalShow} />
            </div>
        )
    }
}

export default Cartoes
