

import React from 'react'; // Import React
import {Container, ListGroup, ListGroupItem} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './Relatorios.scss';

class Extrato extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            };

        this.props.titlebar(this.props.title, this.props.description);
    }


    render(){
        let REF = this;
        return (<>
           <Container fluid={true}>
                    <ListGroup className="mt-4">
                        <Link className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/estoque-alerta"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Alertas de estoque baixo</Link>
                        <Link hidden={window.userCan.rel_extrato_vendas !== 1} className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/movimentacao"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Extrato de vendas</Link>
                        <Link hidden={window.userCan.rel_produtos_vendidos !== 1} className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/produtos-vendidos"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Produtos vendidos</Link>

                        </ListGroup> 
                        <ListGroup className="mt-4">
                        <Link className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/cartao"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Extrato por cartão</Link>

                        <Link hidden={window.userCan.rel_recargas !== 1} className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/recarga"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Extrato de recargas</Link>
                        <Link className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/cardapio"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Relatório do cardápio</Link>
                        <Link className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/tef"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Extrato TEF</Link>

                        </ListGroup> 
                        <ListGroup className="mt-4">
                        <Link className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/dre"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Demonstrativo de Resultados do Exercício</Link>

                        <Link hidden={window.userCan.relatorio_fechamento !== 1} className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/fechamento"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Fechamento</Link>
                        <Link className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/atividade"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Relatório de atividade de cartões este mês</Link>
                        <Link className="lv-2 list-group-item" onClick={this.props.ontoggle} to="/relatorio/inatividade"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Relatório de cartões inativos este mês</Link>

                    </ListGroup> 
                    </Container>
        </>);
    }
    }
    
    export default Extrato;