import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import ProdutoSelect from '../produto/ProdutoSelect';
import { isValid, selectChangeHandler, radioSwitchHandler, inputChangeHandler } from '../CustomForm';
import {todayDmy} from '../util/date';
import {Row} from 'react-bootstrap';

class TransfForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      id: undefined,
      empresa_id: sessionGet('empresa'),
      validator: {},
      show: false,
      dt_vencimento: new Date(),
      data_removido_format: undefined,
      dt_entrada: todayDmy(),
      //debito
      tipo: undefined
    };

    this.selectChangeHandler = selectChangeHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.radioSwitchHandler = radioSwitchHandler.bind(this);

  }



  componentDidMount(){

    let form_fields = this.props.form;
    if(form_fields != undefined && form_fields.dt_vencimento == '0000-00-00'){
      form_fields.dt_vencimento = null;
    }

    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });
      this.props.modalcontrol(this);
    }

    this.updateDateIfRemoved();
  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear()+' às '+dt.getHours()+':'+dt.getMinutes(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);
    }

  }

  componentDidUpdate(){
    
    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }

  }

   put = () => {


    axios.put(CFG.URL_API+'/estoque/transfer/'+this.state.produto, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){
    
    let REF = this;

   return(
     <>
       <Row>
          <div className="row m-0"> 
            <div className="form-group col-md-12">         
                <label>
                  Tipo
                </label>
               
            <ul className="list-inline">
            <li className="list-inline-item">
                              <div className="custom-control custom-radio btn btn-secondary m-2">
                                <input name="tipo" className="custom-control-input" id="f-tipo-sa" type="radio" value="1" checked={this.state.tipo == 1} onClick={this.radioSwitchHandler}/>
                                <label className="custom-control-label" htmlFor="f-tipo-sa">
              Fundo  <i className="fa fa-arrow-right"></i> frente   </label>
                                </div>
                              </li> 
                              <li className="list-inline-item">ou</li>

              <li className="list-inline-item">
                <div className="custom-control custom-radio btn btn-secondary m-2">
                  <input name="tipo" className="custom-control-input" id="f-tipo-en" type="radio" value="2" checked={this.state.tipo == 2} onClick={this.radioSwitchHandler} />
                                <label className="custom-control-label" htmlFor="f-tipo-en">
              Frente <i className="fa fa-arrow-right"></i> fundo   </label>
                                </div>
                              </li>
                        
            </ul>
     
              </div> 
        
            </div>
          </Row>
          
      <div className="row m-0">      
          <div className="form-group col-md-9">         
            <label htmlFor="f-centrocustos_id">
              Produto
            </label>
            <ProdutoSelect change={this.selectChangeHandler} produto_id={this.state.produto} />
          </div>

                <div className="form-group col-md-3">         
                <label htmlFor="f-qtd">
                  Qtd.
                </label>
                <input className="form-control" name="qtd" onChange={this.inputChangeHandler} value={this.state.qtd} />
              </div>
       
        </div> 
    
      
          <Row>
          <div className="form-group col-md-12">         
            <label htmlFor="f-motivo">
              Motivo
            </label>
            <textarea className="form-control" name="motivo" id="f-motivo" type="text" value={this.state.motivo} onChange={(e) => REF.inputChangeHandler(e)}  />
          </div>
          </Row>
    </>
);
   }

 
}
export default TransfForm;