import React from 'react';
import axios from 'axios';
import {Row, Col, Button, InputGroup, FormControl} from 'react-bootstrap';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import CentroCustoSelect from '../centrocusto/CentroCustoSelect';
import FornecedorSelect from '../fornecedor/FornecedorSelect';
import DatePicker from 'react-date-picker';
import { selectChangeHandler, inputChangeHandler, dateChangeHandler, isValid } from '../CustomForm';
import {mask_decimal, parseDecimal} from '../util/inputMask';

class ContaPagarForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arquivo_id: 0,
            show: false,
            total: 0,
            centrocustos_id: 0,
            fornecedor: 0,
            dtpagto:"",
            dtvencimento:"",
            empresa_id: sessionGet('empresa'),
            validator: {},

        }
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
        this.isValid = isValid.bind(this);
    }


    componentDidMount(){
        if(this.state.id != this.props.form.id){
          this.setState({ ...this.props.form });
          this.props.modalcontrol(this);
        }
    }

    post (){
        let headers = axiosHeader();
    
        let formData = {};
        let entries = Object.entries(this.state);
        let money_list = ["total"];
        entries.map(([property,value], k) => {
          let v_format = value; 
          if(money_list.includes(property)){
            v_format = parseDecimal(v_format);
          }
          formData[property] = v_format;
        });
        return axios.put(CFG.URL_API+'/movimentacao/contas-apagar/'+this.state.id, this.state, headers)
    }

    render(){
        return <div>
             <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />
            <div className="row">    
                <div className="form-group col-xs-12 col-sm-6 col-md-4">     
                    <label htmlFor="f-centrocustos_id">
                    Centro de custos
                    </label>
                    <CentroCustoSelect empresa_id={this.state.empresa_id} change={this.selectChangeHandler} centrocustos_id={this.state.centrocustos_id} />    
                </div>
                <div className="form-group col-xs-12 col-sm-6 col-md-4">     
                    <label htmlFor="f-fornecedor_id">
                    Fornecedor
                    </label>
                    <FornecedorSelect change={this.selectChangeHandler} fornecedor_id={this.state.fornecedor} name="fornecedor" />
                </div>  
                <div className="form-group col-xs-12 col-sm-12 col-md-4">     
                    <label htmlFor="f-documento">
                    Total
                    </label>
                    <input className="form-control" name="total" id="f-total" type="text" value={this.state.total} onChange={this.inputChangeHandler} />
                </div>
            </div>
        </div>
    }
}  

export default ContaPagarForm