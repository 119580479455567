import React from 'react';

import { Link } from "react-router-dom";
import { Card, Accordion, ListGroup, ListGroupItem } from 'react-bootstrap';

class ProdutosMenu extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            show: this.props.show,
            extraclasses: ''
        };
    }

    toggle = () => {
        let REF = this;

        this.props.onchange();

        if(this.state.show){
            this.setState({ show: false, extraClasses: '' });
            setTimeout(() => REF.setState({ extraClasses: '' }), 500);
        } else {
            this.setState({ show: true, extraClasses: 'active' });
        } 
    }

    render(){

        return (
            <Accordion>
            <Card>
                <Accordion.Header onClick={this.toggle} className={"lv-2 " + this.state.extraClasses} as={Card.Header} eventKey="0">
                <div className="w-icon"><i className="fa fa-file-text-o"></i></div>Produtos
                </Accordion.Header>
                <Accordion.Body eventKey="0">
                <Card.Body>


                <ListGroup>
                <ListGroupItem><Link onClick={this.props.ontoggle} to="/grupo-fornecedor/" className="lv-2"><div className="w-icon"><i className="fa fa-object-group"></i></div>Grupo de fornecedores</Link></ListGroupItem>
                <ListGroupItem><Link onClick={this.props.ontoggle} to="/fornecedor/" className="lv-2"><div className="w-icon"><i className="fa fa-users"></i></div>Fornecedores</Link></ListGroupItem>
                   <ListGroupItem hidden={window.userCan.grade !== 1}><Link onClick={this.props.ontoggle} to="/produto-grade/" className="lv-2"><div className="w-icon"><i className="fa fa-object-group"></i></div>Grade de produtos</Link></ListGroupItem>
                    <ListGroupItem><Link onClick={this.props.ontoggle} to="/produto-categoria/" className="lv-2"> <div className="w-icon"><i className="fa fa-object-group"></i></div>Categorias de produtos</Link></ListGroupItem>
                    <ListGroupItem><Link onClick={this.props.ontoggle} to="/produto/" className="lv-2"> <div className="w-icon"><i className="fa fa-cubes"></i></div>Gerenciar produtos</Link></ListGroupItem>
                </ListGroup>
                </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion>
        );
    }

}   

export default ProdutosMenu;



