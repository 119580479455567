import React from "react" // Import React
import { getListing } from "../../CustomDataTable" // Import React
import "../../DataTable.scss"
import "./Sinteticos.scss"
import { sessionGet } from "../../usuario/Auth"
import { Link } from "react-router-dom"
import { Row, Col, Alert } from "react-bootstrap"
import LoadingPlaceholder from "../../util/LoadingPlaceholder"
class UltimasRecargasPagamentos extends React.Component {
    constructor(props) {
        super(props)

        let d = new Date()

        this.state = {
            id: "",
            loading: true,
            mes: d.getMonth() + 1,
            ano: d.getFullYear(),
            empresa: sessionGet("empresa"),
            form: [],
            data: [],
            searchTerm: null,
            activeIndex: undefined
        }

        this.getListing = getListing.bind(this)
    }
    updateListing = () => {
        let REF = this
        this.getListing("recarga/ultimas?empresa=" + this.state.empresa).then(() =>
            REF.setState({ loading: false })
        )
    }
    componentDidMount() {
        this.updateListing()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.empresa != this.props.empresa) {
            this.setState({ empresa: this.props.empresa })
            this.updateListing()
        }
        if (prevProps.form != this.props.form) {
            this.setState({ form: this.props.form })
        }
    }

    getColumns() {
        return [
            {
                dataField: "id",
                text: "ID",
                style: { "text-align": "center" },
                headerStyle: { width: "10%", "text-align": "center" }
            },
            {
                dataField: "empresa_id",
                text: "Empresa",
                hidden: true
            },
            {
                dataField: "titular",
                text: "Titular",
                sort: true
            },
            {
                dataField: "fornecedor_id",
                text: "Fornecedor",
                hidden: true
            },
            {
                dataField: "fornecedor_nome",
                text: "Fornecedor",
                sort: true
            },
            {
                dataField: "dt_vencimento",
                text: "Data de vencimento",
                hidden: true
            },
            {
                dataField: "vencimento_format",
                text: "Data de vencimento"
            },
            {
                dataField: "valor",
                text: "Valor"
            }
        ]
    }

    render() {
        console.log("data", this.state.data)
        if (!this.state.loading && this.state.data.length == 0)
            return (
                <Alert variant="info">
                    Sem dados correspondentes a {this.state.mes}/{this.state.ano} para o gráfico
                    "Últimas Recargas e Pagamentos"
                </Alert>
            )

        return (
            <>
                <LoadingPlaceholder show={this.state.loading} />

                <div hidden={this.state.data.length == 0} className="Sinteticos">
                    <Row>
                        <Col md="8">
                            <h4>Últimas Recargas e Pagamentos</h4>
                        </Col>
                        <Col md="4 d-flex">
                            <Link
                                disabled
                                to="/relatorio/recarga"
                                className="btn btn-secondary btn-sm ml-auto my-2 d-inline-block"
                            >
                                <i className="fa fa-file-text-o"></i> Extrato completo
                            </Link>
                        </Col>
                    </Row>
                    <table className="table table-striped table-bordered UltimasRecargasPagamentos Sinteticos">
                        <thead>
                            <tr>
                                <th>Titular</th>
                                <th>Operação</th>
                                <th width="20%">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.length > 0 ? (
                                this.state.data.map((m, i) => (
                                    <tr key={`rec_${i}`}>
                                        <td>
                                            <span className="t-local">{m.titular}</span>
                                            <br />
                                            <small className="text-muted m-1">
                                                {m.emissao_format}
                                            </small>
                                        </td>
                                        <td>
                                            {
                                                <span className="badge badge-info m-1">
                                                    {m.tipo_operacao}
                                                </span>
                                            }
                                        </td>
                                        <td>
                                            {
                                                <span
                                                    className={
                                                        m.tipo == 7
                                                            ? "badge badge-danger"
                                                            : "badge badge-success"
                                                    }
                                                >
                                                    {m.totalFormat}
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Sem registro</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}
export default UltimasRecargasPagamentos
