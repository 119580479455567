import React from 'react'; // Import React
import moment from 'moment';
import {getListing, CustomToggleList, tableRender} from '../CustomDataTable'; // Import React
import '../DataTable.scss';
import ContaPagarModal from './ContaPagarModal';
import { sessionGet } from '../usuario/Auth';
class ContasPagarDataTable extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      empresa_id: sessionGet('empresa'),
      form:[],
   
      data: [],
      searchTerm: null,
      activeIndex: undefined,
      inicio:moment().format("YYYY-MM-01"),
      fim:moment().format("YYYY-MM-DD")
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender.bind(this)
  }
  updateListing = (columnsSearch) => {
    this.getListing(`/movimentacao/contas-apagar?inicio=${this.state.inicio}&fim=${this.state.fim}`, columnsSearch);
  }
  componentDidMount(){
    this.updateListing(this.props.filters);
  }
  componentDidUpdate(prevProps){
    if(prevProps.empresa != this.props.empresa){
      this.setState({empresa: this.props.empresa });
      this.updateListing(this.props.filters);
    }
    if(prevProps.form != this.props.form){
      this.setState({ form: this.props.form });
    }
    if(prevProps.refresh != this.props.refresh){
      this.updateListing(this.props.filters);
    }
  }
  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }
  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }
  setModalShow (status, data){
    if(data != null){
      this.setState({  form : data });
    }
    this.setState({  show: status });
  }
  hideModal = () => {
    this.setState({ show: false });
    this.updateListing(this.props.filters);
  }
  getColumns(){
    return [
      {
        dataField: 'id',
        text: 'ID',
        style: { 'text-align' : 'center' },
        headerStyle: { 'width' : '10%', 'text-align':'center' },
        sort: true
      },{
        dataField: 'custo_descricao',
        text: 'Descrição',
        sort: true
      },{
        dataField: 'fornecedor',
        text:"Fornecedor",
        hidden: true
      },{
        dataField: 'fornecedor_razao',
        text: 'Fornecedor',
      },{
        dataField: 'centrocustos_id',
        text:"Cursto id",
        hidden: true
      }
      ,{
        dataField: 'emissao',
        text: 'Data Emissão',
      },{
        dataField: 'dtpagto',
        text: 'Data de vencimento',
      },{
        dataField: 'dtvencimento',
        text: 'Data de pagamento',
      },{
        dataField: 'total',
        text: 'Valor',
      }
    ];
  }

  render(){
    return (
      <>
      <ContaPagarModal
      show={this.state.show}        
      form={this.state.form}        
      onHide={this.hideModal}
      formupdate={this.formUpdate}
      />  
      {
        (() => this.tableRender())()
      }
      </>
      )
    }
  }
  export default ContasPagarDataTable;
