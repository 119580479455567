import React from 'react';
import {mask_decimal} from '../util/inputMask';
import { inputChangeHandler, isValid, nascimentoChangeHandler, cepChangeHandler } from '../CustomForm';
import {sessionGet} from '../usuario/Auth';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';

class CartaoLimiteForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      empresa: sessionGet('empresa'),
        limite_d1: '',
        limite_d2: '',
        limite_d3: '',
        limite_d4: '',
        limite_d5: '',
        limite_d6: '',
        validator: {},
        id: 0
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);

    this.put = this.put.bind(this);

  }

 

   getStateUpdated = () => {
    let up_state = this.state;
    up_state.limite_d1 = up_state.limite_d1;
    up_state.limite_d1 = up_state.limite_d1;
    up_state.limite_d2 = up_state.limite_d2;
    up_state.limite_d3 = up_state.limite_d3;
    up_state.limite_d4 = up_state.limite_d4;
    up_state.limite_d5 = up_state.limite_d5;
    up_state.limite_d6 = up_state.limite_d6;
    return up_state;
  }  

  put () {
    let REF = this;
    let up_state = Object.assign({}, this.getStateUpdated());
    axios.put(CFG.URL_API+'/cartao/'+this.props.id, up_state, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
  .catch(function(error){
    console.log(error);
  });
    
    REF.setState({ submit: true });
    REF.props.close(REF.constructor.name);
   }

getId(){
    return this.state.id;
  }

  componentDidMount(){
    this.setState(this.formFiltered());  
 
  }

    
  componentDidUpdate(prevProps, prevState){

    if(!this.state.submit && this.props.submit && typeof this.props.id !== "undefined"){
      
      if(this.isValid()){
          this.put();     
      } else {
        alert('Atenção aos campos em vermelho do formulário de cartão');
      }      

    }

  
  }

  formFiltered = () => {
    return {
      id: this.props.form.id,
      limite_d1: mask_decimal(this.props.form.limite_d1),
      limite_d2: mask_decimal(this.props.form.limite_d2),
      limite_d3: mask_decimal(this.props.form.limite_d3),
      limite_d4: mask_decimal(this.props.form.limite_d4),
      limite_d5: mask_decimal(this.props.form.limite_d5),
      limite_d6: mask_decimal(this.props.form.limite_d6)
    }
  }



  render() {
    return (
    <form>
      <input name="cartao" value={this.props.form.id} type="hidden"/>
        <div className="row">      
        <div className="form-group col-md-3">         
          <label htmlFor="f-segunda">
            Segunda
          </label>
          <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">R$</span>
            </div>
          <input className="form-control" name="limite_d1" id="f-segunda" type="text" value={this.state.limite_d1} onChange={this.inputChangeHandler}/>
          </div>
        </div>  
        <div className="form-group col-md-3">         
          <label htmlFor="f-terca">
            Terça
          </label>

          <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">R$</span>
            </div>
          <input className="form-control" name="limite_d2" id="f-terca" type="text" value={this.state.limite_d2} onChange={this.inputChangeHandler}/>
          </div>

        </div>  
        <div className="form-group col-md-3">         
          <label htmlFor="f-quarta">
            Quarta
          </label>  
          <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">R$</span>
            </div>
          <input className="form-control" name="limite_d3" id="f-quarta" type="text" value={this.state.limite_d3} onChange={this.inputChangeHandler}/>
          </div>
        </div> 
        </div> 
      <div className="row">      
        <div className="form-group col-md-3">         
          <label htmlFor="f-quinta">
            Quinta
          </label>  
          <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">R$</span>
            </div>
          <input className="form-control" name="limite_d4" id="f-quinta" type="text" value={this.state.limite_d4} onChange={this.inputChangeHandler}/>
          </div>
        </div>  
        <div className="form-group col-md-3">         
          <label htmlFor="f-sexta">
            Sexta
          </label>  
          <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text">R$</span>
                </div>
            <input className="form-control" name="limite_d5" id="f-sexta" type="text" value={this.state.limite_d5} onChange={this.inputChangeHandler}/>
            </div>
         </div>
        <div className="form-group col-md-3">         
          <label htmlFor="f-sabado">
            Sábado
          </label>            
          <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text">R$</span>
                </div>
            <input className="form-control" name="limite_d6" id="f-sabado" type="text" value={this.state.limite_d6} onChange={this.inputChangeHandler}/>
            </div>
          
        </div>
        </div>
    </form>
    );
  }
}
export default CartaoLimiteForm;