import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import CategoriaForm from './CategoriaForm';
import url_btn_close from '../img/icons/close.png';

class CategoriaModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = { 
        id: undefined,
        categoria: '',  
        data: {},
        form: {},
        show: false,
        submit: false,
        delete: false
      };

      this.modalControl = this.modalControl.bind(this);
      this.formSubmit = this.formSubmit.bind(this);
      this.formDelete = this.formDelete.bind(this);

  }

  componentDidMount(){
    this.setState({ submit: false, delete: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.show != this.props.show){
      this.setState({ data: this.props.form, show: this.props.show });
    }
  }

  closeTrigger = () => {
    this.setState({ delete: false, submit: false, loading: false });
    this.props.onHide();
  }

  modalControl(form_comp){
    this.setState({ form: form_comp });
  }


  render () {
    return (
      <Modal
      onShow={this.updateForm}      
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            Categoria 
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body>        
          <CategoriaForm
            close={this.closeTrigger}
            delete={this.state.delete}
            submit={this.state.submit}
            show={this.props.show}
            form={this.state.data}
            modalcontrol={this.modalControl}
          />       
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger btn-left" onClick={this.formDelete}>Excluir</Button>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  formSubmit() {

    this.setState({ submit: true, loading: true }); 
  }

  formDelete() {


    let c = window.confirm("Deseja mesmo remover a categoria "+this.state.data.categoria+" ?");
    if (c == true) {
      if(typeof this.state.id !== "undefined")
      this.setState({ delete: true });
    }

  }
  
}

export default CategoriaModal;