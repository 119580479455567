import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { inputChangeHandler, selectChangeHandler, radioSwitchHandler, isValid } from '../../CustomForm';

import {sessionGet} from '../../usuario/Auth';
import CartaoSelect from '../../cartao/CartaoSelect';
import ProdutoSelect from '../../produto/ProdutoSelect';
import DatePicker from 'react-date-picker';

class FiltroForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
          loading: false,
          cal_inicio: null,
          cal_fim: null,
          inicio: undefined,
          fim: undefined,
          empresa: sessionGet('empresa'),
          validator: {},
          show: false,
          cartao: undefined
        };
        
        this.selectChangeHandler = selectChangeHandler.bind(this);
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.radioSwitchHandler = radioSwitchHandler.bind(this);
        
        this.isValid = isValid.bind(this);
    }   

    componentDidUpdate(prevProps, prevState){

        if(prevProps.submit !== this.props.submit){
        this.props.setfilters(this.state);
      }

    }

    inicioChangeHandler = (date) => {

      if(date == null){
        this.setState({ cal_inicio: null, inicio: '' });
        return;
      }
  
      let REF = this;
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_inicio: dt, inicio: dt_format }); 
    }
  
    fimChangeHandler = (date) => {
  
  
      if(date == null){
        this.setState({ cal_fim: null, fim: '' });
        return;
      }
  
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_fim: dt, fim: dt_format }); 
    }

    render(){
        
        return(
            <> 
                 <Row className="my-2">
          <Col md="6" className="p-1 cj">
              <label>de</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="col_inicio" id="f-inicio" value={this.state.cal_inicio} onChange={this.inicioChangeHandler} />
          </Col>
            <Col md="6" className="p-1 cj">
              <label>até</label>
              <DatePicker locale="pt-BR"  autoComplete="off" name="col_fim" id="f-fim" value={this.state.cal_fim} onChange={this.fimChangeHandler} />
          </Col>
        </Row>       
            <Row>
              <div className="col-md-6">
                <label>ID do cartão</label>
                <CartaoSelect change={this.selectChangeHandler} name="cartao" cartao={this.state.cartao} />
              </div>
              <div className="col-md-6">
                <label>Produto</label>
                <ProdutoSelect change={this.selectChangeHandler} name="produto" produto_id={this.state.produto} />
              </div>
            </Row>    
            <Row>
          
              <div className="col-md-6">
              <label class="form-span">Administradora</label>
              <select name="administradora" className="form-control" onChange={this.inputChangeHandler}  value={this.state.administradora}>
                <option value="">Todas</option>
                <option value="Amex">Amex</option>
                <option value="Diners">Diners</option>
                <option value="Elo">Elo</option>
                <option value="Elo Debito">Elo Debito</option>
                <option value="Hipercard">Hipercard</option>
                <option value="Maestro">Maestro</option>
                <option value="Mastercard">Mastercard</option>
                <option value="Sodexo Ali">Sodexo Ali</option>
                <option value="Sodexo Ref">Sodexo Ref</option>
                <option value="Ticket Res">Ticket Res</option>
                <option value="Visa">Visa</option>
                <option value="Visa Credito">Visa Credito</option>
                <option value="Visa Electron">Visa Electron</option>
                <option value="Visavale">Visavale</option>
            </select>
              </div>
              <div className="col-md-6">
                <label className="form-span">Rede</label>
                <select name="rede" className="form-control" onChange={this.inputChangeHandler} value={this.state.rede}>
                <option value="">Todas</option>
                <option value="Cielo">Cielo</option>
                <option value="Redecard">Redecard</option>
              </select>
              </div>

            </Row>  

            <Row>
            <div className="form-group col-md-6">     
              <label>NSU</label>
              <input className="form-control" value={this.state.nsu} name="nsu" onChange={this.inputChangeHandler} />  
            </div>  
            <div className="form-group col-md-6">         
                <label>
                  Tipo
                </label>
               
            <ul className="list-inline">
            <li className="list-inline-item">
                              <div className="custom-control custom-radio  m-2">
                                <input name="B_tipo" className="custom-control-input" id="f-tipo-d" type="radio" value="D" checked={this.state.B_tipo === "D"} onClick={this.radioSwitchHandler}/>
                                <label className="custom-control-label" htmlFor="f-tipo-d">
              Débito   </label>
                                </div>
                              </li> 
                      

              <li className="list-inline-item">
                <div className="custom-control custom-radio  m-2">
                  <input name="B_tipo" className="custom-control-input" id="f-tipo-c" type="radio" value="C" checked={this.state.B_tipo === "C"} onClick={this.radioSwitchHandler} />
                                <label className="custom-control-label" htmlFor="f-tipo-c">
              Crédito  </label>
                                </div>
                              </li>
                              <li className="list-inline-item">
                <div className="custom-control custom-radio m-2">
                  <input name="B_tipo" className="custom-control-input" id="f-tipo-todos" type="radio" value="" checked={this.state.B_tipo === ""} onClick={this.radioSwitchHandler} />
                                <label className="custom-control-label" htmlFor="f-tipo-todos">
              Todos  </label>
                                </div>
                              </li>
                        
            </ul>
     
              </div> 
            </Row>

   


            </>
            );
        }        
    }
    export default FiltroForm;