import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import ResponsavelForm from './ResponsavelForm';
import './ResponsavelTab.scss';
import CartoesDataTable from '../cartao/CartoesDataTable';

class ResponsavelTab extends React.Component{

    constructor(props) {
    super(props);
    
    this.state = {
      ...this.props.form,
      senha_decode: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.form !== this.props.form){
      this.setState({ ...this.props.form })
    }
  }

  setModalShow (status, data){

    if(data != null){

      if(data.senha != null)
      data.senha_decode = atob(data.senha);

      this.setState({  data: data });
    }

    this.setState({  show: status });
  }

  render () {
    return (
        <div className="ResponsavelTab">
        <Tabs defaultActiveKey="ResponsavelForm" transition={false} id="noanim-tab-example">
          <Tab eventKey="ResponsavelForm" title="Dados">
          <ResponsavelForm
              form={this.props.form}
              modalcontrol={this.props.modalcontrol}
              close={this.props.close}
              submit={this.props.submit}
            />
          </Tab>
          <Tab eventKey="CartoesDataTable" title="Cartões">
          <div className="table-scroll">
              <CartoesDataTable responsavel_id={this.state.id}/>
          </div>
          </Tab>        
        </Tabs>
      </div>
    );
  }

}


export default ResponsavelTab;