import React from 'react';
import axios from 'axios';
import {inputChangeHandler} from '../CustomForm';
import {requestException} from '../util/exception';
import CFG from '../CFG';
import {Row, Col, Button} from 'react-bootstrap';
import {mask_decimal} from '../util/inputMask';

class ParcelDisplay extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            id: this.props.form.id,
            key: this.props.key,
            val_parcela: this.props.form.val_parcela,
            parcela: this.props.form.parcela,
            valor: this.props.form.valor,
            bruto: this.props.form.bruto,
            vencimento: this.props.form.vencimento,
            //id da tabela pivô
            value: this.props.value
        };

        this.inputChangeHandler = inputChangeHandler.bind(this);
  
    }

    changeHandler(event){

        if(event.target.value != "" && typeof this.props.formcontrol === "function")
        this.props.formcontrol(this);

        this.inputChangeHandler(event);
    }

    render(){
        let REF = this;
        return (
        <Row key={this.state.key}>
            <Col xs="5">
                <label htmlFor={"valor_"+this.state.key}>Valor:</label>
                <input type="text" id={"valor_"+this.state.key} value={mask_decimal(this.state.val_parcela)} onChange={this.state.changeHandler}  className="form-control" name="val_parcela" />
            </Col>
            <Col xs="5">
                <label htmlFor={"vencimento_"+this.state.key}>Vencimento:</label>
                <input type="text" id={"vencimento_"+this.state.key} value={this.state.vencimento} onChange={this.state.changeHandler} className="form-control" name="vencimento" />
            </Col>
            <Col xs="2">
                <Button onClick={ () => REF.delete() } variant="danger" className="btn-sm mr-auto d-block" style={{marginTop: '1.6rem'}}>
                    <i className="icon-trash"></i>
                </Button>
            </Col>
        </Row>
        );
    }

    delete = () => {
        let REF = this;
        let c = window.confirm("Deseja mesmo cancelar a parcela "+this.state.key+" ?");
        if (c === true) {
     
        if(this.state.id == null)
        return true;

        axios.delete(CFG.URL_API+'/forma-parcela/'+this.state.id)
        .then(function(response){
          //Perform action based on response
          REF.props.delete(this.state.key);
        })
        .catch(function(error){
            requestException(error);
        });
        }
    }    

}
export default ParcelDisplay;
