import React from 'react';
import axios from 'axios';
import {Row,Col} from 'react-bootstrap';
import {CFG, axiosHeader} from '../../CFG';
import {sessionGet} from '../../usuario/Auth';
import { inputChangeHandler, isValid, selectChangeHandler, checkboxClickHandler } from '../../CustomForm';
import ProdutoSelect from '../../produto/ProdutoSelect';
import CFOPSelect from '../../estoque/nota/CFOPSelect';
import DatePicker from 'react-date-picker';
import {mask_decimal, parseDecimal} from '../../util/inputMask';
import FornecedorSelect from '../../fornecedor/FornecedorSelect';
import UnidadeSelect from '../../produto/UnidadeSelect';

class ItemForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      produto_pair: {
        label: undefined
      },
        useSameProduct: true,
        dtentregaprod: '',
        dtvalprod: '',
        dtfabprod: '',
        cal_dtentregaprod: null,
        cal_dtvalprod: null,
        cal_dtfabprod: null,
      id: 0,
      empresa_id: sessionGet('empresa'),
      fornecedor_id: undefined,
      indicador: '',     
      validator: {},
      unidade: '',
      unidade_qtd: 0,
      test: '',
      produto: '',
      show: false,
      data_removido_format: undefined
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.checkboxClickHandler = checkboxClickHandler.bind(this);

  }

  componentDidMount(prevProps, prevState){
    let REF = this;
    this.setState({ ...this.props.form, xProdSistema: this.props.form.xProd });
  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy });

    }

  }

  componentDidUpdate(prevProps, prevState){


    if(prevState.qtdeprod !== this.state.qtdeprod || prevState.precoprod !== this.state.precoprod || prevState.vldescontoprod !== this.state.vldescontoprod){

      let qtd = typeof this.state.qtdeprod !== "undefined" ? this.state.qtdeprod : 0.0;
      let preco = typeof this.state.precoprod !== "undefined" ? this.state.precoprod : 0.0;
      let desc = typeof this.state.vldescontoprod !== "undefined" ? this.state.vldescontoprod : 0.0;

  
      let totalprod = parseFloat(parseDecimal(qtd) * parseDecimal(preco) - parseDecimal(desc)).toFixed(2);
      let totalprod_format = mask_decimal(totalprod);
      this.setState({ totalprod, totalprod_format });

    }

    if(prevProps.form.id !== this.props.form.id){
      this.setState({ ...this.props.form });
    }

    if(prevState.id !== this.state.id){
      let REF = this;
      this.setState({
        qtdeprod: mask_decimal(REF.state.qtdeprod),
        precoprod: mask_decimal(REF.state.precoprod),
        vldescontoprod: mask_decimal(REF.state.vldescontoprod)
      })
    }

    if(prevState.produto_pair.label !== this.state.produto_pair.label){
      let REF = this;
 
      this.setState({ xProdSistema: REF.state.produto_pair.label });
    }   

    if(prevState.xProdSistema !== this.state.xProdSistema){
      this.setState({ produto: this.state.xProdSistema });
    }

    if(this.state.useSameProduct && prevState.useSameProduct !== this.state.useSameProduct){
      this.setState({ xProdSistema: this.state.xProd });
    }

    if(prevState.vldocumento !== this.state.vldocumento){
      this.props.update_vldocumento(this.state.vldocumento);
    }

    if(this.props.confirm && prevProps.confirm !== this.props.confirm){
      this.props.close();
      this.props.tablerowupdate(this.state);
    }

  
  }



   render(){

    let REF = this;

   return(
     <>
  <div className="modal-body" style={{height: '510px'}}><div className="bootstrap-dialog-body">
      <div className="bootstrap-dialog-message">
        <Row>
            <div className="col-lg-6"> 
              <div hidden={this.state.useSameProduct}>
                <label className="text-uppercase">Produto</label>
                <ProdutoSelect change={this.selectChangeHandler} produto_id={this.state.produto_id} />
              </div>
              <div hidden={!this.state.useSameProduct}>
              <label htmlFor="f-fornecedor" className="d-block">
                Nome do produto
              </label>
                <input className="form-control" id="xProdSistema" name="xProdSistema" value={this.state.xProdSistema} onChange={this.inputChangeHandler}  />
              </div>
            </div>
            <div className="col-lg-6"> 
              <div  className="my-4" >
                <input type="checkbox" value={true} id="f-useSameProduct" name="useSameProduct" checked={this.state.useSameProduct} onClick={this.checkboxClickHandler}  className="mr-2"/>
                <label htmlFor="f-useSameProduct" class="d-inline">
                    <u>Cadastrar produto com base no informado na nota</u>
                </label>
              </div>
            </div>
            </Row>

               <Row>
              <div className="col-lg-4"> 
                <label className="text-uppercase">Fornecedor</label>
                <FornecedorSelect change={this.selectChangeHandler} fornecedor_id={this.state.fornecedor_id} name="fornecedor_id" />

              </div>

              <div className="col-lg-4"> 
                <label className="text-uppercase">Qtde</label><input className="form-control" id="qtdeprod" name="qtdeprod" value={this.state.qtdeprod} onChange={this.inputChangeHandler} />
              </div>
            
              <div className="col-lg-4"> 
                <label className="text-uppercase">Unidade</label>
                <UnidadeSelect change={this.selectChangeHandler} unidade={this.state.unidade.toUpperCase()} name="unidade" />
                <small className="help form-text" hidden={this.state.unidade_qtd === 0 || this.state.unidade_qtd === null}>O sistema irá multiplicar {mask_decimal(this.state.qtdeprod)} x {mask_decimal(this.state.unidade_qtd)} e registrar entrada de estoque com {parseFloat(this.state.qtdeprod) * parseFloat(this.state.unidade_qtd)} unidades</small>
              </div>
         
          </Row>


            <Row>
        
              <div className="col-lg-4"> 
                <label className="text-uppercase">Preço</label><input className="form-control" id="precoprod" name="precoprod" value={this.state.precoprod} onChange={this.inputChangeHandler}  />
              </div>
 
            <div className="col-lg-4"> 
              <label className="text-uppercase">Desconto</label><input className="form-control" id="vldescontoprod" name="vldescontoprod" value={this.state.vldescontoprod} onChange={this.inputChangeHandler}  />
            </div>
            <div className="col-lg-4"> 
              <label className="text-uppercase">Total</label><input className="form-control" readOnly="readonly" id="totalprod_format" name="totalprod_format" value={this.state.totalprod_format}  onChange={this.inputChangeHandler}  />
            </div>
         
          </Row>

       


          <Row className="mt-2">
          <div className="col-lg-6">
            <div className="card card-primary">
              <div className="card-header padding-top-0 padding-bottom-0">
                <h5 className="m-0 card-title">Impostos</h5>
                </div>
              <div className="card-body">
              <div className="row mt-2">
                  <label className="col-lg-4 control-label "></label>
                  <div className="col-lg-4 margin-0">
                    %
                  </div>
                  <div className="col-lg-4 margin-0">
                    R$
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label ">IPI%</label>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control fmoney" id="ipi" name="ipi" value={mask_decimal(this.state.ipi)} onChange={this.inputChangeHandler} />			
                  </div>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control" disabled="disabled" id="totalipiprod" name="totalipiprod" value={mask_decimal(this.state.ipi)} onChange={this.inputChangeHandler}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label ">ICMS%</label>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control fmoney" id="icms" name="icms" value={mask_decimal(this.state.icms)} onChange={this.inputChangeHandler} />			
                  </div>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control" disabled="disabled" id="totalicmsprod" name="totalicmsprod" value={mask_decimal(this.state.ICMS)} onChange={this.inputChangeHandler}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label ">PIS%</label>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control fmoney" id="pis" name="pis" value={mask_decimal(this.state.pis)} onChange={this.inputChangeHandler} />			
                  </div>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control" disabled="disabled" id="totalpisprod" name="totalpisprod" value={mask_decimal(this.state.PIS)} onChange={this.inputChangeHandler}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label ">COFINS%</label>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control fmoney" id="cofinsprod" name="cofinsprod" value={mask_decimal(this.state.cofinsprod)} value={this.state.cofinsprod} onChange={this.inputChangeHandler}/>			
                  </div>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control" disabled="disabled" id="totalcofinsprod" name="totalcofinsprod" value={mask_decimal(this.state.COFINS)} onChange={this.inputChangeHandler}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label " hidden={this.props.tipo === "entrada"}>MVA%</label>
                  <div className="col-lg-4 margin-0" hidden={this.props.tipo === "entrada"}>
                    <input className="form-control fmoney" id="mvaprod" name="mvaprod" value={mask_decimal(this.state.mvaprod)} onChange={this.inputChangeHandler} />			
                  </div>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control" disabled="disabled" id="totalmvaprod" name="totalmvaprod" value={mask_decimal(this.state.totalmvaprod)} onChange={this.inputChangeHandler}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label "  hidden={this.props.tipo === "entrada"}>Aliq.ST%</label>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control fmoney" id="aliqicmsstprod" name="aliqicmsstprod" value={mask_decimal(this.state.aliqicmsstprod)} onChange={this.inputChangeHandler}  hidden={this.props.tipo === "entrada"} />			
                  </div>
                  <div className="col-lg-4 margin-0">
                    <input className="form-control" disabled="disabled" id="totalaliqicmsstprod" name="totalaliqicmsstprod" value={mask_decimal(this.state.totalaliqicmsstprod)} onChange={this.inputChangeHandler}  hidden={this.props.tipo === "entrada"}/>
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label "  hidden={this.props.tipo === "entrada"}>Base.ST</label>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control fmoney" id="baseicmsstprod" name="baseicmsstprod" value={mask_decimal(this.state.baseicmsstprod)} onChange={this.inputChangeHandler} />			
                  </div>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control" disabled="disabled" id="totalbaseicmsstprod" name="totalbaseicmsstprod" value={mask_decimal(this.state.totalbaseicmsstprod)} onChange={this.inputChangeHandler} />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 control-label "  hidden={this.props.tipo === "entrada"}>Valor.ST</label>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control fmoney" id="totalicmsstprod" name="totalicmsstprod" value={mask_decimal(this.state.totalicmsstprod)} onChange={this.inputChangeHandler} />			
                  </div>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control" disabled="disabled" id="totaltotalicmsstprod" name="totaltotalicmsstprod" value={mask_decimal(this.state.totaltotalicmsstprod)} onChange={this.inputChangeHandler}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-4 text-righ t control-label "  hidden={this.props.tipo === "entrada"}>Aliq.Aplic</label>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control fmoney" id="aliqaplprod" name="aliqaplprod" value={mask_decimal(this.state.aliqaplprod)} onChange={this.inputChangeHandler} />			
                  </div>
                  <div className="col-lg-4 margin-0"  hidden={this.props.tipo === "entrada"}>
                    <input className="form-control" disabled="disabled" id="totalaliqaplprod" name="totalaliqaplprod" value={mask_decimal(this.state.totalaliqaplprod)} onChange={this.inputChangeHandler} />
                  </div>
                </div>
              </div>
            </div>	
          </div>
          <div className="col-lg-6">
            <div className="card card-info">
              <div className="card-header padding-top-0 padding-bottom-0">
                <h5 className="m-0 card-title">Demais Informações</h5></div>
              <div className="card-body">
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">CFOP</label>
                  <div className="col-lg-9 padding-0">
                    <CFOPSelect  change={this.selectChangeHandler} cfop={this.state.cfop}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Vl. Frete</label>
                  <div className="col-lg-3 padding-0">
                    <input className="form-control fmoney" id="vlfreteprod" name="vlfreteprod" value={this.state.vlfreteprod} value={mask_decimal(this.state.vlfreteprod)} onChange={this.inputChangeHandler}/>			
                  </div>
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Vl. Trib.</label>
                  <div className="col-lg-3 padding-0">
                    <input className="form-control fmoney" id="vtribprod" name="vtribprod" value={this.state.vtribprod} value={mask_decimal(this.state.vtribprod)} onChange={this.inputChangeHandler}/>			
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Outr.Desp</label>
                  <div className="col-lg-3 padding-0">
                    <input className="form-control fmoney" id="outrasdespprod" name="outrasdespprod" value={mask_decimal(this.state.outrasdespprod)} onChange={this.inputChangeHandler} />			
                  </div>
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">CEST</label>
                  <div className="col-lg-3 padding-0">
                    <input className="form-control" id="cest" name="cest" value={this.state.cest} onChange={this.inputChangeHandler}  />			
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Pr.Venda</label>
                  <div className="col-lg-3 padding-0">
                    <input className="form-control fmoney" id="prvendaprod" name="prvendaprod" value={mask_decimal(this.state.prvendaprod)} onChange={this.inputChangeHandler} />			
                  </div>
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Lote</label>
                  <div className="col-lg-3 padding-0">
                    <input className="form-control fmoney" id="prvendaprod" name="prvendaprod" value={mask_decimal(this.state.prvendaprod)} onChange={this.inputChangeHandler} />			
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Dt.Entrg</label>
                  <div className="col-lg-9 padding-0">
                  <DatePicker locale="pt-BR"  autoComplete="off" className="form-control" name="dtentregaprod" id="f-dtentregaprod" value={this.state.cal_dtentregaprod}  type="text" onChange={(e) => REF.dateChangeHandler(e, 'dtentregaprod')}  />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Dt.Valid</label>
                  <div className="col-lg-9 padding-0">
                  <DatePicker locale="pt-BR"  autoComplete="off" className="form-control" name="dtvalprod" id="f-dtvalprod" value={this.state.cal_dtvalprod} type="text" onChange={(e) => REF.dateChangeHandler(e, 'dtvalprod')} />

                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Dt.Fabrc</label>
                  <div className="col-lg-9 padding-0">
                    <DatePicker locale="pt-BR"  autoComplete="off" className="form-control" name="dtfabprod"  value={this.state.cal_dtfabprod} id="f-dtfabprod"  type="text" onChange={(e) => REF.dateChangeHandler(e, 'dtfabprod')} />
                  </div>
                </div>
                <div className="row mt-2">
                  <label className="col-lg-3 padding-left-0 padding-right-5 control-label ">Info</label>
                  <div className="col-lg-9 padding-0">
                  <input class="form-control" id="informacaoprod" name="informacaoprod" value={this.state.informacaoprod} onChange={this.inputChangeHandler}  />
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Row>
    </div>
    </div>
    </div>
</>
);
   }

}
export default ItemForm;