import React from 'react';
import NFe from 'djf-nfe';
import {CFG, axiosHeader} from '../../CFG';
import axios from 'axios';
import {xmlToJson} from '../../util/parse';
import {requestException} from '../../util/exception';
import {floatToReal, mask_decimal} from '../../util/inputMask';
import {def} from '../../util/string';

export function insertGeneralInfo(){
    let REF = this;
 
    if(typeof REF.state.NFe.infNFe.ide.dhEmi === "undefined")
    throw 'Data de emissão faltando';

     if(typeof REF.state.NFe.infNFe.ide.dhSaiEnt === "undefined")
    throw 'Data de entrada/saída faltando';

    let infAdFisco = typeof REF.state.NFe.infNFe.infAdic.infAdFisco !== "undefined" ? REF.state.NFe.infNFe.infAdic.infAdFisco['#text'] : '';
    let infCpl = typeof REF.state.NFe.infNFe.infAdic.infCpl !== "undefined" ? REF.state.NFe.infNFe.infAdic.infCpl['#text'] : '';
    this.setState({
        import: true,
        emitente: {
            nome: REF.state.NFe.infNFe.emit.xNome['#text'],
            cpf_cnpj: REF.state.NFe.infNFe.emit.CNPJ['#text'],
            logradouro: REF.state.NFe.infNFe.emit.enderEmit.xLgr['#text'],
            numero: REF.state.NFe.infNFe.emit.enderEmit.nro['#text'],
            bairro: REF.state.NFe.infNFe.emit.enderEmit.xBairro['#text'],
            cidade: REF.state.NFe.infNFe.emit.enderEmit.xMun['#text'],
            uf: REF.state.NFe.infNFe.emit.enderEmit.UF['#text'],
            cep: REF.state.NFe.infNFe.emit.enderEmit.CEP['#text'],
            telefone: REF.state.NFe.infNFe.emit.enderEmit.fone['#text'],
        },
        impostos: {
            id: REF.state.NFe.infNFe.ide.nNF['#text'],
            totalipi: REF.state.NFe.infNFe.total.ICMSTot.vIPI['#text'],
            baseicmsubst: REF.state.NFe.infNFe.total.ICMSTot.vST['#text'],
            vldocumento: REF.state.NFe.infNFe.total.ICMSTot.vNF['#text'],
            totalpis: REF.state.NFe.infNFe.total.ICMSTot.vPIS['#text'],
            totalcofins: REF.state.NFe.infNFe.total.ICMSTot.vCOFINS['#text'],    
            vlfrete: REF.state.NFe.infNFe.total.ICMSTot.vFrete['#text'],
            vldesconto: REF.state.NFe.infNFe.total.ICMSTot.vDesc['#text'],
            outrasdesp: REF.state.NFe.infNFe.total.ICMSTot.vOutro['#text'],
            baseicms: REF.state.NFe.infNFe.total.ICMSTot.vBC['#text'],
            totalicms: REF.state.NFe.infNFe.total.ICMSTot.vICMS['#text'],
            vlprodutos: REF.state.NFe.infNFe.total.ICMSTot.vProd['#text']
        },
        info_adicional: {
            id: REF.state.NFe.infNFe.ide.nNF['#text'],
            fiscoadcio: infAdFisco,
            infocomplementar: infCpl
        },
        transporte: {
            id: REF.state.NFe.infNFe.ide.nNF['#text'],
            nome: REF.state.NFe.infNFe.transp.transporta.xNome['#text'],
            cnpj: REF.state.NFe.infNFe.transp.transporta.CNPJ['#text'],
            endereco: REF.state.NFe.infNFe.transp.transporta.xEnder['#text'],
            uf: REF.state.NFe.infNFe.transp.transporta.UF['#text'],
            cidade: REF.state.NFe.infNFe.transp.transporta.xMun['#text']
        },
        id: REF.state.NFe.infNFe.ide.nNF['#text'],
        doc: REF.state.NFe.infNFe['@attributes'].Id['#text'],
        modelo: 1,
        tpfrete: REF.state.NFe.infNFe.transp.modFrete['#text'],
        serie: REF.state.NFe.infNFe.ide.serie['#text'],
        cal_dhEmi: new Date(REF.state.NFe.infNFe.ide.dhEmi['#text']),
        cal_dhSaiEnt: new Date(REF.state.NFe.infNFe.ide.dhSaiEnt['#text']),
        cal_dhRecbto: new Date(REF.state.protNFe.infProt.dhRecbto['#text']),
        motivo: REF.state.protNFe.infProt.xMotivo['#text']
    });
}

export function processItem(i, k){

    let REF = this;
    let product = {};

    if(def('i.imposto.ICMS.ICMS10.vICMS'))
    product.ICMS = i.imposto.ICMS.ICMS10.vICMS['#text'];

    if(def('i.imposto.ICMS.ICMS10.vICMS'))
    product.ICMS_ST = i.imposto.ICMS.ICMS10.vICMSST['#text'];

    if(def('i.imposto.ICMS.ICMS20.vICMS'))
    product.ICMS = i.imposto.ICMS.ICMS10.vICMS['#text'];

    if(def('i.imposto.ICMS.ICMS20.vICMS'))
    product.ICMS_ST = i.imposto.ICMS.ICMS20.vICMSST['#text'];

    if(def('i.imposto.ICMS.ICMS10.CST'))
    product.cst = i.imposto.ICMS.ICMS10.CST['#text'];

    if(def('i.imposto.IPI.IPINT.CST'))
    product.cst = i.imposto.IPI.IPINT.CST['#text'];

    if(typeof i.imposto.COFINS !== "undefined"  && typeof i.imposto.COFINS.COFINSAliq !== "undefined")
    product.COFINS = i.imposto.COFINS.COFINSAliq.vCOFINS['#text'];

    if(typeof i.imposto.COFINS !== "undefined"  && typeof i.imposto.COFINS.COFINSNT !== "undefined")
    product.COFINS = i.imposto.COFINS.COFINSNT.CST['#text'];

    if(def('i.imposto.PIS.PISNT.CST'))
    product.IPI = i.imposto.PIS.PISNT.CST['#text'];

    

    if(typeof i.imposto.PIS !== "undefined" && typeof i.imposto.PIS.PISAliq !== "undefined")
    product.PIS = i.imposto.PIS.PISAliq.vPIS['#text'];

    if(typeof i.imposto.IPI !== "undefined" && typeof i.imposto.IPI.IPITrib !== "undefined")
    product.IPI = i.imposto.IPI.IPITrib.vIPI['#text'];
    
    if(typeof i.imposto.vTotTrib !== "undefined")
    product.vTotTrib = i.imposto.vTotTrib['#text']; 

    let transport_info = REF.state.NFe.infNFe.transp.vol;
    //let transport_info = (typeof k === "undefined") ? REF.state.NFe.infNFe.transp.vol : REF.state.NFe.infNFe.transp.vol[k];

    product.transport = {
        id: REF.state.NFe.infNFe.ide.nNF['#text'],
        especie: def('transport_info.esp') ? transport_info.esp['#text'] : "",
        volume: def('transport_info.pesoB') ? transport_info.pesoB['#text'] : "",
        pesol: def('transport_info.pesoL') ? transport_info.pesoL['#text'] : "",
        pesob: def('transport_info.qVol') ? transport_info.qVol['#text'] : ""
    };

    let list = Object.entries(i.prod);

    list.map(([k, v]) => {
        product[k] = v['#text'];
    });

    product.unidade = product.uCom;
    product.precoprod = parseFloat(product.vProd).toFixed(2);
    product.precoprod_format = mask_decimal(parseFloat(product.vProd));
    product.totalprod = (parseFloat(product.qCom) * parseFloat(product.precoprod)).toFixed(2);
    product.totalprod_format = mask_decimal(parseFloat(parseFloat(product.qCom) * parseFloat(product.precoprod)).toFixed(2));
    product.informacaoprod = i.infAdProd['#text'];
    product.vldescontoprod = typeof product.vDesc !== "undefined" ? parseFloat(product.vDesc) : 0;

    if(product.totalprod > 0){
        product.ipi = parseFloat(parseFloat(product.IPI) / parseFloat(product.totalprod) * 100).toFixed(2);  
        product.ICMS = typeof product.ICMS === "undefined" ? 0 : product.ICMS;
        product.icms = parseFloat(parseFloat(product.ICMS) / parseFloat(product.totalprod) * 100).toFixed(2);  
        product.pis = parseFloat(parseFloat(product.PIS) / parseFloat(product.totalprod) * 100).toFixed(2);  
        product.cofins = parseFloat(parseFloat(product.COFINS) / parseFloat(product.totalprod) * 100).toFixed(2);  
    }

    product.qtdeprod = parseFloat(product.qCom).toFixed(2);
    product.outrasdespprod = parseFloat(product.vOutro).toFixed(2);
    product.vtribprod = parseFloat(product.vTotTrib).toFixed(2);
    product.vtribprod = parseFloat(product.vTotTrib).toFixed(2);
    product.codigo = product.CEST;
    product.cest = product.CEST;
    //descomentar na nota de saída
    //product.cfop = product.CFOP;
    product.cfop_entrada = product.CFOP;
    product.cal_dtentregaprod = null;
    product.cal_dtvalprod = null;
    product.cal_dtfabprod = null;
    product.xProdSistema = product.xProd;

    product.id = product.cEAN;
    product.ean = product.cEAN;
    product.useSameProduct = true;

    return product;
}

export function insertItems(){
    let nfe_items = [];
    let REF = this;
    if(typeof this.state.NFe !== "undefined"){

        REF.processItem = processItem.bind(this);
    
        if(typeof this.state.NFe.infNFe.det.prod !== "undefined"){
            let product = REF.processItem(this.state.NFe.infNFe.det);
            nfe_items.push(product);
        } else {
            this.state.NFe.infNFe.det.map((i,x) => {       
                let product = REF.processItem(i, x);
                nfe_items.push(product);    
            });
        }

        REF.setState({ nfe_items, item_refresh: true });
    }    
   
}


export function checkStoreImportLog (nfe_id, emitente_nome) {
    let REF = this;
 
      return axios.get(CFG.URL_API+"/log?log="+nfe_id+'&tipo=Importar%20Nota', axiosHeader())
      .then((response) => {
          if(response.data.length > 0){
            REF.addError('Atenção! A nota #'+nfe_id+' em nome de '+emitente_nome+' já foi importada!');
          }
      });
  } 

export function processXml(file, process = null){
    let REF = this;
    //You could insert a check here to ensure proper file type
  
    if (file.type !== "text/xml")
    REF.addError("Esse não é um arquivo XML");

    var reader = new FileReader();
    reader.readAsText(file);


    reader.onloadend = function () {

        let parser = new DOMParser();
        let xmlData = parser.parseFromString(reader.result, "text/xml");


        let jsonData = xmlToJson(xmlData);

       
        setTimeout(() => {
        
             if (typeof jsonData.html !== "undefined" && typeof jsonData.html.body.parseerror !== "undefined")
            REF.addError("Esse não é um XML de Nota Fiscal válido 1."); 
            
            let NFe = {};
            if(typeof jsonData.NFe !== "undefined"){
                if(typeof jsonData.NFe.NFe !== "undefined"){
                    NFe = jsonData.NFe.NFe;                      
                } else {
                    NFe = jsonData.NFe;
                }

            } 

            if(typeof jsonData.nfeProc !== "undefined"){
                NFe = jsonData.nfeProc.NFe;
                REF.setState({ NFe: jsonData.nfeProc.NFe, protNFe: jsonData.nfeProc.protNFe }); 
            }

            let nfe_id = NFe.infNFe.ide.nNF['#text'];
            let emitente_nome = NFe.infNFe.emit.xNome['#text'];
            REF.checkStoreImportLog = checkStoreImportLog.bind(REF);
            REF.checkStoreImportLog(nfe_id, emitente_nome)
            .then(() => {
                if(REF.state.hasErrors){
                    REF.toggleModalError(true);
                } else {
                 
                    REF.setState({ showErrors: false, errors: [] });
                    REF.setSummary = setSummary.bind(REF);
                    REF.setSummary();
              
                    REF.toggleModalSummary(true);
                }
            });
            
           
        }, 1000); 

    }
}

export function setSummary(){
    let REF = this;
    
    if(typeof REF.state === "undefined"){
        return;
    }

    let valor = typeof REF.state.NFe.infNFe.pag.detPag.vPag['#text'] !== "undefined" ? floatToReal(REF.state.NFe.infNFe.pag.detPag.vPag['#text']) : 0;

    REF.setState({
        summary: {
            fornecedor: {
                fantasia: REF.state.NFe.infNFe.emit.xFant['#text'],
                cnpj: REF.state.NFe.infNFe.emit.CNPJ['#text']
            },
            valor,
            numero: REF.state.NFe.infNFe['@attributes'].Id,
        }
    });


}



export function importRoutines(){
    let REF = this;
    REF.insertGeneralInfo = insertGeneralInfo.bind(REF);
    REF.insertItems = insertItems.bind(REF);
    setTimeout(() => {
        try{       
          
            REF.insertGeneralInfo();
          
            REF.insertItems();
            
        } catch(e){
            console.log(e);
            REF.addError('Erro ao popular tabelas');
        }
    }, 3000);
}



export function handleXmlUpload(e) {
    var file = e.target.files[0];



    if (!file) {
      return;
    }

    this.setState({ hasErrors: false });

    this.processXml = processXml.bind(this);


    this.processXml(file, fileRead); 

   
   
}

//
export function fileRead(f){

    var reader = new FileReader();

    reader.onload = function(e) {
      var contents = e.target.result;
      let nfe = NFe(contents);
      console.log(nfe);

      var item = nfe.item(1);

      console.log(item.codigo());
    };

}
  
export function readWithAxios () {   

    axios.get(CFG.URL_APP+'/nfe-test.xml').then((response) => {
        let nfe = NFe(response.data);
        console.log(nfe);

        var item = nfe.item(1);

        console.log(item.codigo());
    });
    
}

export function getProduto(itemId){
    test();
/*
    assert.strictEqual(nfe.nrItens(), 2)

    assert.strictEqual(typeof nfe.item(1), 'object')
    var item = nfe.item(1);

    console.log(item);

    assert.strictEqual(item.codigo(), '3')
    assert.strictEqual(item.descricao(), 'BISCOITO VITAMINADO')
    assert.strictEqual(item.ean(), '0000000000000')
    assert.strictEqual(item.ncm(), '87089100')
    assert.strictEqual(item.cest(), '')
    assert.strictEqual(item.cfop(), '5405')
    assert.strictEqual(item.codigoBeneficioFiscal(), 'SEM CBENEF')
    assert.strictEqual(item.numeroFCI(), '86600455-AA27-4CA7-96A9-8C6877EB0C09')
    assert.strictEqual(item.unidadeComercial(), 'UND')
    assert.strictEqual(item.valorUnitario(), '140.0000000000')
    assert.strictEqual(item.unidadeTributavel(), 'UND')
    assert.strictEqual(item.eanTributavel(), '0000000000000')
    assert.strictEqual(item.quantidadeTributavel(), '1.0000')
    assert.strictEqual(item.quantidadeComercial(), '1.0000')
    assert.strictEqual(item.valorUnitarioTributavel(), '140.0000000000')
    assert.strictEqual(item.valorOutrasDespesas(), '')
    assert.strictEqual(item.indicadorTotal(), '1')
    assert.strictEqual(item.codigoANP(), '620505001')
    assert.strictEqual(item.informacoesProduto(), 'Cod. Forn BISVIT003')

    // imposto
    assert.strictEqual(typeof item.imposto(), 'object')
    assert.strictEqual(typeof item.imposto().icms(), 'object')
    var icms = item.imposto().icms()
    assert.strictEqual(icms.cst(), '10')
    assert.strictEqual(icms.baseCalculo(), '14.63')
    assert.strictEqual(icms.porcetagemIcms(), '4.0000')
    assert.strictEqual(icms.porcetagemIcmsST(), '18.0000')
    assert.strictEqual(icms.valorIcms(), '0.59')
    assert.strictEqual(icms.baseCalculoIcmsST(), '29.42')
    assert.strictEqual(icms.valorIcmsST(), '4.71')
    assert.strictEqual(icms.origem(), '3')
    assert.strictEqual(icms.csosn(), '')
    assert.strictEqual(icms.porcentagemMVAST(), '101.1100')
    assert.strictEqual(icms.modalidadeBCST(), '4')
    assert.strictEqual(icms.valorFCP(), '2.87')
    assert.strictEqual(icms.valorFCPST(), '4.50')
    assert.strictEqual(icms.valorFCPSTRetido(), '0.00')
    assert.strictEqual(icms.porcentagemFCP(), '1.0000')
    assert.strictEqual(icms.porcentagemFCPST(), '1.0000')
    assert.strictEqual(icms.porcentagemFCPSTRetido(), '1.0000')
    assert.strictEqual(icms.baseCalculoFCP(), '249.92')
    assert.strictEqual(icms.baseCalculoFCPST(), '449.92')
    assert.strictEqual(icms.baseCalculoFCPSTRetido(), '100.00')

    assert.strictEqual(typeof item.imposto().ipi(), 'object')
    var ipi = item.imposto().ipi()
    assert.strictEqual(ipi.cst(), '99')
    assert.strictEqual(ipi.baseCalculo(), '140.00')
    assert.strictEqual(ipi.valorIPI(), '0.00')
    assert.strictEqual(ipi.porcentagemIPI(), '0.00')

    assert.strictEqual(typeof item.imposto().pis(), 'object')
    var pis = item.imposto().pis()
    assert.strictEqual(pis.cst(), '01')
    assert.strictEqual(pis.baseCalculo(), '140.00')
    assert.strictEqual(pis.valorPIS(), '0.00')
    assert.strictEqual(pis.porcentagemPIS(), '0.00')

    assert.strictEqual(typeof item.imposto().cofins(), 'object')
    var cofins = item.imposto().cofins()
    assert.strictEqual(cofins.cst(), '01')
    assert.strictEqual(cofins.baseCalculo(), '140.00')
    assert.strictEqual(cofins.porcentagemCOFINS(), '0.00')
    assert.strictEqual(cofins.valorCOFINS(), '0.00')

    */
}