import React from 'react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Card, Accordion, ListGroup, ListGroupItem } from 'react-bootstrap';
import TitulosMenu from './TitulosMenu';

class FinanceiroMenu extends React.Component{


    constructor(props){
        super(props);

        this.state = {
        show: this.props.show,
        extraclasses: ''
        };
  }

  toggle = () => {
    let REF = this;

    this.props.onchange();

    if(this.state.show){
        this.setState({ show: false, extraClasses: '' });
        setTimeout(() => REF.setState({ extraClasses: '' }), 500);
    } else {
        this.setState({ show: true, extraClasses: 'active' });
    } 
}
    render(){
        return (<Accordion defaultActiveKey={this.props.defaultActiveKey} hidden={window.userCan.financeiro !== 1}>
            <Card>
                <Accordion.Header  onClick={this.toggle} className={"lv-1 " + this.state.extraClasses}  as={Card.Header} eventKey="0">
                <div className="w-icon"><i className="fa fa-money"></i></div><span className="text">Financeiro</span>
                </Accordion.Header>
                <Accordion.Body eventKey="0">
                <Card.Body>
                <ListGroup>
                    <ListGroupItem><Link className="lv-2" onClick={this.props.ontoggle} to="/dre/"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Indicadores D.R.E</Link></ListGroupItem>
                    <ListGroupItem><Link className="lv-2" onClick={this.props.ontoggle} to="/centro-custo/"><div className="w-icon"><i className="fa fa-folder-open-o"></i></div>Centro de custo</Link></ListGroupItem>
                    <ListGroupItem><Link className="lv-2" onClick={this.props.ontoggle} to="/forma-pagamento/"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Formas de pagamento</Link></ListGroupItem>
                    <TitulosMenu show={this.props.show} onchange={this.props.onchange} ontoggle={this.props.ontoggle}  />
                    </ListGroup>       
                </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion>);
    }

}   

export default FinanceiroMenu;

