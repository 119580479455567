import React from 'react';
import CategoriasDataTable from './CategoriasDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import CategoriaModal from './CategoriaModal';

class Categorias extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      data: {},
      show: false
    };

    this.props.titlebar(this.props.title, this.props.description);

    this.handleTableRefresh = this.handleTableRefresh.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setModalShow = this.setModalShow.bind(this);
  }

  setModalShow (status, data){

    if(data != null){
      this.setState({  data: data });
    }

    this.setState({  show: status });
  }

  showModal = () => {
    this.setState({ show: true, data: null });
  }

  hideModal(){
    this.setState({ show: false });
  }

  handleTableRefresh(refresh){
    this.setState({ refresh : refresh });
    var Rs = this;
    setTimeout(function(){
      Rs.setState({ refresh : false });
    }, 100);
  }
  
  render (){
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => this.showModal()}>
                <span className="fa fa-plus-square"></span>  Criar categoria
                </Button>              
              <CategoriaModal
                form={{}}
                show={this.state.show}              
                onHide={this.hideModal}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <CategoriasDataTable refresh={this.state.refresh} setModalShow={this.setModalShow}/>
      </div>);
    }
  }
  
  export default Categorias;