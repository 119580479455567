import React from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import Sidebar from "../layout/Sidebar"
import CFG from "../CFG"
import { Container, Row, Col } from "react-bootstrap"
import BRoutes from "../Routes.js"
import "../util/animate.min.css"
import NavbarTop from "../NavbarTop"
import TitleBar from "./TitleBar"

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sidebar_show: false,
            page: {
                title: "",
                description: ""
            }
        }
    }

    onKeyPressed = event => {
        if (event.keyCode === 27) this.setState({ sidebar_show: false })
    }
    componentWillMount() {
        console.log("comecou")
    }
    componentDidMount() {
        console.log("acabou")
    }
    componentDidMount() {
        document.addEventListener("keydown", this.onKeyPressed, false)
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyPressed, false)
    }

    toggleSidebar = () => {
        console.log("clic")
        if (this.state.sidebar_show) {
            this.setState({ sidebar_show: false, extraClasses: "" })
            window.sessionStorage.setItem("hackMenu", "ativo")
            setTimeout(() => window.sessionStorage.removeItem("hackMenu"), 800)
        } else {
            this.setState({ sidebar_show: true, extraClasses: "active faster" })
        }
    }

    submenuDidUpdate = () => {
        if (window.sessionStorage.getItem("hackMenu") != "ativo") {
            this.setState({ sidebar_show: true })
        }
    }

    updateTitleBar = (title, description) => {
        this.setState({ page: { title, description } })
        document.title = title + " :: " + CFG.APP_NAME
    }

    render() {
        return (
            // <>
            //     <Router>
            //         <Switch>
            //         <Container
            //             fluid={true}
            //             className={this.state.sidebar_show ? "scroll-lock" : "scroll-enable"}
            //         >
            //             <Row>
            //                 <Sidebar
            //                     ontoggle={this.toggleSidebar}
            //                     onchange={this.submenuDidUpdate}
            //                     className={
            //                         this.state.sidebar_show
            //                             ? "col-md-3 slideInLeft animated faster"
            //                             : "col-md-1 contract fadeIn animated"
            //                     }
            //                     show={this.state.sidebar_show}
            //                 />

            //                 <Col
            //                     className={this.state.sidebar_show ? "col-md-9" : "col-md-11"}
            //                 >
            //                     <NavbarTop
            //                         show={true}
            //                         ontogglesidebar={this.toggleSidebar}
            //                     ></NavbarTop>
            //                     <TitleBar
            //                         title={this.state.page.title}
            //                         description={this.state.page.description}
            //                     />
            //                     <BRoutes titlebar={this.updateTitleBar} />
            //                 </Col>
            //             </Row>
            //         </Container>
            //         </Switch>
            //     </Router>
            // </>
            <>
            <>
                <>
                <Container
                    fluid={true}
                    className={this.state.sidebar_show ? "scroll-lock" : "scroll-enable"}
                >
                    <Row>
                        <Sidebar
                            ontoggle={this.toggleSidebar}
                            onchange={this.submenuDidUpdate}
                            className={
                                this.state.sidebar_show
                                    ? "col-md-3 slideInLeft animated faster"
                                    : "col-md-1 contract fadeIn animated"
                            }
                            show={this.state.sidebar_show}
                        />

                        <Col
                            className={this.state.sidebar_show ? "col-md-9" : "col-md-11"}
                        >
                            <NavbarTop
                                show={true}
                                ontogglesidebar={this.toggleSidebar}
                            ></NavbarTop>
                            <TitleBar
                                title={this.state.page.title}
                                description={this.state.page.description}
                            />
                            <BRoutes titlebar={this.updateTitleBar} />
                        </Col>
                    </Row>
                </Container>
                </>
            </>
        </>
        )
    }
}

export default App
