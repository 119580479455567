

import React from 'react';
import axios from 'axios';
import CFG from '../CFG';
import { Col} from 'react-bootstrap';
import Img from 'react-image';
import './IconeThumbnail.scss';
import load_gif from '../img/loading-1.gif';
import VisibilitySensor from 'react-visibility-sensor';

class IconeThumbnail extends React.Component{

    constructor(props){
        super(props);

        let url_format = '';
        
        if(typeof this.props.url !== "undefined")
        url_format = this.props.url.indexOf('http') == -1 ? CFG.URL_ICONE+'/'+this.props.url : this.props.url;

        this.state = {
            id: this.props.id,
            url: url_format,
            validator: {}
        };

      
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.id != prevProps.id || prevProps.url != this.props.url){

            let url = '';
            if(this.props.url != null){
                url = this.props.url.indexOf('http') == -1 ? CFG.URL_ICONE+'/'+this.props.url : this.props.url;
            }            

            this.setState({ id: this.props.id, url });
        }
    }

  selectChangeHandler(event){
      this.props.currenticon(this.state.id, this.state.url);
  }
  
    render(){
    
        if(this.props.radio != undefined && this.props.radio){
            return (
                <Col className={this.props.className+ " IconeThumbnail "}  >
                            <figure   onClick={this.selectChangeHandler}>
                        <VisibilitySensor>
                                <img src={this.state.url}  className="img-fluid"/>
                        </VisibilitySensor>
                            </figure>
                            <input name="icone" onChange={this.selectChangeHandler} checked={this.state.id == this.props.icone_select_id} type="radio" value={this.state.id} />
                        </Col>
                        );
        } else {
            return (
                <Col className={this.props.className+ " IconeThumbnail "}>
                    <figure>
                        <VisibilitySensor>
                        <img src={this.state.url} className="img-fluid"/>
                        </VisibilitySensor>
                    </figure>
                </Col>
            );
        }
     
    }
}

export default IconeThumbnail;



