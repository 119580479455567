import React from "react" // Import React
import { getListing, CustomToggleList, tableRender } from "../CustomDataTable" // Import React
import "../DataTable.scss"
import { sessionGet } from "../usuario/Auth"
import RecargaModal from "./RecargaModal"

class RecargaDataTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            recarga_id: "",
            empresa: sessionGet("empresa"),
            data: [],
            fetchComplete: false,
            searchTerm: null,
            activeIndex: undefined,
            filters: {},
            origem: this.props.origem
        }
        this.getCustomToggleList = CustomToggleList.bind(this)
        this.getListing = getListing.bind(this)
        this.tableRender = tableRender.bind(this)
    }

    componentDidMount() {
        console.log("recargaDataTable", this.state)
        window.checkUserCan("recarregar_cartao")
        this.updateListing()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filters !== this.props.filters) {
            let REF = this
            this.setState({ filters: this.props.filters })
            setTimeout(() => {
                REF.updateListing()
            }, 1000)
        }

        if (prevState.fetchComplete !== this.state.fetchComplete) {
            this.props.setloading(false)
        }

        if (this.state.origem !== this.props.origem) {
            this.setState({ origem: this.props.origem })
            this.updateListing({ origem: this.props.origem })
        }

        if (this.props.refresh && prevProps.refresh !== this.props.refresh) {
            this.updateListing()
        }
    }

    setModalShow = (status, data) => {
        this.props.setModalShow(status, data)
    }

    updateListing = columnsSearch => {
        let data_up_tostring = ""
        let REF = this

        if (Object.getOwnPropertyNames(this.state.filters).length > 0) {
            let data_up = Object.entries(this.state.filters)

            data_up_tostring = data_up
                .map(function(e) {
                    return e[0] + "=" + e[1]
                })
                .join("&")
        }

        this.getListing(
            "recarga?empresa=" + this.state.empresa + "&" + data_up_tostring,
            columnsSearch
        )
    }

    getCustomToggleList({ columns, onColumnToggle, toggles }) {
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }

    getColumns() {
        return [
            {
                dataField: "id",
                text: "Cód. recarga",
                sort: true
            },
            {
                dataField: "cartao",
                text: "Cartão (ID)",
                sort: true
            },
            {
                dataField: "origem_format",
                text: "Origem",
                sort: true
            },
            {
                dataField: "emissao_format",
                text: "Data de emissão"
            },
            {
                dataField: "titular",
                text: "Titular",
                sort: true
            },
            {
                dataField: "totalFormat",
                text: "Valor",
                sort: true
            },
            {
                dataField: "status_format",
                text: "Status",
                sort: true
            },
            {
                dataField: "pagto_format",
                text: "Data de pagamento"
            }
        ]
    }
    

    render(){
        let REF = this;
        return (
            <div className="table-report">  
                    
                {REF.tableRender()}
            </div>
            )
        }
}


export default RecargaDataTable
