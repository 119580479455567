import React from "react" // Import React
import { Container, Button, ButtonToolbar, Row, Col } from "react-bootstrap"
import { sessionGet } from "../../usuario/Auth"
import FiltroModal from "./FiltroModal"
import RecargaDataTable from "../../recarga/RecargaDataTable"
import Grafico from "./Grafico"

class Extrato extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            movimentacao_id: "",
            empresa: sessionGet("empresa"),
            searchTerm: null,
            activeIndex: undefined,
            show_modal_filters: false,
            origem: [],
            filters: {}
        }

        this.props.titlebar(this.props.title, this.props.description)
    }
    componentDidMount() {
        window.checkUserCan('rel_recargas');   
        console.log("state", this.state)
    }

    setFiltroModalShow = () => {
        this.setState({ show_modal_filters: true })
    }

    setFilters = form_state => {
        this.setState({ filters: form_state, loading: true })
    }

    hideFiltroModal = () => {
        this.setState({ show_modal_filters: false })
    }

    updateLoading = status => {
        this.setState({ loading: status })
    }

    setOrigem = (e, origem) => {
        this.setState({ origem })
    }

    render() {
        let REF = this
        return (
            <>
                <Container fluid={true}>
                    <div className="row">
                        <div className="col-md-6 my-2">
                            <ButtonToolbar className="m-3" hidden>
                                <Button
                                    variant={this.state.origem.length == 0 ? "success" : "link"}
                                    onClick={e => REF.setOrigem(e, [])}
                                >
                                    Todos
                                </Button>
                                <Button
                                    variant={
                                        this.state.origem.includes("CAN") ||
                                        this.state.origem.includes("CANT")
                                            ? "success"
                                            : "link"
                                    }
                                    onClick={e => REF.setOrigem(e, ["CAN", "CANT"])}
                                >
                                    Cantina
                                </Button>
                                <Button
                                    variant={
                                        this.state.origem.includes("BOL") ||
                                        this.state.origem.includes("RBOL")
                                            ? "success"
                                            : "link"
                                    }
                                    onClick={e => REF.setOrigem(e, ["BOL", "RBOL"])}
                                >
                                    Boleto do Site
                                </Button>
                                <Button
                                    variant={
                                        this.state.origem.includes("LUBOL") ||
                                        this.state.origem.includes("LRBOL")
                                            ? "success"
                                            : "link"
                                    }
                                    onClick={e => REF.setOrigem(e, ["LUBOL", "LRBOL"])}
                                >
                                    Boleto no App
                                </Button>
                                <Button
                                    variant={
                                        this.state.origem.includes("LUCRE") ||
                                        this.state.origem.includes("LRCRE")
                                            ? "success"
                                            : "link"
                                    }
                                    onClick={e => REF.setOrigem(e, ["LUCRE", "LRCRE"])}
                                >
                                    C. Crédito no App
                                </Button>
                            </ButtonToolbar>
                        </div>
                        <div className="col-md-6 my-2">
                            <ButtonToolbar className="float-right my-1 ml-auto">
                                <Button
                                    variant="success"
                                    onClick={() => this.setFiltroModalShow(true)}
                                >
                                    <span className="fa fa-filter"></span>
                                </Button>
                                <FiltroModal
                                    loading={this.state.loading}
                                    empresa={this.state.empresa}
                                    show={this.state.show_modal_filters}
                                    setfilters={this.setFilters}
                                    onHide={this.hideFiltroModal}
                                />
                            </ButtonToolbar>
                        </div>
                    </div>
                    <Row>
                        <Col>{this.renderGrafico()}</Col>
                    </Row>
                    <RecargaDataTable
                        origem={this.state.origem}
                        setloading={this.updateLoading}
                        title={this.props.title}
                        empresa={this.state.empresa}
                        filters={this.state.filters}
                    />
                </Container>
            </>
        )
    }

    renderGrafico = () => {
        if (typeof this.state.filters.inicio !== "undefined")
            return (
                <Grafico
                    empresa={this.state.empresa}
                    fim={this.state.filters.fim}
                    inicio={this.state.filters.inicio}
                />
            )
    }
}

export default Extrato
