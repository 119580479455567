import React from 'react';

import { Card, Accordion, ListGroup, ListGroupItem  } from 'react-bootstrap';

import {Link} from 'react-router-dom';
class RecargaMenu extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            show: this.props.show,
            extraclasses: ''
        };
    }

    toggle = () => {
        let REF = this;

        this.props.onchange();

        if(this.state.show){
            this.setState({ show: false, extraClasses: '' });
            setTimeout(() => REF.setState({ extraClasses: '' }), 500);
        } else {
            this.setState({ show: true, extraClasses: 'active' });
        } 
    }

    render(){

        return (<Accordion className={window.userCanClass('recarregar_cartao')}>
      <Card>
            <Card.Header className="lv-1 no-chevron">
                     <Link  onClick={this.props.ontoggle} to="/recarga">
                <div className="w-icon">
                    <i className="fa fa-refresh"></i></div> <span className="text">Recargas</span>
                </Link>
                </Card.Header>
        </Card>
        </Accordion>);
    }

}   

export default RecargaMenu;

