 import React from 'react';
import '../../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../../util/LoadingModal';
import url_btn_close from '../../img/icons/close.png';
import ItemForm from './ItemForm';

class ItemModal extends React.Component{

  constructor(props) {
    super(props);

    this.state = {  
      loading: false,
      edit: false,
      confirm: false
    };

  }
    

  onKeyPressed = (event) => {
    if(event.keyCode == 13 ){
      this.props.tablerowupdate(this.props.form);
    }
  }

  componentDidUpdate(prevProps, prevState){

    if(this.props.form.id !== prevProps.form.id){
        this.setState({ edit: true });
    }


  }

  componentDidMount(){

    this.setState({ edit: false });
  
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  closeTrigger = () => {
    this.setState({ confirm: false });
  }

  render () {

    let REF = this;

    return (
      <>
      <Modal   
     show={this.props.show}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
              {this.state.edit ? 'Alteração' : 'Inclusão' } de Item <small>{typeof this.props.form.id !== "undefined" ? '#'+this.props.form.id : ''}</small>
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body style={{ height: '510px', overflow: 'scroll' }}>        
          <ItemForm
            tipo={this.props.tipo}
            confirm={this.state.confirm}
            show={this.props.show}
            form={this.props.form}
            close={this.closeTrigger}
            tablerowupdate={this.props.tablerowupdate}
          />       
        </Modal.Body>
        <Modal.Footer>
          {
              (() => {
                if(this.state.allow_delete)
                return ( <Button className="btn-danger btn-left" onClick={this.formDelete}>Excluir</Button>)
              })()
          }

      <Button onClick={ () => REF.setState({ confirm: true })}>Confirmar</Button>

        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading} />
      </>
    );
  }

}

export default ItemModal;