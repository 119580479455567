import React from 'react';
import NotaFiscalDataTable from './NotaFiscalDataTable'; 
import {ButtonToolbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';

class NotaFiscal extends React.Component {
   
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      show: false,
      activeIndex: 0
    };

    this.props.titlebar(this.props.title, this.props.description);


    this.hideModal = this.hideModal.bind(this);
    this.setModalShow = this.setModalShow.bind(this);
  }

  setModalShow (status, data){

    if(data != null){
      this.setState({  data: data });
    }

  }

  showModal = (tipo) => {
    this.setState({ tipo, show: true, data: null });
  }

  hideModal(){
    this.setState({ show: false });
  }

  render (){
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 p-0"> 
            <ButtonToolbar className="float-right m-3">
                <Link className="btn btn-secondary" to="/nota/entrada">
                  <span className="fa fa-plus-square"></span> Entrada
                </Link>   
                <Link hidden className="btn btn-secondary" to="/nota/saida">
                  <span className="fa fa-plus-square"></span> Saída
                </Link>                         
            </ButtonToolbar>            
          </div>
        </div>      
      <NotaFiscalDataTable setModalShow={this.setModalShow}/>
      </div>);
    }
}
  
export default NotaFiscal;