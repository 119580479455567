import React from 'react';
import {CFG, axiosHeader} from '../CFG';
import axios from 'axios';
import {Row, Col} from 'react-bootstrap';
import CardInfo from './CardInfo';
import { sessionGet } from '../usuario/Auth';
import  GraficoVendasMes  from './Graficos/GraficoVendasMes';
import  GraficoVendasDiaSemana  from './Graficos/GraficoVendasDiaSemana';
import Datetime from 'react-datetime';


import UltimasTransacoes from './Sinteticos/UltimasTransacoes';
import UltimasRecargasPagamentos from './Sinteticos/UltimasRecargasPagamentos';
import PosicaoEstoque from './Sinteticos/PosicaoEstoque';
import MaisConsumidos from './Sinteticos/MaisConsumidos';
import MenosConsumidos from './Sinteticos/MenosConsumidos';

import moment from 'moment';
import 'moment/locale/pt-br';

import "react-datetime/css/react-datetime.css";
import "./style.css"

class Dashboard extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      empresa: sessionGet('empresa'),
      cal_inicio: moment().format("DD/MM/YYYY 00:00:00"),
      cal_fim: moment(),
      inicio:moment().format("YYYY-MM-DD 00:00:00"),
      fim:moment().format("YYYY-MM-DD HH:mm:ss"),
      empresa_data: {},
      //series
      range_series: [],
      carregando:false,
    };
    this.loadRange();
    this.getEmpresaData();


 
  }

  componentDidMount(){
    this.props.titlebar(this.props.title, this.props.description);
  }

  getEmpresaData = () => {
    axios.get(CFG.URL_API+'/empresa/'+this.state.empresa, axiosHeader()).then((response) => {
      this.setState({ empresa_data: response.data });
    });    
  }


  inicioChangeHandler = (date) => {
    if(date == null){
      this.setState({ cal_inicio: null, inicio: '' });
      return;
    }
    let REF = this;
    let dt = moment(date);
    let dt_format = dt.format("YYYY-MM-DD 00:00:00");
    this.setState({ cal_inicio: dt, inicio: dt_format }); 
    //setTimeout(() => REF.loadRange(), 500);
  }

  fimChangeHandler = (date) => {
    let REF = this;
    if(date == null){
      this.setState({ cal_fim: null, fim: '' });
      return;
    }
    let dt = moment(date);
    let dt_format = dt.format("YYYY-MM-DD HH:mm:ss");
    this.setState({ cal_fim: dt, fim: dt_format }); 
    //setTimeout(() => REF.loadRange(), 500);
  }

  loadRange = () => {
    let REF = this;
    REF.setState({carregando:true})
    this.get().then(function(response){
      let data_array = Object.entries(response.data).map(function(e){
        return e[1];
      });
      REF.setState({ range_series :  data_array,carregando:false });
    });
  }

  displayCards = () => {
    let REF = this;
    console.log('Series', this.state.range_series)
    let render = [];
    const venda =  this.state.range_series.filter(it => (/venda|ticket/gi).test(it.titulo)).map(function(opcao, tipo_opc){
          return (
              <Col xs="6" md="6" className="p-1" key={tipo_opc}>
                <CardInfo total={opcao.total.toFixed(2)} qtd={opcao.quantidade} icone={opcao.icone} titulo={opcao.titulo} empresa={REF.state.empresa}  />
              </Col>
            )
        })

    const recarga =  this.state.range_series.filter(it => (/recarga/gi).test(it.titulo)).map(function(opcao, tipo_opc){
      return (
          <Col xs="6" md="6" className="p-1" key={tipo_opc}>
            <CardInfo total={opcao.total.toFixed(2)} qtd={opcao.quantidade} icone={opcao.icone} titulo={opcao.titulo} empresa={REF.state.empresa}  />
          </Col>
        )
    })

    const others = this.state.range_series.filter(it => !(/(recarga|venda|ticket)/gi).test(it.titulo)).map(function(opcao, tipo_opc){
      return (
          <Col xs="6" md="3" className="p-1" key={tipo_opc}>
            <CardInfo total={opcao.total.toFixed(2)} qtd={opcao.quantidade} icone={opcao.icone} titulo={opcao.titulo} empresa={REF.state.empresa}  />
          </Col>
        )
    })

    render = <>
     <Col xs="6" md="6">
       
        <h5>Venda</h5>
        <Row>
          {venda}
        </Row>
     </Col>

     <Col xs="6" md="6">
        <h5>Recarga</h5>
        <Row>
          {recarga}
        </Row>
     </Col>

     <Col xs="12" md="12">
        <h5>Movimentações</h5>
        <Row>
          {others}
        </Row>
     </Col>
    </>;

    return render;
  }

  get = () => {
    const dtInicio = this.state.inicio;
    const dtFim = this.state.fim;
    return axios.get(CFG.URL_API+'/relatorio/vendas-periodo?empresa='+this.state.empresa+'&inicio='+dtInicio+'&fim='+dtFim, axiosHeader())
  }
  carregando = () => {
    return this.state.carregando === true ? (
      <div className="carregando-full">
          <div class="spiner"></div>
      </div>
    ) : (
        <span></span>
    )
  }
  render() {   
   
    return (
      <>
        {this.carregando()}
        <Row className="my-2">
          <Col md="2" className="p-1"></Col>
          <Col md="3" className="p-1 cj">
              <label style={{paddingTop:5, paddingRight:5}}>de</label>
              <Datetime className="datetime-picker " locale="pt-br" style={{border:"none"}}  autoComplete="off" name="col_inicio" id="f-inicio" value={this.state.cal_inicio} onChange={this.inicioChangeHandler} />
          </Col>
            <Col md="3" className="p-1 cj">
              <label style={{paddingTop:5, paddingRight:5}}>até</label>
              <Datetime className="datetime-picker " locale="ppt-br"  autoComplete="off" name="col_fim" id="f-fim" value={this.state.cal_fim} onChange={this.fimChangeHandler} />
          </Col>

          <Col md="2" className="p-1">
              <button onClick={()=>this.loadRange()} className="btn btn-info">Filtrar</button>
          </Col>
          <Col md="2" className="p-1"></Col>

        </Row>
       <Row id="display-cards">
        {
          this.displayCards()
        }
      </Row>

      <Row>
       
       <Col md="6">
         <GraficoVendasMes empresa={this.state.empresa} />
       </Col>

       <Col md="6" >
         <GraficoVendasDiaSemana  empresa={this.state.empresa} />
       </Col>
      
     </Row>

     <Row>
       
       <Col md="6">
         <UltimasTransacoes empresa={this.state.empresa} />
       </Col>

       <Col md="6">
         <UltimasRecargasPagamentos empresa={this.state.empresa} />
       </Col>
      
     </Row>

     <Row>
           
      <Col md="6">
        <MaisConsumidos empresa={this.state.empresa} />
      </Col>
       
     <Col md="6">
        <MenosConsumidos empresa={this.state.empresa} />
      </Col>
    

     </Row>

     <Row> 

     <Col md="6">
        <PosicaoEstoque hidden={this.state.empresa_data.estoque == 0} controle={this.state.empresa_data.estoque == 1} empresa={this.state.empresa} />
      </Col>
     </Row>
    
     
      </>
    );   
  }

}
  
  export default Dashboard;