import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import { inputBlurHandler } from '../CustomForm';

class IconeCategoriaSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            list: [['', 'Carregando opções']],
            categoria: '',
            validator: {}
        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputBlurHandler = inputBlurHandler.bind(this);
        this.get = this.get.bind(this);
     
      
    }

    componentDidMount(){
        this.get();
    }

    componentDidUpdate(prevProps, prevState){
     
    if(this.state.categoria != this.props.categoria)
    this.setState({ categoria : this.props.categoria });

        
  }

  inputChangeHandler(event){

    if(typeof this.props.change == "function")
      this.props.change(event);
  }

    render(){
        let CS = this;
        return (
            <div>
                <select className="form-control" value={this.state.categoria} name="categoria" id="f-categoria" onChange={this.inputChangeHandler}  >
                    <option key="0" value="">Selecione</option>
                    {
                        this.state.list.map(function(val) {
                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>) 
                        })
                    }
                </select>
            </div>
        );
    }

    get(){
        let CS = this; 

        axios.get(CFG.URL_API+'/icone-categoria?selectMode=pluck&pluckKey=id&pluckValue=categoria', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);

            let htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });
            
            CS.setState({ list: [] });
            CS.setState({ list: htmlList });

          //Perform action based on response
      })
        .catch(function(error){
      requestException(error);
 });
    }

}

export default IconeCategoriaSelect;
