import React from 'react'; // Import React
import {getListing} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import './Sinteticos.scss';
import CFG from '../../CFG';
import { sessionGet } from '../../usuario/Auth';
import {Link} from 'react-router-dom';
import { ListGroup, ListGroupItem, Row, Col, Alert } from 'react-bootstrap';
import VisibilitySensor from 'react-visibility-sensor';
import {mask_decimal} from '../../util/inputMask';
import LoadingPlaceholder from '../../util/LoadingPlaceholder';


class PosicaoEstoque extends React.Component{
  constructor(props) {
    super(props);

    let d = new Date();

    this.state = {
      id: '',
      loading:true,
      mes: d.getMonth()+1,
      ano: d.getFullYear(),
      empresa: sessionGet('empresa'),
      data: []
    };
    this.getListing = getListing.bind(this);

  }
  updateListing = () => {
    let REF = this;
    this.getListing('produto/estoque-baixo?empresa='+this.state.empresa).then(() => REF.setState({ loading: false }));
  }
  componentDidMount(){
    this.updateListing();
  }

  componentDidUpdate(prevProps){
    if(prevProps.empresa != this.props.empresa){
      this.setState({empresa: this.props.empresa });
      this.updateListing();
    }
  }


  render(){

    if(!this.state.loading && this.props.controle == 0)
    return (<Alert variant="info">Gráfico "Posição do Estoque" omitido. O controle de estoque está desabilitado.</Alert>);

    if(!this.state.loading && this.state.data.length == 0)
    return (<Alert variant="info">Sem dados correspondentes a {this.state.mes}/{this.state.ano} para o gráfico "Mais consumidos"</Alert>);

    return (
      <>
      <LoadingPlaceholder show={this.state.loading}/>
      <div className="Sinteticos" hidden={this.state.loading}> 
    <Row>
        <Col md="8">
          <h4>Alerta de Posição de Estoque</h4>
        </Col>
          <Col md="4 d-flex">
            <Link hidden to="/relatorio/estoque" className="btn btn-secondary btn-sm ml-auto my-2 d-none"><i className="fa fa-file-text-o"></i> Ver todos</Link>
          </Col>
      </Row>
      <ListGroup>
          { 
            this.state.data.map((item) => (
          <ListGroupItem>
          <Row>
            <Col md="2">
            <VisibilitySensor>
                <img src={CFG.URL_ICONE+'/'+item.icone} alt={item.descricao}/>
            </VisibilitySensor>
            </Col>
            <Col md="6">
              {item.produto}
            </Col>
            <Col md="4" hidden={!this.props.controle} className={item.estoque > 0 ? "text-warning" : "text-danger"}>
              <small>temos</small> <strong>{mask_decimal(item.estoque)}</strong> <span hidden={item.estoqueminino == 0}>| Mín: {mask_decimal(item.estoqueminino)}</span>
            </Col>
          </Row>
          </ListGroupItem>
          ))
        }
      </ListGroup>
      </div>
      </>
      )
    }
  }
  export default PosicaoEstoque;
