import React from "react";
import "./styles/app.scss";
import {CFG, axiosHeader} from "../CFG";
import axios from "axios";

import {Container, Row, Col} from 'react-bootstrap';
import {sessionGet} from '../usuario/Auth';
import {mask_decimal} from '../util/inputMask';
import LoadingModal from '../util/LoadingModal';
import { userCanMissing } from '../util/exception';

class ProdutoGrade extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            empresa: sessionGet('empresa'),
            produtos: [],
            categorias: [],
            grades: [],
            escolhidos: [],
            width: 100,
            category: "" ,
            loading: false          
        };

        this.props.titlebar(this.props.title, this.props.description);
    }

    async componentDidMount() {

        if(window.userCan.grade !== 1)
        return userCanMissing('grade');
        

        this.reload();
    }

    reload = async () => {
        await this.getGrades();
        let categorias = await this.getCategorias();
        let produtos = await this.fetchProdutos()     
        this.setState({ categorias, produtos });  
    }

    getGrades = async () => {
        let escolhidos = [];
        let REF = this;

         return setTimeout(async () =>  {
            let grades =  await this.fetchGrade();
            grades.forEach(element => {
                if(typeof element.produto_data !== "undefined")
                escolhidos.push(element.produto_data.id)
            })
            REF.setState({ grades, escolhidos, loading: false });
       }, 500);    
        
    }
    
     getProdutos = async (id) => {
       // await this.getGrades()
        let { escolhidos } = this.state
        let produtos = await this.fetchProdutos(id)

        setTimeout(() =>  {
            produtos = produtos.filter(produto => {
                return escolhidos.includes(produto.id) === false
            })
        }, 500);

        this.setState({ produtos,  category: id, loading: false })
    }

    onDragStart = (e, v) => {
        e.dataTransfer.dropEffect = "move"
        e.dataTransfer.setData("text/plain", v)
    }

    allowDrop = ev => {
        ev.preventDefault();
    }

    onDrop = async e => {
        e.preventDefault();
        let { grades, produtos } = this.state;

        this.setState({ loading: true });
        
        let data = e.dataTransfer.getData("text/plain")
        data = JSON.parse(data);


        let l = e.currentTarget.attributes['data-line'].value;
        let c = e.currentTarget.attributes['data-column'].value;
        
        let check = grades.find(element => {

            return typeof element.produto_data !== "undefined" && element.produto_data.id === data.id;

        })        
      
        if(typeof check === "undefined")
        await this.postGrade(data.id, l, c);
        else {
            check.linha = l;
            check.seq = c;
            await this.updateGrade(check.id, check);
            //await this.updateGrade(grade_id, data)
        }
        
        produtos = produtos.filter(element => element.id !== data.id)

        await this.getGrades();
        this.setState({ produtos });
    }

    onDropExclude = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        let data = e.dataTransfer.getData("text/plain")
        data = JSON.parse(data)

        
        
        let check = this.state.grades.find(element => {
            return typeof element.produto_data !== "undefined" && element.produto_data.id === data.id;
        })

        if (check !== undefined) {
            await this.deleteGrade(check.id);
        }

        this.reload();
    }

    renderGrades = () => {
        let REF = this;
        let { grades } = this.state;

        let l = 1;
        let c = 0;

        let item = (l,c) => {
            let REF = this;
            let find = REF.state.grades.find((g) => {
                return g.linha === l && g.seq === c;
            });

            return typeof find !== "undefined" ? REF.renderItem(find) : (<></>); 
        }

        return grades.map((grade, key) => {

            if(key > 1 && key % 5 === 0){
                l++;
                c = 0;
            }

            c++;

            let id = typeof grade.id !== "undefined" ? "item_"+grade.id : undefined;

            return (
                <Col
                    xs={{ span: 2, offset: key % 5 === 0 ? 1 : 0 }}
                    className={"line-"+l}
                    data-line={l}
                    data-column={c}
                    key={id}
                    id={id}
                    onDragOver={REF.allowDrop}
                    onDrop={REF.onDrop}
                >
                    <div className="item">
                        {item(l,c)}
                    </div>
                </Col>
            )
        })
    }

  

    renderItem = (grade) => {
        let REF = this;
      
        return grade.produto && (
            <div
                className="produtos-item"
                key={`pro_${grade.produto_data.id}`}
                draggable="true"
                onDragStart={e => REF.onDragStart(e, JSON.stringify(grade.produto_data))}
            >
                <span className="price-tag">R$ {mask_decimal(parseFloat(grade.produto_data.preco).toFixed(2))}</span>
                <figure>
                    
                    <img className="img-fluid" src={CFG.URL_ICONE+"/"+grade.icone_url} alt={grade.produto_data.produto}/>
                    <figcaption>{grade.produto_data.produto}</figcaption>
                </figure>
                
            </div>
        )
    } 

    renderCategorias =  () =>  {
        let categorias  = this.state.categorias;
   
        return categorias.map(cat => {
            return (
                <option key={`opt_${cat.id}`} value={cat.id}>
                    {cat.categoria}
                </option>
            )
        });
    }

    renderProdutos = () => {

        return this.state.produtos.map(produto => {
            let icone = produto.icone_url !== null ? produto.icone_url : 'imagens/icones/64x64/f4b77d31314597863964b8caa0b79cb6.png';
            return (
                <div
                    className="produtos-item"
                    key={`pro_${produto.id}`}
                    draggable="true"
                    onDragStart={e => this.onDragStart(e, JSON.stringify(produto))}
                >
                 <img  alt="" className="img-fluid" src={CFG.URL_ICONE+"/"+icone} />
                    {produto.produto}
                    <span className="price-tag">R$ {mask_decimal(parseFloat(produto.preco).toFixed(2))}</span>

                </div>
            )
        })
    }

    render() {
        let REF = this;
        return (
            <>
            <LoadingModal show={this.state.loading} />
            <Container fluid>
                <Row>
                <Col lg="9" xs="12" className="py-4 order-2 order-lg-1">
                        <div className="grade row">{REF.renderGrades()}</div>
                </Col>
                <Col lg="3" xs="12" className="order-1 order-lg-2">
                        <div
                            className="produtos"
                            onDragOver={REF.allowDrop}
                            onDrop={REF.onDropExclude}
                        >
                            <span>Selecione a categoria</span>
                            <div className="clear5"></div>

                            <div className="select">
                                <select onChange={v => REF.getProdutos(v.target.value)} className="form-control">
                                    <option value="">Todos</option>
                                    {REF.renderCategorias()}
                                </select>
                            </div>
                            <div className="clear15"></div>
                            <span>Selecione os produtos</span>
                            <div className="produtos_wrap">
                                <div className="produtos_scrol">
                                    {REF.renderProdutos()}
                                </div>
                            </div>
                        </div>
                    </Col>
                  
                </Row>
            </Container>
            </>
        )
    }

    getCategorias = async () => {
        let response = await axios.get(CFG.URL_API+"/produto-categoria?empresa="+this.state.empresa, axiosHeader())
        return response.data
    }

    fetchProdutos = async categoria_id => {
        this.setState({ categoria: categoria_id });
        let url = `produto?empresa=${this.state.empresa}`;
        if (typeof categoria_id !== "undefined") {
            url = `${url}&pdt_itens_categoria=${categoria_id}`
        }
        let response = await axios.get(CFG.URL_API+"/"+url, axiosHeader())
        return response.data
    }

    updateGrade = async (grade_id, produto) => {
        let response = await axios.put(CFG.URL_API+"/produto-grade/"+grade_id, produto, axiosHeader())
        return response.data
    }

    deleteGrade = async (grade_id) => {
        let REF = this;
        let response = await axios.delete(CFG.URL_API+"/produto-grade/"+grade_id, axiosHeader())
        return response.data
    }

    postGrade = async (produto_id, line, column) => {
        let REF = this;
        let data = {
            linha: line,
            seq: column,
            produto: produto_id,
            empresa: REF.state.empresa
        };
        let response = await axios.post(CFG.URL_API+"/produto-grade", data, axiosHeader())
        return response.data
    }

    fetchGrade = async () => {
        let response = await axios.get(CFG.URL_API+"/produto-grade?empresa="+this.state.empresa, axiosHeader())
        
        for(let c = response.data.length; c < 30;c++)
        response.data.push({ id: undefined, produto: undefined });

        return response.data;
    }
}

export default ProdutoGrade
