import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import url_btn_close from '../img/icons/close.png';
import ContaPagarForm from './ContaPagarForm';

class ContaPagarModal extends React.Component{

  constructor(props) {
    super(props);

    this.state = {  
      form: {
        state: {
          id: 0
        }
      },
      loading: false,
      dt_removido_format: undefined,
      allow_delete: false
    };

  }
    
  modalcontrol = (form_comp) => {
    this.setState({ form: form_comp });
  }

  onKeyPressed = (event) => {
    if(event.keyCode == 13 )
    this.formSubmit();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.form.state.id != this.state.form.state.id){
      let REF = this;
      setTimeout(() => REF.updateButtons(), 2000);
    }
  }

  updateButtons = () => {

      if(Object.getOwnPropertyNames(this.state.form).length > 0){
        let me = this;    

        if(typeof me.state.form.state.dt_removido_format === "undefined"){
          me.setState({ dt_removido_format: undefined });
          if(typeof me.state.form.state.id !== "undefined"){
            me.setState({ allow_delete: true });
          }
        } else {       
            me.setState({ allow_delete: false, dt_removido_format: me.state.form.state.dt_removido_format });

        }


      }
  }

  componentDidMount(){

    this.setState({ loading:false });
    let REF = this;
    
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  render () {

    const confirmOrWarning = () => {
      if(typeof this.state.dt_removido_format !== "undefined")
      return (<div className="text-danger p-2 my-2 mx-auto">Removido em {this.state.dt_removido_format}</div>)
      else 
      return ( <Button onClick={this.formSubmit}>Confirmar</Button>)              
   
    }

    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
          Conta a pagar #{this.props.form.id}
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />
        </Modal.Header>
        <Modal.Body>        
          <ContaPagarForm
            show={this.props.show}
            form={this.props.form}
            modalcontrol={this.modalcontrol}
          />       
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.formSubmit}>Confirmar</Button>)            
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading} />
      </>
    );
  }
  
  formSubmit = () => {

    let REF = this;

    if(Object.getOwnPropertyNames(this.state.form).length > 0){

      if(!this.state.form.isValid()){
        return false;
      } else {
        this.state.form.post().then(()=>{
          this.setState({ loading: false });
          this.props.onHide();
        });
      }

    }
    
    
    }
  
    
  formDelete = () => {
    let c = window.confirm("Deseja mesmo remover a conta a pagar "+this.props.form.descricao+" ?");
    if (c == true) {
      if(this.state.form.state.id)
      this.state.form.delete();
      this.props.onHide();  
    }
  }
}

export default ContaPagarModal;