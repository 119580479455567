import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import { inputChangeHandler, isValid, inputBlurHandler } from '../CustomForm';

class DREForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      indicador: '',     
      validator: {},
      show: false,
      dt_removido_format: undefined
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }


  updateDateIfRemoved = () => {

    if(this.state.dt_removido_format == undefined && this.state.dt_removido != null){
      let dt = new Date(this.state.dt_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ dt_removido_format : dmy })
      this.props.modalcontrol(this);

    }

  }

  componentDidUpdate(prevProps, prevState){

    if(typeof this.props.form.id !== "undefined" && typeof this.state.id === "undefined" && this.state.id !== this.props.form.id){
      this.setState({ ...this.props.form });

      this.props.modalcontrol(this);

      this.updateDateIfRemoved();

    }

    if(this.state.dt_removido_format == undefined && this.state.dt_removido != null){
      this.updateDateIfRemoved();
    }

    if(this.props.submit && prevProps.submit !== this.props.submit){
      if(typeof this.state.id !== "undefined"){
        this.put();
      } else {
        this.post();
      }
    }

    if(this.props.delete && prevProps.delete !== this.props.delete){
      this.delete();
    }

  }

 

   post (){
     let REF = this;
    axios.post(CFG.URL_API+'/dre', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(function(){
      REF.props.close();
    });
   }
   put () {
     let REF = this;
    axios.put(CFG.URL_API+'/dre/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(function(){
      REF.props.close();
    });

    return true;
   }

   render(){

   return(
     <>
  <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />
<div className="row">      
<div className="form-group col-md-3">         
      <label htmlFor="f-id">
        ID
      </label>
      <input readOnly className="form-control text-center" name="id" id="f-id" type="text" value={this.state.id} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
    </div>
    <div className="form-group col-md-9">         
      <label htmlFor="f-indicador">
        Indicador
      </label>
      <input readOnly={this.state.dt_removido_format != undefined} className="form-control" name="indicador" id="f-indicador" type="text" value={this.state.indicador} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
    </div>
</div>

</>
);
   }

   delete = () => {
    let REF = this;
    axios.delete(CFG.URL_API+'/dre/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(function(){
      REF.props.close();
    });
   }

 
 
}
export default DREForm;