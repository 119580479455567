import React from 'react';
import { Row, Col} from 'react-bootstrap';

export class TitleBar extends React.Component { 
    render(props){
 return (
   <Row className="w-title m-0">
    <Col> 
  <h1>{this.props.title}</h1>
  <p>{this.props.description}</p>
    </Col>
  </Row>);
}
}

export default TitleBar;