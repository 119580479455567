import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import FechamentoEspelho from './FechamentoEspelho';

class FechamentoDataTable extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            movimentacao_id: '',
            empresa: this.props.empresa,
            data: [],
            fetchComplete: false,
            form_state: {},
            show_modal: false,
            searchTerm: null,
            activeIndex: undefined,
            filters: {}
        };
        
        this.handleTableRefresh = this.handleTableRefresh.bind(this);
        this.tablerowupdate = this.tablerowupdate.bind(this);
        this.formUpdate = this.formUpdate.bind(this);
        
        this.onKeyPressed = this.onKeyPressed.bind(this);
        this.getCustomToggleList = CustomToggleList.bind(this);
        this.getListing = getListing.bind(this);
        this.tableRender = tableRender;

    }
    
    updateListing = (columnsSearch) => {
        let data_up_tostring = '';

        if(Object.getOwnPropertyNames(this.state.filters).length > 0){
            
            let data_up = Object.entries(this.state.filters);

            data_up_tostring = data_up.map(function(e){
                return e[0]+'='+e[1];
            }).join('&');    
        } 
        
        this.getListing('fechamento?empresa='+this.state.empresa+data_up_tostring, columnsSearch);
        
    }
    
    componentDidMount(){
     
            window.checkUserCan('relatorio_fechamento');
          

        this.updateListing();
    }
    
    componentDidUpdate(prevProps, prevState){

        if(prevProps.filters != this.props.filters){
            let REF = this;
            this.setState({ filters: this.props.filters });
            setTimeout(() => {
                REF.updateListing();              
            }, 1000);
        }

        if(prevState.data.length != this.state.data.length){
            this.props.resultcount(this.state.data.length);
        }

        if(prevState.fetchComplete !== this.state.fetchComplete){
            this.props.setloading(false);
        }

    }
    
    onKeyPressed(event){
        if(event.keyCode == 27 )
        this.setState({ popoverVisible : false });
    }
    
    getCustomToggleList({
        columns,
        onColumnToggle,
        toggles
    }){
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }

    setModalShow = (status, form_state) => {
        
        if(form_state != null){            
            this.setState({ form_state : form_state });
            this.setState({ movimentacao_id : form_state.id });
        }
        
        this.setState({  show_modal: status });
    }

    hideModal = () => {
        this.setState({ show_modal: false });
    }
    
    handleTableRefresh(refresh){
        
        this.setState({ refresh : refresh });
        
        var Rs = this;
        
        setTimeout(function(){            
            Rs.setState({ refresh : false });
        }, 100);
        
    }
    
    getColumns(){
        return [
            {
                dataField: 'id',
                text: 'ID',
                hidden: true
            },{
                dataField: 'emissao_format',
                text: 'Data de emissão'
            },{
                dataField: 'titular',
                text: 'Usuário',
                sort: true
            },{
                dataField: 'dinheiro_format',
                text: 'Dinheiro',
                sort: true
            },{
                dataField: 'recargas_format',
                text: 'Recargas',
                sort: true
            },{
                dataField: 'lanchar_format',
                text: 'Lanchar',
                sort: true
            },{
                dataField: 'cartao_format',
                text: 'Cartão',
                sort: true
            },{
                dataField: 'ticket_format',
                text: 'Ticket',
                sort: true
            },{
                dataField: 'suprimento_format',
                text: 'Suprimento',
                sort: true
            },{
                dataField: 'sangria_format',
                text: 'Sangria',
                sort: true
            },{
                dataField: 'cancelado_format',
                text: 'Cancelado',
                sort: true
            },{
                dataField: 'bruto_format',
                text: 'Total bruto',
                sort: true
            },{
                dataField: 'liquido_format',
                text: 'Total líquido',
                sort: true
            }
        ];
    }
    
    tablerowupdate(form){
        let data = this.state.data; 
        let data_updated = this.updateTableRow(data, this.state.activeIndex, form);
        
        setTimeout(() => this.setState({ data: data_updated }));
    }
    
    updateTableRow(array, target_index, data) {
        return array.map((item, index) => {
            if (index == target_index) {
                // This isn't the item we care about - keep it as-is
                return Object.assign(item, data);
            } else {
                // Otherwise, this is the one we want - return an updated value
                return item;
            } 
        })
    }
    
    formUpdate(form_data_complete){
        this.setState({ form: form_data_complete });
    }
    
    render(){
        let REF = this;
        return (
            <div className="table-report">
                <FechamentoEspelho
                    show={this.state.show_modal}        
                    form={this.state.form_state}        
                    onHide={this.hideModal}
                    tablerowupdate={this.tablerowupdate}
                />            
                {
                    (() => REF.tableRender(false))()
                }
            </div>
            )
        }
    }
    
    export default FechamentoDataTable;