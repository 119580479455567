import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import { inputBlurHandler } from '../CustomForm';


class TerminalSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            empresa: this.props.empresa,
            list: [['', 'Carregando opções']],
            validator: {},

        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputBlurHandler = inputBlurHandler.bind(this);
        this.get = this.get.bind(this);
        this.get();
    }

    componentDidUpdate(prevProps, prevState){
     
    if(prevState.terminal != this.props.terminal){
        this.setState({ terminal : this.props.terminal });
    }

  }

  inputChangeHandler(event){

    if(typeof this.props.change == "function"){

        this.props.change(event);
    }
  }

    render(){
        let REF = this;
        return (
            <select className="form-control" value={this.state.terminal} name="terminal" id="f-terminal" onChange={this.inputChangeHandler}  >
                <option key={0} value="">Selecione</option>
                {
                      this.state.list.map(function(val) {
                          return (<option key={val[0]} value={val[1]}>{val[1]}</option>) 
                      })
                }
            </select>
        );
    }

    get(){
        let REF = this; 

        axios.get(CFG.URL_API+'/empresa/'+this.state.empresa+'/terminais', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);

            let htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });
            
            REF.setState({ list: htmlList });

          //Perform action based on response
      })
        .catch(function(error){
      requestException(error);
 });
    }

}

export default TerminalSelect;
