import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import { sessionGet } from '../../usuario/Auth';
import MovCabDetalhes from '../../movimentacao/MovCabDetalhes';

class Emissao2ViaDataTable extends React.Component{
  
  constructor(props) {
    super(props);
    
    this.state = {
      responsavel_id: '',
      empresa: sessionGet('empresa'),
        form:[],
        data: [],
        form_state: {},
        show_modal: false,
        searchTerm: null,
        activeIndex: undefined
    };

    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender;

 }

 updateListing = (columnsSearch) => {
  this.getListing('movimentacao?empresa='+this.state.empresa+'&tipo=2', columnsSearch);
 }

 componentDidMount(){
  this.updateListing();
 }

  componentDidUpdate(prevProps){

    if(this.props.refresh !== prevProps.refresh){
      this.updateListing();
    }

    if(prevProps.form != this.props.form){
      this.setState({ form: this.props.form });
    }
  }

  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }

  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }

  setModalShow = (status, form_state) => {
        
    if(form_state != null){            
        this.setState({  form_state : form_state });
        this.setState({  movimentacao_id : form_state.id });
    }
    
    this.setState({  show_modal: status });
    this.updateListing();
  }

  getColumns(){
    return [
      {
        dataField: 'id',
        text: 'ID',
        sort:true,
        headerClasses: 'cardinal'
      },{
        dataField: 'emissao_format',
        text: 'Data',
        sort:true,
        headerClasses: 'cardinal'
      }, {
        dataField: 'usuario',
        text: 'Usuário',
        sort:true,
        headerClasses: 'cardinal'
      },{
        dataField: 'ncartao',
        text: 'Código'      
      },{
        dataField: 'titular',
        text: 'Titular',
        sort: true,
        headerStyle: { width: '50%' },
        headerClasses: 'alpha'
      },{
        dataField: 'total',
        text: 'Crédito',
        sort: true
      }
    ];
  }

    render(){    
      let REF = this;
      return (
      <>
          <MovCabDetalhes
                    cancel_hide={true}
                    show={this.state.show_modal}        
                    form={this.state.form_state}        
                    onHide={() => REF.setModalShow(false, null)}
                    tablerowupdate={this.tablerowupdate}
                />   
              {
                (() => this.tableRender())()
              }
      </>
      )
    }
        }
        
        export default Emissao2ViaDataTable;
        
        