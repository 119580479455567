import React from 'react';

class Alert extends React.Component{
    componentDidMount(){
        setTimeout(()=>{
            this.props.close();
        },4000)
    }
    render(){
        const {data} = this.props
        return  <div className="alerta" onClick={()=>this.props.close()}>
        <div className={`alert alert-${data.variant || 'success'}`}>{data.message}</div>
    </div>
    }
}

export default Alert