import React from 'react';
import axios from 'axios';
import {sessionGet} from '../usuario/Auth';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import {Alert} from 'react-bootstrap';
import Select from 'react-select';

class FornecedorSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            empresa_id: sessionGet('empresa'),
            list: [],
            fornecedor: undefined,
            validator: {},
            value: ''
        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.get = this.get.bind(this);
    }

    componentDidMount(){
        this.get();
    }

    componentDidUpdate(prevProps, prevState){

        if(this.state.fornecedor != this.props.fornecedor_id){
            this.setState({ fornecedor: this.props.fornecedor_id });
        }
        
        if(prevState.list != this.state.list){
            let REF = this;
            let find = REF.state.list.find(([value, text]) => { return value == REF.state.fornecedor });
            if(typeof find !== "undefined"){
                let value = { value: find[0], label: "( "+(find[0])+" )".padEnd(10,' ')+find[1]  };
                this.setState({ value });
            }
        }

        if(prevState.empresa_id != this.props.empresa_id)
        this.setState({ empresa_id : this.props.empresa_id });
    
    }

    inputChangeHandler(pair, actionMeta){
        this.setState({ value: pair });
        if(typeof this.props.change == "function")
          this.props.change(pair, actionMeta);
    }

    render(){

        if(!this.state.loading && this.state.list.length == 0)
        return (<Alert variant="warning">
            Nenhum fornecedor encontrado. Experimente cadastrar novos fornecedores.
        </Alert>);

        let REF = this;

        return (
            <>
                <Select noOptionsMessage={() => REF.state.loading ? "Carregando opções..." : "Lamento. Não há opções disponíveis"} readOnly={typeof this.props.readonly !== "undefined"} name={this.props.name} value={REF.state.value} isClearable={true} placeholder="pesquisar e selecionar" id="f-fornecedor" onChange={this.inputChangeHandler} options={ this.state.list.map(([value, text]) => { return { value, label: "( "+(value)+" )".padEnd(10,' ')+text } }) } />
            </>
        );
    }

    get(){
        let CS = this; 

        axios.get(CFG.URL_API+'/fornecedor?empresa_id='+this.state.empresa_id+'&selectMode=pluck&pluckKey=id&pluckValue=razao', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);
           
            let htmlList = [];
          
            htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });

            
            CS.setState({ list: htmlList, loading: false });

      })
        .catch(function(error){
      requestException(error);
 });
    }

}

export default FornecedorSelect;
