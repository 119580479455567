import React from 'react';

class FooterCopyright extends React.Component  {


	render(){
    console.log("envs",process.env) 
        return (
            <div className="app-footer app-footer-default" id="footer">
            <div className="app-footer-line darken">
              <div className="copyright wide text-center">
                © 2019 Lanchar Tecnologia. Todos os direitos reservados. | 
                <span className="fa fa-chrome" /> <span className="fa fa-firefox" /> | 
                <span className="fa fa-phone" /> (31) 4063-6026 / (21) 4063-6027 / (11) 4063-6199 / (19) 4062-9330 / (83) 4064-9022
                | <b>versão: {process.env.REACT_APP_VERSION}</b>
              </div>
            </div>
          </div>
          
        );
	}
}


export default FooterCopyright;