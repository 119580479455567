import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import url_btn_close from '../img/icons/close.png';
import DREForm from './DREForm';

class DREModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = {  
        form: {
          state: {
            id: undefined
          }
        },
        loading: false,
        dt_removido_format: undefined,
        allow_delete: false,
        submit: false,
        delete: false
      };

    }
    
  
  modalcontrol = (form_comp) => {
    this.setState({ form: form_comp });
  }

  onKeyPressed = (event) => {
    if(event.keyCode == 13 )
    this.formSubmit();
  }

  componentDidUpdate(prevProps, prevState){
  
    if(typeof this.props.form.id !== "undefined" && this.props.form.id !== prevProps.form.id){
      this.setState({ submit: false, delete: false, loading: false });
      this.updateButtons();
    }

  }

  updateButtons = () => {

   
        let me = this;    
      

        if(typeof me.props.form.dt_removido_format === "undefined"){
          me.setState({ dt_removido_format: undefined });
          if(typeof me.props.form.id !== "undefined"){
            me.setState({ allow_delete: true });
          }
        } else {       
            me.setState({ allow_delete: false, dt_removido_format: me.props.form.dt_removido_format });        
        }
  }

  componentDidMount(){
    this.updateButtons();
  
   
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  closeTrigger = () => {
      this.setState({ delete: false, submit: false, loading: false });
      this.props.onHide();
  }

  render () {
    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
          Demonstração de Resultados no Exercício 
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body>        
          <DREForm
            show={this.props.show}
            form={this.props.form}
            submit={this.state.submit}
            delete={this.state.delete}
            modalcontrol={this.modalcontrol}
            close={this.closeTrigger}
          />       
        </Modal.Body>
        <Modal.Footer>

        {
            (() => {
              if(this.state.allow_delete)
              return ( <Button className="btn-danger btn-left" onClick={this.formDelete}>Excluir</Button>)
            })()
          }

          {
            (() => {
              if(typeof this.state.dt_removido_format !== "undefined")
              return (<div className="text-danger p-2 my-2 mx-auto">Removido em {this.state.dt_removido_format}</div>)
              else 
              return ( <Button onClick={this.formSubmit}>Confirmar</Button>)              
            })()
          }

  
       
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  formSubmit = () => {
    this.setState({ submit: true, loading: true });
  }
    
  formDelete = () => {

    let c = window.confirm("Deseja mesmo remover o indicador "+this.props.form.indicador+" ?");
    if (c == true) {
      this.setState({ delete: true, loading: true });
    }


  }

}

export default DREModal;