import React from 'react';
import EstoqueDataTable from './EstoqueDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import EstoqueModal from './EstoqueModal';
import TransfModal from './TransfModal';

class Estoque extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      data: {},
      show: false,
      showTransfModal: false,
      activeIndex: 0
    };

    this.props.titlebar(this.props.title, this.props.description);

    this.setModalShow = this.setModalShow.bind(this);
  }

  componentDidMount(){
    window.checkUserCan('estoque');
  }

  setModalShow (status, data){

    if(data != null){
      this.setState({  data: data });
    }

  }

  toggleModal = (tipo, show) => {
    this.setState({ tipo, show, data: null, refresh: !show });


  }

  toggleTransfModal = (showTransfModal) => {
    this.setState({ showTransfModal });
  }

  
  render (){
    let REF = this;

    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 p-0"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="secondary" onClick={() => REF.toggleModal("EN", true)}>
                  <span className="fa fa-plus-square"></span>  Entrada
                </Button>   
                <Button variant="secondary" className="ml-1" onClick={() => REF.toggleModal("SA", true)}>
                  <span className="fa fa-plus-square"></span>  Saída
                </Button> 
                <Button variant="secondary" className="ml-1" onClick={() => REF.toggleTransfModal(true)}>
                  <span className="fa fa-exchange"></span>  Transf.
                </Button>                                   
              <EstoqueModal
                tipo={this.state.tipo}
                form={{}}
                show={this.state.show}             
                onHide={() => REF.toggleModal(REF.state.tipo, false)}
              />
                 <TransfModal
                form={{}}
                show={this.state.showTransfModal}             
                onHide={() => REF.toggleTransfModal(false)}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <EstoqueDataTable refresh={this.state.refresh} setModalShow={this.setModalShow}/>
      </div>);
    }
}
  
export default Estoque;