import React from 'react';
import ResponsaveisDataTable from './ResponsaveisDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import ResponsavelModal from './ResponsavelModal';

class Responsaveis extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      data: {},
      show: false
    };

    this.props.titlebar(this.props.title, this.props.description);
 
    this.setModalShow = this.setModalShow.bind(this);
  }

  setModalShow (status, data, refresh = false){

    if(data != null){

      if(data.senha != null)
      data.senha_decode = atob(data.senha);

      if(data.nascimento == '0000-00-00')
      data.nascimento = '';

      this.setState({  data: data });
    }

    this.setState({  show: status, refresh });
  }


  render (){
    let REF = this;
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => REF.setModalShow(true, [])}>
                <span className="fa fa-plus-square"></span>  Criar responsável
                </Button>              
              <ResponsavelModal
                fields={this.state.data}
                show={this.state.show}              
                onHide={() => REF.setModalShow(false, [])}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <ResponsaveisDataTable refresh={this.state.refresh} setModalShow={REF.setModalShow}/>
      </div>);
    }
  }
  
  export default Responsaveis;