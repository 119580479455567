import React from "react"
import CadastrosMenu from "./submenu/CadastrosMenu"
import MovimentacoesMenu from "./submenu/MovimentacoesMenu"
import RelatoriosMenu from "./submenu/RelatoriosMenu"
import FinanceiroMenu from "./submenu/FinanceiroMenu"
import RecargaMenu from "./submenu/RecargaMenu"
import { Link } from "react-router-dom"

class MenuVertical extends React.Component {
    constructor(props) {
        super(props)
    }

    render(props) {
        return (
            <>
                <div className="w-logo-title">
                    <Link to="/">
                        <div className="w-icon">
                            <i className="fa fa-shopping-bag"></i>
                        </div>
                        <span className="text">Lanchar Tecnologia</span>
                    </Link>
                </div>
                <section id="MenuVertical" className={this.props.className}>
                    <CadastrosMenu
                        show={this.props.show}
                        onchange={this.props.onchange}
                        ontoggle={this.props.ontoggle}
                    />
                    <MovimentacoesMenu
                        show={this.props.show}
                        onchange={this.props.onchange}
                        ontoggle={this.props.ontoggle}
                    />
                    <FinanceiroMenu
                        show={this.props.show}
                        onchange={this.props.onchange}
                        ontoggle={this.props.ontoggle}
                    />
                    <RecargaMenu
                        show={this.props.show}
                        onchange={this.props.onchange}
                        ontoggle={this.props.ontoggle}
                    />
                    <RelatoriosMenu
                        show={this.props.show}
                        onchange={this.props.onchange}
                        ontoggle={this.props.ontoggle}
                    />
                </section>
            </>
        )
    }
}

export default MenuVertical
