import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import { inputChangeHandler, isValid, inputBlurHandler } from '../CustomForm';
import {mask_decimal} from '../util/inputMask';
import {InputGroup, FormControl} from 'react-bootstrap';

class FormaPagamentoForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      validator: {},
      tipo: "0",
      show: false,
      dt_vencimento: new Date(),
      dt_removido_format: undefined,
      edit_lock: false,
      npar: 1
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){

    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });
    }

    if(typeof this.props.formcontrol !== "undefined")
    this.props.formcontrol(this);

    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {
    if(this.state.dt_removido_format == undefined && this.state.dt_removido != null){
      let dt = new Date(this.state.dt_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear()+' às '+dt.getHours()+':'+dt.getMinutes(); 
      this.setState({ dt_removido_format : dmy, edit_lock: true })
      if(typeof this.props.formcontrol !== "undefined")
      this.props.formcontrol(this);
    }

  }

  componentDidUpdate(prevProps, prevState){

    if(this.props.submit && prevProps.submit !== this.props.submit){
      if(this.isValid()){
        if(typeof this.state.id !== "undefined"){
          this.put();
        } else{
          this.post();
        }
      }
    }

    if(prevState.npar != this.state.npar){
      //commit apenas quando input > parent

      if(typeof this.props.npar === "undefined" || this.props.npar < this.state.npar){
        this.props.update_npar(this.state.npar); 
        this.setState({ npar_warning: false });
      } else {
        let npar_warning = true;
        //input < parent, mantenha valor do parent
        if(this.props.npar != this.state.npar){
          this.setState({ npar: this.props.npar, npar_warning });
        }
      }     
    } else {
      //quando ParcelasForm atualizar
      if(this.props.npar != this.state.npar){
        this.setState({ npar: this.props.npar, npar_warning: false });
      }
    }

  
    if(this.state.dt_removido_format == undefined && this.state.dt_removido != null){
      this.updateDateIfRemoved();
    }

    if(prevState.tipo != this.state.tipo){
      this.props.update_tipo(this.state.tipo);
    }

  }

   post (){
    let REF = this;

  
    axios.post(CFG.URL_API+'/forma-pagamento', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      REF.props.set_id(response.data.id);
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.submit_confirm('FormaPagamentoForm'));
   }

   put () {
    let REF = this;
  
    axios.put(CFG.URL_API+'/forma-pagamento/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      REF.props.set_id(response.data.id);
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.submit_confirm('FormaPagamentoForm'));

    return true;
   }

   nparWarning = () => {
     if(this.state.npar_warning)
      return (<small id="passwordHelpBlock" className="form-text text-warning">
      Por segurança, digite um número maior que {this.state.npar}. Remova parcelas na aba "Parcelas".
    </small>)
   }

   render(){

   return(
     <>
    
  <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />

  <div className="row">

  <div className="form-group col-md-6">         
        <label htmlFor="f-descricao">
          Descrição
        </label>
        <input readOnly={this.state.edit_lock} className="form-control" name="descricao" id="f-descricao" type="text" value={this.state.descricao} onChange={this.inputChangeHandler}  />
      </div>

  <div className="form-group col-md-6">         
      <label>
        Tipo
      </label>
        <br/>
        <ul className="list-inline">
          <li className="list-inline-item">
            <div className="custom-control custom-radio">
              <input readOnly={this.state.edit_lock} name="tipo" className="custom-control-input" id="f-tipo-0" type="radio" value="0" checked={this.state.tipo == "0"} onChange={this.inputChangeHandler} />
              <label className="custom-control-label" htmlFor="f-tipo-0">
  A vista     </label>
              </div>
            </li>
            <li className="list-inline-item">
            <div className="custom-control custom-radio">
              <input readOnly={this.state.edit_lock} name="tipo" className="custom-control-input" id="f-tipo-1" type="radio" value="1" checked={this.state.tipo == "1"} onChange={this.inputChangeHandler} />
              <label className="custom-control-label" htmlFor="f-tipo-1">
  A prazo    </label>
              </div>
            </li> 
        </ul>
    </div> 
</div>

    <div className="row"> 

    <div className="form-group col-md-6">         
    <label htmlFor="f-juros">
      Juros
    </label>

    <InputGroup className="mb-3">
    <FormControl
      placeholder=""
      aria-label="Juros"
      aria-describedby="basic-addon2"
      maxlength={6}
      readOnly={this.state.edit_lock} className="form-control" name="juros" id="f-juros" type="text" value={this.state.juros} onChange={this.inputChangeHandler}
    />
    <InputGroup.Append>
      <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
    </InputGroup.Append>
  </InputGroup>

  </div>

   
      <div className="form-group col-md-6">         
        <label htmlFor="f-npar">
        Qtd. Parcelas
        </label>
        <input readOnly={this.state.edit_lock} max="999" className="form-control" name="npar" id="f-npar" type="number" value={this.state.npar} onChange={this.inputChangeHandler}  />
        {this.nparWarning()}
      </div>
    </div>

    <div className="row">

      <div className="form-group col-md-6">         
        <label htmlFor="f-acrescimo">
        Acréscimo
        </label>
        <input readOnly={this.state.edit_lock} className="form-control" name="acrescimo" id="f-acrescimo" type="text" value={this.state.acrescimo} onChange={this.inputChangeHandler} />
      </div>
      <div className="form-group col-md-6">         
        <label htmlFor="f-desconto">
        Desconto
        </label>
        <input readOnly={this.state.edit_lock} className="form-control" name="desconto" id="f-desconto" type="text" value={this.state.desconto} onChange={this.inputChangeHandler} />
      </div>
    </div>

</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/forma-pagamento/'+this.state.id, axiosHeader())
    .then(function(response){      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default FormaPagamentoForm;