import React from 'react'; // Import React
import { Container, Button, ButtonToolbar} from 'react-bootstrap';
import { sessionGet } from '../../usuario/Auth';
import FiltroModal from './FiltroModal';
import CardapioDataTable from './CardapioDataTable';
import {firstDateOfMonth, lastDateOfMonth} from '../../util/date';


class Relatorio extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            movimentacao_id: '',
            empresa: sessionGet('empresa'),
            data: [],
            searchTerm: null,
            activeIndex: undefined,
            show_modal_filters: false,
            filters: {
                inicio: firstDateOfMonth(),
                fim: lastDateOfMonth()
            }
        };

        this.props.titlebar(this.props.title, this.props.description);
    }

    setFiltroModalShow = (show) => {
        this.setState({ show_modal_filters: show });
    }
  
    setFilters = (form_state) => {
        this.setState({ filters: form_state, loading: true });
    }
   
    updateLoading = (status) => {
        this.setState({ loading: status  });
    }
       
    render(){
        let REF = this;
        return (<>
           <Container fluid={true}>
            <div className="row">
                <div className="col-12 my-2"> 
                    <ButtonToolbar className="float-right m-3">
                        <Button variant="success" onClick={() => REF.setFiltroModalShow(true)}>
                            <span className="fa fa-filter"></span>
                        </Button>              
                    <FiltroModal
                        loading={this.state.loading}
                        empresa={this.state.empresa}
                        show={this.state.show_modal_filters}
                        setfilters={this.setFilters}
                        onHide={() => REF.setFiltroModalShow(false)}
                    />
                    </ButtonToolbar>            
                </div>
            </div>
        <CardapioDataTable setloading={this.updateLoading} title={this.props.title} sizeperpage={this.state.data.length} empresa={this.state.empresa} filters={this.state.filters} />
        </Container>
        </>);
    }
    }
    
    export default Relatorio;