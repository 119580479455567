import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import CFG from '../CFG';
import {Row, Col, Button} from 'react-bootstrap';
class FornecedorInput extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            index: this.props.index,
            //id da tabela pivô
            id: this.props.id,
            fornecedor_id: this.props.fornecedor_id,
            fornecedor_razao: this.props.fornecedor_razao,
            validator: {}
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
       
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps != this.props){
            this.setState({
                index: this.props.index,
                id: this.props.id,
                fornecedor_id: this.props.fornecedor_id,
                fornecedor_razao: this.props.fornecedor_razao
            });
        }
    }

    inputChangeHandler(event){
        if(event.target.value != "" && typeof this.props.onchange === "function")
        this.props.onchange(event);
    }

    render(){
        let REF = this;
        return (
        <Row key={this.state.index}>
            <Col md="10">
                <input type="text" readOnly={true} className="form-control" onChange={this.inputChangeHandler} value={this.state.fornecedor_razao} />
            </Col>
            <Col md="2">
                <Button onClick={ () => REF.delete() } variant="danger" className="btn-sm mx-auto d-block">
                    <i className="icon-trash"></i>
                </Button>
            </Col>
        </Row>
        );
    }

    delete = () => {
        let c = window.confirm("Deseja mesmo desvincular o fornecedor "+this.state.fornecedor_razao+" ?");
        if (c == true) {
     
        this.props.delete(this.state.index);

        if(this.state.id == null)
        return true;

        axios.delete(CFG.URL_API+'/fornecedor-produto/'+this.state.id)
        .then(function(response){
          //Perform action based on response
      })
        .catch(function(error){
      requestException(error);
 });
        }
    }    

}
export default FornecedorInput;
