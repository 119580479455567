import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import MovCabDetalhes from './MovCabDetalhes';
import url_btn_close from '../img/icons/close.png';

class MovCabModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
        form: {},
        loading: false
      };

    }

  modalControl = (form_comp) => {
    this.setState({ form: form_comp });
  }

  render () {
    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            Movimentação {this.state.tipo_movimentacao} 
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />
        </Modal.Header>
        <Modal.Body>        
          <MovCabDetalhes
            show={this.props.show}
            form={this.props.form}
            modalcontrol={this.modalControl}
          />       
        </Modal.Body>
        <Modal.Footer>
          <Button className="ml-auto"  onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  
  formSubmit = () =>  {

    let CM = this;

    if(!this.state.form.isValid())
    return false;
    else
    this.setState({ loading: true });

    if(Object.getOwnPropertyNames(this.state.form).length === 0){
      this.setState({ loading: false });
      this.props.onHide();
      return false;
    }
 
    if(typeof this.props.tablerowupdate === "function")
      this.props.tablerowupdate(this.state.form.state);

    if(this.state.form.state.id != null){
      this.state.form.put();
    } else {
      this.state.form.post();
    }

    setTimeout(() => {
      CM.setState({ loading: false })
      CM.props.onHide();
    } , 1000);
  }  
}

export default MovCabModal;