import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {Row, Col} from 'react-bootstrap';
import CartaoSelect from '../cartao/CartaoSelect';
import {sessionGet} from '../usuario/Auth';
import {mask_decimal, parseDecimal} from '../util/inputMask';
import {getFullTimestamp} from '../util/date';
import { inputChangeHandler, selectChangeHandler, isValid } from '../CustomForm';


class RecargaForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            emissao: getFullTimestamp(new Date()),
            empresa: sessionGet('empresa'),
            validator: {}
        };
        
        this.inputChangeHandler = inputChangeHandler.bind(this);
        this.selectChangeHandler = selectChangeHandler.bind(this);
        
        this.isValid = isValid.bind(this);
   
    }
    
    componentDidMount() {
        let form_data = this.props.form;
        if(typeof form_data !== "undefined"){
            form_data.valor = mask_decimal(form_data.valor);
            this.setState({ ...form_data, show: this.props.show });   
        }
        
    }
  
    componentDidUpdate(prevProps, prevState){
        if(typeof prevProps.form !== "undefined" && typeof this.props.form.id !== "undefined" && prevProps.form.id !== this.props.form.id){
            this.setState({ ...this.props.form });
        }

        if(this.props.submit && prevProps.submit !== this.props.submit){
            if(typeof this.props.form.id === "undefined"){
                this.post();
            }
        }

    }

    post = () => {
        let REF = this;
        let post_data = this.state;
        post_data.valor = parseDecimal(this.state.valor);
        console.log(post_data.valor);

        axios.post(CFG.URL_API+'/recarga', post_data, axiosHeader())
        .then(function(response){
          //Perform action based on response
      })
        .catch(function(error){
          //Perform action based on error
        }).finally(() => REF.props.close());
       }
    
    
    
    render(){
        
        return(
            <>
                 <Row>
                <Col md="12">         
                <label htmlFor="f-valor">
                    Valor
                    </label>
                    <input readOnly={typeof this.props.form.id !== "undefined"} className="form-control" name="valor" id="f-valor" type="text" value={this.state.valor} onChange={this.inputChangeHandler} />
                </Col>
                </Row>
                <Row>
                <Col md="12">
                    <label className="text-uppercase mt-3">Cartão</label>
                    <CartaoSelect ativos={true} disabled={typeof this.props.form.id !== "undefined"} change={this.selectChangeHandler} cartao={this.state.cartao} />
                </Col>
                </Row>
                <Row>
                <Col md="12" hidden={typeof this.props.form.emissao_format === "undefined"}>
                    <label className="text-uppercase mt-3 d-block">Recarga realizada em </label>
                    {this.props.form.emissao_format}
                </Col>
                </Row>
            </>
            );
        }
        
    }
    export default RecargaForm;