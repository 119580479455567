import React from 'react';
import axios from 'axios';
import {Row} from 'react-bootstrap';
import {CFG, axiosHeader} from '../../CFG';
import {sessionGet} from '../../usuario/Auth';
import { inputChangeHandler, checkboxClickHandler, isValid, selectChangeHandler } from '../../CustomForm';
import CFOPSelect from './CFOPSelect';
import ClienteSelect from './ClienteSelect';
import TipoMovimentoSelect from './TipoMovimentoSelect';
import FornecedorSelect from '../../fornecedor/FornecedorSelect';
import CentroCustoSelect from '../../centrocusto/CentroCustoSelect';

class DadosForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      indicador: '',   
      useSameVendor: true,
      validator: {},
      show: false,
      data_removido_format: undefined
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    
    this.checkboxClickHandler = checkboxClickHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){

    if(typeof this.props.form !== "undefined" && this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });

      this.props.formcontrol(this);

    }

    let tpmovimento_id = this.props.tipo === "entrada" ? 2 : 1;
    this.setState({ tpmovimento_id });

    this.updateDateIfRemoved();
  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy })
      this.props.formcontrol(this);

    }

  }

  componentDidUpdate(prevProps, prevState){

    if(typeof this.props.emitente !== "undefined" && prevState.emitente_nome !== this.props.emitente.nome){
      this.setState({ emitente_nome: this.props.emitente.nome });
    }

    if(typeof prevProps.form !== "undefined" && prevProps.form.id !== this.props.form.id){
      this.setState({ ...this.props.form });
    }

    if(prevState.centrocustos_id !== this.state.centrocustos_id){
      this.props.set_centrocustos(this.state.centrocustos_id);
    }

    if(prevState.fornecedor_id !== this.state.fornecedor_id){
      this.props.set_fornecedor(this.state.fornecedor_id);
    }    

  }

  postFornecedor = () => {
    let f = Object.assign({}, this.props.emitente);
    f.empresa_id = this.state.empresa_id;
    f.razao = this.state.emitente_nome;
    f.fantasia = this.state.emitente_nome;

    axios.post(CFG.URL_API+'/fornecedor', f, axiosHeader())
    .then(function(response){
      //Perform action based on response
      
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   post = () => {
      this.postFornecedor();

      this.props.post_confirm('DadosForm');
   }
   put () {
    axios.put(CFG.URL_API+'/dre/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){

   return(
     <>
  <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />
    
<div className="form-group">         
      <label htmlFor="f-cfop">
        CFOP
      </label>
      <CFOPSelect change={this.selectChangeHandler} cfop={this.state.cfop} />
    </div>

    <div className="form-group" hidden={this.props.tipo === "entrada"}>         
      <label htmlFor="f-cliente">
        Cliente
      </label>
      <ClienteSelect change={this.selectChangeHandler} cliente={this.state.cliente} />
    </div>

    <Row>
    <div className="col-lg-6" hidden={!this.state.useSameVendor}> 
          <div >
            <input type="checkbox" value={true} id="f-useSameVendor" name="useSameVendor" checked={this.state.useSameVendor} onClick={this.checkboxClickHandler}  className="mr-2" />
            <label htmlFor="f-useSameVendor" className="d-inline text-info">
                <u>Cadastrar fornecedor com base no informado na nota</u>
            </label>
          </div>
        </div>
    </Row>
    <div hidden={!this.state.useSameVendor} className="mb-3">
    <label htmlFor="f-fornecedor" className="d-block mt-3">
        Fornecedor
      </label>
      {this.state.emitente_nome}
    </div>
    <div className="form-group" hidden={this.state.useSameVendor}>         
      <label htmlFor="f-fornecedor">
        Fornecedor
      </label>
      <FornecedorSelect change={this.selectChangeHandler} fornecedor_id={this.state.fornecedor} name="fornecedor_id" />
    </div>

    <div className="form-group">         
      <label htmlFor="f-tpmovimento_id">
        Tipo Movimento
      </label>
      <TipoMovimentoSelect empresa_id={this.state.empresa_id} change={this.selectChangeHandler} tpmovimento_id={this.state.tpmovimento_id} />
    </div>

    <div className="form-group">         
      <label htmlFor="f-centrocustos_id">
        Centro de custo
      </label>
      <CentroCustoSelect empresa_id={this.state.empresa_id} change={this.selectChangeHandler} centrocustos_id={this.state.centrocustos_id} />
    </div>

</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/dre/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default DadosForm;