import React from 'react';
import axios from 'axios';
import {Row,Col} from 'react-bootstrap';
import {CFG, axiosHeader} from '../../CFG';
import {sessionGet} from '../../usuario/Auth';
import { inputChangeHandler, isValid } from '../../CustomForm';
import { mask_decimal } from '../../util/inputMask';

class ImpostosForm extends React.Component {

  initialState = () => {
    return {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      indicador: '',     
      validator: {},
      show: false,
      baseicmsubst: '',
      baseicms: '',
      totalicms: '',
      totalipi: '',
      vlicmsubst: '',
      vlfrete: '',
      vlprodutos: '',
      vldesconto: '',
      outrasdesp: '',
      totalpis: '',
      totalcofins: '',
      vldocumento: 0,
      data_removido_format: ''
    };
  }

  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.inputChangeHandler = inputChangeHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(prevProps, prevState){

   
    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy });

    }

  }

  componentDidUpdate(prevProps, prevState){

    if(prevProps.form.id !== this.props.form.id){
      let REF = this;
      if(typeof this.props.form.id === "undefined"){
        this.setState({ ...REF.initialState() });
      } else {
        this.setState({ ...this.props.form });
      }
      setTimeout(() => REF.props.formcontrol(REF), 2000);
    }

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }

    if(prevState.vldocumento !== this.state.vldocumento){
      this.props.update_vldocumento(this.state.vldocumento);
    }
  }

   post (){
    axios.post(CFG.URL_API+'/impostos', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   put () {
    axios.put(CFG.URL_API+'/impostos/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){

   return(
     <>
  <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />
    
    <Row>
      <Col md="4">         
          <label htmlFor="f-baseicms">
            Base ICMS
          </label>
          <input className="form-control" name="baseicms" id="f-baseicms" type="text" value={mask_decimal(this.state.baseicms)} onChange={this.inputChangeHandler} />
      </Col>
      <Col md="4">         
          <label htmlFor="f-totalicms">
            Total ICMS
          </label>
          <input className="form-control" name="totalicms" id="f-totalicms" type="text" value={mask_decimal(this.state.totalicms)} onChange={this.inputChangeHandler} />
      </Col>

      <Col md="4">         
          <label htmlFor="f-baseicmsubst">
            Total ICMS S.T.
          </label>
          <input className="form-control" name="baseicmsubst" id="f-baseicmsubst" type="text" value={mask_decimal(this.state.baseicmsubst)} onChange={this.inputChangeHandler} />
      </Col>
    </Row>

    <Row>
      <Col md="4">         
          <label htmlFor="f-vlicmsubst">
            Valor ICMS S.T.
          </label>
          <input className="form-control" name="vlicmsubst" id="f-vlicmsubst" type="text" value={mask_decimal(this.state.vlicmsubst)} onChange={this.inputChangeHandler} />
      </Col>
      <Col md="4">         
          <label htmlFor="f-vIPI">
            Valor IPI
          </label>
          <input className="form-control" name="totalipi" id="f-totalipi" type="text" value={mask_decimal(this.state.totalipi)} onChange={this.inputChangeHandler} />
      </Col>

      <Col md="4">         
          <label htmlFor="f-vlfrete">
            Valor frete
          </label>
          <input className="form-control" name="vlfrete" id="f-vlfrete" type="text" value={mask_decimal(this.state.vlfrete)} onChange={this.inputChangeHandler} />
      </Col>
    </Row>

    <Row>
      <Col md="4">         
          <label htmlFor="f-vlprodutos">
            Valor dos Produtos
          </label>
          <input className="form-control" name="vlprodutos" id="f-vlprodutos" type="text" value={mask_decimal(this.state.vlprodutos)} onChange={this.inputChangeHandler} />
      </Col>
      <Col md="4">         
          <label htmlFor="f-vldesconto">
            Valor dos Descontos
          </label>
          <input className="form-control" name="vldesconto" id="f-vldesconto" type="text" value={mask_decimal(this.state.vldesconto)} onChange={this.inputChangeHandler} />
      </Col>

      <Col md="4">         
          <label htmlFor="f-outrasdesp">
            Outras despesas
          </label>
          <input className="form-control" name="outrasdesp" id="f-outrasdesp" type="text" value={mask_decimal(this.state.outrasdesp)} onChange={this.inputChangeHandler} />
      </Col>
    </Row>
<Row>
    <Col md="4">         
          <label htmlFor="f-totalpis">
            PIS
          </label>
          <input  className="form-control" name="totalpis" id="f-totalpis" type="text" value={mask_decimal(this.state.totalpis)} onChange={this.inputChangeHandler} />
      </Col>
      <Col md="4">         
          <label htmlFor="f-totalcofins">
            COFINS
          </label>
          <input  className="form-control" name="totalcofins" id="f-totalcofins" type="text" value={mask_decimal(this.state.totalcofins)} onChange={this.inputChangeHandler} />
      </Col>

      <Col md="4">         
          <label htmlFor="f-vldocumento">
            Valor da Nota
          </label>
          <input className="form-control" name="vldocumento" id="f-vldocumento" type="text" value={mask_decimal(this.state.vldocumento)} onChange={this.inputChangeHandler} />
      </Col>
    </Row>

</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/impostos/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default ImpostosForm;