import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import DRESelect from '../dre/DRESelect';
import { inputChangeHandler, isValid, radioSwitchHandler, selectChangeHandler } from '../CustomForm';

class CentroCustoForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa_id: sessionGet('empresa'),   
      indicadoresdre_id: 1,     
      classificacao: '',     
      descricao: '',     
      tipo: 'C',     
      tipo_movcab: '',
      validator: {},
      show: false,
      dt_removido_format: undefined
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.radioSwitchHandler = radioSwitchHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){
    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });
      console.log(this.state);

      this.props.modalcontrol(this);
    }

    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {

    if(this.state.dt_removido_format == undefined && this.state.dt_removido != null){
      let dt = new Date(this.state.dt_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ dt_removido_format : dmy })
      this.props.modalcontrol(this);

    }

  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.dt_removido_format == undefined && this.state.dt_removido != null){
      this.updateDateIfRemoved();
    }
  }

 

   post (){
    axios.post(CFG.URL_API+'/centro-custo', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   put () {
    axios.put(CFG.URL_API+'/centro-custo/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){
   return(
     <>
    <div className="row"> 
    <div className="form-group col-md-6">         
      <label htmlFor="f-tipo">
        Tipo
      </label>
      <select name="tipo" value={this.state.tipo} className="form-control" onChange={this.inputChangeHandler}>
        <option value="C">Crédito</option>
        <option value="D">Débito</option>
      </select>
    </div>
    <div className="form-group col-md-6">         
      <label htmlFor="f-tipo">
        Indicador
      </label>
      <DRESelect indicadoresdre_id={this.state.indicadoresdre_id} change={this.selectChangeHandler} empresa_id={this.state.empresa_id} show={this.state.show} />
    </div>   

    </div>

    <div className="row">
    <div className="form-group col-md-6">         
      <label htmlFor="f-classificacao">
        Classificação
      </label>
      <input className="form-control" name="classificacao" id="f-classificacao" type="text" value={this.state.classificacao} onChange={this.inputChangeHandler} />
    </div>
        <div className="form-group col-12 col-md-6">         
            <label>
              Meio de pagamento
            </label>
            <select name="tipo_movcab" value={this.state.tipo_movcab} className="form-control" onChange={this.inputChangeHandler}>
                <option value="">Indefinido</option>
                <option value="5">( 5 ) Dinheiro</option>
                <option value="15">( 15 ) Débito</option>
                <option value="16">( 16 ) Crédito</option>
                <option value="10">( 10 ) Ticket Alimentação</option>
                <option value="0">( 0 ) Lanchar PRÉ</option>
                <option value="8">( 8 ) Lanchar PÓS</option>
            </select>
        </div>  
        </div>
    <div className="row"> 
    <div className="form-group col-md-12">         
      <label htmlFor="f-descricao">
        Descrição
      </label>
      <textarea rows="6" className="form-control" name="descricao" id="f-descricao" type="text" value={this.state.descricao} onChange={this.inputChangeHandler} />
    </div>
</div>

</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/centro-custo/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

}
export default CentroCustoForm;