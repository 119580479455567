import React from 'react';
import axios from 'axios';
import {sessionGet} from '../../usuario/Auth';
import {requestException} from '../../util/exception';
import {CFG, axiosHeader} from '../../CFG';
import {Alert} from 'react-bootstrap';
import Select from 'react-select';

class CFOPSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            empresa: sessionGet('empresa'),
            list: [],
            cfop: undefined,
            validator: {},
            value: null
        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.get = this.get.bind(this);
    }

    componentDidMount(){
        this.get();
    }

    setSelected = () => {
        let REF = this;
        let find = REF.state.list.find(([value, text]) => { return value == REF.state.cfop });
        if(typeof find !== "undefined"){
            let value = { value: find[0], label: "( "+(find[0])+" )".padEnd(10,' ')+find[1]  };
            setTimeout(() => REF.setState({ value }), 300);
        }    
    }

    componentDidUpdate(prevProps, prevState){

        if(prevProps.cfop !== this.props.cfop){
            this.setState({ cfop: this.props.cfop });
        }

        if(prevState.list != this.state.list || prevState.cfop !== this.state.cfop){
            this.setSelected();      
        }

        if(prevState.empresa != this.props.empresa)
        this.setState({ empresa : this.props.empresa });

        if(prevState.list != this.state.list){
            let REF = this;
            let find = REF.state.list.find(([value, text]) => { return value == REF.state.cfop });
            if(typeof find !== "undefined"){
                let value = { value: find[0], label: "( "+(find[0])+" )".padEnd(10,' ')+find[1]  };
                setTimeout(() => REF.setState({ value }), 300);
            }            
        }
    
    }

    inputChangeHandler(pair, actionMeta){
        this.setState({ value: pair });
        if(typeof this.props.change == "function")
          this.props.change(pair, actionMeta);
    }

    render(){

        if(!this.state.loading && this.state.list.length == 1)
        return (<Alert variant="warning">
            Lista vazia
        </Alert>);

        let REF = this;

        return (
            <>
                <Select noOptionsMessage={() => REF.state.loading ? "Carregando opções..." : "Lamento. Não há opções disponíveis"} readOnly={typeof this.props.readonly !== "undefined"} name="cfop" value={REF.state.value} isClearable={true} placeholder="pesquisar e selecionar" id="f-cfop" onChange={this.inputChangeHandler} options={ this.state.list.map(([value, text]) => { return { value, label: "( "+(value)+" )".padEnd(10,' ')+text } }) } />
            </>
        );
    }

    get(){
        let CS = this; 

        axios.get(CFG.URL_API+'/cfop?selectMode=pluck&pluckKey=codigo&pluckValue=descricao', axiosHeader())
        .then(function(response){
            let entries = Object.entries(response.data);
           
            let htmlList = [];
          
            htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });

            
            CS.setState({ list: htmlList, loading: false });

      })
        .catch(function(error){
      requestException(error);
 });
    }

}

export default CFOPSelect;
