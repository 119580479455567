import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';

export function SummaryModal(props){

    let listSummary = (summary) => {
        
        return (
            <ul>
                <li>Número da nota: {summary.numero}</li>
                <li>Nome do fornecedor: {summary.fornecedor.fantasia}</li>
                <li>CNPJ do fornecedor: {summary.fornecedor.cnpj}</li>
                <li>Valor da nota: {summary.valor}</li>
            </ul>
        )
        
    };

    return (<>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Confirme a importação</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <ul>
          {listSummary(props.summary)}
        </ul>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
            Não
          </Button>
          <Button variant="success" onClick={props.onConfirm}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>
        </>);
}

export default SummaryModal;