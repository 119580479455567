import React from "react"
import { sessionGet } from "./usuario/Auth"

export const ENV = !(/localhost|127.0.0.1/gi).test(window.location.href) ? "production" : "local";
//export const ENV = "local"

//const URL_APP = window.location.href.includes("cantina-lanchar.web") ? "https://cantina-lanchar.web.app" :  "https://cantina.lanchar.com.br"
//const URL_APP = "https://cantina.lanchar.com.br"
const URL_APP = ENV == "production" ? 'https://cantina.lanchar.com.br' : "http://localhost:3000";
export const CFG = (() => {
    if (ENV == "production")
        return {
            APP_NAME: "Lanchar Tecnologia",
            SESSION_PREFIX: "lanchar.ac",
            IGNORE_AUTH: ["login", "login-recuperar"],
            URL_ICONE: "https://lanchar.com.br",
            URL_APP,
            URL_API: "https://api.lanchar.com.br"
        }

    if (ENV == "local")
        return {
            APP_NAME: "Lanchar Tecnologia",
            SESSION_PREFIX: "lanchar.ac",
            IGNORE_AUTH: ["login", "login-recuperar"],
            URL_ICONE: "https://lanchar.com.br",
            URL_APP: "http://127.0.0.1:3000",
            URL_API: "https://api.lanchar.com.br"
        }
})()

export function axiosHeader() {
    return {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionGet("key")
        }
    }
}

export default CFG
