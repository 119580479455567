import React from 'react'
import { Form, Row,Col } from 'react-bootstrap';

class TabDadosGerais extends React.Component {
    constructor(props) {
      super(props);
      this.handleInputGerais = this.handleInputGerais.bind(this) 
    }

    handleInputGerais = (e)=>{
        let form = this.props.form;
        form.dadosGerais[e.target.name] = e.target.value;
        this.props.handleForm(form);
    }

    
    render(){
        const empresa = this.props.form
        return <div style={{ padding:10}}>
            <fieldset className="fieldset">
                <legend>Identificação</legend>
                <Form>

                    <Form.Group as={Col}  xs={6} sm={4} lg={2}>
                        <label>CNPJ</label>
                        <Form.Control readOnly value={empresa.dadosGerais.cnpj} onChange={this.handleInputGerais} name="cnpj" />
                    </Form.Group>

                    <Form.Group as={Col} xs={6}  sm={8} lg={5} >
                        <label>Razão Social</label>
                        <Form.Control readOnly value={empresa.dadosGerais.razaoSocial} onChange={this.handleInputGerais}  name="razaoSocial" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={8} lg={5}>
                        <label>Nome Fantasia</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.nomeFantasia}  name="nomeFantasia" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={4} lg={3}>
                        <label>Senha</label>
                        <Form.Control onChange={this.handleInputGerais}  name="senha"  type="password"/>
                    </Form.Group>
                    <Form.Group as={Col} xs={6} lg={3} >
                        <label>Inscrição estadual</label>
                        <Form.Control onChange={this.handleInputGerais}  value={empresa.dadosGerais.inscricaoEstadual} name="inscricaoEstadual" />
                    </Form.Group>
                </Form>
            </fieldset>
            <fieldset className="fieldset">
                <legend>Dados de contato</legend>
                <Form>
                    <Form.Group as={Col} xs={6} sm={6} md={3} lg={2}  >
                        <label>Telefone</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.telefone} name="telefone" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} sm={6} md={3} lg={2} >
                        <label>Celular</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.celular} name="celular" />
                    </Form.Group>
                    <Form.Group as={Col}  xs={12} sm={6} md={6} lg={4} >
                        <label>E-Mail</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.email} name="email" />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} sm={6} md={12} lg={4} >
                        <label>Contato</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.contato} name="contato" />
                    </Form.Group>
                </Form>
            </fieldset>
            <fieldset className="fieldset">
                <legend>Endereço</legend>
                <Form>
                    <Form.Group as={Col} xs={4} lg={2} >
                        <label>Cep</label>
                        <Form.Control onChange={this.handleInputGerais}  value={empresa.dadosGerais.cep} name="cep" />
                    </Form.Group>
                    <Form.Group as={Col} xs={8}  >
                        <label>Logradouro</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.endereco} name="endereco" />
                    </Form.Group>
                    <Form.Group as={Col} xs={4} lg={2}  >
                        <label>Numero</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.numero}  name="numero" />
                    </Form.Group>
                    <Form.Group as={Col} xs={8} lg={6} >
                        <label>Complemento</label>
                        <Form.Control onChange={this.handleInputGerais}  value={empresa.dadosGerais.complemento}  name="complemento" />
                    </Form.Group>
                
                    <Form.Group as={Col} xs={12} md={6} lg={6}>
                        <label>Bairro</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.bairro}  name="bairro" />
                    </Form.Group>
                    <Form.Group as={Col} xs={8} md={6} lg={5}>
                        <label>Cidade</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.cidade}  name="cidade" />
                    </Form.Group>
                    <Form.Group as={Col} xs={4} md={2} lg={1}>
                        <label>UF</label>
                        <Form.Control onChange={this.handleInputGerais} maxLength={2} value={empresa.dadosGerais.estado}  name="estado" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} md={5} lg={3}>
                        <label>Latitude</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.latitude}  name="latitude" />
                    </Form.Group>
                    <Form.Group as={Col} xs={6} md={5} lg={3}>
                        <label>Longitude</label>
                        <Form.Control onChange={this.handleInputGerais} value={empresa.dadosGerais.longitude}  name="longitude" />
                    </Form.Group>
                </Form>
            </fieldset>
        </div>
    }
}

export default TabDadosGerais