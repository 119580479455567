import React from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import CartoesDataTable from '../cartao/CartoesDataTable';
import CartaoForm from './CartaoForm';
import './CartaoTab.scss';
import CartaoLimiteForm from './CartaoLimiteForm';

class CartaoTab extends React.Component{

  render () {
    return (
        <div className="CartaoTab">
        <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example">
          <Tab eventKey="home" title="Dados">
          <CartaoForm
              id={this.props.id}
              submit={this.props.submit}
              responsavel_id={this.props.responsavel_id}
              show={this.props.show}
              form={this.props.form}
              setid={this.props.setid}
              {...this.props}
            />
          </Tab>
          <Tab eventKey="profile" title="Limites diários">
              <CartaoLimiteForm 
              id={this.props.id}
              submit={this.props.submit} 
              show={this.props.show}    
              form={this.props.form}
              close={this.props.close}
              />
          </Tab>        
        </Tabs>
      </div>
    );
  }

}


export default CartaoTab;