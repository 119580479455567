import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import axios from 'axios';
import {CFG, axiosHeader} from '../../CFG';
import { sessionGet } from '../../usuario/Auth';
import './ItemsDataTable.scss';
import ItemModal from './ItemModal';
import {requestException} from '../../util/exception';
import {todayTimestamp} from '../../util/date';

class ItemsDataTable extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      fetchComplete: true,
      empresa: sessionGet('empresa'),
      form:[],
      data: [],
      searchTerm: null,
      activeIndex: undefined
    };

    this.tablerowupdate = this.tablerowupdate.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender;
  }


  componentDidUpdate(prevProps, prevState){

    if(typeof this.props.data !== "undefined" && prevProps.data.length !== this.props.data.length){
      this.setState({ data: this.props.data, fetchComplete: true });
    }


    if(prevProps.tipo !== this.props.tipo){
       this.setState({ tipo: this.props.tipo });
    }

    if(prevProps.form != this.props.form){
      this.setState({ form: this.props.form });
    }

    if(this.props.submit && prevProps.submit !== this.props.submit){
      this.post();
    }

  }

  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }

  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }

  setModalShow (status, data){

    if(typeof data !== "undefined"){
      this.setState({  form : data });
    }

    this.setState({  show: status });
    
  }

  getColumns(){
    return [
      {
        dataField: 'id',
        text: 'ID',
        style: { 'text-align' : 'center' },
        headerStyle: { 'width' : '10%', 'textAlign':'center' },
        hidden:true
      },{
        dataField: 'cEAN',
        text: 'Cód. EAN',
        style: { 'text-align' : 'center' },
        headerStyle: { 'width' : '10%', 'textAlign':'center' }
      },{
        dataField: 'xProd',
        text: 'Produto NF',
        sort: true,
        headerClasses: 'alpha',
        headerStyle: { 'width' : '25%', 'textAlign':'center' }
      },{
        dataField: 'xProdSistema',
        text: 'Produto Base',
        sort: true,
        headerClasses: 'alpha',
        headerStyle: { 'width' : '25%', 'textAlign':'center' }
      },{
        dataField: 'unidade',
        text: 'Unidade',
        sort: true
      },{
        dataField: 'qtdeprod',
        text: 'Quantidade',
        sort: true,
        headerClasses: 'cardinal'
      },{
        dataField: 'precoprod_format',
        text: 'Preço',
        sort: true,
        headerClasses: 'cardinal'
      },{
        dataField: 'totalprod_format',
        text: 'Total',
        sort: true,
        headerClasses: 'cardinal'
      }    
    ];
  }

  getUnidadeQtd = async (sigla) => {

      return axios.get(CFG.URL_API+'/produto-unidade?sigla='+sigla, axiosHeader())
      .then(function(response){
          if(response.data.length === 0)
          return null;

          return response.data[0].qtddefault;;
    });
  }

  tablerowupdate(form){
    let data = this.state.data; 
    this.setState({ data: [] });
    let data_updated = this.updateTableRow(data, this.state.activeIndex, form);
    let REF = this;
    setTimeout(() => { 
      REF.props.data_update(data_updated);
      REF.setState({ data: data_updated, show: false });
    }, 1000);
  }

  updateTableRow(array, target_index, data) {
    return array.map((item, index) => {
      if (index === target_index) {
        // This isn't the item we care about - keep it as-is
        return Object.assign(item, data);
      } else {
        // Otherwise, this is the one we want - return an updated value
        return item;
      } 
    })
  }

  fetchProduct = async (f) => {

    let REF = this;

    return axios.get(CFG.URL_API+'/produto/'+f.produto, f, axiosHeader()).then(async (response) => {
      response.data.produto_id = response.data.id;
      return response.data;
    });
   
  }

  createProduct = async (f) => {

    delete f.id;
    return axios.post(CFG.URL_API+'/produto', f, axiosHeader()).then(async (response) => {
      response.data.produto_id = response.data.id;
      return response.data;
    });
   
  }


  createMovProduto = async (p) => {
    let REF = this;
    let item_data = {
     codigo: p.cProd,
     serie: REF.state.serie,
     empresa_id: REF.state.empresa,
     formaspagamento_id: REF.props.formaspagamento_id,
     modelodocumento_id: REF.props.modelo_id,
     fornecedor_id: p.fornecedor_id,
     usuario_id: REF.props.usuario_id
    };

    Object.assign(p, item_data);

    delete p.id;

    return axios.post(CFG.URL_API+'/movproduto', p, axiosHeader())
    .then(async function(response){
      return response.id;
    })
    .catch(function(error){
      requestException(error);
    });
  }


  createMovItem = async (p, movprodutos_id) => {

    let item_data = {
      movprodutos_id,
     item: p.xProd,
     item: p.xProd,
    };

    Object.assign(p, item_data);

    delete p.id;

    return axios.post(CFG.URL_API+'/movitem', p, axiosHeader())
    .then(async function(response){
      return response;
    })
    .catch(function(error){
      requestException(error);
    });
  }

  createEstoque = async (p, qtd, tipo) => {
    let REF = this;

    let item_data = {
      empresa: REF.state.empresa,
      produto: p.id,
      tipo: REF.state.tipo,
      qtd,
      data: todayTimestamp(),
      motivo: 'Ref. ao item '+p.id+' da NFe '+REF.props.nfe_id+' de '+REF.props.tipo
    };

    Object.assign(p, item_data);

    delete p.id;


    return axios.post(CFG.URL_API+'/estoque', p, axiosHeader())
    .then(async function(response){
      return response;
    })
    .catch(function(error){
      requestException(error);
    });
  }

  

  post = async () => {

    let REF = this;
   
    return this.state.data.map(async function(f){
      let p = {};
      let unidade_qtd = await REF.getUnidadeQtd(f.unidade);
      //conversão de unidades
      if(unidade_qtd !== null){
        f.unidade = 'UN';        
        f.qtdeprod = f.qtdeprod * unidade_qtd;
      }

      //cria novo produto      
      if(f.useSameProduct){
        p = await REF.createProduct(f);
      } else {
        //usa produto existente
        p = await REF.fetchProduct(f);
      }

      

      //atualiza estoque do produto novo ou existente
      let e = await REF.createEstoque(p, parseFloat(f.qtdeprod), REF.props.tipo);
      let movprodutos_id = await REF.createMovProduto(p);
      let mv = await REF.createMovItem(p, movprodutos_id);

    });

    this.props.post_confirm('ItemsDataTable');

  }


  render(){
      let REF = this;
    return (
      <>
        <ItemModal
            tipo={this.props.tipo}
            show={this.state.show}        
            form={this.state.form}        
            onHide={() => REF.setModalShow(false)}
            tablerowupdate={this.tablerowupdate}
          />  
        <div className="ItemsDataTable">
        {
          this.tableRender()
        }
        </div>
      </>
      )
    }
  }
  export default ItemsDataTable;
