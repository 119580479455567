import React from "react" // Import React
import { Container, Table, Button, ButtonToolbar, Row, Col } from "react-bootstrap"
import { sessionGet } from "../../usuario/Auth"
import axios from "axios"
import { CFG, axiosHeader } from "../../CFG"
import Loading from "../../util/Loading"
class Cartao extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            movimentacao_id: "",
            empresa: sessionGet("empresa"),
            searchTerm: null,
            activeIndex: undefined,
            origem: [],
            currentPage: 1,
            filters: {
                empresa: sessionGet("empresa")
            },
            items: []
        }

        this.props.titlebar(this.props.title, this.props.description)
    }

    componentDidMount() {
        this.initFilters()
        this.fetchCartoes()
        this.keyEnter()
    }
    keyEnter = () => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                this.fetchCartoes(0, true)
            }
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }
    }
    initFilters = () => {
        let filters = this.state.filters
        filters.titular = ""
        filters.cartao = ""
        filters.tipo = ""
        filters.desativado = "N"
        filters.offset = 0
        filters.max_per_page = 0
        this.setState({ filters })
    }

    fetchCartoes = (offset = 1, reset = false) => {
        this.setState({ loading: true, currentPage: offset })
        if (reset === true) {
            this.setState({ items: [] })
        }
        let filters = { ...this.state.filters, offset }
        let url = CFG.URL_API + "/relatorio/cartoes"
        let REF = this
        let options = axiosHeader()
        options.params = filters
        console.log("op", options)

        axios.get(url, options).then(function(response) {
            console.log(response)
            let { items, max_per_page, links } = response.data
            REF.setState({
                items,
                links,
                max_per_page,
                loading: false
            })
        })
    }

    renderTable = () => {
        return this.state.items.map(e => (
            <tr key={`ct_${e.id}`}>
                <td>{e.id}</td>
                <td>{e.ncartao}</td>
                <td>{e.tipo}</td>
                <td>{e.credencial}</td>
                <td>{e.titular}</td>
                <td>{e.credito}</td>
                <td>{e.desativado === "N" ? "SIM" : "NÃO"}</td>
            </tr>
        ))
    }

    renderPaginacao = () => {
        let { links, max_per_page, currentPage } = this.state
        console.log("currentPage", currentPage)
        let paginas = []

        if (links > 1) {
            for (let i = currentPage - 3, limiteDeLinks = i + 6; i <= limiteDeLinks; i++) {
                if (i < 1) {
                    i = 1
                    limiteDeLinks = 7
                }

                if (limiteDeLinks > links) {
                    limiteDeLinks = links
                    i = limiteDeLinks - 6
                }
                if (i < 1) {
                    i = 1
                    limiteDeLinks = links
                }

                //for (let i = currentPage; i <= currentPage + 5; i++) {
                paginas.push(i)
            }
            return paginas.map(i => {
                let active = i === this.state.currentPage ? "active" : ""
                return (
                    <li class={`page-item ${active}`}>
                        <a
                            class="page-link"
                            href="javascript:;"
                            onClick={() => this.fetchCartoes(i)}
                        >
                            {i}
                        </a>
                    </li>
                )
            })
        }
    }
    handlerFilter = e => {
        let key = e.target.id
        let value = e.target.value
        let filters = { ...this.state.filters, [key]: value }
        this.setState({ filters })
    }
    render() {
        return (
            <Container fluid={true}>
                <div className="row">
                    <div className="col-12 my-2">
                        <div className="form-filtro">
                            <div className="row">
                                <div class=" col-xs-6 col-sm-3 form-group">
                                    <input
                                        placeholder="Titular"
                                        type="text"
                                        onKeyUp={e => this.handlerFilter(e)}
                                        id="titular"
                                        class="form-control"
                                    />
                                </div>
                                <div class=" col-xs-6 col-sm-3 form-group">
                                    <input
                                        placeholder="Cartão"
                                        type="text"
                                        onKeyUp={e => this.handlerFilter(e)}
                                        id="cartao"
                                        class="form-control"
                                    />
                                </div>
                                <div class=" col-xs-6 col-sm-2 form-group">
                                    <select
                                        id="tipo"
                                        onChange={e => this.handlerFilter(e)}
                                        class="form-control"
                                    >
                                        <option value="">Tipo</option>
                                        <option value="A">Pré-Pago</option>
                                        <option value="P">Pós-Pago</option>
                                    </select>
                                </div>
                                <div class=" col-xs-6 col-sm-2 form-group">
                                    <select
                                        id="desativado"
                                        onChange={e => this.handlerFilter(e)}
                                        class="form-control"
                                    >
                                        <option value="">Todos</option>
                                        <option value="S">SIM</option>
                                        <option value="N" selected>
                                            Ativo
                                        </option>
                                    </select>
                                </div>
                                <div class=" col-xs-6 col-sm-2 form-group">
                                    <button
                                        title="Filtrar"
                                        className="btn btn-filter btn-success"
                                        onClick={() => this.fetchCartoes(0, true)}
                                    >
                                        <span class="icon-eye"></span>
                                    </button>
                                    <button
                                        title="Imprimir"
                                        class="btn btn-secondary btn-filter"
                                        onClick={() => window.print()}
                                    >
                                        <i class="fa fa-file-text-o"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Cartão</th>
                                    <th>Tipo</th>
                                    <th>Credencial</th>
                                    <th>Titular</th>
                                    <th>Credito</th>
                                    <th>Ativo</th>
                                </tr>
                            </thead>
                            <tbody>{this.renderTable()}</tbody>
                        </Table>
                        <Loading show={this.state.loading} />
                        <nav aria-label="navigation" class="paginacao">
                            <ul className="pagination">{this.renderPaginacao()}</ul>
                        </nav>
                    </div>
                </div>
            </Container>
        )
    }
}

export default Cartao
