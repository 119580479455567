import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import {getListing} from '../CustomDataTable'; // Import React
import {isValid, inputBlurHandler} from '../CustomForm';
import {sessionGet } from '../usuario/Auth';
import {Row, Col, Button} from 'react-bootstrap';
import FormaPagamentoSelect from '../forma-pagamento/FormaPagamentoSelect';
import ParcelaDisplay from './ParcelaDisplay';
import {getDmy, getFullTimestamp, objectToDate, parseBR} from '../util/date';
import {parseDecimal} from '../util/inputMask';
import './GerarParcelas.scss';

class GerarParcelas extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formaspagamento_id: this.props.formaspagamento_id,
      select_current_id: undefined,
      select_current_descricao: undefined,
      select_current_data: {},
      empresa: sessionGet('empresa'),
      ...this.props.form,
      data: [],
      input_list: [],
      item_exists: false,
      commit: false,
      loading: true
    };

    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.isValid = isValid.bind(this);
    //listar fornecedores vinculados ao produto
    this.getListing = getListing.bind(this);

  }

  inputChangeHandler(event){
    if(event.target.value != "" && typeof this.props.change === "function"
    && typeof this.props.formcontrol !== "undefined")
    this.props.formcontrol(this);
  }

  componentDidUpdate(prevProps, prevState){

      if(prevProps.formaspagamento_id !== this.props.formaspagamento_id){
        this.setState({ formaspagamento_id: this.props.formaspagamento_id });
      }

 
      if(prevState.select_current_id !== this.state.select_current_id){
        this.setState({ formaspagamento_id: this.state.select_current_id, input_list: [], data: [] });
      }

      if(!this.state.commit && typeof this.props.modalcontrol !== "undefined"){
        this.props.modalcontrol(this, 'parcelas');
        this.setState({ commit: true });
      }

      if(this.props.submit && this.props.submit !== prevProps.submit){
        this.post();
      }

  }

  componentDidMount(){
    let REF = this;
    this.setState({ commit: false });
    this.reloadForma();
  }

  reloadForma = () => {

    this.getListing('forma-parcela?formaspagamento_id='+this.state.formaspagamento_id).then(function(response){
      
    });
  }

  gerarParcelas = (e) => {
    for(let c = 0; c < this.state.data.length; c++){
      console.log(this.state.data[c]);
    }    
  }

  render(){ 
      
    let REF = this;
  
    let countOrMessage = () => {

      let msg;

      //excluiu dados que vieram do banco
      if(typeof REF.state.data !== "undefined" && REF.state.loading && REF.state.data.length > 0 &&  REF.state.input_list.length == 0) 
       msg = 'Selecione da lista';


      if(REF.state.input_list.length > 0 && typeof this.state.formaspagamento_id !== "undefined")
      msg = '('+REF.state.input_list.length+')';

      if(REF.state.loading)
      msg = 'Aguarde...';

      return msg;
    };

    return (<>

    { (() => REF.add())() }
  
    <Row >
      <Col >
      <fieldset className="mt-3" className="input-list">
        <legend>Parcelas <span className="text-muted">{ countOrMessage() }</span></legend>
            { REF.load_input_list() }
        </fieldset>
      </Col>
    </Row>
      <hr></hr>
      <div hidden={this.state.select_current_data.npar == 1}  className="d-flex justify-content-end">
          <Button variant="info" onClick={this.onAddParcela}><i className="fa fa-plus"></i> Adicionar parcela</Button>
      </div>
     </>
    );
  }

  onAddParcela = (e) => {
    let up_list = this.state.input_list;
    let c = up_list.length;
    up_list.push({ vencimento: '', val_parcela: 0, key: (c+1) });
    this.setState({ input_list: up_list })
  }

  display = (key, parcela) => {
    let REF = this;
    return (<div key={key}>
      <label>Parcela #{key}</label>
      <ParcelaDisplay key={key} form={parcela} formcontrol={this.formcontrol} />
   </div>);
  }

  load_db = () => {

    let input_list_copy = this.state.input_list;
    var c = input_list_copy.length;

    let vldocumento = this.props.vldocumento;
    let npar = this.state.data.npar;
    console.log(vldocumento, npar);

    if(typeof vldocumento === "undefined" || typeof npar === "undefined")
    return;

    let val_parcela = parseFloat(vldocumento) / parseFloat(npar);

    
    if(typeof this.state.data.parcelas !== "undefined"){

      let d = new Date();

      this.state.data.parcelas.map(function(f, key){
        let d_new = new Date(d.setDate(d.getDate()+f.dias));
        let vencimento = getDmy(d_new);
        c++;
        f.key = c;

        let val_parcela_calc = val_parcela;

        if(f.desco !== "")
        val_parcela_calc = val_parcela_calc - parseFloat(val_parcela_calc * (parseFloat(f.desco) / 100));
  
        if(f.juros !== "")
        val_parcela_calc = val_parcela_calc + parseFloat(val_parcela_calc * (parseFloat(f.juros) / 100));
   
        f.val_parcela = (val_parcela_calc).toFixed(2);

        f.vencimento = vencimento;
        input_list_copy.push(f);
      });

    }

    this.setState({ input_list: input_list_copy });
    let REF = this;
    setTimeout(() => REF.setState({ loading: false }) , 1000);
  }


  load_input_list = () => {
    let REF = this;

    if(this.state.input_list.length == 0)
    return (<></>);

    let c = this.state.input_list.length;

    return this.state.input_list.map(function(f){
        c++;
        return REF.display(c, f);
    });

  } 

  onAddChange = (pair, actionMeta) => {
    let REF = this;

      if(null == pair){
        return null;
      }
    
      this.setState({ data: [], input_list: [], select_current_id: pair.value, select_current_descricao: pair.label });

          setTimeout((e) => {
            REF.props.change(pair, actionMeta);
            REF.get()
          }, 500);
        
  }

  get = () => {
    let REF = this; 

    axios.get(CFG.URL_API+'/forma-pagamento/'+this.state.select_current_id, axiosHeader())
    .then(function(response){

        REF.setState({ data : response.data });

        setTimeout(() => {
          REF.load_db();
        }, 2000);
       

  })
    .catch(function(error){
  requestException(error);
});
}

  add = () => {
    let REF = this;
    return (
    <Row className="my-2">
        <Col md="12">
            <FormaPagamentoSelect change={ REF.onAddChange } />
        </Col>
    </Row>

    )
  }

   post = () => {

    let REF = this;

    let attach_data = {
      empresa_id: REF.state.empresa,
      centrocustos_id: REF.state.centrocustos_id,
      fornecedor_id: REF.props.fornecedor_id,
      cliente_id: REF.state.cliente_id,
      documento: REF.state.documento,
      descricao: REF.state.descricao,
      dt_entrada: REF.state.dt_entrada,
      tipo: REF.state.tipo,
      documento: REF.props.nfe_id,
      centrocustos_id: REF.props.centrocustos_id,
      nparcela : REF.state.input_list.length
    };

    return this.state.input_list.map(function(f){

      if(f.val_parcela == 0)
      return;

        f.valor = f.val_parcela;
        f.desco = (parseFloat(f.desco) / 100).toFixed(2) * parseFloat(f.val_parcela);
        f.acrescimo = (parseFloat(f.juros) / 100).toFixed(2) * parseFloat(f.val_parcela);
        f.dt_vencimento = getFullTimestamp(objectToDate(parseBR(f.vencimento)));
        f.descricao = 'Parcela '+f.parcela+' referente a NFe '+REF.props.nfe_id+' de '+REF.props.tipo;

        Object.assign(attach_data, f);

        axios.post(CFG.URL_API+'/titulo', f, axiosHeader())
        .then(function(response){
          REF.props.post_confirm('GerarParcelas');
        })
        .catch(function(error){
          requestException(error);
        });
 
    });
  
   }

  delete = (event, key) => {
    let data_copy = this.state.input_list;
    data_copy.splice(key,1);
    this.setState({ input_list : data_copy });
  }

}

export default GerarParcelas;
