import React from 'react';
import axios from 'axios';
import {axiosHeader, CFG} from '../CFG';
import { inputChangeHandler, isValid, dateChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import {sessionGet} from '../usuario/Auth';
import EstadoSelect from '../util/EstadoSelect';
import {cpf_lookup} from '../util/inputLookup';
import {validate_cpf} from '../util/inputValidate';
import './Responsaveis.scss';

class ResponsavelForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      rg: '',
      cpf: '',
      email: '',
      sexo: '',
      telefone: '',
      celular: '',
      empresa: sessionGet('empresa'),
      cep: '',
      senha: '',
      senha_decode: '',
      nome: '',
      nascimento: '',
      cal_nascimento: new Date(),
      validator: {}
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.dateChangeHandler = dateChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);


  }
  getId(){
    return this.state.id;
  }

  componentDidMount(){
      let form_fields = this.props.form;
      if(form_fields != undefined && form_fields.nascimento == '0000-00-00'){
        form_fields.nascimento = null;
      }

      if(form_fields != undefined && form_fields.senha_decode == undefined){
        form_fields.senha_decode = atob(form_fields.senha);
      }

      this.setState({ ...form_fields });
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.submit !== this.props.submit){
      if(this.state.id)
      this.put();
     else
      this.post(); 
    }

    if(prevProps.show !== this.props.show){
    
      this.setState({nome: this.props.form.nome});

      if(this.state.senha_decode == undefined){
        this.state.senha_decode = atob(this.state.senha);
      }

      this.setState({ show: this.props.show });

      this.props.modalcontrol(this);
    }
  }

  cpfChangeHandler = (e) => {
    let REF = this;

    e.persist();
    REF.inputChangeHandler(e);

    let db_check = cpf_lookup(e.target);
    db_check.then((response) => {    
      REF.setState({
        validator: {
          cpf: response.data
        }
      });

      if(response.data && validate_cpf(e.target)){
        e.target.classList.add('is-valid');
        e.target.classList.remove('is-invalid');
      } else {
        e.target.classList.add('is-invalid');
        e.target.classList.remove('is-valid');
      }

    });

   
  }

  render() {
    let REF = this;
    return (
    <form>
      <input type="hidden" name="id" value={this.state.id}/>
      <input type="hidden" name="empresa" value={this.state.empresa}/>
      <div className="row">      
      <div className="form-group col-md-3">         
          <label htmlFor="f-name">
            Código
          </label>
          <input className="form-control" name="codigo" id="f-codigo" type="text" value={this.state.codigo} readOnly />
        </div>   
        <div className="form-group col-md-6">         
          <label htmlFor="f-name required">
            Nome
          </label>
          <input className="form-control" name="nome" id="f-name" type="text" value={this.state.nome} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-3">         
          <label>
            Sexo
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="sexo" className="custom-control-input" id="f-sexo-m" type="radio" value="M" onBlur={this.inputBlurHandler} onChange={this.inputChangeHandler} checked={this.state.sexo == "M" || this.state.sexo == null} />
                  <label className="custom-control-label" htmlFor="f-sexo-m">
 Masc.    </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="sexo" className="custom-control-input" id="f-sexo-f" type="radio" value="F" onBlur={this.inputBlurHandler} onChange={this.inputChangeHandler} checked={this.state.sexo == "F"} />
                  <label className="custom-control-label" htmlFor="f-sexo-f">
 Fem.    </label>
                  </div>
                </li> 
            </ul>
        </div>   
        </div>
        <div className="row">
        <div className="form-group col-md-3">         
          <label htmlFor="f-cpf">
            CPF
          </label>
          <input className="form-control" name="cpf" id="f-cpf" type="tel" data-id={this.state.id} value={this.state.cpf} onChange={this.cpfChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-3">         
          <label htmlFor="f-rg">
            RG
          </label>
          <input className="form-control" name="rg" id="f-rg" type="text" value={this.state.rg} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-3">         
          <label htmlFor="f-nascimento">
            Nascimento
          </label>
          <DatePicker calendarClassName="calendar-top-span" locale="pt-BR"  autoComplete="off" className="form-control" name="nascimento" id="f-nascimento" type="text" value={this.state.cal_nascimento} onChange={(date) => REF.dateChangeHandler(date, 'nascimento')} />
        </div>
        <div className="form-group col-md-3">         
          <label htmlFor="f-senha">
            Senha (4 números)
          </label>
          <input className="form-control" name="senha_decode" id="f-senha" type="text" value={this.state.senha_decode} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
      </div>
      <div className="row">
      <div className="form-group col-md-6">         
          <label htmlFor="f-telefone required">
            Telefone
          </label>
          <input className="form-control" name="telefone" id="f-telefone" type="text" value={this.state.telefone} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-6">         
          <label htmlFor="f-celular">
            Celular
          </label>
          <input className="form-control" name="celular" id="f-celular" type="text" value={this.state.celular} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <label htmlFor="f-email">
            Email
          </label>
          <input className="form-control" name="email" id="f-email" type="email" value={this.state.email} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
      </div>
      <div className="row">   
        <div className="form-group col-md-3">     
          <label htmlFor="f-name">
            CEP
          </label>
          <input className="form-control" name="cep" id="f-name" type="text" value={this.state.cep} onChange={this.cepChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-7">         
          <label htmlFor="f-endereco">
            Endereço
          </label>
          <input className="form-control" name="endereco" id="f-endereco" type="text" value={this.state.endereco} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-2">         
          <label htmlFor="f-numero">
            Número
          </label>
          <input className="form-control" name="numero" id="f-numero" type="tel" value={this.state.numero} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-5">         
          <label htmlFor="f-name">
            Bairro
          </label>
          <input className="form-control" name="bairro" id="f-bairro" type="text" value={this.state.bairro} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-5">         
          <label htmlFor="f-cidade">
            Cidade
          </label>
          <input className="form-control" name="cidade" id="f-cidade" type="text" value={this.state.cidade} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-2">         
          <label htmlFor="f-uf">
            Estado
          </label>
          <EstadoSelect uf={this.state.uf} />
        </div>
      </div>
    </form>
    );
  }

  get(){
    axios.get(CFG.URL_API+'/responsavel/'+this.state.id, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }


   post = () => {
    let REF = this;
    let data = Object.assign({}, this.state);
    delete data.id;
    data.senha = btoa(data.senha_decode);

    axios.post(CFG.URL_API+'/responsavel', data, axiosHeader())
    .then(function(response){
      REF.setState({ id: response.data.id });
      REF.props.close();
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.close());
   }

   put = () => {
    let REF = this;
    let put_data = this.state;
    put_data.senha = btoa(put_data.senha_decode);

    axios.put(CFG.URL_API+'/responsavel/'+this.state.id, put_data, axiosHeader())
    .then(function(response){
     REF.props.close();
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.close());
   }

}
export default ResponsavelForm;