import React from 'react';
import {ENV, CFG} from './CFG';

import { BrowserRouter as Router } from 'react-router-dom'

import './normalize.css';
import './bootstrap.spacelab.min.css';
import './font-awesome.css';
import './App.scss';
import FooterCopyright from './FooterCopyright';

import { AuthCheck, logout, updateSessao, sessionGet} from './usuario/Auth';

import LayoutLeftSidebar from './layout/LayoutLeftSidebar';
import layoutMap from './layout/layoutMap';

import {getUserPermissions} from './usuario/Auth';
import SimpleCrypto from "simple-crypto-js";


window.userCan = {};


class App extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      session: false
    };

    this.AuthCheck = AuthCheck.bind(this);

    this.setupSession()

  }

  setupSession () {
    if(sessionGet('permissions') !== null){

      //permissões do usuário em todo o sistema
      let simpleCrypto = new SimpleCrypto(CFG.SESSION_PREFIX);

      //window.userCan = JSON.parse(simpleCrypto.decrypt(sessionGet('permissions')));
      window.userCan = simpleCrypto.decrypt(sessionGet('permissions'))
    }

    window.checkUserCan = function(section) {

      if(typeof window.userCan === "undefined")
      return;
  
      if(window.userCan[section] !== 1){
  
        document.body.innerHTML = "";
    
      alert('Você não tem permissões suficientes. \n Você será redirecionado à página principal.');
      window.location.href=CFG.URL_APP;
    }
  
    }
  
    window.userCanClass = function(section){
  
      if(typeof window.userCan === "undefined")
      return "d-none";
  
      if(window.userCan[section] !== 1)
      return "d-none";
  
      return "d-block";
    }
  }
 
  componentDidUpdate(prevProps, prevState){
    if(prevState.path !== this.state.path){
      alert('change');
    }

   
  }

  

  componentDidMount(){  
 
    if(!CFG.IGNORE_AUTH.includes(window.location.pathname.replace(/\//g, ''))){

      if(ENV === 'production')
          this.AuthCheck();   
        

       } 

  }



  render() {
   
    const LayoutSetup = layoutMap() == undefined ? LayoutLeftSidebar : layoutMap();
   
      return (
        <Router>
          <LayoutSetup/>
        <div className="fixed-bottom-placeholder"></div>
        <div className="fixed-bottom">
          <FooterCopyright></FooterCopyright>
        </div>
        </Router>
        );
   
  }
}
  
  export default App;