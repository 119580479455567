import React from 'react'
import axios from "axios"
import { Button,Tabs,Tab } from 'react-bootstrap';
import { sessionGet } from '../usuario/Auth';
import { CFG, axiosHeader } from "../CFG"

import TabDadosGerais from './TabDadosGerais'
import TabNfce from './TabNfce'
import TabDelivery from './TabDelivery'
import Alert from '../util/alert'

class DadosCadastrais extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: {},
        loading: true,
        tab: 'dadosGerais',
        submite:false,
        empresa_id: sessionGet('empresa'),
        form:{
            dadosGerais:{},
            dadosNfce:{},
            dadosDelivery:{},
        },
        alert:{
            variant:"success",
            show:false
        }
      };
      this.props.titlebar(this.props.title, this.props.description);
      this.handleForm = this.handleForm.bind(this)
      this.getDadosEmpresa = this.getDadosEmpresa.bind(this)
      this.submitForm = this.submitForm.bind(this)
    }

    handleForm = (dados)=>{
        this.setState({form:dados}) 
    }
    componentDidMount(){
        this.getDadosEmpresa();
    }

    getDadosEmpresa = ()=>{
        const REF = this;
        const url = CFG.URL_API + "/empresa/dados-cadastrar"
        axios.get(url, axiosHeader())
        .then(function(response) {
            const form = REF.state.form;
            form.dadosGerais = response.data.dadosGerais;
            form.dadosNfce = response.data.dadosNfce;
            form.dadosDelivery = response.data.dadosDelivery;
            REF.setState({ form, loading: false})
        })
        .catch(function(error) {
            console.log(error)
        })
    }

    submitForm = () => {
        this.setState({submite:true});
        const REF = this;
        const url = CFG.URL_API + "/empresa/dados-cadastrar"
        axios.put(url,REF.state.form, axiosHeader())
        .then(function(response) {
            REF.setState({submite:false,alert:{variant:'success',show:true,message:"Dados atualizado com sucesso!"} });
        })
        .catch(function(error) {
            alert("Erro ao enviar");
            console.log(error)
            
        })
    }
    closeAlert = ()=>{
        this.setState({
            alert:{
                variant:"success",
                show:false,
                message:""
            }
        })
    }

    render(){
        return this.state.loading === false ?  
        <>
        {
            this.state.alert.show && <Alert close={this.closeAlert} data={this.state.alert}  />
        }
        {
            this.state.submite && <div className="carregando-full">
                <div class="spiner"></div>
            </div>
        }
        <div style={{paddingTop:20, marginBottom:40}}>
        <Tabs defaultActiveKey={this.state.tab} id="uncontrolled-tab-example">
            <Tab eventKey="dadosGerais" title="Dados Gerais">
                <TabDadosGerais form={this.state.form} handleForm={this.handleForm} />
            </Tab>
            <Tab eventKey="dadosNfce" title="Nfc-e">
                <TabNfce form={this.state.form} handleForm={this.handleForm} />
            </Tab>
            <Tab eventKey="dadosDelivery" title="Delivery">
                <TabDelivery form={this.state.form} handleForm={this.handleForm} />
            </Tab>
        </Tabs>
        <div style={{height:30}}></div>
        <Button onClick={this.submitForm}>Salvar</Button>
        </div>
        </>
        :
        <div>Carregando...</div>
    }
}

export default DadosCadastrais