import React from 'react'; // Import React
import {Container, Button, ButtonToolbar, Row, Col} from 'react-bootstrap';
import { sessionGet } from '../../usuario/Auth';
import FiltroModal from './FiltroModal';
import ExtratoDRE from './ExtratoDRE';
import ReactToPrint from 'react-to-print';
import {getDmy,parseUS, firstDateOfMonth, lastDateOfMonth, objectToDate} from '../../util/date';

class Relatorio extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            movimentacao_id: '',
            empresa: sessionGet('empresa'),
            searchTerm: null,
            activeIndex: undefined,
            show_modal_filters: false,
            origem: [],
            filters: {
                inicio: firstDateOfMonth(),
                fim: lastDateOfMonth(),
                inicio_format: getDmy(objectToDate(parseUS( firstDateOfMonth() ))) ,
                fim_format: getDmy(objectToDate(parseUS( lastDateOfMonth() )))
            }
        };

        this.props.titlebar(this.props.title, this.props.description);
    }
 
    componentDidUpdate(prevProps, prevState){
            if(prevState.filters.inicio != this.state.filters.inicio
                || prevState.filters.fim != this.state.filters.fim){
                    let up_filters = this.state.filters;
                    up_filters.inicio_format = getDmy(objectToDate(parseUS( this.state.filters.inicio )));
                    up_filters.fim_format = getDmy(objectToDate(parseUS( this.state.filters.fim )));
                    this.setState({
                        filters: up_filters
                    })
                }
    }

    setFiltroModalShow = () => {
        this.setState({ show_modal_filters: true });
    }

    setFilters = (form_state) => {
        this.setState({ filters: form_state, loading: true });
    }

    hideFiltroModal = () => {
        this.setState({ show_modal_filters: false });
    }

    updateLoading = (status) => {
        this.setState({ loading: status  });
    }
       
    setOrigem = (e, origem) => {
        this.setState({ origem });
    }

    render(){

        let REF = this;

        const componentRef = React.createRef();

        return (<>
           <Container fluid={true}>
            <div className="row">
                <div className="col-md-9">
                <h3 className="my-3 text-uppercase">Período: {this.state.filters.inicio_format+" a "+this.state.filters.fim_format}</h3>

                </div>
            <div className="col-md-3 my-2" > 
      
                <ButtonToolbar className="float-right my-1 ml-auto">
             
                <Button variant="secondary" className="no-print" onClick={() => window.print()}>
                            <span className="fa fa-print"></span>
                        </Button>   
                        <Button  className="no-print" variant="success" onClick={() => this.setFiltroModalShow(true)}>
                            <span className="fa fa-filter"></span>
                        </Button>              
                        <FiltroModal
                            loading={this.state.loading}
                            empresa={this.state.empresa}
                            show={this.state.show_modal_filters} 
                            setfilters={this.setFilters} 
                            onHide={this.hideFiltroModal} 
                        />
                    </ButtonToolbar>            
                </div>
            </div>
        <ExtratoDRE setfilters={this.setFilters} setloading={this.updateLoading} title={this.props.title} empresa={this.state.empresa} filters={this.state.filters} />
        </Container>
        </>);
    }
    }
    
    export default Relatorio;