import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import url_btn_close from '../img/icons/close.png';
import FormaPagamentoTab from './FormaPagamentoTab';

class FormaPagamentoModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
        FormaPagamentoForm: {},
        ParcelasForm: {},
        loading: false,
        dt_removido_format: undefined,
        allow_delete: false,
        submit: false,
        submit_parcelas: false,
        npar: 1,
        id: undefined
      };

    }  
  
  formcontrol = (form_comp) => {
    this.setState({ [form_comp.constructor.name]: form_comp,  npar: form_comp.state.npar });
  }

  update_npar = (npar) => {
    this.setState({ npar: npar });
  }

  update_tipo = (tipo) => {
    this.setState({ tipo: tipo });
  }

  onKeyPressed = (event) => {
  
    if(event.keyCode == 13)
    this.formSubmit();
  }

  componentDidUpdate(prevProps, prevState){



    this.updateButtons();
  }

  updateButtons = () => {

      if(Object.getOwnPropertyNames(this.state.FormaPagamentoForm).length > 0){
        let me = this;    
        setTimeout(() => {

        if(me.state.FormaPagamentoForm.state.dt_removido_format == undefined){
          me.setState({ dt_removido_format: undefined });
          if(typeof me.state.FormaPagamentoForm.state.id !== "undefined"){
            me.setState({ allow_delete: true });
          }
        } else { 
            me.setState({ allow_delete: false, dt_removido_format: me.state.FormaPagamentoForm.state.dt_removido_format });
        }

      }, 2000);    

      }
  }

  componentDidMount(){
    this.updateButtons();
    
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }
  
  set_id = (id) => {
    this.setState({ id, submit_parcelas: true });
  }

   closeTrigger = () => {
    let REF = this;

    setTimeout(() => {
      REF.setState({ loading: false, submit: false });
      REF.props.onHide();
    }, 1000);

  }
 
  render () {
    let REF = this;

    const updateSubmitStatus = (formName) => {
      let submit_status = REF.state.submit_status;
      submit_status[formName] = true;
      this.setState({ submit_status });
    }

    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
          Forma de pagamento #{Object.getOwnPropertyNames(this.state.FormaPagamentoForm).length > 0 ? this.state.FormaPagamentoForm.state.id : '' }
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body>
          <FormaPagamentoTab
            tipo={this.state.tipo}
            update_tipo={this.update_tipo}
            set_id={this.set_id}
            id={this.state.id}
            show={this.props.show}
            form={this.props.form}
            update_npar={this.update_npar}
            npar={this.state.npar}
            close={this.closeTrigger}
            formcontrol={this.formcontrol}
            submit={this.state.submit}
            submit_confirm={updateSubmitStatus}
         />
        </Modal.Body>
        <Modal.Footer>
          {
              (() => {
                if(this.state.allow_delete)
                return ( <Button className="btn-danger btn-left" onClick={this.formDelete}>Excluir</Button>)
              })()
          }

          {
            (() => {
              if(this.state.dt_removido_format != undefined)
              return (<div className="text-danger p-2 my-2 mx-auto">Removido em {this.state.dt_removido_format}</div>)
              else 
              return ( <Button onClick={() => REF.formSubmit()}>Confirmar</Button>)              
            })()
          }

        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading} />
      </>
    );
  }

  formSubmit = () => {
    let REF = this;
    REF.setState({ submit: true, loading: true });

  }
    
  formDelete = () => {
    let REF = this;
    REF.setState({ loading: true });
    let c = window.confirm("Deseja mesmo remover a forma de pagamento "+this.props.form.descricao+" ?");
    if (c == true) {
      if(this.state.FormaPagamentoForm.state.id)
      this.state.FormaPagamentoForm.delete();

      setTimeout(() => {
        REF.setState({ loading: false });
        REF.props.onHide(); 
      }, 2000);
    }

  }

}

export default FormaPagamentoModal;