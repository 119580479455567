import React from 'react';
import FornecedorDataTable from './FornecedorDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import FornecedorModal from './FornecedorModal';

class Fornecedores extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      show: false,
      activeIndex: 0,
      refresh: false
    };

    this.props.titlebar(this.props.title, this.props.description);

    this.hideModal = this.hideModal.bind(this);
    this.setModalShow = this.setModalShow.bind(this);
  }

  setModalShow (status, data){

    if(data != null){
      this.setState({  data: data });
    }

  }

  showModal = () => {
    this.setState({ show: true, data: null, refresh: false });
  }

  hideModal(){
    this.setState({ show: false, refresh: true });
  }

  render(){
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => this.showModal()}>
                  <span className="fa fa-plus-square"></span>  Cadastrar fornecedor
                </Button>              
              <FornecedorModal
                form={{}}
                show={this.state.show}             
                onHide={this.hideModal}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <FornecedorDataTable refresh={this.state.refresh} setModalShow={this.setModalShow}/>
      </div>);
    }
}
  
export default Fornecedores;