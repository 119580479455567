import React from "react"
import cep from "cep-promise"
import axios from "axios"
import { inputValidate, validate_required } from "./util/inputValidate"
import { inputMask, mask_decimal } from "./util/inputMask"
import "./CustomForm.scss"

export default function e() {
    return true
}

function hasEmptyRequired() {
    let required_els = document.body.querySelectorAll("[required]")

    let invalid = false
    for (let c = 0; c < required_els.length; c++) {
        let e = required_els[c]
        let name = e.getAttribute("name")
        if (e.value === "" || e.value === "0,00" || e.value === "0") invalid = true
    }

    return invalid
}

export function checkRequired(target_info) {
    let REF = this

    if (!hasEmptyRequired()) return true

    let message = "Atenção para campos obrigatórios "

    if (typeof target_info !== "undefined") message += target_info

    let required_els = document.body.querySelectorAll("[required]")
    let valid = true
    for (let c = 0; c < required_els.length; c++) {
        let e = required_els[c]

        console.log(e.value)

        let name = e.getAttribute("name")
        if (e.value === "" || parseFloat(e.value) === 0) {
            e.classList.remove("is-valid")
            e.classList.add("is-invalid")
            valid = false
        } else {
            e.classList.add("is-valid")
            e.classList.remove("is-invalid")
        }

        REF.setState({
            validator: {
                [name]: valid
            }
        })
    }

    return valid

    /* if(e.target.value === ""){
    e.target.classList.add('is-valid');
    e.target.classList.remove('is-invalid');
    REF.setState({ validator: {
      ean : true
    }});
    return;
  } */
}

export function eq(o1, o2) {
    return Object.entries(o1).toString() === Object.entries(o2).toString()
}

export function money_format(v) {
    return "R$ " + mask_decimal(parseFloat(v).toFixed(2))
}

export function cepChangeHandler(event) {
    var CF = this
    let cep_format = event.target.value.replace(/\D/g, "")

    if (cep_format.length == 8) {
        axios.get("https://viacep.com.br/ws/" + cep_format + "/json/").then(function(response) {
            CF.setState({
                cep_updated: true,
                cidade: response.data.localidade,
                bairro: response.data.bairro,
                estado: response.data.uf,
                endereco: response.data.logradouro,
                logradouro: response.data.logradouro
            })
        })
    }

    if (typeof this.inputChangeHandler === "function") this.inputChangeHandler(event)
}

export function inputChangeHandler(event) {
    this.setState({ [event.target.name]: inputMask(event.target) })

    let validate = inputValidate(event)
    this.state.validator[event.target.name] = validate
    if (typeof this.props.modalcontrol === "function") this.props.modalcontrol(this)
}
export function nascimentoChangeHandler(date) {
    this.setState({ cal_nascimento: date })
}

export function vencimentoChangeHandler(date) {
    this.setState({ dt_vencimento: date })
}

export function inputBlurHandler(event) {
    /* let validate = inputValidate(event);
  this.state.validator[event.target.name] = validate;
  if(typeof this.props.modalcontrol === "function")
  this.props.modalcontrol(this); */
}

export function checkboxClickHandler(event) {
    this.setState({ [event.target.name]: event.target.checked })

    this.state.validator[event.target.name] = true
    if (typeof this.props.modalcontrol === "function") this.props.modalcontrol(this)
}

export function radioSwitchHandler(event) {
    this.setState({
        [event.target.name]: event.target.value,
        validator: { [event.target.name]: true }
    })

    if (typeof this.props.modalcontrol === "function") this.props.modalcontrol(this)
}

export function dateChangeHandler(date, target_name) {
    if (date == null) {
        this.setState({ ["cal_" + target_name]: null, [target_name]: "" })
        return
    }

    let REF = this
    let dt = date
    let dt_format =
        dt.getFullYear() +
        "-" +
        (dt.getMonth() + 1).toString().padStart(2, 0) +
        "-" +
        dt
            .getDate()
            .toString()
            .padStart(2, "0")
    this.setState({
        ["cal_" + target_name]: dt,
        [target_name]: dt_format,
        validator: { [target_name]: true }
    })
}

export function selectChangeHandler(pair, actionMeta) {
    console.log(actionMeta)

    let REF = this
    this.setState({
        [actionMeta.name + "_pair"]: pair,
        [actionMeta.name]: pair != null ? pair.value : null,
        validator: {
            ...REF.state.validator,
            [actionMeta.name]: true
        }
    })
    if (typeof this.props.modalcontrol === "function") this.props.modalcontrol(this)
}

export function isValid() {
    let fields = Object.entries(this.state.validator)
    let invalid = fields.find(function(field) {
        return field[1] === false
    })
    return invalid === undefined
}
