import React from 'react';
import { inputChangeHandler } from '../CustomForm';
import {Button} from 'react-bootstrap';
class ParcelaInput extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            id: this.props.form.id,
            key: this.props.form.key,
            parcela: this.props.form.parcela,
            dias: this.props.form.dias,
            juros: this.props.form.juros,
            desco: this.props.form.desco,
            //id da tabela pivô
            validator: {}
        };

        this.inputChangeHandler = inputChangeHandler.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState != this.state){
            if(typeof this.props.change === "function")
            this.props.change(this.state);
        }
    }

    render(){
        let REF = this;
        return (
        <tr key={this.props.index}>
            <td>
                <input className="form-control text-center" name="parcela" readOnly value={this.state.parcela} onChange={this.inputChangeHandler}  />
            </td>
            <td>
                <input className="form-control" name="dias" value={this.state.dias} onChange={this.inputChangeHandler} />
            </td>
            <td>
                <input className="form-control" name="juros" value={this.state.juros} onChange={this.inputChangeHandler} />
            </td>
            <td>
                <input className="form-control" name="desco" value={this.state.desco} onChange={this.inputChangeHandler} />
            </td>
            <td>
                <Button onClick={ () => REF.delete() } variant="danger" className="btn-sm mx-auto d-block">
                    <i className="icon-trash"></i>
                </Button>
            </td>
        </tr>
        );
    }

    delete = () => {   
        let c = window.confirm("Deseja mesmo desvincular a parcela "+this.state.parcela+" ?");
        if (c == true) {
            this.props.delete(this.state.key);
        }
    }    

}
export default ParcelaInput;
