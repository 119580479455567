import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import { inputChangeHandler, isValid, nascimentoChangeHandler, inputBlurHandler, cepChangeHandler } from '../CustomForm';
import DatePicker from 'react-date-picker';
import {sessionGet} from '../usuario/Auth';

class CategoriaForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa: sessionGet('empresa'),
      categoria: '',
      vias: undefined,
      terminal: undefined,
      validator: {},
      show: false
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.nascimentoChangeHandler = nascimentoChangeHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);
    this.isValid = isValid.bind(this);
  }
  getId(){
    return this.state.id;
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.show != this.props.show){
     this.setState({ ...this.props.form });
     this.setState({ show: this.props.show });

     this.props.modalcontrol(this);
   }

   if(this.props.submit && prevProps.submit !== this.props.submit){
    if(typeof this.state.id !== "undefined")
    this.put();
    else
    this.post();
   }

   if(this.props.delete && prevProps.delete !== this.props.delete){
      this.delete();
   }
  }
  render() {
    return (
    <form>
      <input type="hidden" name="id" defaultValue={this.state.id}/>
      <input type="hidden" name="empresa" defaultValue={this.state.empresa}/>
      <div className="row"> 
      {
        () => {
          if(this.state.id != 0){
            return (  
              <div className="form-group col-md-3">         
              <label htmlFor="f-codigo required">
                Código
              </label>
              <input readOnly className="form-control " name="id" id="f-codigo" type="text" value={this.state.id}  />
            </div>
            )
          }
         
        }
      }
    

        <div className="form-group col">         
          <label htmlFor="f-categoria required">
            Categoria
          </label>
          <input className="form-control" name="categoria" id="f-categoria" type="text" defaultValue={this.state.categoria} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler} />
        </div>
        <div className="form-group col-md-3">         
          <label>
            Imprimir duas vias
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="vias" className="custom-control-input" id="f-vias-sim" type="radio" value="1" checked={this.state.vias == 1} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-vias-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="vias" className="custom-control-input" id="f-vias-nao" type="radio" value="0" checked={this.state.vias == 0} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-vias-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div>   

        <div className="form-group col-md-3">         
          <label>
            Liberar no terminal
            </label>
            <br/>
            <ul className="list-inline">
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="terminal" className="custom-control-input" id="f-terminal-sim" type="radio" value="S" checked={this.state.terminal == "S"} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-terminal-sim">
Sim   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="terminal" className="custom-control-input" id="f-terminal-nao" type="radio" value="N" checked={this.state.terminal == "N"} onChange={this.inputChangeHandler} onBlur={this.inputBlurHandler}/>
                  <label className="custom-control-label" htmlFor="f-terminal-nao">
Não    </label>
                  </div>
                </li> 
            </ul>
        </div>  

     
      </div>
    </form>
    );
  }
  get(){
    axios.get(CFG.URL_API+'/produto-categoria/'+this.state.id, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   post = () => {
    let REF = this;
    let post_data = this.state;
    delete post_data.id;
    axios.post(CFG.URL_API+'/produto-categoria', post_data, axiosHeader())
    .then(function(response){
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.close());
   }
   put = () => {
    let REF = this;
    axios.put(CFG.URL_API+'/produto-categoria/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.close());
   }
   delete(){
    let REF = this;
    axios.delete(CFG.URL_API+'/produto-categoria/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    }).finally(() => REF.props.close());
   }
}
export default CategoriaForm;