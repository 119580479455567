import React from 'react';

import { Link } from "react-router-dom";
import { Card, Accordion, ListGroup, ListGroupItem } from 'react-bootstrap';

class TitulosMenu extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            show: this.props.show,
            extraclasses: ''
        };
    }

    toggle = () => {
        let REF = this;

        this.props.onchange();

        if(this.state.show){
            this.setState({ show: false, extraClasses: '' });
            setTimeout(() => REF.setState({ extraClasses: '' }), 500);
        } else {
            this.setState({ show: true, extraClasses: 'active' });
        } 
    }

    render(){

        return (
            <Accordion >
            <Card>
                <Accordion.Header onClick={this.toggle} className={"lv-2 " + this.state.extraClasses} as={Card.Header} eventKey="0">
                <div className="w-icon"><i className="fa fa-barcode"></i></div>Títulos
                </Accordion.Header>
                <Accordion.Body eventKey="0">
                <Card.Body>

                <ListGroup>
                <ListGroupItem><Link className="lv-3" onClick={this.props.ontoggle} to="/conta-pagar/"><div className="w-icon"><i className="fa fa-barcode"></i></div>Contas a pagar</Link></ListGroupItem>
                <ListGroupItem><Link className="lv-3" onClick={this.props.ontoggle} to="/conta-receber/"><div className="w-icon"><i className="fa fa-barcode"></i></div>Contas a receber</Link></ListGroupItem>
              {/*}  <ListGroupItem><Link className="lv-3" onClick={this.props.ontoggle} to="/baixa-titulo/"><div className="w-icon"><i className="fa fa-barcode"></i></div>Baixa de títulos</Link></ListGroupItem>*/}


                 </ListGroup>       

                </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion>

        );
    }

}   

export default TitulosMenu;




