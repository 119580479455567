import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {getMonthName} from '../util/date';
import {sessionGet} from '../usuario/Auth';
import GrupoFornecedorSelect from '../grupo-fornecedor/GrupoFornecedorSelect';
import { cepChangeHandler, selectChangeHandler, inputChangeHandler, isValid, inputBlurHandler } from '../CustomForm';
import EstadoSelect from '../util/EstadoSelect';

class FornecedorForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      razao: '',     
      indicadoresdre_id: 1,     
      classificacao: '',    
      tipo: 'C',     
      validator: {},
      show: false,
      data_removido_format: undefined,
      cep_updated:false,
      readonly_mode: false
    };

    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);
    this.cepChangeHandler = cepChangeHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){

    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });

      let dt = new Date(this.props.form.dt_inclusao);
      let dmy = dt.getDate()+' de '+getMonthName(dt.getMonth())+' de '+dt.getFullYear(); 
      this.setState({ dt_inclusao : dmy });

      this.props.modalcontrol(this);
    }

    this.updateDateIfRemoved();
  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+' de '+getMonthName(dt.getMonth())+' de '+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);

    }

  }

  componentDidUpdate(prevProps, prevState){

    if( this.state.data_removido != null){
      if(this.state.data_removido_format == undefined){
        this.updateDateIfRemoved();
      }
      if(!this.state.readonly_mode)
      this.setState({ readonly_mode : true });
    }

    if(this.state.cep_updated){
      this.setState({ cep_updated: false, uf: this.state.estado });
    }
   
  }

   post (){
     let REF = this;
     let data = this.state;
     delete data.dt_inclusao;
    axios.post(CFG.URL_API+'/fornecedor', data, axiosHeader())
    .then(function(response){
      //Perform action based on response
      REF.props.onHide();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   put () {
    let data = this.state;
    delete data.dt_inclusao;
    axios.put(CFG.URL_API+'/fornecedor/'+this.state.id, data, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){

   return(
     <>
  <input type="hidden" name="empresa_id" defaultValue={this.state.empresa_id} />
<div className="row">      

    <div className="form-group col-md-2">         
      <label htmlFor="f-id">
        ID
      </label>
      <input readOnly className="form-control text-center" name="id" id="f-id" type="text" value={this.state.id} onChange={this.inputChangeHandler}/>
    </div>

    <div className="form-group col-md-6">         
      <label htmlFor="f-cnpj">
        CNPJ
      </label>
      <input readOnly={this.state.readonly_mode} placeholder={!this.state.readonly_mode ? "" : ""} className="form-control" name="cpf_cnpj" id="f-cpf_cnpj" type="text" value={this.state.cpf_cnpj} onChange={this.inputChangeHandler}/>
    </div>

    <div className="form-group col-md-4">         
      <label htmlFor="f-grupo">
        Grupo
      </label>
      <GrupoFornecedorSelect readOnly={this.state.readonly_mode} id={this.state.grupofornecedores_id} change={this.selectChangeHandler} empresa_id={this.state.empresa} />
    </div>  

    </div>
    <div className="row"> 

    <div className="form-group col-md-6">         
      <label htmlFor="f-razao">
        Razão
      </label>
      <input readOnly={this.state.readonly_mode} className="form-control" name="razao" id="f-razao" type="text" value={this.state.razao} onChange={this.inputChangeHandler}/>
    </div>

    <div className="form-group col-md-6">         
      <label htmlFor="f-fantasia">
        Fantasia
      </label>
      <input readOnly={this.state.readonly_mode} className="form-control" name="fantasia" id="f-fantasia" type="text" value={this.state.fantasia} onChange={this.inputChangeHandler}/>
    </div>
    </div>
    <div className="row"> 

    <div className="form-group col-md-6">         
      <label htmlFor="f-insc_est">
        Inscrição estadual
      </label>
      <input readOnly={this.state.readonly_mode} className="form-control" name="insc_est" id="f-insc_est" type="text" value={this.state.insc_est} onChange={this.inputChangeHandler}/>
    </div>

    <div className="form-group col-md-6">         
      <label htmlFor="f-insc_mun">
        Inscrição municipal
      </label>
      <input readOnly={this.state.readonly_mode} className="form-control" name="insc_mun" id="f-insc_mun" type="text" value={this.state.insc_mun} onChange={this.inputChangeHandler}/>
    </div>
    
    </div>

    <div className="row"> 

        <div className="form-group col-md-6">         
          <label htmlFor="f-email">
            E-mail
          </label>
          <input readOnly={this.state.readonly_mode} className="form-control" name="email" id="f-email" type="text" value={this.state.email} onChange={this.inputChangeHandler}/>
        </div>

        <div className="form-group col-md-6">         
          <label htmlFor="f-site">
            Site
          </label>
          <input readOnly={this.state.readonly_mode} className="form-control" name="site" id="f-site" type="text" value={this.state.site} onChange={this.inputChangeHandler}/>
        </div>

    </div>
    <div className="row"> 

<div className="form-group col-md-6">         
  <label htmlFor="f-telefone">
    Tel. 1
  </label>
  <input readOnly={this.state.readonly_mode} className="form-control" name="telefone" id="f-telefone" type="text" value={this.state.telefone} onChange={this.inputChangeHandler}/>
</div>

<div className="form-group col-md-6">         
  <label htmlFor="f-telefone2">
    Tel. 2
  </label>
  <input readOnly={this.state.readonly_mode} className="form-control" name="telefone2" id="f-telefone2" type="text" value={this.state.telefone2} onChange={this.inputChangeHandler}/>
</div>

</div>

    <div className="row">   
        <div className="form-group col-md-3">     
          <label htmlFor="f-cep">
            CEP
          </label>
          <input readOnly={this.state.readonly_mode} className="form-control" name="cep" id="f-cep" type="text" value={this.state.cep} onChange={this.cepChangeHandler}/>
        </div>
        <div className="form-group col-md-7">         
          <label htmlFor="f-endereco">
            Endereço
          </label>
          <input readOnly={this.state.readonly_mode} className="form-control" name="endereco" id="f-endereco" type="text" value={this.state.endereco} onChange={this.inputChangeHandler}/>
        </div>
        <div className="form-group col-md-2">         
          <label htmlFor="f-numero">
            Número
          </label>
          <input readOnly={this.state.readonly_mode} className="form-control" name="numero" id="f-numero" type="tel" value={this.state.numero} onChange={this.inputChangeHandler}/>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-5">         
          <label htmlFor="f-bairro">
            Bairro
          </label>
          <input readOnly={this.state.readonly_mode} className="form-control" name="bairro" id="f-bairro" type="text" value={this.state.bairro} onChange={this.inputChangeHandler}/>
        </div>
        <div className="form-group col-md-5">         
          <label htmlFor="f-cidade">
            Cidade
          </label>
          <input readOnly={this.state.readonly_mode} className="form-control" name="cidade" id="f-cidade" type="text" value={this.state.cidade} onChange={this.inputChangeHandler}/>
        </div>
        <div className="form-group col-md-2">         
          <label htmlFor="f-uf">
            Estado
          </label>
          <EstadoSelect readOnly={this.state.readonly_mode} uf={this.state.uf} onchange={this.inputChangeHandler} />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-md-5">         
          <label htmlFor="f-bairro">
            Inclusão
          </label>
          <input readOnly className="form-control" id="f-bairro" type="text" defaultValue={this.state.dt_inclusao} onChange={this.inputChangeHandler}/>
        </div>
      </div>

</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/fornecedor/'+this.state.id, axiosHeader())
    .then(function(response){
      window.location.reload();
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default FornecedorForm;