import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import RecargaForm from './RecargaForm';
import url_btn_close from '../img/icons/close.png';
import './RecargaModal.scss';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import { mask_decimal } from '../util/inputMask';
import RecargaComprovante from './RecargaComprovante';

class RecargaModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
        form: {},
        loading: false,
        comp_show: false,
    
      };

    }

  closeTrigger = () => {
    this.setState({ loading: false, submit: false });
    this.props.onHide();
  }

  formCancel = () => {
    let c = window.confirm("Deseja mesmo cancelar a recarga "+this.props.form.id+" no valor de "+mask_decimal(this.props.form.valor)+"?");
   
    if (c == true) {
      this.cancel();       
    }

  }

  

  cancel = () => {
    let REF = this;
    axios.put(CFG.URL_API+'/recarga/'+this.props.form.id, {status: 2}, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  }).catch(function(error){
      //Perform action based on error
    }).finally(function(){
      REF.props.onHide();
    });
   }


  setComprovanteShow  = (status) => {  
    this.setState({ comp_show: status });
  }

  render () {
    let REF = this;

    let confirmOrReceipt = () => {

      if(typeof REF.props.form.id !== "undefined")
      return (<Button className="ml-auto" variant="info" onClick={() => REF.setComprovanteShow(true)}>Emitir Recibo</Button>);
      
      return (<Button hidden={typeof this.props.form.id !== "undefined"} className="ml-auto" onClick={this.formSubmit}>Confirmar</Button>);
    }

    return (
      <>
      <Modal   
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            {typeof this.props.form.id === "undefined" ? "Nova recarga" : "Detalhes da recarga "+this.props.form.id} 
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />
        </Modal.Header>
        <Modal.Body style={{ minHeight: '35vh' }}>        
          <RecargaForm
            show={this.props.show}
            form={this.props.form}
            submit={this.state.submit}
            close={this.closeTrigger}
          />       
        </Modal.Body>
        <Modal.Footer>
          <Button hidden={typeof this.props.form.id === "undefined"} className="mr-auto" variant="danger" onClick={this.formCancel}>Cancelar a recarga</Button>
          { confirmOrReceipt() }          
        </Modal.Footer>
      </Modal>
      <RecargaComprovante
          form={this.props.form}
          show={this.state.comp_show}              
          onHide={() => REF.setComprovanteShow(false)}
        />
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  
  formSubmit = () =>  {

    this.setState({ submit: true, loading: true });
    
  }  
}

export default RecargaModal;