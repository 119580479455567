import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import ResponsavelTab from './ResponsavelTab';
import LoadingModal from '../util/LoadingModal';
import url_btn_close from '../img/icons/close.png';

class ResponsavelModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
        form: {},
        loading: false
      };

      this.formSubmit = this.formSubmit.bind(this);

  }

  modalControl = (form_comp) => {
    this.setState({ form: form_comp });
  }

  closeTrigger = () => {
    let REF = this;

    setTimeout(() => {
      REF.setState({ loading: false, submit: false });
      REF.props.onHide();
    }, 1000);

  }

  render () {
    return (
   <>
      <Modal      
      onEscapeKeyDown={this.props.onHide}
        show={this.props.show}
        onHide={this.props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header onHide={this.props.onHide} className="py-2">
          <Modal.Title id="contained-modal-title">
            Responsável
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />
        </Modal.Header>
        <Modal.Body>        
          <ResponsavelTab
            show={this.props.show}
            form={this.props.form}
            modalcontrol={this.modalControl}
            close={this.closeTrigger}
            submit={this.state.submit}
          /> 
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }

  formSubmit() {

    this.setState({ loading: true, submit: true });
   
  }

}


export default ResponsavelModal;