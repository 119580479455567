import React from 'react';
import axios from 'axios';
import {Row, Col} from 'react-bootstrap';
import {CFG, axiosHeader} from '../../CFG';
import {sessionGet} from '../../usuario/Auth';
import { inputChangeHandler, isValid, checkboxClickHandler } from '../../CustomForm';
import TransportadoraSelect from './TransportadoraSelect';

class TransporteForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa_id: sessionGet('empresa'),
      indicador: '',     
      validator: {},
      show: false,
      useSameTransport: true,
      data_removido_format: undefined
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.checkboxClickHandler = checkboxClickHandler.bind(this);

    this.isValid = isValid.bind(this);

  }

  componentDidMount(){

 
    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol('transporte', this);

    }

  }

  componentDidUpdate(prevProps){

    if(prevProps.form.id !== this.props.form.id){
      let REF = this;
      let data = Object.assign(this.state, this.props.form);
      this.setState({ ...data, razao: REF.state.nome, fantasia: REF.state.nome });
      setTimeout(() => REF.props.formcontrol(REF), 2000);
    }
    
    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }

    if(this.state.useSameTransport && this.props.submit && prevProps.submit !== this.props.submit){
      this.post();
    }
  }

   post = () => {
    let REF = this;

    REF.props.post_confirm('TransporteForm');

    axios.post(CFG.URL_API+'/transportadora', this.state, axiosHeader())
    .then(function(response){
    
    })
    .catch(function(error){
      //Perform action based on error
    });
   }

   render(){

   return(
     <>    

  <Row className="mb-3">
  <div className="col-lg-6 mt-3" hidden={!this.props.import}> 
             
             <input type="checkbox" value={true} id="f-useSameTransport" name="useSameTransport" checked={this.state.useSameTransport} onClick={this.checkboxClickHandler} className="mr-2"/> 
             <label htmlFor="f-useSameTransport" className="d-inline text-info">
                 <u>Cadastrar transportadora com base na informada na nota</u>
             </label>
         </div>
            <div className="col-lg-6"> 
              <div hidden={this.state.useSameTransport} className="mt-3">
                <label className="text-uppercase">Transportadora</label>
                <TransportadoraSelect change={this.selectChangeHandler} transportadora_id={this.state.id} />
              </div>
              <div hidden={!this.state.useSameTransport} className="mt-2">
              <label htmlFor="f-fornecedor" className="d-block">
                Transportadora
              </label>
                {this.state.nome}
              </div>
            </div>
        
            </Row>

  
    <Row>
      <Col>
        <label htmlFor="f-especie">
        Espécie
        </label>
        <input className="form-control" name="especie" onChange={this.inputChangeHandler} value={this.state.especie} />
      </Col>
      <Col>
        <label htmlFor="f-volume">
        Volume
        </label>
        <input className="form-control" name="volume" onChange={this.inputChangeHandler} value={this.state.volume} />

      </Col>
    </Row>

    <Row>
      <Col>
        <label htmlFor="f-pesol">
        Peso líquido
        </label>
        <input className="form-control" name="pesol" onChange={this.inputChangeHandler} value={this.state.pesol} />
      </Col>
      <Col>
        <label htmlFor="f-pesob">
        Peso bruto
        </label>
        <input className="form-control" name="pesob" onChange={this.inputChangeHandler} value={this.state.pesob} />

      </Col>
    </Row>

    <Row>
      <Col>
        <label htmlFor="f-outrasdesp">
        Valor
        </label>
        <input className="form-control" name="outrasdesp" onChange={this.inputChangeHandler} value={this.state.outrasdesp} />
      </Col>
    </Row>


    

</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/dre/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default TransporteForm;