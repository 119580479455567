import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { inputChangeHandler, isValid, checkboxClickHandler } from '../../CustomForm';
import '../../CustomForm.scss';
import DatePicker from 'react-date-picker';
import {getYmd, firstDateOfMonth, lastDateOfMonth, objectToDate, parseUS} from '../../util/date';
import CategoriaSelect from '../../produto-categoria/CategoriaSelect'; 
import GrupoProdutoSelect from '../../grupo-produto/GrupoProdutoSelect'; 
import SubgrupoProdutoSelect from '../../subgrupo-produto/SubgrupoProdutoSelect'; 
import ProdutoSelect from '../../produto/ProdutoSelect';
import {sessionGet} from '../../usuario/Auth';
import Select from 'react-select';
import axios from "axios"
import { CFG, axiosHeader } from "../../CFG"
import { requestException } from "../../util/exception"

import "./style.css"


class FiltroForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            validator: {},
            show: false,
            filtro_data: 'dt_pagto',
            edit_lock: true,
            cal_inicio: objectToDate(parseUS(firstDateOfMonth())),
            cal_fim: objectToDate(parseUS(lastDateOfMonth())),
            inicio: firstDateOfMonth(),
            fim: lastDateOfMonth(),
            horario_de: '',
            horario_ate: '',
            centrocustos_id:'',
            custos:[]
        };
        
        this.checkboxClickHandler = checkboxClickHandler.bind(this);
        this.inputChangeHandler = inputChangeHandler.bind(this);
        
        this.isValid = isValid.bind(this);

    }
    
    formattedState = () => {

      let state_old = {};
      Object.assign(state_old, this.state);
      delete state_old.validator;
      delete state_old.cal_inicio;
      delete state_old.cal_fim;
      delete state_old.show;
      delete state_old.edit_lock;
      delete state_old.custos;

      return state_old;
    }

    componentDidUpdate(prevProps, prevState){

      if(prevState != this.state){
        let REF = this;
        setTimeout(function() {
            REF.props.modalcontrol(REF);
        }, 1000);
      }
    }
    
    componentDidMount() {
        this.setState({ show: this.props.show });
        this.getCentroCustos()
        
    }

    getCentroCustos = ()=>{
      const url  = CFG.URL_API ;
      const REF = this;
      axios.get(url + `/dre/centro-custos?empresa_id=${REF.props.empresa}`, axiosHeader())
        .then(function(response) {
          const {data} = response;
          const custos = [];
          data.map(item=>{
            custos.push({
              value:item.id,
              label:`${item.classificacao} - ${item.descricao}`
            })
          })
          REF.setState({custos})
        })
        .catch(function(error) {
            requestException(error)
        })
    }

    centroChangeHandler = (e)=>{
      this.setState({centrocustos_id:e.value})
    }

    inicioChangeHandler = (date) => {

      if(date == null){
        this.setState({ cal_inicio: null, inicio: '' });
        return;
      }

      let REF = this;
      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_inicio: dt, inicio: dt_format }); 
    }
  
    fimChangeHandler = (date) => {


      if(date == null){
        this.setState({ cal_fim: null, fim: '' });
        return;
      }

      let dt = date;
      let dt_format = dt.getFullYear()+'-'+(dt.getMonth()+1).toString().padStart(2, '0')+'-'+dt.getDate().toString().padStart(2, '0');
      this.setState({ cal_fim: dt, fim: dt_format }); 
    }
  
    render(){
        
        return(
            <>   
            
        <Row>
          <Col md="4" className="p-1 cj">
            <div className="form-group" style={{width:'100%'}}>
                <strong>Emissão de:</strong>
                <DatePicker className="form-control" locale="pt-BR"  locale="pt-BR" autoComplete="off" name="B_emissao" id="f-inicio" value={this.state.cal_inicio} onChange={this.inicioChangeHandler} />
              </div>
          </Col>
            <Col md="4" className="p-1 cj">
              <div className="form-group" style={{width:'100%'}}>
                <strong>Emissão até:</strong>
                <DatePicker className="form-control" locale="pt-BR"  locale="pt-BR" autoComplete="off" name="B_emissao_" id="f-fim" value={this.state.cal_fim} onChange={this.fimChangeHandler} />
              </div>
          </Col>
          <Col md="4" className="p-1 cj">
              <div className="form-group"  style={{width:'100%'}}>
                <strong>Centro custos</strong>
                <Select onChange={this.centroChangeHandler} options={this.state.custos} />
              </div>
          </Col>
      </Row>

            </>
            );
        }        
    }
    export default FiltroForm;