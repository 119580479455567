import React from 'react';
import {CFG, axiosHeader} from '../../CFG';
import axios from 'axios';
import Chart from 'react-apexcharts';
import {mask_decimal} from '../../util/inputMask';
import LoadingPlaceholder from '../../util/LoadingPlaceholder';
import {todayYmd, parseUS, objectToDate, getDmy} from '../../util/date';
import {requestException} from '../../util/exception';
import { sessionGet } from '../../usuario/Auth';

class Grafico extends React.Component {
      
  constructor(props) {
    super(props);


    this.state = {
      loading: true,
      empresa: sessionGet('empresa'),
      options: {},
      series: [],
      inicio: todayYmd(),
      fim: todayYmd()
    };

  }

  componentDidUpdate(prevProps){
    if(this.props.empresa != this.state.empresa){
      this.setState({ empresa: this.props.empresa });
    }
    if(this.props.inicio != this.state.inicio){
      this.setState({ inicio: this.props.inicio });
      this.loadRange();
    }
    if(this.props.fim != this.state.fim){
      this.setState({ fim: this.props.fim });
      this.loadRange();
    }

  }

  componentDidMount(){
    this.setOptions();
    this.loadRange();
  }

  loadCategories = () => {
    let REF = this;
    return axios.get(CFG.URL_API+'/recarga/tipos', axiosHeader())
    .then(function(response){      
      return response.data;
      //Perform action based on response
    })
    .catch(function(error){
      requestException(error);
    });
  }

  setOptions = function() {
  
    let REF = this;
  
    this.loadCategories().then((e) => {
    
    let categories = Object.entries(e).map(function(e){
        return e[1];
    });

    let options = {
      chart: {
        id: 'GraficoBCD'
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        width: [1, 1, 4]
      },  
      xaxis: {
        categories: [REF.getPeriodo()],
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            formatter: function (value) {
              return "R$ "+mask_decimal(value.toFixed(2))
            },
            style: {
              color: '#008FFB',
            }
          },
          title: {
            text: "Valor (em reais)",
            style: {
              color: '#008FFB',
            }
          },
          tooltip: {
            enabled: true
          }
        }  

      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    };

    REF.setState({ options, loading:false });

    }); 
    
  };

  getSeries = () => [{
      tipo: 'DEB',
      name: 'Débito',
      type: 'column',
      data: []
    }, {
      tipo: 'CRE',
      name: 'Crédito',
      type: 'column',
      data: []
    }, {
      tipo: 'BOL',
      name: 'Boleto',
      type: 'column',
      data: []
    }];

  loadRange = () => {

    var REF = this;

    REF.setState({ series: [] });
     
      REF.get().then(function(response){

        let data_up_sync = REF.getSeries();
        data_up_sync[1].total = response.data.DEB;
        data_up_sync[0].total = response.data.CRE;
        data_up_sync[2].total = response.data.BOL;
  
        REF.setState({
          series: data_up_sync.map((e) => { return {name: e.name, data: [e.total]} })
        }); 
        
        });

  }

  get = () => {
    return axios.get(CFG.URL_API+'/relatorio/recarga?empresa='+this.props.empresa+'&inicio='+this.props.inicio+'&fim='+this.props.fim, axiosHeader());
  }

  isLoading = () => {
   return this.state.loading ? "d-block" : "d-none";
  }

  getPeriodo = () => {
    
    let start = '';
    let end = '';

    if(typeof this.state.inicio !== "undefined"){
      let ps = parseUS(this.state.inicio);
      let pe = parseUS(this.state.fim);
      start = objectToDate(ps);
      end = objectToDate(pe);
    }
   
    if(typeof this.state.inicio !== "undefined") 
    return getDmy(start)+" até "+getDmy(end);
    else
    return "Hoje";
    
  }

  render() {

    if(this.state == null)
    return (<></>);
  
    if(this.state.series.length > 0){
      return (
        <div className="Grafico">
          <LoadingPlaceholder show={this.state.loading} title="Carregando o gráfico de recargas do período" />
      <h4>Recargas do período { this.getPeriodo() }</h4>
            {this.renderChart()}
        </div>
      );
    } else {
      return (
        <div className="Grafico">
          <LoadingPlaceholder show={this.state.loading} title="Carregando o gráfico de recargas do período" />
        </div>
      );
    }
  
  }

  renderChart = () => {
    return (<div id="chart">
      <Chart id="GraficoBCD" hidden={this.state.loading} options={this.state.options} series={this.state.series} type="bar" height="350" />
    </div>);
  }
}

export default Grafico;