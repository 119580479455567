import React, { useRef } from "react" // Import React
import "./DataTable.scss"
import axios from "axios"
import { requestException } from "./util/exception"
import { CFG, axiosHeader } from "./CFG"
import { Row, Col } from "react-bootstrap"
// es6
import BootstrapTable from "react-bootstrap-table-next"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { Dropdown, Alert } from "react-bootstrap"

import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import ReactToPrint from "react-to-print"
import { todayDmy } from "./util/date"
import LoadingPlaceholder from "./util/LoadingPlaceholder"

export default function e() {
    return true
}

export function CustomToggleList({ columns, onColumnToggle, toggles }) {
    let CTL = this

    const onToggle = function(isOpen, event, metadata) {
        if (metadata.source == "keydown") alert("keydown")

        if (metadata.source == "select") CTL.setState({ popoverVisible: true })

        if (metadata.source == "click") {
            if (isOpen == false) CTL.setState({ popoverVisible: false })
            else CTL.setState({ popoverVisible: true })
        }
    }

    return (
        <Dropdown show={CTL.state.popoverVisible} drop={"left"} onToggle={onToggle}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="px-2 py-0">
                <span className="icon-eye"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item
                    onSelect={() => CTL.setState({ popoverVisible: false })}
                    eventKey="close"
                >
                    Fechar <i className="icon-arrow-right"></i>
                </Dropdown.Item>

                {CTL.getColumns()
                    .map(column => ({
                        ...column,
                        toggle: toggles[column.dataField]
                    }))
                    .map(function(column) {
                        if (column.hidden && column.exclude !== false)
                            return null    
                        

                        return (
                            <Dropdown.Item
                                key={column.dataField}
                                onClick={() => onColumnToggle(column.dataField)}
                                href="#"
                                eventKey={column.dataField}
                                className={`list-group-item p-0 ${column.toggle ? "" : "active"}`}
                                aria-pressed={column.toggle ? "false" : "true"}
                            >
                                <label className="w-column-toggle d-inline-flex m-0 py-2 px-3 w-100 ">
                                    <input
                                        type="checkbox"
                                        className="column-toggle mr-1"
                                        checked={column.toggle ? "checked" : ""}
                                        defaultValue="1"
                                    />
                                    {column.text}
                                </label>
                            </Dropdown.Item>
                        )
                    })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export function getListing(uri, filters = {}) {
    this.setState({ fetchComplete: false, data: [], activeIndex: undefined })

    let REF = this

    let qs_filters = ""
    var queryString = CFG.URL_API + "/" + uri

    if (filters != {}) {
        for (var [key, term] of Object.entries(filters)) {
            if (term != "") qs_filters += "&" + key + "=" + term + ""
        }

        if (qs_filters != "") {
            queryString += queryString.indexOf("?") == -1 ? "?" : "&"
            if (queryString.indexOf("whereMode") != -1) queryString += qs_filters
            else queryString += "whereMode=or" + qs_filters
        }
    }

    return axios
        .get(queryString, axiosHeader())
        .then(function(response) {
            if (response.data !== "" && response.data !== null) {
                REF.setState({ fetchComplete: true, data: response.data, loading: false })
            } else {
                REF.setState({ fetchComplete: true, data: [], loading: false })
            }
            //Perform action based on response
        })
        .catch(function(error) {
            REF.setState({ fetchComplete: true, data: [], loading: false })
            requestException(error)
        })
}

export function filtersForQuery(filters) {
    let data_up = Object.entries(filters)

    return data_up
        .map(function(e) {
            if (typeof e[1] !== "undefined" && e[1] !== "") return e[0] + "=" + e[1]
        })
        .join("&")
}

export function printTable(props, rowClasses, selectRow, rowEvents, paginateOrNot) {
    let REF = this

    return (
        <>
            <BootstrapTable
                hidden={!this.state.fetchComplete}
                rowClasses={rowClasses}
                selectRow={selectRow}
                {...props.baseProps}
                keyField="id"
                data={this.state.data}
                columns={this.getColumns()}
                pagination={paginateOrNot}
                rowEvents={rowEvents}
            />
            <LoadingPlaceholder hidden={REF.state.fetchComplete} />
            <Alert variant="info" hidden={!REF.state.fetchComplete || REF.state.data.length > 0}>
                Sem resultados para exibir...
            </Alert>
        </>
    )
}

export function printHeader(title) {
    return (
        <div className="print-only print-header">
            <table className="table ">
                <tbody>
                    <tr>
                        <td>Relatório: {title}</td>
                        <td>Data: {todayDmy()}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export function getCustomToggleList({ columns, onColumnToggle, toggles }) {
    return CustomToggleList({
        columns,
        onColumnToggle,
        toggles
    })
}

export function tableRender(sizePerPage, rowClasses, report_title = "") {
    let REF = this

    const componentRef = React.createRef()

    this.printTable = printTable.bind(this)

    if (typeof rowClasses === "undefined") {
        rowClasses = (row, rowIndex) => {
            return typeof row.dt_removido !== "undefined" && row.dt_removido != null
                ? "removed"
                : ""
        }
    }

    const selectRow = {
        mode: "radio",
        hideSelectColumn: true,
        classes: "active",
        clickToSelect: true
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.setState({ activeIndex: rowIndex })
            if (typeof REF.setModalShow === "function") REF.setModalShow(true, row)
        }
    }

    const MainSearch = props => {
        let input
        const handleFilterChange = () => {
            let results = props.onSearch(input.value)

            this.setState({ searchTerm: input.value })

            let columnsSearch = {}
            for (var entry of this.getColumns()) {
                if(typeof entry !== "undefined"){
                    columnsSearch[entry.dataField] = input.value
                }
            }

            if (typeof REF.updateListing === "function") REF.updateListing(columnsSearch)
        }

        const styles = props.styles

        return (
            <input
                placeholder="Filtro: comece a digitar para filtrar"
                onChange={handleFilterChange}
                className="form-control"
                autoFocus
                style={{ ...styles, backgroundColor: "#fff" }}
                ref={n => (input = n)}
                type="text"
                defaultValue={this.state.searchTerm}
            />
        )
    }

    let paginateOrNot

    if (typeof sizePerPage === "undefined") paginateOrNot = paginationFactory({ sizePerPage: 5 })

    if (Number.isInteger(sizePerPage)) paginateOrNot = paginationFactory({ sizePerPage })

    if (false === sizePerPage) paginateOrNot = undefined

    const noComponentStatus = () => <span></span>
    const CustomComponent = this.state.componentStatus || noComponentStatus

    return (
        <div>
            <ToolkitProvider
                keyField="id"
                data={this.state.data}
                columns={this.getColumns()}
                search
                columnToggle
            >
                {props => (
                    <div>
                        <Row className="cdt-toolbar">
                            <Col className="col-md-4 offset-md-4 my-1 p-0 w-column-dropdown">
                                <ReactToPrint
                                    trigger={() => (
                                        <button className="btn-sm btn-secondary btn-print px-2 py-0">
                                            <i className="fa fa-file-text-o"></i>
                                        </button>
                                    )}
                                    content={() => componentRef.current}
                                />
                                {REF.getCustomToggleList({ ...props.columnToggleProps })}
                            </Col>
                            <Col className="col-md-4">
                                <MainSearch
                                    {...props.searchProps}
                                    styles={
                                        this.state.componentStatus && {
                                            float: "left",
                                            width: "80%"
                                        }
                                    }
                                />
                                {this.state.componentStatus && <CustomComponent />}
                            </Col>
                        </Row>
                        <div ref={componentRef}>
                            {printHeader(report_title)}

                            {this.printTable(
                                props,
                                rowClasses,
                                selectRow,
                                rowEvents,
                                paginateOrNot
                            )}
                        </div>
                    </div>
                )}
            </ToolkitProvider>
        </div>
    )
}
