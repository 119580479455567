import React from 'react';
import axios from 'axios';
import {Card, Row, Col, Button, ButtonToolbar} from 'react-bootstrap';
import {CFG, axiosHeader} from '../../CFG';
import {sessionGet, getUser} from '../../usuario/Auth';
import ModeloSelect from './ModeloSelect';
import DatePicker from 'react-date-picker';
import { isValid, selectChangeHandler, dateChangeHandler, inputChangeHandler, radioSwitchHandler } from '../../CustomForm';
import {todayDmy, todayTimestamp} from '../../util/date';
import {camelize} from '../../util/string';
import CalculosTab from './CalculosTab';
import DadosTab from './DadosTab';
import ItemModal from './ItemModal';
import './NotaFiscalForm.scss';
import ItemsDataTable from './ItemsDataTable';
import {mask_decimal, floatToReal, parseDecimal} from '../../util/inputMask';
//import {previewXML, processarXMLNF} from './Exportar';
import {getProduto, fixture, handleXmlUpload, importRoutines} from './Importar';
import {ErrorModal} from '../../util/exception';
import {SummaryModal} from './SummaryModal';

class NotaFiscalForm extends React.Component {

  initialState (){
    return {
      import: false,
      info_adicional: {
        id: undefined
      },
      transporte: {
        id: undefined
      },
      summary: {
         fornecedor: {
            fantasia: '',
            cnpj: '',
        },
        valor: '',
        numero: ''
      },
      impostos: {
        id: undefined
      },
      NFe: {
        infNFe: {
          det: []
        } 
      },
      cal_dhEmi: null,
      items: [],
      item_status: false,
      item_form: {
        id: undefined
      },
      item_refresh: false,
      show: false,
      vldocumento: 0,
      id: undefined,
      serie: '',
      doc: '',
      empresa_id: sessionGet('empresa'),
      validator: {},
      show: false,
      dt_documento: null,
      formaspagamento_id: undefined,
      dt_movimento: null,
      dt_vencimento: null,
      data_removido_format: undefined,
      dt_entrada: todayDmy(),
      cal_dhSaiEnt: null,
      cal_dhEmi: null,
      //debito
      tipo: undefined,
      formRefs: {
        ImpostosForm: {}
      },
      vldocumento: 0,
      nfe_items: [],
      errors: [],
      post_confirm: [],
      emitente: {
        nome: ''
      },
      usuario: {
        id: undefined
      }
    };
  }

  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.getUser = getUser.bind(this);

    this.dateChangeHandler = dateChangeHandler.bind(this);
    this.selectChangeHandler = selectChangeHandler.bind(this);
    this.radioSwitchHandler = radioSwitchHandler.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);

    this.importRoutines = importRoutines.bind(this);
    this.isValid = isValid.bind(this);
  
    this.props.titlebar(this.props.title+" de "+camelize(this.props.tipo), this.props.description);
    this.handleXmlUpload = handleXmlUpload.bind(this);
  }

  fileChangeHandler = (files) => {

    if(files.length == 0)
    return;      
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.addError(error);  
  }

  addError = (error) => {
    let REF = this;
    this.state.errors.push(error);
    this.setState({ hasErrors: true });
  }

  itemsUpdate = (nfe_items) => {
    let vldocumento = 0.0;
    nfe_items.map((nitem) => {
      vldocumento += parseFloat(nitem.totalprod)
    });
    this.setState({ nfe_items, vldocumento: vldocumento.toFixed(2) });
  }
  
  componentDidMount(){

    let form_fields = this.props.form;

    this.getUser();

    let tipo = this.props.tipo === "entrada" ? "EN" : "SA";
    this.setState({ tipo });

    if(form_fields != undefined && form_fields.dt_vencimento == '0000-00-00'){
      form_fields.dt_vencimento = null;
    }
  

    this.updateDateIfRemoved();
  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear()+' às '+dt.getHours()+':'+dt.getMinutes(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);
    }

  }

  componentDidUpdate(prevProps, prevState){

    if(prevProps.id !== this.props.id){
  
      this.setState({ id: this.props.id });
    } 

    if(this.state.item_refresh && prevState.item_refresh !== this.state.item_refresh){
      this.setState({ item_refresh: false });
    }

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }

    if(prevState.emitente.nome !== this.state.emitente.nome || prevState.vldocumento !== this.state.vldocumento){
      this.props.titlebar(this.props.title, camelize(this.props.tipo)+" :: "+this.state.emitente.nome+" - R$ "+mask_decimal(this.state.vldocumento));
    }

    if(this.state.post_confirm.includes('ItemsDataTable') 
    && this.state.post_confirm.includes('GerarParcelas') 
    && this.state.post_confirm.includes('TransporteForm')
    && this.state.post_confirm.includes('DadosForm')
    && this.state.post_confirm.includes('NotaFiscalForm')
    ){
      this.setState({ import_success: true });
    }

    if(this.state.import_success && prevState.import_success !== this.state.import_success){
      this.storeImportLog();
    }

  }

  storeImportLog = () => {
    let REF = this;
      let log = {
        empresa: REF.state.empresa,
        emissao: todayTimestamp(),
        tipo: 'Importar Nota',
        log: REF.state.id
      };
      axios.post(CFG.URL_API+"/log", log, axiosHeader())
      .then((response) => REF.setState({ import_success: false, submit: false }));
  }

   post = async () => {

      this.setState({ submit: true });

      this.postEmpresaEstoque();

      this.componentPostConfirm('NotaFiscalForm');   
   }

   postEmpresaEstoque = () => {

    let data = {};
    data.empresa = this.state.empresa;
    data.fornecedor = this.state.fornecedor_id;
    data.nf = this.state.id;
    data.emissao = this.state.dhEmi;
    data.total = this.state.vldocumento;

     axios.post(CFG.URL_API+"/empresa-estoque", data, axiosHeader());
   }

  

    formcontrol = (formRef) => {
  

      if(formRef.constructor.name === "ItemForm"){
        this.addItem(formRef.state);
      }

      let formRefsUpdated = this.state.formRefs;
      formRefsUpdated[formRef.constructor.name] = formRef;
      this.setState({ formRefs: formRefsUpdated });

      
    }
  
    addItem = (data) => {
        let items = this.state.items;

        if(typeof data.produto_pair !== "undefined"){
          data.produto_nf = data.produto_pair.label;
          data.produto_base = data.produto_pair.label;
        }

        data.id = items.length+1;
        data.key = items.length+1;
        data.unidade = 'INDEF.';
        data.qtdeprod_format = mask_decimal(parseDecimal(data.qtdeprod));
        data.precoprod_format = floatToReal(data.precoprod);
        items.push(data);
        this.setState({ items }); 
    }

    setVldocumento = (vldocumento) => {
      this.setState({ vldocumento });  
    }

    toggleModalItem  = (status, data) => {
      this.setState({ item_show: status , item_form: data });
    }

    toggleModalError = (status) => {
      this.setState({ showErrors: status });

      if(!status)
      this.setState({ errors: [] });
    }

    toggleModalSummary = (status) => {

      this.setState({ showSummary: status });
    }

    confirmImport = () => {
      let REF = this;
      REF.importRoutines();
      REF.toggleModalSummary(false);
    }

    undoImport = () => {
      this.setState(this.initialState());
    }

    componentPostConfirm = (component_name) => {
      let c_names = this.state.post_confirm;
      c_names.push(component_name);
      this.setState({ post_confirm: c_names });
    }

   render(){
    
    let REF = this;


   return(
     <>
  <SummaryModal show={this.state.showSummary} onHide={() => REF.toggleModalSummary(false)} onConfirm={REF.confirmImport} summary={REF.state.summary} />
  <ErrorModal show={this.state.showErrors} onHide={() => REF.toggleModalError(false)} errors={REF.state.errors} />

  <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar>
                <label className="ml-auto btn btn-primary" hidden={this.state.nfe_items.length > 0} >
                  <input onChange={this.handleXmlUpload} hidden={true} type="file" id="xml-up" /><span className="fa fa-plus-square"></span>  Importar XML
                </label>  
                <Button className="ml-auto" variant="warning" onClick={this.undoImport} hidden={this.state.nfe_items.length === 0}>Desfazer importação</Button>
                <Button className="ml-auto" variant="secondary" onClick={() => REF.toggleModalItem(true, [])}>
                  <span className="fa fa-plus-square"></span>  Incluir item
                </Button>              
              <ItemModal
                tipo={this.props.tipo}
                form={this.state.item_form}
                show={this.state.item_show} 
                formcontrol={this.formcontrol}             
                onHide={() => REF.toggleModalItem(false, [])}
              />
            </ButtonToolbar>            
          </div>
        </div>  

     <Row>
       <Col>
       <h5>Itens <span hidden={REF.state.nfe_items.length === 0} className="badge badge-warning">Confira os itens</span></h5>

      <ItemsDataTable 
       post_confirm={this.componentPostConfirm}
       usuario_id={REF.state.usuario.id} 
       modelo_id={REF.state.modelo} 
       submit={this.state.submit} 
       nfe_id={this.state.id} 
       tipo={this.state.tipo} 
       data={REF.state.nfe_items}
       data_update={this.itemsUpdate}
       refresh={this.state.item_refresh} 
       formaspagamento_id={REF.state.formaspagamento_id} />
       </Col>
       </Row>
      <div className="row mt-3">      
          <div className="form-group col-lg-4"> 
          <Card>
                <Card.Body>
                <div className="row">      
                    <div className="form-group col-lg-4">           
                        <label htmlFor="f-id">
                        ID
                        </label>
                        <input className="form-control" type="text" name="id" onChange={this.inputChangeHandler} value={this.state.id} />
                    </div>
                    <div className="form-group col-lg-4">           
                        <label htmlFor="f-serie">
                        Série
                        </label>
                        <input className="form-control" type="text" name="serie" onChange={this.inputChangeHandler} value={this.state.serie} />
                    </div>
                    <div className="form-group col-lg-4">           
                        <label htmlFor="f-doc">
                        DOC
                        </label>
                        <input className="form-control" type="text" name="doc" onChange={this.inputChangeHandler} value={this.state.doc} />
                    </div>
              </div> 
              <div className="row">
              <div className="form-group col-lg-12">         
            <label htmlFor="f-centrocustos_id">
              Modelo
            </label>
            <ModeloSelect change={this.selectChangeHandler} modelo_id={this.state.modelo} />
          </div>
                    </div>
                </Card.Body>
            </Card>

        </div> 

        <div className="form-group col-lg-3"> 
          <Card>
            
                <Card.Body>
                <h6 className="text-info">Tipo de movimentação</h6>
                <div className="row">      
                        <div className="form-group col-lg-6">           
                            <label htmlFor="f-id">
                            ID
                            </label>
                            <input className="form-control" name="id" onChange={this.inputChangeHandler} value={this.state.qtd} />
                        </div>
                        <div className="form-group col-lg-6">           
                            <label htmlFor="f-id">
                            Série
                            </label>
                            <input className="form-control" name="id" onChange={this.inputChangeHandler} value={this.state.qtd} />
                        </div>
                    </div> 
            <h6 className="text-info">Frete</h6>
                            <div className="row">      
                            <div className="form-group col">         
       
            <ul className="list-inline">
            <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="tpfrete" className="custom-control-input" id="f-tpfrete-nd" type="radio" value="" checked={this.state.tpfrete !== "0" && this.state.tpfrete !== "1"} onChange={this.radioSwitchHandler} />
                  <label className="custom-control-label" htmlFor="f-tpfrete-nd">
                ND    </label>
                  </div>
                </li> 
              <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="tpfrete" className="custom-control-input" id="f-tpfrete-cif" type="radio" value="0" checked={this.state.tpfrete === "0"} onChange={this.radioSwitchHandler} />
                  <label className="custom-control-label" htmlFor="f-tpfrete-cif">
                CIF   </label>
                  </div>
                </li>
                <li className="list-inline-item">
                <div className="custom-control custom-radio">
                  <input name="tpfrete" className="custom-control-input" id="f-tpfrete-fob" type="radio" value="1" checked={this.state.tpfrete === "1"} onChange={this.radioSwitchHandler} />
                  <label className="custom-control-label" htmlFor="f-tpfrete-fob">
              FOB    </label>
                  </div>
                </li>              
            </ul>
        </div> 
                            </div> 
                
                </Card.Body>
            </Card>

         
        </div> 
        <div className="form-group col-lg-5"> 
          <Card>
                <Card.Body>
                <div className="row">      
                        <div className="form-group col-lg-6">           
                            <label htmlFor="f-dt_documento">
                            DT DOC
                            </label>
                            <DatePicker locale="pt-BR"  autoComplete="off" name="dhRecbto" id="f-dt_documento" value={this.state.cal_dhRecbto} onChange={(date) => REF.dateChangeHandler(date, 'dhRecbto')} />
                        </div>
                        <div className="form-group col-lg-6">           
                            <label htmlFor="f-dhEmi">
                            DT Emissão
                            </label>
                            <DatePicker locale="pt-BR"  autoComplete="off" name="dhEmi" id="f-dhEmi" value={this.state.cal_dhEmi} onChange={(date) => REF.dateChangeHandler(date, 'dhEmi')} />
                        </div>
                        </div>
                        <div className="row">   
                        <div className="form-group col-lg-6">           
                            <label htmlFor="f-dhSaiEnt">
                            Saída/Entrada
                            </label>
                            <DatePicker locale="pt-BR"  autoComplete="off" name="dhSaiEnt" id="f-dt_movimento" value={this.state.cal_dhSaiEnt} onChange={(date) => REF.dateChangeHandler(date, 'dhSaiEnt')} />

                        </div>
                        <div className="form-group col-lg-6">           
                            <label htmlFor="f-hsaida">
                            Saída
                            </label>
                            <input className="form-control" name="hsaida" onChange={this.inputChangeHandler} value={this.state.hsaida} />
                        </div>
              </div> 
  
                </Card.Body>
            </Card>

        </div> 

        </div> 

        <Row>
          <Col lg="6"> 
          <h5 className="text-uppercase text-info">Tarifas ICMS</h5>
            <Card className="">
                  <Card.Body>
                      <CalculosTab post_confirm={this.componentPostConfirm} import={this.state.import} tipo={this.props.tipo} update_vldocumento={this.setVldocumento} submit={this.state.submit} impostos={this.state.impostos} info_adicional={this.state.info_adicional} transporte={this.state.transporte} formcontrol={this.formcontrol} />
                  </Card.Body>
              </Card>
          </Col>
          <Col lg="6"> 
          <h5 className="text-uppercase text-info">Conta a pagar</h5>
            <Card className="">
                <Card.Body>
                    <DadosTab post_confirm={this.componentPostConfirm} change_formaspagamento={this.selectChangeHandler} nfe_id={this.state.id} submit={this.state.submit} tipo={this.props.tipo} emitente={this.state.emitente} import={this.state.import} vldocumento={this.state.vldocumento} form={this.state.info_adicional} formcontrol={this.formcontrol} />
                </Card.Body>
              </Card>
          </Col>  
        </Row>

        <hr className="mt-4"/>

        <div className="row mt-2"> 
          <div className="form-group col-lg-12">         
            <label htmlFor="f-motivo">
              Motivo
            </label>
            <textarea className="form-control" name="motivo" id="f-motivo" type="text" value={this.state.motivo} onChange={REF.inputChangeHandler}  />
          </div>
        </div>

        <Row>
        <Col lg="6" className="ml-auto justify-content-end d-flex">
            <Button size="lg" variant="success" onClick={this.post}>Registrar</Button>
        </Col>
        </Row>
        <div style={{ margin: '48px 0' }}></div>
    </>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/estoque/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
 
 
}
export default NotaFiscalForm;