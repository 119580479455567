import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../../CustomDataTable'; // Import React
import '../../DataTable.scss';
import {getDmy,parseUS, firstDateOfMonth, lastDateOfMonth, objectToDate} from '../../util/date';
import {sessionGet} from '../../usuario/Auth';
import moment from 'moment'

class TEFDataTable extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.state = {
            id: undefined,
            form_state: {},
            empresa: sessionGet('empresa'),
            data: [],
            fetchComplete: false,
            searchTerm: null,
            activeIndex: undefined,
            filters: {
                empresa:sessionGet('empresa'),
                inicio: moment().format('YYYY-MM-DD'),
                fim:  moment().format('YYYY-MM-DD'),
            },
            origem: this.props.origem
        };
        this.getCustomToggleList = CustomToggleList.bind(this);
        this.getListing = getListing.bind(this);
        this.tableRender = tableRender;
    }
   

    componentDidMount(){
        this.updateListing();   
    }

    componentDidUpdate(prevProps, prevState){

        if(prevProps.filters !== this.props.filters){
            let REF = this;
            this.setState({ filters: this.props.filters });
            setTimeout(() => {

                REF.updateListing();   
                                
            }, 1000);
        }

        if(prevState.fetchComplete !== this.state.fetchComplete){
            this.props.setloading(false);
        }   
    }

    setModalShow = (show, data) => {

    }

    updateListing = (columnsSearch) => {
        let data_up_tostring = '';

        if(Object.getOwnPropertyNames(this.state.filters).length > 0){
            
            let data_up = Object.entries(this.state.filters);

            data_up_tostring = data_up.filter(function(e){
                return typeof e[1] !== "undefined";
            }).map(function(e){
                
                return e[0]+'='+e[1];
            }).join('&');    
        } 
        this.getListing('relatorio/tef?'+data_up_tostring, columnsSearch);
    }
    
    getCustomToggleList({
        columns,
        onColumnToggle,
        toggles
    }){
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }
    
    getColumns(){
        return [
            {
                dataField: 'id',
                sort: true                
            },{
                dataField: 'emissao_format',
                text: 'Data'
            },{
                dataField: 'tipo',
                text: 'Tipo',
                sort: true
            },{
                dataField: 'autorizacao',
                text: 'Autorização',
                sort: true
            },{
                dataField: 'codigoautorizacao',
                text: 'Cód. Autorização',
                sort: true               
            },{
                dataField: 'nsu',
                text: 'NSU',
                sort: true               
            },{
                dataField: 'rede',
                text: 'Rede',
                sort: true               
            },{
                dataField: 'administradora',
                text: 'Administradora',
                sort: true               
            },{
                dataField: 'valor',
                text: 'Valor',
                sort: true               
            }
        ];
    }
    
    render(){
        let REF = this;

        return (
            <div className="table-report">  
         
                {
                    (() => REF.tableRender(false))()
                }
            </div>
            )
        }
    }
    
    export default TEFDataTable;