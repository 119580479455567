import React  from 'react'; // componentWillUnmountmport React
import {getListing, CustomToggleList, tableRender} from '../CustomDataTable'; // Import React
import '../DataTable.scss';

import {Col, Row} from 'react-bootstrap';
// es6
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ProdutoModal from './ProdutoModal';
import {sessionGet} from '../usuario/Auth';


class ProdutosDataTable extends React.Component{
  
  constructor(props) {
    super(props);
    
    this.state = {
        popoverVisible: false,
        empresa: sessionGet('empresa'),
        form:[],
        data: [],
        searchTerm: null,
        activeIndex: undefined
    };

    this.tablerowupdate = this.tablerowupdate.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
    this.getListing = getListing.bind(this);
    this.getCustomToggleList = CustomToggleList.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.tableRender = tableRender.bind(this);

    
  }

  componentDidUpdate(prevProps, prevState){

    if(this.state.refresh != this.props.refresh){
      this.setState({ refresh: this.props.refresh });
    }

    if(prevState.refresh != this.state.refresh && this.state.refresh){
      this.updateListing();
    }
  }

  setModalShow = (show, data) => {
    this.props.setModalShow(show,data);
  }

  onKeyPressed = (event) => {
    if(event.keyCode == 13 )
    this.formSubmit();
  }

  updateListing = (columnsSearch) => {
    let REF = this;
    this.getListing('produto?empresa='+REF.state.empresa, columnsSearch).then(() => REF.setState({ loading: false }));
  }

  componentDidMount(){
    this.updateListing();

    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  isColumnHidden = (column) => {

    if(typeof this.props.hidden_column === "undefined")
    return false;

    return this.props.hidden_column.split(',').includes(column);
  }

  getColumns = () => {
    return [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },{
        dataField: 'codigo',
        text: 'Cód. Manual',
        sort: true,
        hidden:true,
        exclude:false,
      },{
        dataField: 'categoria',
        text: 'Categoria',
        hidden:true
      },{
        dataField: 'pc_nome',
        text: 'Categoria',
        sort: true,
        headerClasses: 'alpha'
      },{
        dataField: 'grupo',
        text: 'Grupo',
        hidden:true
      },{
        dataField: 'pg_nome',
        text: 'Grupo',
        hidden:true
      },{
        dataField: 'subgrupo',
        text: 'Subgrupo',
        hidden: true
      },{
        dataField: 'sbg_nome',
        text: 'Subgrupo',
        hidden:true
      },{
        dataField: 'produto',
        text: 'Nome',
        sort: true,
        headerClasses: 'alpha'
      },{
        dataField: 'unidade',
        text: 'UN',
      },{
        dataField: 'preco',
        text: 'Preço',
        sort: true,
        headerClasses: 'cardinal'
      },{
        dataField: 'custo',
        text: 'Custo',
        sort: true,
        hidden:true,
        exclude:false,
        headerClasses: 'cardinal'
      },
      {
        dataField: 'desconto',
        text: 'Desconto',
        sort: true,
        hidden:true,
        exclude:false,
        headerClasses: 'cardinal'
      },
      {
        dataField: 'estoque_format',
        text: 'Estoque \n frente',
        sort: true,
        headerClasses: 'alpha'
      },{
        dataField: 'estoquefundo',
        text: 'Estoque \n fundo',
        sort: true,
        hidden:true,
        exclude:false,
        headerClasses: 'alpha'
      },{
        dataField: 'qtd',
        text: 'Qtd.',
        hidden: this.isColumnHidden('qtd'),
        sort: true,
        headerClasses: 'cardinal'
      },{
        dataField: 'total',
        text: 'Total',
        hidden: this.isColumnHidden('total'),
        sort: true,
        headerClasses: 'cardinal'
      },{
        dataField: 'ativo_format',
        text: 'Ativo'
      },{
        dataField: 'excluido_format',
        text: 'Excluído'
      }
    ];
  }

  tablerowupdate(form){
    let data = this.state.data; 
    this.setState({ data: this.updateTableRow(data, this.state.activeIndex, form) });
  
  }

  updateTableRow(array, target_index, form) {
    return array.map((item, index) => {
      if (index == target_index) {
        let item_replace = Object.assign(item, form.state);
       
        // This isn't the item we care about - keep it as-is
        return item_replace;
      }
  
      // Otherwise, this is the one we want - return an updated value
      return item;
    })
  }

  formUpdate(form_data_complete){
    this.setState({ form: form_data_complete });
  }

  onKeyPressed(event){
    if(event.keyCode == 27 )
    this.setState({ popoverVisible : false });
  }

  getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }




 
render(){    
  let REF = this;
  return (
  <>
       
          {
            (() => REF.tableRender())()
          }
  </>
  )
}
        }
        
        export default ProdutosDataTable;
        
        