import React from "react"

export default props => {
    if (props.show === true) {
        return (
            <div className="carregando">
                <div class="spiner"></div>
            </div>
        )
    } else {
        return <span></span>
    }
}
