import React from 'react';
import axios from 'axios';
import {Row, Button, Collapse, Col} from 'react-bootstrap';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet, permissionLabels} from './Auth';
import { inputChangeHandler, isValid, inputBlurHandler, checkboxClickHandler, radioSwitchHandler } from '../CustomForm';

class UsuarioForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa: sessionGet('empresa'),
      nome: '',     
      validator: {},
      show: false,
      data_removido_format: undefined,
      perms_open: false,
      permission_list: {},
      fetchComplete: false,
      labels: permissionLabels
    };
    
    this.checkboxClickHandler = checkboxClickHandler.bind(this);
    this.radioSwitchHandler = radioSwitchHandler.bind(this);
    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){

    if(this.state.id !== this.props.form.id){
      this.setState({ fetchComplete: false, ...this.props.form, status: this.props.form.status.toString() });
   
      this.props.modalcontrol(this);
    } else {

      this.loadDefaultPermissions();
    }

    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);

    }

  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }

    if(prevState.id !== this.state.id){
      this.loadPermissionList(this.state.id);
    }
  }

  loadPermissionList = (id) => {
    let REF = this;

    axios.get(CFG.URL_API+'/usuario-permissao?id='+id, axiosHeader())
    .then(function(response){

      if(response.data.length === 0)
      return REF.loadDefaultPermissions();

      //Perform action based on response
      let permission_list = response.data[0];
    
      let perm_list = Object.entries(permission_list);
      perm_list.map(( [key, value], index) => { 
        if(value === 1 || id === 1)
        REF.setState({ ["f_"+key]: true });
      });
      
      REF.setState({ permission_list, fetchComplete: true });
    })
    .catch(function(error){
      //Perform action based on error
      return REF.loadDefaultPermissions();
    });
  }

  loadDefaultPermissions = () => {
    this.loadPermissionList(1);
  }


   post () {
     let REF = this;
    axios.post(CFG.URL_API+'/usuario', this.state, axiosHeader())
    .then(function (response){
      //Perform action based on response
       REF.putPermissions(response.data.id);
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

   put ()  {
     let REF = this;
    axios.put(CFG.URL_API+'/usuario/'+this.state.id, this.state, axiosHeader())
    .then(function  (response){
      //Perform action based on response
       REF.putPermissions(response.data.id);
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   putPermissions = (id) => {

    let update = this.state.permission_list.id !== 1;
    let pl = this.state.permission_list;
    let perm_list = Object.entries(this.state);
    let up_list = {};
    perm_list.map(( [key, value], index) => {
      if(key.search("f_") !== -1){
        let k_up = key.replace(/f_/g, '');
        up_list[k_up] = value ? 1 : 0;
      }
    });

    up_list.id = id;

    pl = Object.assign(pl, up_list);
    this.setState({ permission_list: pl });

    if(update)
    return axios.put(CFG.URL_API+'/usuario-permissao/'+this.state.id, pl, axiosHeader()).finally(() => window.location.reload());
    else{
    return axios.post(CFG.URL_API+'/usuario-permissao/', pl, axiosHeader()).finally(() => window.location.reload());
    }
   }

   render(){
    let REF = this;

    const togglePerms = (perms_open) => {
      REF.setState({ perms_open })
    }

   return(
     <>
  <input type="hidden" name="empresa" defaultValue={this.state.empresa} />
<div className="row">      
<div className="form-group col-md-3">         
      <label htmlFor="f-id">
        ID
      </label>
      <input readOnly className="form-control text-center" name="id" id="f-id" type="text" value={this.state.id} onChange={this.inputChangeHandler} />
    </div>
    <div className="form-group col-md-9">         
      <label htmlFor="f-nome">
        Nome
      </label>
      <input className="form-control" name="nome" id="f-nome" type="text" value={this.state.nome} onChange={this.inputChangeHandler} />
    </div>
</div>
<Row>
<div className="form-group col-md-6">         
      <label htmlFor="f-login">
        Login
      </label>
      <input className="form-control" name="login" id="f-login" type="text" value={this.state.login} onChange={this.inputChangeHandler} />
    </div>
    <div className="form-group col-md-6">         
      <label htmlFor="f-senha">
        Senha
      </label>
      <input className="form-control" name="senha" id="f-senha" type="text" value={this.state.senha} onChange={this.inputChangeHandler} />
    </div>
</Row>
<Row>
<div className="form-group col-md-6">         
<ul className="list-inline">
      <li className="list-inline-item">
          <div className="custom-control custom-radio">
            <input name="status" className="custom-control-input" id="f-status-1" type="radio" value="1" checked={this.state.status === "1"} onChange={this.radioSwitchHandler} />
            <label className="custom-control-label" htmlFor="f-status-1">
          Ativo    </label>
            </div>
          </li> 
        <li className="list-inline-item">
          <div className="custom-control custom-radio">
            <input name="status" className="custom-control-input" id="f-status-0" type="radio" value="0" checked={this.state.status === "0"} onChange={this.radioSwitchHandler} />
            <label className="custom-control-label" htmlFor="f-status-0">
          Inativo   </label>
            </div>
          </li>   
            </ul>
     </div>
</Row>
<fieldset>
  <legend>
  <Button
        variant="link"
        onClick={() => togglePerms(!REF.state.perms_open)}
        aria-controls="permissions"
        aria-expanded={REF.state.perms_open}
      >
        Editar permissões
      </Button>
  </legend>
  <Collapse in={REF.state.perms_open}>
        <div id="permissions">
          <Row>
            {this.displayPermissions()}
          </Row>
       </div>
      </Collapse>
</fieldset>

     
</>
);
   }

   displayPermissions = () => {
    let REF = this;
    if(!this.state.fetchComplete)
    return (<></>);

     let perm_list = Object.entries(this.state.permission_list);
      return perm_list.map(( [key, value], index) => {

        if(key === "id")
        return (<></>);

          return (
            <Col xs={6} lg={4} key={index}>
              <label>
                <input className="mr-2" onClick={this.checkboxClickHandler} type="checkbox" name={"f_"+key} value={"1"} checked={this.state["f_"+key] === true} />
                 {typeof REF.state.labels[key] !== "undefined" ? REF.state.labels[key] : key}
              </label>
            </Col>
          )
      });
   }

   delete = () => {
    let REF = this;
    axios.delete(CFG.URL_API+'/usuario/'+this.state.id, axiosHeader())
    .then(function(response){
      axios.delete(CFG.URL_API+'/usuario-permissao/'+REF.state.id, axiosHeader());
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default UsuarioForm;