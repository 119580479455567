import React from 'react'; // Import React
import {getListing, CustomToggleList, tableRender} from '../CustomDataTable'; // Import React
import '../DataTable.scss';
import {Col} from 'react-bootstrap';
// es6
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Route, Redirect } from 'react-router';
import GrupoProdutoModal from './GrupoProdutoModal';
import {sessionGet} from '../usuario/Auth';


class GrupoProdutosDataTable extends React.Component{
  
  constructor(props) {
    super(props);
    
    this.state = {
        empresa: sessionGet('empresa'),
        form:[],
        data: [],
        searchTerm: null,
        activeIndex: undefined
    };

    this.hideModal = this.hideModal.bind(this);
    this.tablerowupdate = this.tablerowupdate.bind(this);
    this.formUpdate = this.formUpdate.bind(this);

    this.getCustomToggleList = CustomToggleList.bind(this);
    this.getListing = getListing.bind(this);
    this.tableRender = tableRender

    this.updateListing();

  }

  updateListing = (columnsSearch) => {

    this.getListing('grupo-produto?empresa='+this.state.empresa, columnsSearch);
  
   }

   getCustomToggleList({
    columns,
    onColumnToggle,
    toggles
  }){
    return CustomToggleList({
      columns,
      onColumnToggle,
      toggles
    })
  }


  componentDidUpdate(prevProps){

    if(prevProps.form != this.props.form){
      this.setState({ form: this.props.form });
    }

  }


  setModalShow (status, data){

    if(data != null){

      this.setState({  form : data });
    }

    this.setState({  show: status });
  }

  hideModal(){
    this.setState({ show: false });
    this.updateListing();
  }


  getColumns(){
    return [
      {
        dataField: 'id',
        text: 'ID',
        //    hidden: true
        sort: true
      },{
        dataField: 'empresa',
        text: 'Empresa',
        sort: true,
        hidden: true
      },{
        dataField: 'grupo',
        text: 'Grupo'
      }
    ];
  }

  tablerowupdate(form){
    let data_updated = this.updateTableRow(this.state.data, this.state.activeIndex, form);
    
    let REF = this;
    setTimeout(() => {
      REF.setState({ data: data_updated });
      REF.updateListing();
    }, 300);
  }

  updateTableRow(array, target_index, data) {
    return array.map((item, index) => {
      if (index == target_index) {
        // This isn't the item we care about - keep it as-is
        return Object.assign(item, data);
      } else {
   // Otherwise, this is the one we want - return an updated value
   return item;
      } 
    })
  }

  formUpdate(form_data_complete){
    this.setState({ form: form_data_complete });
  }

      render(){
        return (
        <>
              <GrupoProdutoModal
                show={this.state.show}        
                form={this.state.form}        
                onHide={this.hideModal}
                tablerowupdate={this.tablerowupdate}
                formupdate={this.formUpdate}
                />  
            
                {
                  (() => this.tableRender())()
                }
        </>
        )
      }
        }
        
        export default GrupoProdutosDataTable;
        
        