import React from 'react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Card, Accordion, ListGroup, ListGroupItem } from 'react-bootstrap';


class RelatoriosMenu extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            show: this.props.show,
            extraclasses: ''
        };
  }

  toggle = () => {
    let REF = this;

    this.props.onchange();

    if(this.state.show){
        this.setState({ show: false, extraClasses: '' });
        setTimeout(() => REF.setState({ extraClasses: '' }), 500);
    } else {
        this.setState({ show: true, extraClasses: 'active' });
    } 
}

    render(){
        return (
            <div className="accordion">
            <Card>
            <Card.Header className="lv-1 no-chevron">
                     <Link  onClick={this.props.ontoggle} to="/relatorios">
                <div className="w-icon">
                    <i className="fa fa-file-text-o"></i></div> <span className="text">Relatórios</span>
                </Link>
                </Card.Header>
        </Card>
        </div>
        );
    }

}   

export default RelatoriosMenu;

