import React from "react"

import { Card, Accordion, ListGroup, ListGroupItem } from "react-bootstrap"
import { Link } from "react-router-dom"
import ProdutosMenu from "./ProdutosMenu"
import CartoesMenu from "./CartoesMenu"

class CadastrosMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: this.props.show,
            extraclasses: "",
            session: {
                user: {}
            }
        }
    }

    toggle = () => {
        let REF = this

        this.props.onchange()

        if (this.state.show) {
            this.setState({ show: false, extraClasses: "" })
            setTimeout(() => REF.setState({ extraClasses: "" }), 500)
        } else {
            this.setState({ show: true, extraClasses: "active" })
        }
    }

    componentDidMount() {}

    render() {
        return (
            <Accordion>
                <Card>
                    <Accordion.Header
                        onClick={this.toggle}
                        className={"lv-1 " + this.state.extraClasses}
                        as={Card.Header}
                        eventKey="0"
                    >
                        <div className="w-icon">
                            <i className="fa fa-plus-square"></i>
                        </div>{" "}
                        <span className="text">Cadastros</span>{" "}
                    </Accordion.Header>
                    <Accordion.Body eventKey="0">
                        <Card.Body>
                            
                            <ListGroup>
                                <ListGroupItem
                                    onClick={this.props.ontoggle}
                                     >
                                    <Link to="/empresa/dados-cadastrais" className="lv-2">
                                        <div className="w-icon">
                                            <i className="fa fa-file-text-o"></i>
                                        </div>
                                        Dados da Empresa
                                    </Link>
                                </ListGroupItem>
                                <ListGroupItem
                                    onClick={this.props.ontoggle}
                                    className={window.userCanClass("usuarios")}
                                >
                                    <Link to="/usuario/" className="lv-2">
                                        <div className="w-icon">
                                            <i className="fa fa-file-text-o"></i>
                                        </div>
                                        Gerenciar usuários
                                    </Link>
                                </ListGroupItem>
                                <ListGroupItem onClick={this.props.ontoggle}>
                                    <Link to="/turma/" className="lv-2">
                                        <div className="w-icon">
                                            <i className="fa fa-file-text-o"></i>
                                        </div>
                                        Gerenciar turmas
                                    </Link>
                                </ListGroupItem>
                            </ListGroup>
                            <CartoesMenu
                                show={this.props.show}
                                onchange={this.props.onchange}
                                ontoggle={this.props.ontoggle}
                            />

                            <ProdutosMenu
                                show={this.props.show}
                                onchange={this.props.onchange}
                                ontoggle={this.props.ontoggle}
                            />
                        </Card.Body>
                    </Accordion.Body>
                </Card>
            </Accordion>
        )
    }
}

export default CadastrosMenu
