import React from 'react';
import {Tabs, Tab } from 'react-bootstrap';

import ImpostosForm from './ImpostosForm';
import InfoAdicionaisForm from './InfoAdicionaisForm';
import TransporteForm from './TransporteForm';

class CalculosTab extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      produto_id: 0,
      icone_select_id : '',
      icone_select_url : '',
      produto_id: '',
      key: 'home',
      formula_enabled: false
    };
      
  }

  componentDidUpdate = (prevProps) => {

    if(this.props.form != null
      && Object.getOwnPropertyNames(this.props.form).length > 0
      && this.state.produto_id != this.props.form.id){
      this.setState({ produto_id: this.props.form.id });   
    }
  }

  setCurrentIcon = (id, url) => {
    this.setState({ icone_select_id: id, icone_select_url: url });
  }

  toggleFormula = (status) => {
    this.setState({ formula_enabled: status });
  }

  

  render(){
    let REF = this;

    const tabTransporte = () => {

    
      return (
         <Tab eventKey="transporte" title="Transporte" >
        <TransporteForm submit={REF.props.submit} post_confirm={REF.props.post_confirm} import={REF.props.import} tipo={REF.props.tipo} formcontrol={REF.props.formcontrol} form={REF.props.transporte} />
      </Tab>   
      );
    }

    return (
            <>

      <div className="DadosTab">


      <Tabs defaultActiveKey="impostos" transition={false}>
            <Tab eventKey="impostos" title="Impostos">
                <ImpostosForm update_vldocumento={this.props.update_vldocumento} form={this.props.impostos} formcontrol={this.props.formcontrol} />
            </Tab>
            <Tab eventKey="infoadicionais" title="Info. Adicionais">
                <InfoAdicionaisForm  formcontrol={this.props.formcontrol} form={this.props.info_adicional}  />
            </Tab> 
            {tabTransporte()}       
      </Tabs>
  
        </div>
        </>
    );
  }
}


export default CalculosTab;