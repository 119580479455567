import React from 'react';
import axios from 'axios';
import {CFG, axiosHeader} from '../CFG';
import {sessionGet} from '../usuario/Auth';
import { inputChangeHandler, isValid, inputBlurHandler } from '../CustomForm';

class SangriaInfoForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      empresa: sessionGet('empresa'),
      descricao: '',     
      validator: {},
      show: false,
      data_removido_format: undefined
    };

    this.inputChangeHandler = inputChangeHandler.bind(this);
    this.inputBlurHandler = inputBlurHandler.bind(this);

    this.isValid = isValid.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);

  }

  componentDidMount(){
    if(this.state.id != this.props.form.id){
      this.setState({ ...this.props.form });
      console.log(this.state);

      this.props.modalcontrol(this);
    }

    this.updateDateIfRemoved();

  }

  updateDateIfRemoved = () => {

    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      let dt = new Date(this.state.data_removido);
      console.log(dt);
      let dmy = dt.getDate()+'/'+dt.getMonth()+'/'+dt.getFullYear(); 
      this.setState({ data_removido_format : dmy })
      this.props.modalcontrol(this);

    }

  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.data_removido_format == undefined && this.state.data_removido != null){
      this.updateDateIfRemoved();
    }
  }

 

   post (){
    axios.post(CFG.URL_API+'/sangria-info', this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
      window.location.reload();
  })
    .catch(function(error){
      //Perform action based on error
    });
   }
   put () {
    axios.put(CFG.URL_API+'/sangria-info/'+this.state.id, this.state, axiosHeader())
    .then(function(response){
      //Perform action based on response
 
  })
    .catch(function(error){
      //Perform action based on error
    });

    return true;
   }

   render(){

   return(
     <>
  <input type="hidden" name="empresa" defaultValue={this.state.empresa} />
<div className="row">      
<div className="form-group col-md-3">         
      <label htmlFor="f-id">
        ID
      </label>
      <input readOnly className="form-control text-center" name="id" id="f-id" type="text" value={this.state.id} onChange={this.inputChangeHandler} />
    </div>
    <div className="form-group col-md-9">         
      <label htmlFor="f-descricao">
        Descrição
      </label>
      <input className="form-control" name="descricao" id="f-descricao" type="text" value={this.state.descricao} onChange={this.inputChangeHandler} />
    </div>
</div>

</>
);
   }

   delete = () => {

    axios.delete(CFG.URL_API+'/sangria-info/'+this.state.id, axiosHeader())
    .then(function(response){
      
      //Perform action based on response
  })
    .catch(function(error){
      //Perform action based on error
    });
   }

 
 
}
export default SangriaInfoForm;