import React from 'react';
import {Tabs, Tab, Overlay, OverlayTrigger, Button, Popover} from 'react-bootstrap';
import FormaPagamentoForm from './FormaPagamentoForm';
import ParcelasForm from './ParcelasForm';
import './FormaPagamentoTab.scss';
import './../Popover.scss';

class FormaPagamentoTab extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      key: 'home',
      submit_status: {
        FormaPagamentoForm: false,
        ParcelasForm: false
      }
    };

}

  componentDidUpdate(prevProps){
    if(this.state.submit_status.FormaPagamentoForm){

      this.setState({ submit_status: {
        FormaPagamentoForm: false
      } });

      this.props.close();
    }
  }
  
  render(){

    const REF = this;

    const setKey = (k) => {
      this.setState({ key: k });

    }

    const updateSubmitStatus = (formName) => {
      let submit_status = REF.state.submit_status;
      submit_status[formName] = true;
      this.setState({ submit_status });
      this.props.close();
    }

   
    const pop = (
      <Popover id="popover-forma-pagamento">
        <Popover.Title as="h3">Parcelas</Popover.Title>
        <Popover.Content>
         Marque opção de tipo "A prazo" para habilitar a aba Parcelas
        </Popover.Content>
      </Popover>
    );


    return (
      <>

      <div className="FormaPagamentoTab">
      {/*<Overlay target={REF.current} placement="top" show={this.props.tipo != "1"}>
  {pop}
  </Overlay>*/}
      
          <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example"  activeKey={this.state.key} onSelect={k => setKey(k)}>
              <Tab eventKey="home" title="Dados">
                <FormaPagamentoForm close={this.props.close} submit_confirm={updateSubmitStatus} update_tipo={this.props.update_tipo} set_id={this.props.set_id} update_npar={this.props.update_npar} npar={this.props.npar} submit={this.props.submit} show={this.props.show} form={this.props.form} formcontrol={this.props.formcontrol} />
              </Tab>
              {/*<Tab ref={REF} eventKey="profile" title="Parcelas" disabled={this.props.tipo !== "1"}>
                <ParcelasForm submit_confirm={updateSubmitStatus} tipo={this.props.tipo}  id={this.props.id} update_npar={this.props.update_npar} npar={this.props.npar} submit={this.props.submit_parcelas} show={this.props.show} form={this.props.form} formcontrol={this.props.formcontrol} />
            </Tab>*/}
            </Tabs>
        </div>
        </>
    );
  }
}


export default FormaPagamentoTab;