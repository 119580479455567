import React from 'react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Card, Accordion, ListGroup, ListGroupItem } from 'react-bootstrap';

class EstoqueMenu extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            show: this.props.show,
            extraclasses: ''
        };
    }

    toggle = () => {
        let REF = this;

        this.props.onchange();

        if(this.state.show){
            this.setState({ show: false, extraClasses: '' });
            setTimeout(() => REF.setState({ extraClasses: '' }), 500);
        } else {
            this.setState({ show: true, extraClasses: 'active' });
        } 
    }

    render(){

        return (
            <Accordion hidden={window.userCan.estoque !== 1}>
            <Card>
                <Accordion.Header onClick={this.toggle} className={"lv-2 " + this.state.extraClasses} as={Card.Header} eventKey="0">
                <div className="w-icon"><i className="fa fa-cubes"></i></div>Estoque
                </Accordion.Header>
                <Accordion.Body eventKey="0">
                <Card.Body>

                <ListGroup>
                    <ListGroupItem><Link className="lv-2" onClick={this.props.ontoggle} to="/estoque/"><div className="w-icon"><i className="fa fa-file-text-o"></i></div>Entrada/Saída de estoque manual</Link></ListGroupItem>
                    <ListGroupItem><Link className="lv-2" onClick={this.props.ontoggle} to="/nota/"><div className="w-icon"><i className="fa fa-exchange"></i></div>Nota Fiscal</Link></ListGroupItem>
                 </ListGroup>       

                </Card.Body>
                </Accordion.Body>
            </Card>
        </Accordion>

        );
    }

}   

export default EstoqueMenu;




