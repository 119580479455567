import React from 'react';
import RecargaDataTable from './RecargaDataTable'; 
import RecargaModal from './RecargaModal';
import {setUser, setUserPermissions} from '../usuario/Auth';
import ReactToPrint from 'react-to-print';
import {floatToReal} from '../util/inputMask';
import {Modal, Button} from 'react-bootstrap';
import url_btn_close from '../img/icons/close.png';
import { CFG } from '../CFG';
import './RecargaComprovante.scss';
import logo from "../img/logomarca-lanchar-top.png"

class RecargaComprovante extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      refresh: false,
      show: false,
      form: {},
      session: {
        user: {

        }
      }
    }
  }



  componentDidUpdate(prevProps){
    if(prevProps.show !== this.props.show && this.props.form.id !== this.state.id){
      this.setState({ ...this.props.form });
    }
  }

  
  render (){

    const componentRef = React.createRef();

    let REF = this;
    return (  
      <>
        <Modal   
          {...this.props}
          size="md"
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header className="py-2">
            <Modal.Title id="contained-modal-title">
              {"Comprovante da recarga "+this.props.form.id} 
            </Modal.Title>
            <img className="close" src={url_btn_close} onClick={this.props.onHide} />
          </Modal.Header>
          <Modal.Body style={{ minHeight: '35vh' }} ref={componentRef}>        
              <table class="table receipt">
                <tbody>
                  <tr>
                    <td colspan="2">
                    <div className="w-100">
                        <img src={logo} className="d-block m-auto" style={{ width: '120px' }} />
                      </div>
                    </td>
                  </tr>
                    <tr>
                      <td className="text-center">{CFG.APP_NAME}</td>
                      <td className="text-center">Recarga</td>
                    </tr>
                    <tr>
                      <td>Empresa</td>
                      <td>{this.state.razao}</td>
                    </tr>
                    <tr>
                      <td>Data</td>
                      <td>{this.state.pagto_format}</td>
                    </tr>
                    <tr>
                      <td>ID do cartão</td>
                      <td>{this.state.cartao}</td>
                    </tr>
                    <tr>
                      <td>Titular do cartão</td>
                      <td>{this.state.titular}</td>
                    </tr>
                    <tr>
                      <td>CV</td>
                      <td>{this.state.unique}</td>
                    </tr>
                    <tr>
                      <td>Saldo anterior</td>
                      <td>{floatToReal(parseFloat(this.state.saldo_anterior))}</td>
                    </tr>
                    <tr>
                      <td>Saldo atual</td>
                      <td>{floatToReal(parseFloat(this.state.credito))}</td>
                    </tr>
                
                    <tr>
                      <td>Descrição</td>
                      <td><span className="badge badge-info">{this.state.status_format}</span></td>
                    </tr>
                 
                    <tr>
                      <td>Terminal: {this.state.terminal}</td>
                      <td>Usuário: {this.state.terminal_nome}</td>
                    </tr>
                    <tr>
                      <td>Recarga</td>
                      <td>{this.state.totalFormat}</td>
                    </tr>
                    <tr>
               
                      <td>Acesso</td>
                      <td>{CFG.URL_APP}</td>
                    </tr>
                </tbody>
              </table>
          </Modal.Body>
          <Modal.Footer>

          <ReactToPrint
                  trigger={() => <button className="btn-sm btn-secondary btn-print px-2 py-0"><i className="fa fa-file-text-o"></i></button>}
                  content={() => componentRef.current}
                />                 
          </Modal.Footer>
        </Modal>
      </>
      );
    }
  }
  
  export default RecargaComprovante;