import React from 'react';
import '../../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../../util/LoadingModal';
import url_btn_close from '../../img/icons/close.png';

class NotaFiscalModal extends React.Component{

  constructor(props) {
    super(props);

    this.state = {  
      form: {},
      loading: false,
      data_removido_format: undefined,
      allow_delete: false
    };

  }
    
  modalcontrol = (form_comp) => {
    this.setState({ form: form_comp });
  }

  onKeyPressed = (event) => {
    if(event.keyCode == 13 )
    this.formSubmit();
  }

  componentDidUpdate(prevProps, prevState){
    this.updateButtons();
  }

  updateButtons = () => {

      if(Object.getOwnPropertyNames(this.state.form).length > 0){
        let me = this;    
        setTimeout(() => {
        if(typeof me.state.form.state.data_removido_format === "undefined"){
          me.setState({ data_removido_format: undefined });

          if(typeof me.state.form.state.id !== "undefined"){
            me.setState({ allow_delete: true });
          }

        } else {
          me.setState({ allow_delete: false, data_removido_format: me.state.form.state.data_removido_format });
        }

      }, 2000)    

      }
  }

  componentDidMount(){
    this.updateButtons();
  
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  render () {
    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
              {this.props.tipo == "EN" ? 'Entrada' : 'Saída' } de NotaFiscal <small>{Object.getOwnPropertyNames(this.props.form).length > 0 ? '#'+this.props.form.id : ''}</small>
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body>        
            
        </Modal.Body>
      </Modal>
      <LoadingModal show={this.state.loading} />
      </>
    );
  }
  formSubmit = () => {

    let REF = this;

    if(this.state.form != {} && !this.state.form.isValid())
    return false;
    else
    this.setState({ loading: true });

    if(Object.getOwnPropertyNames(this.state.form).length === 0){
      this.setState({ loading: false });
      this.props.onHide();
      return false;
    }

    if(this.state.form.state.id != null){
      this.state.form.put();
    } else {
      this.state.form.post();
    }

    setTimeout(() => {
      REF.setState({ loading: false })
      REF.props.onHide();
    } , 1000);

  }
    
  formDelete = () => {
    let c = window.confirm("Deseja mesmo remover a "+(this.props.tipo == "EN" ? 'entrada' : 'saída')+" "+this.props.form.id+" ?");
    if (c == true) {
      if(this.state.form.state.id)
      this.state.form.delete();
      this.props.onHide();  
      window.location.reload();
    }
  }
}

export default NotaFiscalModal;