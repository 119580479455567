import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import CartaoTab from './CartaoTab';
import url_btn_close from '../img/icons/close.png';

class CartaoModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = {   
        loading: false,
        allowConfirm: false,   
        alerta:{show:false,variant:"",message:""}
      };

      this.closeTrigger = this.closeTrigger.bind(this);
      this.alerta = this.alerta.bind(this);
      this.errorSubmit = this.errorSubmit.bind(this);
      
      

    }

    componentDidMount(){
      this.setState({ submit: false, loading: false });
    
    }

    componentDidUpdate(prevProps, prevState){

      if(prevProps.show !== this.props.show){
        this.setState({ id: this.props.form.id });

        this.setState({ submit: false, loading: false, allowConfirm: false });

        let REF = this;
        setTimeout(() => { 
          REF.setState({ allowConfirm: true });
        }, 3000);
      }
      
    }


  closeTrigger  (form_name) {
    let REF = this;
    this.setState({ [form_name]: true });

    setTimeout(() => {
      REF.setState({ 
        loading: false, 
        submit: false, 
        allowConfirm: false,
        alerta:{show:false,variant:"",message:""}
      });
      REF.props.onHide();
    }, 1000);
  }


  alerta(variant,message){
    this.setState({alerta:{show:true,variant,message}})
    setTimeout(()=>{
      this.setState({alerta:{show:false,variant:"",message:""}})
    },5000)
  }

  errorSubmit(message){
    this.alerta("danger",message);
    this.setState({ submit: false, loading: false });

  }
  componentWillUnmount(){
    this.setState({ allowConfirm: false });
  }

  render () {
    let REF = this;

    const setId = (id) => {
      REF.setState({ id });
    };

    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            Cartão {this.props.form.id}
          </Modal.Title>
          <img className="close" alt="" src={url_btn_close} onClick={this.props.onHide} />
        </Modal.Header>
        <Modal.Body>        
          <CartaoTab
            errorSubmit={this.errorSubmit}
            setid={setId}
            id={this.state.id}
            submit={this.state.submit}
            responsavel_id={this.props.responsavel_id}
            show={this.props.show}
            form={this.props.form}
            close={this.closeTrigger}
          />       
        </Modal.Body>
        <Modal.Footer>
        {this.state.alerta.show && <div className={`alert alert-${this.state.alerta.variant}`}>{this.state.alerta.message}</div>}

          <Button hidden={!this.state.allowConfirm} onClick={REF.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }

  formSubmit = () =>  {
    this.setState({ submit: true, loading: true });
  }  

}

export default CartaoModal;