import React from 'react';
import '../../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../../util/LoadingModal';
import FiltroForm from './FiltroForm';
import url_btn_close from '../../img/icons/close.png';

class FiltroModal extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      empresa: this.props.empresa,
      formFilters: {},
      loading: false
    };

  }

  modalControl = (form_comp) => {
    this.setState({ formFilters: form_comp });
  }

  componentDidUpdate(prevProps, prevState){

    if(this.props.loading != this.state.loading){
      let REF = this;
      REF.setState({ loading: this.props.loading });
      this.props.onHide();
    }

  }

  render () {
    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
            Filtro do Extrato de Movimentação {this.state.tipo_movimentacao} 
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />

        </Modal.Header>
        <Modal.Body>        
          <FiltroForm
            empresa={this.props.empresa}
            show={this.props.show}
            modalcontrol={this.modalControl}
            setfilters={this.props.setfilters}
          />       
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading}/>
      </>
    );
  }
  
  formSubmit = () =>  {

    let REF = this;

    if(!this.state.formFilters.isValid())
    return false;
    else
    this.setState({ loading: true });

    if(Object.getOwnPropertyNames(this.state.formFilters).length === 0){
      this.setState({ loading: false });
      this.props.onHide();
      return false;
    }
 
      this.props.setfilters(REF.state.formFilters.formattedState());

   
  }  
}

export default FiltroModal;