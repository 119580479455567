import React from "react"
import axios from "axios"
import { sessionGet } from "../usuario/Auth"
import { requestException } from "../util/exception"
import { CFG, axiosHeader } from "../CFG"
import { Alert } from "react-bootstrap"
import Select from "react-select"

class CategoriaSelect extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            empresa: sessionGet("empresa"),
            list: [],
            categoria: this.props.categoria,
            categoriaText:'',
            validator: {},
            value: null
        }
        this.inputChangeHandler = this.inputChangeHandler.bind(this)
        this.get = this.get.bind(this)
    }

    componentDidMount() {
        this.get()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.empresa != this.props.empresa) this.setState({ empresa: this.props.empresa })

        if (this.props.categoria != this.state.categoria)
            this.setState({ categoria: this.props.categoria })

        if (prevState.list != this.state.list) {
            let REF = this
            let find = REF.state.list.find(([value, text]) => {
                return value == REF.state.categoria
            })
            if (typeof find !== "undefined") {
                let value = {
                    value: find[0],
                    label: "( " + find[0] + " )".padEnd(10, " ") + find[1]
                }
                setTimeout(() => REF.setState({ value }), 300)
            }
        }
    }

    /*
    inputChangeHandler(data, event) {
        this.setState({ value: data })
        this.props.change(event.name, data.value)
    }
    */
    inputChangeHandler(pair, actionMeta){
        this.setState({ value: pair });
        if(typeof this.props.change === "function")
        if(actionMeta != ''){
            this.props.change(pair, actionMeta);
            this.props.validate(true);
        }else{
            this.props.validate(false);
        }
    }

    render() {
        if (!this.state.loading && this.state.list.length == 1)
            return <Alert variant="warning">Lista vazia</Alert>

        let REF = this

        return (
            <>
                <Select
                    noOptionsMessage={() =>
                        REF.state.loading
                            ? "Carregando opções..."
                            : "Lamento. Não há opções disponíveis"
                    }
                    readOnly={typeof this.props.readonly !== "undefined"}
                    name="categoria"
                    className={this.props.error && 'is-error'}
                    value={REF.state.value}
                    isClearable={false}
                    placeholder="pesquisar e selecionar"
                    id="f-categoria"
                    onChange={this.inputChangeHandler}
                    options={this.state.list.map(([value, text]) => {
                        return { value, label: "( " + value + " )".padEnd(10, " ") + text }
                    })}
                />
            </>
        )
    }

    get() {
        let CS = this

        axios
            .get(
                CFG.URL_API +
                    "/produto-categoria?empresa=" +
                    this.state.empresa +
                    "&selectMode=pluck&pluckKey=id&pluckValue=categoria",
                axiosHeader()
            )
            .then(function(response) {
                let entries = Object.entries(response.data)

                let htmlList = []

                htmlList = entries.sort(function(a, b) {
                    return a[1] > b[1] ? 1 : -1
                })

                CS.setState({ list: htmlList, loading: false })
            })
            .catch(function(error) {
                requestException(error)
            })
    }
}

export default CategoriaSelect
