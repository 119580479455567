import React from 'react';
import CFG from '../CFG';
import EmpresaSelect from './EmpresaSelect';
import { Dropdown, Button } from 'react-bootstrap';
import {updateEmpresa} from '../usuario/Auth';
import {sessionGet} from '../usuario/Auth';

class EmpresaDropdown extends React.Component{

    constructor(props){
        super(props);
        
        this.state = {
            empresa: sessionGet('empresa'),
            razao: sessionGet('empresa_razao')
        };
    }

    onEmpresaClick = (event) => {
        this.setState({ razao: event.currentTarget.getAttribute('data-razao') });
        updateEmpresa(event.currentTarget.getAttribute('data-grupo'), event.currentTarget.getAttribute('data-cnpj'), event.currentTarget.getAttribute('data-key'), event.currentTarget.getAttribute('data-razao'));
        window.location.href = CFG.URL_APP;
	}

    render(){
        if(this.props.grupo != undefined){
            return (
                <Dropdown>
                <Dropdown.Toggle variant="info">
                   <span className="badge bg-warning">{this.state.empresa}</span> {this.state.razao}
                </Dropdown.Toggle>
                
                <Dropdown.Menu>
                    <EmpresaSelect empresa={sessionGet('empresa')} onClick={this.onEmpresaClick} />
                </Dropdown.Menu>
                </Dropdown>
            )
        } else {
          return (<Button className="razao-empty" disabled variant="secondary"><i className="icon-list"></i>  {sessionGet('empresa_razao')}</Button>)
        }
    }
    
}

export default EmpresaDropdown;

