import React from 'react';
import UsuarioDataTable from './UsuarioDataTable'; 
import {Button,ButtonToolbar} from 'react-bootstrap';
import UsuarioModal from './UsuarioModal';

class Usuarios extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      show: false,
      activeIndex: 0
    };

    this.props.titlebar(this.props.title, this.props.description);

    this.setModalShow = this.setModalShow.bind(this);
  }

  componentDidMount(){
      window.checkUserCan('usuarios');
  }

  setModalShow (status, data){

    if(data != null){
      this.setState({  data: data });
    }

    this.setState({  show: status, refresh: !status });
  }

  render (){
    let REF = this;
    return (<div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2"> 
            <ButtonToolbar className="float-right m-3">
                <Button variant="success" onClick={() => REF.setModalShow(true, [])}>
                  <span className="fa fa-plus-square"></span> Cadastrar
                </Button>              
              <UsuarioModal
                form={{}}
                show={this.state.show}             
                onHide={() => REF.setModalShow(false, [])}
              />
            </ButtonToolbar>            
          </div>
        </div>      
      <UsuarioDataTable setModalShow={REF.setModalShow} refresh={this.state.refresh}/>
      </div>);
    }
}
  
export default Usuarios;