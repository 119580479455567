import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import LoadingModal from '../util/LoadingModal';
import url_btn_close from '../img/icons/close.png';
import EstoqueForm from './EstoqueForm';

class EstoqueModal extends React.Component{

  constructor(props) {
    super(props);

    this.state = {  
      form: {},
      loading: false,
      data_removido_format: undefined,
      allow_delete: false,
      delete: false,
      submit: false
    };

  }
    
  modalcontrol = (form_comp) => {
    this.setState({ form: form_comp });
  }

  onKeyPressed = (event) => {
    if(event.keyCode == 13 )
    this.formSubmit();
  }

  componentDidUpdate(prevProps, prevState){
    this.updateButtons();
  }

  updateButtons = () => {

      if(Object.getOwnPropertyNames(this.state.form).length > 0){
        let me = this;    
        setTimeout(() => {
        if(typeof me.state.form.state.data_removido_format === "undefined"){
          me.setState({ data_removido_format: undefined });

          if(typeof me.state.form.state.id !== "undefined"){
            me.setState({ allow_delete: true });
          }

        } else {
          me.setState({ allow_delete: false, data_removido_format: me.state.form.state.data_removido_format });
        }

      }, 2000)    

      }
  }

  componentDidMount(){

    this.setState({ delete: false, submit: false, loading: false });
    this.updateButtons();
  
    document.addEventListener("keydown", this.onKeyPressed, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.onKeyPressed, false);
  }

  closeTrigger = () => {
    this.setState({ submit: false, loading: false, delete: false });
    this.props.onHide();
  }

  render () {
    return (
      <>
      <Modal   
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header className="py-2">
          <Modal.Title id="contained-modal-title">
              {this.props.tipo == "EN" ? 'Entrada' : 'Saída' } de Estoque <small>{Object.getOwnPropertyNames(this.props.form).length > 0 ? '#'+this.props.form.id : ''}</small>
          </Modal.Title>
          <img className="close" src={url_btn_close} onClick={this.props.onHide} />
        </Modal.Header>
        <Modal.Body>        
          <EstoqueForm
            delete={this.state.delete}
            submit={this.state.submit}
            close={this.closeTrigger}            
            tipo={this.props.tipo}
            show={this.props.show}
            form={this.props.form}
            modalcontrol={this.modalcontrol}
          />       
        </Modal.Body>
        <Modal.Footer  className="d-block">
          {
              (() => {
                if(this.state.allow_delete)
                return ( <Button className="btn-danger mr-auto" onClick={this.formDelete}>Excluir</Button>)
              })()
          }
          {
            (() => {
              if(this.state.data_removido_format != undefined)
              return (<div className="text-danger p-2 my-2 mx-auto">Removido em {this.state.data_removido_format}</div>)
              else {
                if(typeof this.state.form.state !== "undefined" && typeof this.state.form.state.id === "undefined")
                return ( <Button onClick={this.formSubmit}>Confirmar</Button>)              
              }
            })()
          }
        </Modal.Footer>
      </Modal>
      <LoadingModal show={this.state.loading} />
      </>
    );
  }


  formSubmit = () => {
    this.setState({ submit: true, loading: true });

  }
    
  formDelete = () => {
    let c = window.confirm("Deseja mesmo remover a "+(this.props.tipo == "EN" ? 'entrada' : 'saída')+" "+this.props.form.id+" ?");
    if (c == true) {
      this.setState({ delete: true, loading: true });

    }
  }
}

export default EstoqueModal;