import React from "react" // Import React
import { getListing, CustomToggleList, tableRender } from "../CustomDataTable" // Import React
import "../DataTable.scss"

import GrupoFornecedorModal from "./GrupoFornecedorModal"
import { sessionGet } from "../usuario/Auth"

class GrupoFornecedoresDataTable extends React.Component {
    state = {
        empresa_id: sessionGet("empresa"),
        form: [],
        data: [],
        searchTerm: null,
        activeIndex: undefined
    }
    constructor(props) {
        super(props)

        this.hideModal = this.hideModal.bind(this)
        this.tablerowupdate = this.tablerowupdate.bind(this)
        this.formUpdate = this.formUpdate.bind(this)

        this.getCustomToggleList = CustomToggleList.bind(this)
        this.getListing = getListing.bind(this)
        this.tableRender = tableRender

        this.updateListing()
    }

    updateListing = columnsSearch => {
        this.getListing("grupo-fornecedor?empresa_id=" + this.state.empresa_id, columnsSearch)
    }

    getCustomToggleList({ columns, onColumnToggle, toggles }) {
        return CustomToggleList({
            columns,
            onColumnToggle,
            toggles
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.form != this.props.form) {
            this.setState({ form: this.props.form })
        }
    }

    setModalShow(status, data) {
        if (data != null) {
            this.setState({ form: data })
        }

        this.setState({ show: status })
    }

    hideModal() {
        this.setState({ show: false })
        this.updateListing()
    }

    getColumns() {
        return [
            {
                dataField: "id",
                text: "ID",
                //    hidden: true
                sort: true,
                style: { textAlign: "center" },
                headerStyle: { width: "10%", textAlign: "center" }
            },
            {
                dataField: "descricao",
                text: "Descrição",
                sort: true,
                headerClasses: "alpha"
            }
        ]
    }

    tablerowupdate(form) {
        let data_updated = this.updateTableRow(this.state.data, this.state.activeIndex, form)

        let REF = this
        setTimeout(() => {
            REF.setState({ data: data_updated })
            REF.updateListing()
        }, 300)
    }

    updateTableRow(array, target_index, data) {
        return array.map((item, index) => {
            if (index == target_index) {
                // This isn't the item we care about - keep it as-is
                return Object.assign(item, data)
            } else {
                // Otherwise, this is the one we want - return an updated value
                return item
            }
        })
    }

    formUpdate(form_data_complete) {
        this.setState({ form: form_data_complete })
    }

    render() {
        return (
            <>
                <GrupoFornecedorModal
                    show={this.state.show}
                    form={this.state.form}
                    onHide={this.hideModal}
                    tablerowupdate={this.tablerowupdate}
                    formupdate={this.formUpdate}
                />

                {(() => this.tableRender())()}
            </>
        )
    }
}

export default GrupoFornecedoresDataTable
