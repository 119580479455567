import React from 'react';
import axios from 'axios';
import {requestException} from '../util/exception';
import {CFG, axiosHeader} from '../CFG';
import LoadingModal from '../util/LoadingModal';

class EmpresaSelect extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            empresa: this.props.empresa,
            list: [['', 'Carregando opções']],
            cnpj: this.props.cnpj,
            grupo: this.props.grupo,
            validator: {}
        };
        this.get = this.get.bind(this);
       
    }

    componentDidMount(){
        this.get();
    }

    onClick = (event) => {
        if(typeof this.props.onClick == "function")
        this.props.onClick(event);
        
    }

    render(){
        let REF = this;
        return (
            <>
            {
                this.state.list.map(function(kp){
                                        
                    if(window.location.pathname.replace(/\//g, '') != 'login' && kp[0] == REF.props.empresa)
                    return (<></>);

                return (
                    <div onClick={REF.onClick} className="empresa panel panel-success" key={kp[0]} data-razao={kp[1]} data-key={kp[0]} data-grupo={REF.state.grupo} data-cnpj={REF.state.cnpj}>
                    <div className="panel-body"><span className="badge badge-primary">{kp[0]}</span> {kp[1]}
                        <i className="icon-arrow-right float-right"></i>
                        </div>
                </div>
                )
            })
            }
            </>
        )
    }

    get(){
        
        let REF = this;

        REF.setState({ loading:true });

        return axios.get(CFG.URL_API+'/empresa/'+this.state.empresa+'/grupo-empresas', axiosHeader())
        .then(function(response){

            let entries = Object.entries(response.data);

            let htmlList = entries.sort(function(a,b){
                return (a[1] > b[1]) ? 1  : -1;
            });

            REF.setState({ list: htmlList });

           REF.setState({ loading:false });

          //Perform action based on response
      })
        .catch(function(error){
      requestException(error);
 });
    }
}

export default EmpresaSelect;
