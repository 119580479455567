import React from 'react';
import axios from 'axios';
import CFG from '../CFG';

export function e(){
    return null;
}

export function empresa_lookup(id){
    return axios.get(CFG.URL_API+'/empresa/'+id);
}

export function responsavel_lookup(empresa, cpf){
    return axios.get(CFG.URL_API+'/responsavel?empresa='+empresa+'&cpf='+cpf);
}

export function usuario_lookup(id){
    return axios.get(CFG.URL_API+'/usuario/'+id);
}

export function cpf_lookup(input){
    return axios.get(CFG.URL_API+'/responsavel/check?&cpf='+input.value+'&id='+input.getAttribute('data-id'));
    }
   
    export function cnpj_lookup(){
     return axios.get(CFG.URL_API+'/empresa?selectMode=pluck&pluckKey=id&pluckValue=nome&cnpj='+encodeURIComponent(this.state.cnpj))
      .then(function(response){
        return response.data;
        //Perform action based on response
      })
      .catch(function(error){
        //Perform action based on error
      });
     }

export default e;