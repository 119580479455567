import React from 'react';
import '../Modal.scss';
import {Modal, Button} from 'react-bootstrap';
import SubgrupoProdutoForm from './SubgrupoProdutoForm';

class SubgrupoProdutoModal extends React.Component{

    constructor(props) {
      super(props);

      this.state = { 
        categoria: '',  
        data: {},
        form: {},
        show: false
      };

      this.modalControl = this.modalControl.bind(this);
      this.formSubmit = this.formSubmit.bind(this);
      this.formDelete = this.formDelete.bind(this);

  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.show != this.props.show){
      this.setState({ data: this.props.form, show: this.props.show });
    }
  }

  modalControl(form_comp){
    this.setState({ form: form_comp });
  }

  render () {
    return (
      <Modal
      onShow={this.updateForm}      
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title id="contained-modal-title">
            Subgrupo de Produto 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>        
          <SubgrupoProdutoForm
            show={this.props.show}
            form={this.state.data}
            modalcontrol={this.modalControl}
          />       
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger btn-left" onClick={this.formDelete}>Excluir</Button>
          <Button onClick={this.props.onHide}>Fechar</Button>
          <Button onClick={this.formSubmit}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  formSubmit() {

    if(!this.state.form.isValid())
    return false;

    if(typeof this.props.tablerowupdate === "function")
    this.props.tablerowupdate(this.state.form);

    if(this.state.form.state.id)
     this.state.form.put();
    else
     this.state.form.post();

    this.props.onHide();  
  }

  formDelete() {

    if(typeof this.props.tablerowupdate === "function")
    this.props.tablerowupdate(this.state.form);

    let c = window.confirm("Deseja mesmo remover o grupo "+this.state.categoria+" ?");
    if (c == true) {
      if(this.state.form.state.id)
      this.state.form.delete();
    }

    this.props.onHide();  
  }
  
}

export default SubgrupoProdutoModal;